import PropTypes from 'prop-types';
import React from 'react';
import { UptimeStatusChart, UptimeHistoryChart, Card, Loading } from '../../components';
// import { UptimeFilters } from '../../containers';
// import { fetchDashboard } from '../../actions/dashboards';
// import { connect } from 'react-redux';
// import find from 'lodash/find';
// import { withAccountId } from '../../containers/AccountContainer';

class DashboardUptime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <article className="dashboard">
                <Loading loading={this.props.isLoading} />

                <Card>
                    <div className="inline-items">
                        <UptimeStatusChart />
                        <UptimeHistoryChart />
                    </div>
                </Card>
                {/* <UptimeFilters
                    accountId={this.props.accountId}
                    filters={this.props.filters}
                /> */}
            </article>
        );
    }
}

DashboardUptime.propTypes = {
    // accountId: PropTypes.string.isRequired,
    // fetchDashboard: PropTypes.func,
    // charts: PropTypes.array,
    isLoading: PropTypes.bool,
    filters: PropTypes.array,
};


export default DashboardUptime;
