import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CopyToClipBoard, Button } from '../components';
import { validateHttp } from '../modules/domainValidation';

const HttpValidation = (props) => {
    const sendValidation = (e) => {
        e.preventDefault();
        props.validateHttp(props.domain.id);
    };

    return (
        <article>
            <h2>Validação por Hash</h2>

            <p>Você terá que adicionar o código abaixo antes da tag <strong>{'</head>'}</strong> HTML.</p>

            <p>Ele não alterará aspectos visuais ou funcionais da sua aplicação e uma vez <br />
            validado o certificado ele pode ser removido sem problemas.</p>

            <CopyToClipBoard>
                {`<meta name="sb.validation_hash" content="${props.domain.validation_code}" />`}
            </CopyToClipBoard>

            <p>Depois de adiciona-lo clique no botão abaixo</p>

            <div className="space-button">
                <Button onClick={sendValidation} loading={props.validating}>
                    Validar Domínio via Hash
                </Button>
            </div>
        </article>
    );
};

HttpValidation.propTypes = {
    domain: PropTypes.object,
    validating: PropTypes.bool,
    validateHttp: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        domain: state.domainValidation.domain,
        validating: state.domainValidation.validating,
    };
}

export default connect(mapStateToProps, { validateHttp })(HttpValidation);
