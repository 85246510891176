import PropTypes from 'prop-types';
import React from 'react';
import { Content } from '../../components';

export class IndexSerasa extends React.Component {
    constructor(props) {
        super(props);

        this.state = { title: 'Loja' };
        this.setTitle = this.setTitle.bind(this);
    }

    setTitle(title) {
        this.setState({ title });
        this.props.setTitle(title);
    }

    render() {
        return (
            <div>
                <Content title={this.state.title} {...this.props}>
                    {this.props.children && React.cloneElement(this.props.children, {
                        setTitle: this.setTitle,
                    })}
                </Content>
            </div>
        );
    }
}

IndexSerasa.propTypes = {
    children: PropTypes.element.isRequired,
    setTitle: PropTypes.func,
};
