import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { TextField, Button } from '../../components'
import { toFormErrors } from '../../services/utils'
import { sendInvite } from '../../actions/organizations'

class InviteUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = { errors: {} }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(data) {
    return this.props.sendInvite(this.props.organizationId, data.email)
      .then((res) => {
        this.setState({ errors: toFormErrors(res.errors) })
      })
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="form inline"
      >
        <Field
          component={TextField}
          name="email"
          placeholder="Digite um Email"
          error={this.state.errors.email || ''}
          floatMessage
        />

        <Button loading={this.props.submitting}>
          Convidar Usuário
        </Button>
      </form>
    )
  }
}

InviteUser.propTypes = {
  sendInvite: PropTypes.func,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  errors: PropTypes.object,
  organizationId: PropTypes.string,
  submitting: PropTypes.bool,
}

const Form = reduxForm({})(InviteUser)

const mapStateToProps = ({ form }, props) => ({
  form: `formInvite${props.formName}`,
})

export default connect(mapStateToProps, { sendInvite })(Form)
