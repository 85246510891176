import { css } from 'styled-components';

import { $lightgrey600 } from '../settings/variables';

export default css`
    .users-box {
        margin-top: 3rem;
    }

    .users-box > label {
        width: 35rem;
    }

    .users-box > label > input {
        width: 100%;
    }

    .users-box .field-group {
        margin-bottom: 1rem;
    }

    .users-list {
        border: .1rem solid ${$lightgrey600};
        padding: 0 1rem;
        width: 35rem;
        margin-bottom: 1rem;
    }

    .users-list > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
    }

    .users-list > li > a {
        display: flex;
    }

    .users-list > li i {
        cursor: pointer;
        font-size: 2rem;
    }
`;
