import { css } from 'styled-components';

export default css`
    .app, .app-outside {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        flex: 1;
        background-color: #eee;

    }

    .app-outside {
        justify-content: center;
    }
`;
