import { FETCH, ADD, CLEAR, FETCH_DEVICES } from '../actions/domains';
import { LOGOUT_USER } from '../actions/auth';

const INITIAL_STATE = {
    isLoading: false,
    errors: [],
    results: [],
    page: 1,
    count: 0,
};

export const devices = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${FETCH_DEVICES}_PENDING`:
            return { ...state, isLoading: true };
        case `${FETCH_DEVICES}_FULFILLED`:
            return { ...state, ...action.payload, isLoading: false };
        case LOGOUT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const domains = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${FETCH}_PENDING`:
            return { ...state, isLoading: true };
        case `${FETCH}_FULFILLED`:
            return { ...state, ...action.payload, isLoading: false };
        case LOGOUT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const domain = (state = {}, action) => {
    switch (action.type) {
        case `${ADD}_PENDING`:
            return { ...state, isLoading: true };
        case `${ADD}_FULFILLED`:
            return { ...state, ...action.payload, isLoading: false };
        case CLEAR:
            return INITIAL_STATE;

        default:
            return state;
    }
};
