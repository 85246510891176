import { Business, Lock, Person } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { IconCircle } from '../../components'

export const Details = (props) => (
  <section className="content details">
    <article>
      <div className="item">
        <div className="details-title">
          <IconCircle color="#74B61B">
            <Lock />
          </IconCircle>
          <span>
            Dados do Certificado
          </span>
        </div>
      </div>
    </article>

    <div className="flex">
      <p><strong>Pedido</strong><br />
        {props.order}
      </p>
      <p><strong>Produto</strong><br />
        {props.product}
      </p>
      <p><strong>Status</strong><br />
        {props.status}
      </p>
    </div>

    <div className="flex">
      <p><strong>Tamanho da Chave</strong><br />
        {`${props.key_size} bits`}
      </p>
      <p><strong>Tipo do Servidor</strong><br />
        {props.server || '-'}
      </p>
    </div>

    <div className="flex multi-domain">
      <p>
        <strong>Nome do Domínio</strong><br />

        {props.domains &&
          props.domains.map((item, key) =>
            <span className="multi-domain-item" key={key}>{item.domain}</span>
          )
        }
      </p>
    </div>

    <div className="flex">
      <p><strong>Emitido por</strong><br />
        {props.user || '-'}
      </p>
    </div>

    <article>
      <div className="item">
        <div className="details-title">
          <IconCircle color="#50AFE4">
            <Business />
          </IconCircle>

          <span>
            Dados da Organização
          </span>
        </div>
      </div>
    </article>

    <div className="flex">
      <p><strong>Nome</strong><br />
        {props.organization_name || '-'}
      </p>
      <p><strong>CNPJ</strong><br />
        {props.organization_registration || '-'}
      </p>
      <p><strong>CEP</strong><br />
        {props.organization_postalcode || '-'}
      </p>
    </div>

    <div className="flex">
      <p><strong>Complemento</strong><br />
        {props.organization_complement || '-'}
      </p>
      <p><strong>Endereço</strong><br />
        {props.organization_street || '-'}
        {props.organization_number || ''}
      </p>
      <p><strong>Bairro</strong><br />
        {props.organization_district || '-'}
      </p>
    </div>

    <div className="flex">
      <p><strong>País</strong><br />
        {props.organization_country || '-'}
      </p>
      <p><strong>Cidade</strong><br />
        {props.organization_city || '-'}
      </p>
      <p><strong>Estado</strong><br />
        {props.organization_state || '-'}
      </p>
    </div>

    <article>
      <div className="item">
        <div className="details-title">
          <IconCircle color="#244272">
            <Person />
          </IconCircle>

          <span>
            Dados do Contato
          </span>
        </div>
      </div>
    </article>

    <div className="flex">
      <p><strong>Nome</strong><br />
        {props.contact_title && `${props.contact_title} `}
        {props.contact_forename || '-'}
      </p>
      <p><strong>Sobrenome</strong><br />
        {props.contact_surname || '-'}
      </p>
    </div>

    <div className="flex">
      <p><strong>Email</strong><br />
        {props.contact_email || '-'}
      </p>
      <p><strong>Telefone</strong><br />
        {props.contact_phone || '-'}
      </p>
    </div>
  </section>
)

Details.propTypes = {
  order: PropTypes.number,
  product: PropTypes.string,
  status: PropTypes.string,
  server: PropTypes.string,
  key_size: PropTypes.number,
  domains: PropTypes.array,
  user: PropTypes.string,
  organization_name: PropTypes.string,
  organization_postalcode: PropTypes.string,
  organization_street: PropTypes.string,
  organization_complement: PropTypes.string,
  organization_number: PropTypes.string,
  organization_district: PropTypes.string,
  organization_country: PropTypes.string,
  organization_city: PropTypes.string,
  organization_state: PropTypes.string,
  organization_registration: PropTypes.string,
  contact_title: PropTypes.string,
  contact_forename: PropTypes.string,
  contact_surname: PropTypes.string,
  contact_email: PropTypes.string,
  contact_phone: PropTypes.string,
}
