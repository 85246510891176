export const NEXT_STEP = 'wizard/NEXT_STEP';
export const PREV_STEP = 'wizard/PREV_STEP';
export const RESET = 'wizard/RESET';
export const GO_TO_STEP = 'wizard/GO_TO_STEP';

export const nextStep = () => ({ type: NEXT_STEP });

export const prevStep = () => ({ type: PREV_STEP });

export const reset = () => ({ type: RESET });

export const goToStep = (step) => ({
    type: GO_TO_STEP,
    payload: { step },
});
