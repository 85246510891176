import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Code} from "../Code";

export class Evidences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.getTypes = this
            .getTypes
            .bind(this);
    }

    getTypes(evidence) {
        const types = {
            code: (item) => (
                <tr key={item.type}>
                    <td className="title">{item.name}</td>
                    <td>
                        <Code>{item.value}</Code>
                    </td>
                </tr>
            ),
            href: (item) => (
                <tr key={item.type}>

                    <td colSpan="2">
                        <Link href={item.href} className="link" target="_blank">
                            {item.name}
                        </Link>
                    </td>
                </tr>
            ),
            text: (item) => (
                <tr key={item.type}>
                    <td className="title">{item.name}</td>
                    <td>
                        <Code>{item.value}</Code>
                    </td>
                </tr>
            ),
        };

        const data = Array.from(evidence.data);
        const array2 = data.filter(element => element.format !== 'href');
        return (array2.map(item => types[item.format](item)));
    }

    render() {
        return (
            <table className="table-info">
                <tbody>
                    {this.getTypes(this.props.evidence, 'this.props.falsePositive')}
                    {/* {this.evidence.data.map(item => this.getTypes[item.format](item))} */}
                </tbody>
            </table>
        );
    }
}

Evidences.propTypes = {
    // falsePositive: PropTypes.func.isRequired,
    evidence: PropTypes.object,
    // solicit: PropTypes.func, isLoading: PropTypes.bool,
};
