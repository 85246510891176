import { css } from 'styled-components';

import {
    $borderRadiusBase,
    $darkgrey600,
    $fontSizeLarge,
    $fontSizeSmall,
    $lightgrey400,
    $shadowBase,
    spaceBase,
} from '../settings/variables';

export default css`
    .dropdown-trigger {
        cursor: pointer;
    }

    .dropdown {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .dropdown .dropdown-list {
        position: absolute;
        display: none;
        min-width: 20rem;
        background-color: ${$lightgrey400};
        border-radius: ${$borderRadiusBase};
        z-index: 2000;
        transition: all .1s ease-out;
        box-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.3);
        border-radius: .4rem;

        box-shadow: ${$shadowBase};
        top: 4.5rem;
        font-size: ${$fontSizeSmall};
    }

    .dropdown .dropdown-list:before {
        content: '';
        display: block;
        background-color: transparent;
        width: 0;
        height: 0;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1.2rem solid ${$darkgrey600};
        position: absolute;
        top: -1.2rem;
    }

    .dropdown .dropdown-list:after {
        content: '';
        display: block;
        background-color: transparent;
        width: 0;
        height: 0;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1.2rem solid ${$lightgrey400};
        position: absolute;
        top: -1.2rem;
    }

    .dropdown .info-area .email {
        font-size: 1.2rem;
    }

    .dropdown .list {
        width: 100%;
    }

    .dropdown .list i {
        margin-right: ${spaceBase / 2}rem;
        font-size: ${$fontSizeLarge};
    }

    .dropdown .list a {
        display: flex;
        align-items: center;
        color: ${$darkgrey600};
        padding: 1.2rem;
        cursor: pointer;
    }

    .dropdown .list a:hover {
        transition: all .5s ease-out;
        background-color: #21272e;
        color: #fff;
    }

    .dropdown .list li:first-child a:hover {
        border-radius: .4rem .4rem 0 0;
    }

    .dropdown .list li:last-child a:hover {
        border-radius: 0 0 .4rem .4rem;
    }

    .dropdown-left .dropdown-list {
        left: 0;
    }

    .dropdown-left .dropdown-list:before,
    .dropdown-left .dropdown-list:after {
        left: .5rem;
    }

    .dropdown-right .dropdown-list {
        right: 0;
    }

    .dropdown-right .dropdown-list:before,
    .dropdown-right .dropdown-list:after {
        right: .5rem;
    }
`;
