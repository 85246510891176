import { FETCH_UPTIME } from '../actions/uptime';
import { LOGOUT_USER } from '../actions/auth';
import normalizedState from '../services/normalize';

const INITIAL_LIST = {
    results: {},
    allIds: [],
    isLoading: false,
    count: 0,
    page: 0,
};

export const uptime = (state = INITIAL_LIST, action) => {
    switch (action.type) {
        case `${FETCH_UPTIME}_PENDING`:
            return { ...state, isLoading: true };
        case `${FETCH_UPTIME}_FULFILLED`: {
            const { results, allIds } = normalizedState(action.payload.results);
            return { ...state, ...action.payload, results, allIds, isLoading: false };
        }
        case LOGOUT_USER:
            return INITIAL_LIST;
        default:
            return state;
    }
};
