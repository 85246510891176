import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@material-ui/core/styles'
import {ErrorOutline} from '@material-ui/icons'
import {Card} from '../components'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    padding: 20,
    flexDirection: 'column !important',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '4rem !important',
    textTransform: 'uppercase',
    fontWeight: 'bold !important',
    color: theme.palette.success.main,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: '2.5rem',
    textAlign: 'center'
    // color: theme.palette.success.main,
  },
  icon: {
    fontSize: '4rem',
    margin: 15,
    color: theme.palette.success.main,
  }
}))

export const BlockedPage = props => {
  const classes = useStyles()

  return (
    <article className="page">
      <div className="page-content">
        <div className="content">
          <Card>
            <Grid className={classes.container}>
              <Typography className={classes.title}>
                <ErrorOutline className={classes.icon}/>

                Ops...
              </Typography>

              <Typography className={classes.text}>
                Você não tem acesso a página requisitada.<br/>
                Verifique se a URL está correta ou utilize o menu lateral para lhe auxiliar.
              </Typography>
            </Grid>
          </Card>
        </div>
      </div>
    </article>
    // <Grid className={classes.container}>


    // </Grid>
  )
}