import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '../Card';

export const VulnerabilityDigest = ({ children, title, description }) => (
    <Card className="family">
        <div className="cardheader">
            <div className="cardheader-name">{title}</div>
            <div className="cardheader-description">
                <span>{description}</span>
            </div>
        </div>

        {children}

    </Card>
);

VulnerabilityDigest.propTypes = ({
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.any,
});
