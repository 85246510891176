import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

export const SelectField = ({ label, options, input, selected, meta, error, emissao }) => {
  const { error: metaError, touched } = meta

  const selectClass = classnames('combo', {
    'input-error': (!!error || !!metaError) && touched,
  })

  return (
    <div className="field">
      {label &&
        <label>{label}</label>
      }

      <select {...input} className={selectClass} value={selected}>
        <option value=''>
          {emissao === "" ? 'Primeiro selecione um Emissor' : 'Selecione uma opção'}
        </option>

        {options.map((item, index) =>
          <option
            key={index}
            value={item.id}
          >
            {item.description}
          </option>
        )}
      </select>

      {!!error &&
        <span className="input-error">{error}<br /></span>
      }

      {!!metaError && touched &&
        <span className="input-error">{metaError}</span>
      }
    </div>
  )
}

SelectField.propTypes = {
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  selected: PropTypes.string,
  input: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}
