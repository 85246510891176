import PropTypes from 'prop-types';
import React from 'react';

export const TableWrapRight = ({ children }) => (
    <div className="items">
        {children}
    </div>
);

TableWrapRight.defaultProps = {
    name: 'right',
};

TableWrapRight.propTypes = {
    children: PropTypes.any,
};
