// import { List, Map } from 'immutable';
import { ADD, FETCH } from '../actions/notifications';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD:
            return [...state, action.payload];

        case `${FETCH}_PENDING`:
            return [...state, action.payload];

        case `${FETCH}_FULFILLED`:
            return [...state, action.payload];

        default:
            return state;
    }
};
