import { environment } from '../../environments/environment'
import store from '../store'
import { refreshToken, logout } from '../actions/auth'
import localStore from 'store'

// require('es6-promise').polyfill()
// require('isomorphic-fetch')

const checkStatus = (response) => {
  if ((response.status >= 100 && response.status < 300)
    || response.status === 403
    || response.status === 404
    || response.status === 401
  ) {
    return response
  }
  
  const json = response.json()

  return json.then(Promise.reject.bind(Promise))
}

async function sbFetch(url, options = {}, retries = 3) {
  const defaultOptions = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  const token = options.token || localStore.get('token')

  const checkOptions = () => {
    if (options.headers) {
      return { ...options }
    }

    return {
      ...options,
      ...defaultOptions,
    }
  }

  const fetchOption = checkOptions()

  if (token) {
    defaultOptions.headers.Authorization = `Bearer ${token}`
  }

  const fetchUrl = `${process.env.REACT_APP_API_URL}${environment.API_VERSION}${url}`


  let response = await fetch(fetchUrl, fetchOption)

  if (response.status === 401 && url === '/token') {
    store.dispatch(logout())

    return checkStatus(response)
  }

  if (response.status === 401) {
    const refresh = await store.dispatch(refreshToken())

    fetchOption.headers.Authorization = `Bearer ${refresh.access_token}`
    response = await fetch(fetchUrl, fetchOption)
  }

  // if (response.status === 403) {
  //   store.dispatch(logout())
  //   return checkStatus(response)
  // }

  if (response.status === 504 && retries > 0) {
    return sbFetch(url, options, retries - 1)
  }

  return await checkStatus(response)
}

export { sbFetch as fetch }
