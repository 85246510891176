import PropTypes from 'prop-types';
import React from 'react';
import { Loading, Button } from '../../components';

export class ChangeOwnership extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            isLoading: false,
        };

        this.handleClick = this.handleClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onCancel() {
        this.props.closeSlider();

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    onSubmit() {
        this.setState({
            isChecked: false,
            isLoading: true,
        });

        this.props.changeOwnership()
        .then(() => this.setState({ isLoading: false }))
        .then(this.props.closeSlider());
    }

    handleClick() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        return (
            <article className="slide-confirmation">
                <Loading loading={this.state.isLoading} />

                <p>
                    <strong>Cuidado!</strong> A organização será <strong>transferida</strong> e
                    <strong> não há como desfazer</strong> esta ação. Ao ser transferida, todos os privilégios
                    desta organização serão repassados para o novo proprietário.
                </p>

                <label className="checkbox">
                    <input
                        type="checkbox"
                        name="remove_organization"
                        onClick={this.handleClick}
                        checked={this.state.isChecked || false}
                    />
                    <span>Sim, desejo realmente trocar o proprietário desta organização.</span>
                </label>

                <div className="buttons space-button">
                    <Button
                        type="submit"
                        disabled={!this.state.isChecked}
                        onClick={this.onSubmit}
                    >
                        Transferir
                    </Button>

                    <Button
                        pure
                        onClick={this.onCancel}
                    >
                        Cancelar
                    </Button>
                </div>
            </article>
        );
    }
}

ChangeOwnership.propTypes = {
    fullName: PropTypes.string,
    changeOwnership: PropTypes.func,
    onCancel: PropTypes.func,
    closeSlider: PropTypes.func,
};
