import { css } from 'styled-components';

import { $borderRadiusBase, $darkgrey700 } from '../settings/variables';

export default css`
    .menu-page {
        background-color: #000;
        padding: 1rem 1.5rem;
        display: flex;
    }

    .menu-page > .item {
        padding: 0.7rem 1rem;
        border-radius: ${$borderRadiusBase};
        margin-right: 3rem;
        cursor: pointer;
        /* color: sub-nav-fontColor; */
        transition: all .3s ease-out;
    }

    .menu-page > .item:hover {
        background-color: ${$darkgrey700};
        color: #fff;
    }
`;
