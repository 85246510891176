import { css } from 'styled-components';

export default css`
    /* TODO - Diferenciar a classe dashboard do item do menu, para evitar conflito */
    .content > .dashboard {
        position: relative;
        min-height: 30rem;
    }

    .content > .dashboard .recharts-pie .recharts-sector {
        stroke: none;
    }
`;
