import React from 'react';

export const AboutValidation = () => (
    <article className="about-validation">
        <p>Ao validar seu domínio junto a Site Blindado, você poderá:</p>
        <ul>
            <li>Rodar scans períodicos em busca de vulnerabilidades</li>
            <li>Utilizar o selo SSL Blindado para certificados emitidos com a Site Blindado</li>
            <li>Receber emails de alertas sobre a expiração do certificado de seus domínios</li>
            <li>Receber emails de alertas sobre problemas de instalação de seu certificado</li>
        </ul>
    </article>
);
