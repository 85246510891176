import { css } from 'styled-components';

// Colors
export const $black = '#000000';

// Grey
export const $lightgrey300 = '#f7f7f7';
export const $lightgrey400 = '#f3f3f3';
export const $lightgrey500 = '#eeeeee';
export const $lightgrey600 = '#e3e3e3';
export const $lightgrey700 = '#dddddd';
export const $lightgrey800 = '#cccccc';

export const $darkgrey200 = '#bbbbbb';
export const $darkgrey500 = '#888888';
export const $darkgrey600 = '#666666';
export const $darkgrey700 = '#2D343D';
export const $darkgrey800 = '#333333';

export const $bluegray600 = '#2A2D32';

// Green
export const $green100 = '#b1e9a5';
export const $green300 = '#74b61b';
export const $green400 = '#66aa55';
export const $green500 = '#519548';
export const $green700 = '#357623';
export const $greenSb = '#74B537';

// Yellow
export const $yellow400 = '#fee549';
export const $yellow600 = '#fdd32e';

// Golden
export const $golden500 = '#e9c23a';

// Red
export const $lightred300 = '#FD4D4D';
export const $lightred400 = '#f73e3e';
export const $lightred500 = '#E32828';

// Blue
export const $blue400 = '#244272';
export const $light$blue400 = '#50afe4';

// Petroleum
export const $petroleum400 = '#22797d';

// Bluegrey
export const $bluegrey200 = '#6F767D';
export const $bluegrey800 = '#13161C';

// Oramge
export const $orange500 = '#ed6113';

/* Levels: tom de verde pra apontamento, tom de vermelho pra vulnerabilidade */
export const $level5 = '#fd4d4d';
export const $level4 = '#ff807e';
export const $level3 = '#ffa8a8';
export const $level2 = '#b1de80';
export const $level1 = '#74c676';
export const $level0 = '#bbbbbb';
/**/

/* Levels: teste de verde-amarelo-vermelho *
export const $level5 = '#fd4d4d';
export const $level4 = '#ffaf45';
export const $level3 = '#f7ee3e';
export const $level2 = '#b1de80';
export const $level1 = '#74c676';
export const $level0 = '#bbbbbb';
/**/

/* Levels: original *
export const $level5 = '#fd4d4d';
export const $level4 = '#f2ac5b';
export const $level3 = '#fee549';
export const $level2 = '#7dc671';
export const $level1 = '#65c4ed';
export const $level0 = '#bbbbbb';
/**/

// Fonts
export const $fontFamilySansSerif = 'Lato, sans-serif';
const fontSizeBase = 1.4;
export const $fontSizeBase = `${fontSizeBase}rem`; // usado como base para o calculo dos outros fonts sizes
export const $fontSizeLargest = `${Math.ceil(fontSizeBase * 15) / 10}`; // 21px
export const $fontSizeLarger = `${Math.ceil(fontSizeBase * 12.5) / 10}rem`; // 18px
export const $fontSizeLarge = `${Math.ceil(fontSizeBase * 11) / 10}rem`; // 17px
export const $fontSizeSmall = `${Math.ceil(fontSizeBase * 9) / 10}rem`; // 13px
export const fontSizeSmaller = Math.ceil(fontSizeBase * 8) / 10; // 12px
export const $fontSizeSmaller = `${Math.ceil(fontSizeBase * 8) / 10}rem`; // 12px
export const $fontSizeTiny = `${Math.floor(fontSizeBase * 7) / 10}rem`; // 10px

// Body
export const $bodyBgColor = $lightgrey500;
export const $bodyFontColor = $darkgrey700;
export const lightgrey = $lightgrey500;

// Spaces padding and margins
export const spaceBase = 1.5;
export const $spaceBase = '1.5rem';

// Shadow
export const $shadowBase = '0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24)';
export const $shadowButton = '0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24)';
export const $shadowButtonPressed = '0px 0px 2px 0px rgba(0,0,0,0.12), 0px 1px 2px 2px rgba(0,0,0,0.24)';
export const $shadowTooltip = '0px 0px 0px 2px rgba(0,0,0,0.12), 0px 0px 0px 0px rgba(0,0,0,0.24)';

// Effect
export const borderRadiusBase = 0.25;
export const $borderRadiusBase = '.25rem';
export const $fontColorDisabled = $darkgrey600;
export const $linkColor = $green700;
export const $linkColorHover = $greenSb; // '#00cb00';
export const $btnColorHover = $bluegrey800;
export const $btnSecondaryColor = $bluegrey800;

export const $safeAreaH = '26w';

export default css`
    // Colors
$black = '#000000';

// Grey
$lightgrey300 = '#f7f7f7';
$lightgrey400 = '#f3f3f3';
$lightgrey500 = '#eeeeee';
$lightgrey600 = '#e3e3e3';
$lightgrey700 = '#dddddd';
$lightgrey800 = '#cccccc';

$darkgrey200 = '#bbbbbb';
$darkgrey500 = '#888888';
$darkgrey600 = '#666666';
$darkgrey700 = '#2D343D';
$darkgrey800 = '#333333';

$bluegray600 = '#2A2D32';

// Green
$green100 = '#b1e9a5';
$green300 = '#74b61b';
$green400 = '#66aa55';
$green500 = '#519548';
$green700 = '#357623';
$greenSb = '#74B537';

// Yellow
$yellow400 = '#fee549';
$yellow600 = '#fdd32e';

// Golden
$golden500 = '#e9c23a';

// Red
$lightred300 = '#FD4D4D';
$lightred400 = '#f73e3e';
$lightred500 = '#E32828';

// Blue
$blue400 = '#244272';
$light$blue400 = '#50afe4';

// Petroleum
$petroleum400 = '#22797d';

// Bluegrey
$bluegrey200 = '#6F767D';
$bluegrey800 = '#13161C';

// Oramge
$orange500 = '#ed6113';

/* Levels: tom de verde pra apontamento, tom de vermelho pra vulnerabilidade */
$level5 = '#fd4d4d';
$level4 = '#ff807e';
$level3 = '#ffa8a8';
$level2 = '#b1de80';
$level1 = '#74c676';
$level0 = '#bbbbbb';
/**/

/* Levels: teste de verde-amarelo-vermelho *
$level5 = '#fd4d4d';
$level4 = '#ffaf45';
$level3 = '#f7ee3e';
$level2 = '#b1de80';
$level1 = '#74c676';
$level0 = '#bbbbbb';
/**/

/* Levels: original *
$level5 = '#fd4d4d';
$level4 = '#f2ac5b';
$level3 = '#fee549';
$level2 = '#7dc671';
$level1 = '#65c4ed';
$level0 = '#bbbbbb';
/**/

// Fonts
$fontFamilySansSerif = 'Lato, sans-serif';
const fontSizeBase = 1.4;
$fontSizeBase = \`${fontSizeBase}rem\`; // usado como base para o calculo dos outros fonts sizes
$fontSizeLargest = \`${Math.ceil(fontSizeBase * 15) / 10}\`; // 21px
$fontSizeLarger = \`${Math.ceil(fontSizeBase * 12.5) / 10}rem\`; // 18px
$fontSizeLarge = \`${Math.ceil(fontSizeBase * 11) / 10}rem\`; // 17px
$fontSizeSmall = \`${Math.ceil(fontSizeBase * 9) / 10}rem\`; // 13px
fontSizeSmaller = Math.ceil(fontSizeBase * 8) / 10; // 12px
$fontSizeSmaller = \`${Math.ceil(fontSizeBase * 8) / 10}rem\`; // 12px
$fontSizeTiny = \`${Math.floor(fontSizeBase * 7) / 10}rem\`; // 10px

// Body
$bodyBgColor = $lightgrey500;
$bodyFontColor = $darkgrey700;

// Spaces padding and margins
spaceBase = 1.5;
$spaceBase = '1.5rem';

// Shadow
$shadowBase = '0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24)';
$shadowButton = '0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24)';
$shadowButtonPressed = '0px 0px 2px 0px rgba(0,0,0,0.12), 0px 1px 2px 2px rgba(0,0,0,0.24)';
$shadowTooltip = '0px 0px 0px 2px rgba(0,0,0,0.12), 0px 0px 0px 0px rgba(0,0,0,0.24)';

// Effect
borderRadiusBase = 0.25;
$borderRadiusBase = '.25rem';
$fontColorDisabled = $darkgrey600;
$linkColor = $green700;
$linkColorHover = $greenSb; // '#00cb00';
$btnColorHover = $bluegrey800;
$btnSecondaryColor = $bluegrey800;

$safeAreaH = '26w';
`;
