import { css } from 'styled-components';

export default css`
    .alert-card > .title {
        display: inline-flex;
        width: 100%;
        padding: 1rem;
        align-items: center;
    }

    .alert-card > .title .error {
        background: #f73e3e;
        color: white;
    }

    .alert-card > .title .warning {
        background-color: #FBE459;
    }

    .alert-card > .title > h2 {
        margin: 0 0 0 1rem;
        align-self: center;
        font-weight: bold;
    }

    .alert-card > .text {
        padding: 2rem;
    }
`;
