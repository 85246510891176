import { css } from 'styled-components';

import { $darkgrey600, spaceBase, $lightgrey600, $borderRadiusBase } from '../settings/variables';

export default css`
    .form, .form-translate {
        max-width: 50rem;
    }

    .form p {
        margin-bottom: 1.5rem;
    }

    .form li {
        margin-bottom: 0.7rem;
    }

    .form label {
        display: block;
        color: ${$darkgrey600};
        margin-bottom: .5rem;
        text-align: left;
        font-weight: bold;
    }

    .form select,
    .form input,
    .form textarea {
        width: 100%;
        margin: 0;
    }

    .form.form-default {
        box-shadow: 0 .2rem .2rem rgba(0, 0, 0, 0.2);
    }

    .form .field {
        margin-bottom: 2rem;
    }

    .form .checkbox {
        margin-right: 1rem;
    }

    .form .field-radio {
        margin-bottom: 1rem;
    }

    .form .field-radio.vertical > .radio {
        display: block;
        display: block !important;
        margin-bottom: 1rem;
    }

    .form .field-title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .form .field-address .cep-loading {
        left: initial;
        right: 2rem;
        bottom: 2.5rem;
    }

    .form .buttons {
        margin-top: 2rem;
    }

    .form .buttons > * {
        margin-right: 3rem;
    }

    .form.inline {
        display: inline-flex;
        align-items: center;
    }

    .form.inline .field {
        position: relative;
        margin-bottom: 0;
        margin-right: 2rem;
        width: 30rem;
    }

    .content h2:first-child {
        margin-top: 0px;
    }

    .form-new {
        max-width: 50rem;
    }

    .combo-http {
        font-size: 1.2rem;
        padding: ${spaceBase / 2}rem;
        background-color: #fff;
        border: .1rem solid ${$lightgrey600};
        box-shadow: none;
        border-radius: ${$borderRadiusBase};
        height: 3.5rem;
        outline: none;
        line-height: normal !important;
        transition: all .2s ease-in-out;

        position: absolute;
        width: 8rem !important;
    }

    .combo-http + .field > input {
        margin-left: 8.5rem;
        width: calc(100% - 8.5rem)
    }

    .form-translate {
        max-width: initial;
    }

    .form-translate .translate-field {
        position: relative;
        display: flex;
        align-items: center;
    }

    .form-translate .translate-button {
        margin: 0 2rem;
    }

    .form-translate .translate-button a {
        color: #5084F1;
    }

    .form-translate .field {
        flex: 1;
        max-width: 50%;
    }

    /* .form-translate .field:not(:last-child) {
        margin-right: 2rem;
        max-width: calc(50% - 2rem);
    } */

    .form-translate .DraftEditor-editorContainer {
        height: 30rem
    }

    .radio-form {
        display: flex;
        justify-content: flex-start;
    }

    .radio-item {
        margin-right: 2em;
    }

    .input-armour {
        width: 2em;
    }
`;
