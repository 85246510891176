import { FETCH_MRU } from '../actions/account'
import { LOGOUT_USER } from '../actions/auth'

const INITIAL_STATE = []

const account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MRU:
      return action.payload

    case LOGOUT_USER:
      return INITIAL_STATE

    default:
      return state
  }
}

export default account;