import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Score, Loading, Card, AccordionOld, ContextMessage, Button } from '../../components'
import { getReport, runScan } from '../../actions/certificates'
import { IconSbBook } from '../../sb2w-icons/sb'
import { fetchDomain } from '../../actions/domains'
import { Build, ExpandLess, ExpandMore, WorkspacePremium } from '@mui/icons-material'
import { Grid } from '@mui/material'
import moment from 'moment'
import loadingSvg from '../../../assets/images/loading.svg';

const colors = {
  info: '#244272',
  error: '#f73e3e',
  success: '#66aa55',
  warning: '#ed6113',
}

export class FullReport extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      domain: undefined,
      singleIp: {},
    }
  }

  componentDidMount () {
    const id = new URLSearchParams(this.props.location.search).get("id")

    if (!this.props.isLoading) {
      this.props.fetchDomain(id)
    }

    this.setState({ domain: id })
    this.props.setTitle(this.props.title)
  }

  async handleClick (ip) {
    const { scanId } = this.props.match.params

    if (ip === this.state.open) {
      return this.setState({ open: false })
    }

    const report = await this.props.getReport(scanId, ip)

    this.setState({ singleIp: report.value })
    this.setState({ open: ip })
  }

  renderAccordionHeader (ip, grade) {
    return (
      <div onClick={() => this.handleClick(ip)}>
        {this.state.open === ip ? <ExpandLess /> : <ExpandMore />}

        <Score value={grade} />

        <strong>{ip}</strong>
      </div>
    )
  }

  renderContent (content) {
    return (
      content?.map(topic =>
        <div className="report-content" key={topic.title}>
          <div className="title">
            <h3>
              {topic.title}:
            </h3>
          </div>

          <div className="list">
            {topic.items.map((item, key) => {
              if (item.items) {
                return (
                  <React.Fragment key={key}>
                    {this.renderContent([item])}
                    <br />
                  </React.Fragment>
                )
              }

              return (
                <div key={key} style={{ color: colors[item.status], paddingBottom: 15, borderBottom: '1px solid #80808033', width: '100%' }}>
                  <strong style={{ display: 'flex', alignItems: 'center' }}>
                    {item.name}
                  </strong>

                  <div>
                    <span>
                      <div>{item.value}</div>
                      <div>{item.title}</div>
                      <div>{item.message}</div>
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    )
  }

  render () {
    if (this.props.isLoading) {
      return <Loading loading />
    }

    const lastScan = this.props.details.latest_ssl_scans.ready
    const singleIp = this.state.singleIp

    return (
      <section className="content full-report">
        <Card>
          <div className="summary">
            <Score value={lastScan.grade} />

            <section className="details">
              <div className="title">
                <h2>Detalhes do Scan</h2>

                <div className="icons">
                  <a
                    className="link-icon"
                    href="https://www.siteblindado.com/documents/manual_da_nota.pdf"
                    target="_blank"
                    title="Manual da Nota"
                    rel="noreferrer"

                  >
                    <IconSbBook />
                  </a>
                </div>
              </div>

              <div className="resume">
                <div>
                  <strong>
                    Domínio
                  </strong>
                  <br />

                  <span>
                    {this.props.details.name}
                  </span>
                </div>

                <div>
                  <strong>
                    Gerado em
                  </strong>
                  <br />

                  <span>
                    {moment(lastScan.date_ready).format('DD/MM/YYYY HH:mm')}
                  </span>
                </div>

                {this.props.details.latest_ssl_scans.in_progress &&
                  <div className="lastUpdated">
                    <img src={loadingSvg} alt="Loading" />
                    escaneando
                  </div>
                }

                {lastScan && !this.props.details.latest_ssl_scans.in_progress &&
                  <div>
                    <Button
                      className="right"
                      onClick={() => (
                        this.props.runScan(this.props.details.id)
                          .then(() => {
                            this.props.fetchDomain(this.state.domain)
                          })
                      )}
                    >
                      Rodar Scan
                    </Button>
                  </div>
                }
              </div>
            </section>
          </div>
        </Card>

        <Card>
          <div>
            {lastScan.result.endpoints.map((endpoint, key) => (
              <AccordionOld
                key={key}
                open={this.state.open === endpoint.ipAddress}
                close={this.state.open !== endpoint.ipAddress}
                header={this.renderAccordionHeader(endpoint.ipAddress, endpoint.grade)}
              >
                {singleIp?.isLoading &&
                  <Loading loading={this.state.open === endpoint.ipAddress} />
                }
                <section className="full-report">
                  {singleIp?.summary?.value === 'T' &&
                    <ContextMessage
                      type="warning"
                      title="Observação"
                      message={`Se não houvessem problemas de segurança no certificado,
                                            sua nota seria ${singleIp?.summary?.message}`}
                    />
                  }

                  <Grid style={{ border: '1px solid #80808033 ' }}>
                    <h1 style={{ width: '100%', backgroundColor: '#80808033', paddingLeft: '2rem', height: 50, display: 'flex', alignItems: 'center' }}>
                      <WorkspacePremium fontSize="large" style={{ marginLeft: 20 }} />
                      Certificado
                    </h1>

                    {this.renderContent(singleIp?.certificate)}
                  </Grid>

                  <br />

                  <Grid style={{ border: '1px solid #80808033 ' }}>
                    <h1 style={{ width: '100%', backgroundColor: '#80808033', paddingLeft: '2rem', height: 50, display: 'flex', alignItems: 'center' }}>
                      <Build fontSize="large" style={{ marginLeft: 20 }} />
                      Configuração
                    </h1>

                    {this.renderContent(singleIp?.configurations)}
                  </Grid>

                </section>
              </AccordionOld>
            ))}
          </div>
        </Card>
      </section>
    )
  }
}

const mapStateToProps = ({ report, dispatch }) => ({ ...report, dispatch })

export default connect(mapStateToProps, { getReport, fetchDomain, runScan })(FullReport)

FullReport.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  getReport: PropTypes.func,
  fetchDomain: PropTypes.func,
  setBreadcrumbName: PropTypes.func,
  runScan: PropTypes.func,
  isLoading: PropTypes.bool,
  details: PropTypes.object,
  result: PropTypes.object,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
