import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { SelectField } from '../../components';
import { fetchPromoCodes } from '../../actions/promocode';

class SelectAccountsField extends React.PureComponent {
    // eslint-disable-next-line no-undef
    static defaultProps = {
        name: 'product',
    }

    componentWillMount() {
        this.props.fetchPromoCodes(this.props.promoCodeId);
    }

    render() {
        return (
            <Field
                {...this.props}
                options={this.props.productsOptions}
                component={SelectField}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { promoaccounts } = state;
    return {
        productsOptions: promoaccounts.allIds.map(id => ({
            id: promoaccounts.results[id].id,
            description: promoaccounts.results[id].name,
        })),
    };
};

export default connect(mapStateToProps, { fetchPromoCodes })(SelectAccountsField);
