import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Field } from 'redux-form';

export const RadiosField = ({ label, title, items, input, meta: { touched, error }, vertical }) => (
    <div className={vertical ? 'field-radio vertical' : 'field'}>
        {label ? (<label>{label}</label>) : (<p>{title}</p>)}

        {items.map(item =>
            <label key={item.value} className="radio">
                <input
                    type="radio"
                    {...input}
                    value={item.value}
                    checked={String(item.value) === String(input.value)}
                    onChange={() => input.onChange(item.value)}
                />
                <span>{item.label}</span>
            </label>
        )}

        {touched && error &&
            <span className={classnames({ 'input-error': !!error })}>{error}</span>
        }
    </div>
);

RadiosField.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    input: PropTypes.object,
    meta: PropTypes.object,
    error: PropTypes.string,
    vertical: PropTypes.bool,
};
