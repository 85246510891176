import { fetch } from '../services/fetch';

export const FETCH_CHART_UPTIME_ONLINE = 'uptime-chart/FETCH_CHART_UPTIME_ONLINE';
export const FETCH_CHART_UPTIME_HISTORY = 'uptime-chart/FETCH_CHART_UPTIME_HISTORY';

export function fetchChartUptimeOnline(section, accountId, loading = true) {
    const url = '/uptime/online-chart';
    const request = fetch(url).then(res => res.json());
    return {
        type: FETCH_CHART_UPTIME_ONLINE,
        payload: {
            promise: request,
            data: { section, loading },
        },
    };
}

export function fetchChartUptimeHistory(section, accountId, loading = true) {
    const url = '/uptime/history-chart';
    const request = fetch(url).then(res => res.json());
    return {
        type: FETCH_CHART_UPTIME_HISTORY,
        payload: {
            promise: request,
            data: { section, loading },
        },
    };
}
