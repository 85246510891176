import PropTypes from 'prop-types';
import React from 'react';
import { IconMdContentCopy } from '../../sb2w-icons/md';

export const CopyToClipBoardPassword = ({ children }, context) => {
    const copyClipboard = () => {
        const range = document.createRange();
        const selection = window.getSelection();
        const mark = document.querySelector('.code-block-password pre');

        selection.removeAllRanges();
        range.selectNode(mark);
        selection.addRange(range);

        const copy = document.execCommand('copy');

        if (copy) {
            context.alert({ text: 'Copiado com sucesso!', type: 'success' });
        }
    };

    return (
        <article>
            <div className="code-block-password row">
                <div className="col-md-2 label-password">
                    <strong>Contrasenha:</strong>
                </div>
                <div className="col-md-8">
                    <input
                        type="text"
                        placeholder="Link"
                        value={children}
                    />
                </div>
                <div className="col-md-2 link-icon" onClick={copyClipboard}>
                    <IconMdContentCopy />
                </div>
            </div>
        </article>
    );
};

CopyToClipBoardPassword.contextTypes = {
    alert: PropTypes.func,
};

CopyToClipBoardPassword.propTypes = {
    children: PropTypes.any,
};
