import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { UsersNotify, UsersBox } from '../../containers'
import { Card } from '../../components'

export const EmailNotifications = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <div className="card-content">
          <p>
            O portal da Site Blindado envia por email diversas notificações,
            dentre elas de expiração de certificados SSL e de vulnerabilidades encontradas em suas aplicações.
            <br />
            Marque abaixo quais usuários desta organização desejam receber estes emails:
          </p>
          <br />

          <UsersNotify organizationId={props.match.params.id} />
          <UsersBox organizationId={props.match.params.id} />
        </div>
      </Card>
    </section>
  )
}

EmailNotifications.propTypes = {
  params: PropTypes.object,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
