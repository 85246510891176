import { fetch } from '../services/fetch';
import { setMessage, SUCCESS } from '../modules/messages';
import { push } from 'connected-react-router';

export const CREATELEAD = 'waf/CREATELEAD';
export const CREATE = 'waf/CREATE';
export const FETCH_ALL = 'waf/FETCH_ALL';

export function createLeadWAF(accountId, plan) {
    return dispatch => {
        const request = fetch(`/accounts/${accountId}/waf-lead`, {
            method: 'POST',
            body: JSON.stringify({ plan }),
        })
            .then(res => {
                dispatch(push('/waf'));
                dispatch(setMessage(SUCCESS, 'Solicitação enviada com sucesso!'));

                return res.json();
            })
            .catch(error => error);

        return dispatch({
            type: CREATELEAD,
            payload: request,
        });
    };
}

export function fetchWaf(accountId, params = {}) {
    // const query = toParams(params);
    // const request = fetch(`/accounts/${accountId}/waf/domains${query}`).then(res => res.json());
    // return {
    //     type: FETCH_ALL,
    //     payload: request,
    //     meta: { filters: params },
    // };
}

export function createApplication(data) {
    return dispatch => {
        const request = fetch(`/waf/application/${data.account_id}`, {
            method: 'POST',
            body: JSON.stringify(data),
        })
        .then(res => {
            dispatch(setMessage(SUCCESS, 'Aplicação criada com sucesso!'));
            dispatch(push('/waf/applications'));
            return res;
        })
        // .catch(res => {
        //     dispatch(setMessage(ERROR, res.message));
        //
        //     throw res;
        // });

        return dispatch({
            type: CREATE,
            payload: request,
        });
    };
}

export function fetchApplication(accountId) {
    const request = fetch(`/waf/application/${accountId}`).then(res => res.json());
    return {
        type: FETCH_ALL,
        payload: request,
    };
}
