import { useEffect } from 'react'
import { ApplicationsFilter } from '../../components'
import { ApplicationsList, DashboardArmour } from '../../containers'

export const Applications = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <article className="content">
      <DashboardArmour />
      <ApplicationsFilter />
      <ApplicationsList />
    </article>
  )
}
