import moment from 'moment'
import _ from 'lodash';

/**
 * Parse de object para url params
 * @param  {object} obj { nome: 'Lorem', sobrenome: 'Ipsum' }
 * @return {string}     nome=Lorem&sobrenome=Ipsum
 */
export const toParams = (paramsObj) => {
  const toArray = (obj) => (
    Object.keys(obj).filter(key => String(obj[key])).map(key => {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        return toArray(obj[key]).join('&')
      }

      const value = Array.isArray(obj[key]) ?
        obj[key].map(item => encodeURIComponent(item))
        : encodeURIComponent(obj[key])

      return `${key}=${value}`
    })
  )

  const query = toArray(paramsObj).join('&')
  return `${query && `?${query}`}`
}

/**
 * Converte os erros padrões da API para obj utilizado nos Formulários.
 * @param  {array} obj Lista de objetos com os campos fields e message
 * @return {object}    Object { field: message }
 */
export const toFormErrors = (obj = {}) => {
  const errors = {}

  if (!_.isEmpty(obj)) {
    obj.forEach(item => {
      errors[item.field] = item.message
    })
  }

  return errors
}

/**
 * Converte a data do formato UTC para e padrão do sistema
 * @param {Date | string | number} date : String da data no formato UTC
 * @param {{fstr: string, time: boolean}} options : Configurações
 * @return {string}
 */
export const formatDate = (date, options = {}) => {
  if (!date) {
    return ''
  }

  if (options.fstr && options.time) {
    const err = new Error('Não use time=true quando modificar o fstr')
    throw err
  }

  if (date === Infinity) {
    return ''
  } else {
    const opt = {
      fstr: !options.time ? 'DD/MM/YYYY' : 'DD/MM/YYYY [às] HH[h]mm',
      ...options,
    }

    const formattedDate = moment(date).format(opt.fstr)

    return formattedDate
  }
  // return format(date, opt.fstr, { locale });
}

/**
 * Retorna a diferença da data comparada com o dia atual
 * @param {Date | string | number} date : String da data no formato UTC
 * @return {string}
 */
export const humanize = (date) => moment(date).format('DD/MM/YYYY [às] HH:mm')
