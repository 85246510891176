import PropTypes from 'prop-types'
import React from 'react'
import { FormBuyBox } from '../../containers'

export const ProductDetails = ({ name, description, id, config, catalog }) => {
  return (
    <article className="product-details">
      <h3>{name}</h3>

      <div dangerouslySetInnerHTML={{ __html: description }} />

      <article className="buy-box">
        <FormBuyBox
          id={id}
          config={config}
          catalog={catalog}
        />
      </article>
    </article>
  )
}
ProductDetails.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  validation_type: PropTypes.string,
  description: PropTypes.string,
  config: PropTypes.object,
  catalog: PropTypes.string,
}