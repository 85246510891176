import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {
  TableWrap,
  TableWrapActions,
  TableWrapRight,
  TableWrapLeft,
  Choices,
} from '../../components'
import { Link } from 'react-router-dom'
import { fetchOrganizations, deleteOrganization, saveOrganization } from '../../actions/organizations'
import { onlyAdmin } from '../../services/auth'
import {fetchOrders} from "../../actions";
import {withAccountId} from "../AccountContainer";
import AccountOriginBadge from "../../components/AccountOriginBadge";

const organizationStatus = [
  { label: 'Ativo', value: 'active' },
  { label: 'Suspenso', value: 'suspended' },
  { label: 'Cancelado', value: 'cancelled' },
]

const ChooseStatus = onlyAdmin(props => (
  <Choices
    items={organizationStatus}
    itemChecked={props.item}
    onClick={(choice) => props.saveOrganization(props.obj.id, { status: choice })}
  />
), props => (
  <span>
    {organizationStatus.find(status => props.obj.status === status.value).label}
  </span>
))

class OrganizationsList extends React.Component {
  constructor(props) {
    super(props)
    this.model = {
      full_name: {
        name: 'Nome Fantasia',
        renderer: (n, { id }) => (
          <Link to={`/organizations/${id}`}>
            {n}
          </Link>
        ),
      },
      legal_name: { name: 'Razão Social' },
      cnpj: { name: 'CNPJ' },
      origin: {
        name: 'Origem',
        renderer: (item, obj) => <AccountOriginBadge origin={obj.origin} />,
        visible: props.auth.isAdmin
      },
      status: {
        name: 'Status',
        renderer: (item, obj) => <ChooseStatus {...props} item={item} obj={obj} />,
      },
      dummy: {
        name: ' ',
      },
    }
  }

  render() {
    return (
      <div>
        <TableWrap
          model={this.model}
          source={this.props.organizations}
          actionFetch={this.props.fetchOrganizations}
          location={this.props.location}
          hasSearch
        >
          <TableWrapActions>

            <TableWrapLeft>
              Lista de Organizações
            </TableWrapLeft>

            <TableWrapRight>
              <Link to="/organizations/new">
                Criar Organização
              </Link>
            </TableWrapRight>

          </TableWrapActions>
        </TableWrap>
      </div>
    )
  }
}

OrganizationsList.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  organizations: PropTypes.object,
  fetchOrganizations: PropTypes.func,
  deleteOrganization: PropTypes.func,
  saveOrganization: PropTypes.func,
}

const mapStateToProps = ({ organizations, auth }) => ({ organizations, auth })

export default connect(mapStateToProps, {
  fetchOrganizations, deleteOrganization, saveOrganization
})(withAccountId(OrganizationsList))


