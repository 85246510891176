import debounce from "debounce";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import {
    Loading,
    Pagination,
} from "../index";
import OrderTracker from "./OrderTracker";
import currencyFormatter from 'currency-formatter';
import formatDate from 'format-date';
import {
    Card,
} from "../../components";

export class OrdersWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            columnFilter: {},
            orderBy: "",
            page: 1,
        };
        this.initialPage = 1;
        this.hasOrder = this.hasOrder.bind(this);
        this.handleOrder = this.handleOrder.bind(this);
        this.hasPagination = this.hasPagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleContentFilter = this.handleContentFilter.bind(this);
        this.doFetch = this.doFetch.bind(this);
        this.doSearch = debounce(this.doSearch, 600);
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    componentDidMount() {
        return !this.props.source.isLoading && this.doFetch();
    }

    handleSearch({target}) {
        this.setState({search: target.value}, () => {
            this.doSearch();
        });
    }

    handleChangePage(newPage) {
        this.setState({page: newPage}, () => {
            this.doSearch();
        });
    }

    doSearch() {
        if (!this.props.source.isLoading) {
            this.doFetch();
        }
    }

    clearSearch() {
        this.doSearch();
        this.setState({search: ""});
    }

    hasPagination() {
        const {source} = this.props;
        return this.props.pagination && !!source.count && !!source.page_size;
    }

    hasOrder(model, key) {
        if (!this.props.orderBy) {
            return false;
        }

        return !!this.props.orderBy.includes(model[key].fieldName || key);
    }

    handleOrder(item) {
        let orderBy = "";

        if (`-${item}` !== this.state.orderBy) {
            orderBy = item;
        }

        if (item === this.state.orderBy) {
            orderBy = `-${item}`;
        }

        this.setState({orderBy}, this.doFetch);
    }

    handleContentFilter(item) {
        this.setState(
            {
                columnFilter: {
                    ...this.state.columnFilter,
                    ...item,
                },
            },
            this.doFetch,
        );
    }

    doFetch() {
        if (!this.props.source.isLoading) {
            this.props.actionFetch(this.props.accountId, this.queryParams());
        }
    }

    queryParams() {
        const params = {};

        if (this.hasPagination) {
            params.page =
                (this.props.location.state ? this.props.location.state.page : false) ||
                this.state.page ||
                this.props.source.page ||
                this.initialPage;
        }

        if (this.props.source.filter) {
            params.filter = this.props.source.filter;
        }

        if (this.state.search) {
            params.search = this.state.search;
        }

        if (Object.keys(this.state.columnFilter).length) {
            params.columnFilter = this.state.columnFilter;
        }

        if (this.state.orderBy) {
            params.order_by = this.state.orderBy;
        }

        return params;
    }

    convertAmount(amountInCents) {
        return currencyFormatter.format(amountInCents / 100, {locale: 'pt-BR'});
    }

    convertDate(date) {
        return formatDate('{day}/{month}/{year} às {hours}:{minutes}:{seconds}', new Date(date));
    }

    render() {
        console.log('source', this.props.source);
        return (
            <div className="table-wrap">
                <Loading loading={this.props.source?.isLoading}/>

                {!get(this.props.source, this.props.mapField, []).length && (
                    <div style={{textAlign: "center"}}>
                        {!this.props.source?.isLoading && <span>Não existem Pedidos realizados</span>}
                    </div>
                )}

                {get(this.props.source, this.props.mapField, []).map(
                    (data, index) => (
                        <Card key={index} className={"order-wrap"} style={{
                            width: "100%",
                            maxWidth: "1000px",
                            padding: '3rem',
                            marginBottom: "6rem",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}>
                            <div className={"order-wrap-header"}
                                 style={{
                                     borderBottom: "1px solid #000",
                                     marginBottom: "1rem",
                                     display: "flex",
                                     flexDirection: "row",
                                     justifyContent: "space-between",
                                     paddingBottom: "1rem"
                                 }}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        marginRight: "5rem"
                                    }}><span>PEDIDO Nº</span>
                                        <span><b>{this.props.source.results[data].id}</b></span></div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between"
                                    }}>
                                        <span>TOTAL</span>
                                        <span>{this.convertAmount(this.props.source.results[data].amount_in_cents)}</span>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    textAlign: 'left'
                                }}>
                                    <span>REALIZADO EM</span>
                                    <span>{this.convertDate(this.props.source.results[data].created_at)}</span>
                                </div>
                            </div>
                            <OrderTracker order={this.props.source.results[data]} id={this.props.source.results[data].id}
                                          status={this.props.source.results[data].status}/>
                            <div>PRODUTOS</div>
                            <div className={"order-wrap-content"} style={{width: "100%"}}>
                                <table style={{width: "100%", margin: "auto"}}>
                                    <thead>
                                    <tr style={{width: "100%", padding: "2rem", textAlign: 'center'}}>
                                        <th style={{width: "15%", padding: "1rem"}}>Qto</th>
                                        <th style={{width: "15%", padding: "1rem"}}>FQDN</th>
                                        <th style={{width: "50%", padding: "1rem", textAlign: 'left'}}>Produto</th>
                                        <th style={{width: "20%", padding: "1rem", textAlign: 'right'}}>Valor</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.source.results[data].products.map((product) => (
                                        <tr key={`product-${product.id}`} style={{width: "100%", textAlign: 'center'}}>
                                            <td style={{
                                                width: "15%",
                                                padding: "1rem",
                                                borderTop: "1px solid #000"
                                            }}>{product.quantity}</td>
                                            <td style={{
                                                width: "15%",
                                                padding: "1rem",
                                                borderTop: "1px solid #000"
                                            }}>{product.fqdn_quantity}</td>
                                            <td style={{
                                                width: "50%",
                                                padding: "1rem",
                                                borderTop: "1px solid #000",
                                                textAlign: 'left'
                                            }}>{product.name}</td>
                                            <td style={{
                                                width: "20%",
                                                padding: "1rem",
                                                borderTop: "1px solid #000",
                                                textAlign: 'right'
                                            }}>{this.convertAmount(product.total_amount_in_cents)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    ),
                )}

                <Pagination
                    count={this.props.source.count || 0}
                    pageSize={this.props.source.page_size || 0}
                    page={this.props.source.page || 0}
                    onPageChange={(newPage) => {
                        this.handleChangePage(newPage);
                    }}
                />
            </div>
        );
    }
}

OrdersWrap.defaultProps = {
    pagination: true,
    hasSearch: false,
    mapField: "allIds",
};

OrdersWrap.propTypes = {
    children: PropTypes.any,
    source: PropTypes.object,
    location: PropTypes.object,
    pagination: PropTypes.bool,
    hasSearch: PropTypes.bool,
    actionFetch: PropTypes.func,
    accountId: PropTypes.string,
};
