import { css } from 'styled-components';

import { $fontSizeBase, $shadowBase, $darkgrey700 } from '../settings/variables';

const speed = '0.3s';

export default css`
    .switch-account {
        position: relative;
        color: #333333;
        width: 18rem;
        transition: all ${speed} ease-out;
    }

    .switch-account > i {
        position: absolute;
        margin: .4rem 1rem;
    }

    .switch-account > input {
        font-size: ${$fontSizeBase} !important;
        padding-left: 4rem;
        padding-right: 1rem;
        width: 100%;
        border: none;
        height: 3rem;
    }

    .switch-account > .list {
        width: 100%;
        position: absolute;
        max-height: 0;
        display: block;
        background-color: #fff;
        margin-top: .5rem;
        overflow: hidden;
        box-shadow: ${$shadowBase};
        z-index: 10;
        opacity: 0;
        transition: all ${speed} ease-out;
    }

    .switch-account > .list strong {
        padding: 1rem 0;
        display: block;
        color: ${$darkgrey700};
        text-align: center;
        border-bottom: .1rem dotted #eee;
        margin: 0 5rem;
    }

    .switch-account > .list > a {
        background: #74b61b;
        color: white;
        width: 100%;
        display: flex;
        height: 4rem;
        align-items: center;
        transition: all ${speed} ease-out;
    }

    .switch-account > .list > a:hover {
        background: #519548;
    }

    .switch-account > .list > a > i {
        padding: 0 1rem 0 2rem;
    }

    .switch-account > .list .item {
        background-color: #fff;
        padding: 1.3rem 1.8rem;
        border-bottom: .1rem solid #eee;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all ${speed} ease-out;
    }

    .switch-account > .list .item.active {
        background: #e3e3e3;
    }

    .switch-account > .list .item:hover {
        background-color: #21272e;
        color: #fff;
    }

    .switch-account > .list .item > span {
        display: flex;
        flex-direction: column;
    }

    .switch-account > .list .item > i {
        margin-right: 1rem;
    }

    .switch-account.open {
        width: 35rem;
    }

    .switch-account.open > .list {
        max-height: 50rem;
        opacity: 1;
        overflow: auto;
    }
`;
