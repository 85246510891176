import PropTypes from 'prop-types'
import React from 'react'

export class Pagination extends React.Component {

  renderPagination() {
    // registro inicial da pagina
    const start = ((this.props.page - 1) * this.props.pageSize) + 1

    // registro final da pagina
    const end = Math.min(this.props.page * this.props.pageSize, this.props.count)

    // numero total de registros
    const count = this.props.count

    // numero total de paginas
    const pageCount = Math.ceil(this.props.count / this.props.pageSize)

    // flags se estamos na primeira ou na ultima pagina
    const isFirst = this.props.page === 1
    const isLast = this.props.page === pageCount

    return (
      <div className="pagination">
        <span className="records">
          Exibindo <strong>{start}</strong> a <strong>{end}</strong> de <strong>{count}</strong> resultados
        </span>

        <div className="buttons">
          <button
            className="first"
            disabled={isFirst}
            onClick={() => !isFirst && this.props.onPageChange(1)}
          >
            {this.props.firstText}
          </button>

          <button
            className="prev"
            disabled={isFirst}
            onClick={() => !isFirst && this.props.onPageChange(this.props.page - 1)}
          >
            {this.props.prevText}
          </button>

          <input
            className="currentPage"
            type="number"
            min="1"
            max={pageCount}
            value={this.props.page}
            onChange={(e) => this.props.onPageChange(e.target.value)}
          />

          <span className="pageCount">de {pageCount}</span>

          <button
            className="next"
            disabled={isLast}
            onClick={() => !isLast && this.props.onPageChange(this.props.page + 1)}
          >
            {this.props.nextText}
          </button>

          <button
            className="last"
            disabled={isLast}
            onClick={() => !isLast && this.props.onPageChange(pageCount)}
          >
            {this.props.lastText}
          </button>
        </div>
      </div>
    )
  }

  render() {
    return (
      this.props.count === 0 || this.props.count <= this.props.pageSize ? <div /> : this.renderPagination()
    )
  }
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  onPageChange: PropTypes.func.isRequired,
  firstText: PropTypes.string,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  lastText: PropTypes.string,
}

Pagination.defaultProps = {
  firstText: '<<',
  prevText: '<',
  nextText: '>',
  lastText: '>>',
}
