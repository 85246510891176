var _book = require('./book');var _book2 = _interopRequireDefault(_book);
var _facebook = require('./facebook');var _facebook2 = _interopRequireDefault(_facebook);
var _filter = require('./filter');var _filter2 = _interopRequireDefault(_filter);
var _penTest = require('./penTest');var _penTest2 = _interopRequireDefault(_penTest);
var _schedule = require('./schedule');var _schedule2 = _interopRequireDefault(_schedule);
var _sortDown = require('./sort-down');var _sortDown2 = _interopRequireDefault(_sortDown);
var _sortUp = require('./sort-up');var _sortUp2 = _interopRequireDefault(_sortUp);
var _sort = require('./sort');var _sort2 = _interopRequireDefault(_sort);
var _twitter = require('./twitter');var _twitter2 = _interopRequireDefault(_twitter);
var _waf = require('./waf');var _waf2 = _interopRequireDefault(_waf);function _interopRequireDefault(obj) {return obj && obj.__esModule ? obj : { default: obj };}

module.exports = {
	IconSbBook: _book2.default,
	IconSbFacebook: _facebook2.default,
	IconSbFilter: _filter2.default,
	IconSbPenTest: _penTest2.default,
	IconSbSchedule: _schedule2.default,
	IconSbSortDown: _sortDown2.default,
	IconSbSortUp: _sortUp2.default,
	IconSbSort: _sort2.default,
	IconSbTwitter: _twitter2.default,
	IconSbWaf: _waf2.default };