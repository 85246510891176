import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './style'

import { Divider, Grid, Typography, Collapse, IconButton } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF0000']

const VulnerabilityDetail = React.memo((props) => {
  const classes = useStyles()
  const { expand, data, handleExpand } = props

  return (
    <Grid
      key={data.id}
      className={classes.contentItem}
      style={{ borderLeft: `5px solid ${COLORS[data.risk - 1]}` }}
    >
      <IconButton
        xs={2}
        style={{ height: 50 }}
        onClick={() => handleExpand(data.id)}
      >
        {expand ? (
          <ExpandLess fontSize="large" />
        ) : (
          <ExpandMore fontSize="large" />
        )}
      </IconButton>

      <Grid item xs={10}>
        <Typography className={classes.text} style={{ fontWeight: 'bold' }}>
          {data.title}
        </Typography>

        <Typography className={classes.text}>{data.url}</Typography>

        <Collapse
          in={expand}
          item="true"
          xs={12}
          timeout="auto"
          unmountOnExit
        >
          <Grid className={classes.descriptionItem}>
            <Grid className={classes.contentItemRow}>
              <Typography className={classes.text}>
                <b>ID: </b>
              </Typography>

              <Typography className={classes.text}>
                {data.vulnerability_id}
              </Typography>
            </Grid>

            <Divider />

            <Grid className={classes.contentItemRow}>
              <Typography className={classes.text}>
                <b>Descrição: </b>
              </Typography>

              <Typography className={classes.text}>
                {data.description}
              </Typography>
            </Grid>

            <Divider />

            <Grid className={classes.contentItemRow}>
              <Typography className={classes.text}>
                <b>Portas: </b>
              </Typography>

              <Typography className={classes.text}>
                {data.ports}
              </Typography>
            </Grid>

            <Divider />

            <Grid className={classes.contentItemRow}>
              <Typography className={classes.text}>
                <b>Recomendação: </b>
              </Typography>

              <Typography className={classes.text}>
                {data.recommendation}
              </Typography>
            </Grid>

            <Divider />

            <Grid className={classes.contentItemRow}>
              <Typography className={classes.text}>
                <b>Referências: </b>
              </Typography>

              <Typography className={classes.text}>
                {data.references}
              </Typography>
            </Grid>

            <Divider />

            <Grid className={classes.contentItemRow}>
              <Typography className={classes.text}>
                <b>Evidências: </b>
              </Typography>

              <Typography
                className={classes.text}
                // eslint-disable-next-line max-len
                dangerouslySetInnerHTML={{
                  __html: data.evidences,
                }}
                style={{ maxWidth: '100%' }}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
})

VulnerabilityDetail.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  risk: PropTypes.number,
  expand: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  ports: PropTypes.string,
  recommendation: PropTypes.string,
  handleExpand: PropTypes.func,
}

export default VulnerabilityDetail
