import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Help, AboutSealStatus, RemoveApplication } from '../../components'
// import {ApplicationDetails} from '../../containers';
import { humanize } from '../../services/utils'
import { userIsAdmin } from '../../services/auth'

const RemoveApplicationAdmin = userIsAdmin((props) => (
  <RemoveApplication section="Aplicação" id={props.id} onRemove={props.onRemove} />
))

export class VulnerabilityApiCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      position: this.props.position,
      side: this.props.side,
    }
    this.onSetPosition = this
      .onSetPosition
      .bind(this)
    this.onSetSide = this
      .onSetSide
      .bind(this)
    this.onRemove = this
      .onRemove
      .bind(this)
  }

  onSetPosition (choice) {
    this.setState({ position: choice })
  }

  onSetSide (choice) {
    this.setState({ side: choice })
  }

  onRemove (id) {
    this
      .props
      .deleteApplication(id, { category: 'api' })
  }

  render () {
    const seal = {
      ok: 'Ativo',
      suspended: 'Suspenso',
      vulnerable: 'Expira em: ',
    }

    return (
      <Card>
        <article className="vulnerability-card">
          <div className="header">
            <span className="title">
              <strong>{this.props.name}</strong><br /> {this.props.url}
            </span>

            <div className="status">
              <div className="description">
                <Help title="Status do Selo">
                  <AboutSealStatus />
                </Help>
                Status do selo<br />
                <small>
                  {seal[this.props.sealStatus]}
                  {this.props.sealStatus === 'vulnerable' &&
                    <strong>
                      {humanize(this.props.expirationDate)}
                    </strong>
                  }
                </small>
              </div>
              <div className={`mark-${this.props.sealStatus}`}></div>
            </div>
          </div>

          <div className="content" style={{ minHeight: '37vh' }}>
            {this.props.children}
          </div>

          <footer className="footer-selo">
            <div>
              <RemoveApplicationAdmin id={this.props.id} onRemove={this.onRemove} />

              <Link
                to={`scan-api/${this.props.id}`}
                className="button-primary right"
              >
                Detalhes
              </Link>
              {/* <ApplicationDetails {...this.props} />
                        //Icone de Configuração, removido (solicitado por produto)
                        */}
            </div>
          </footer>
        </article>
      </Card>
    )
  }
}
