import { fetch } from '../../../services/fetch';
import { setMessage, SUCCESS, ERROR } from '../../../modules/messages';

export const FETCH_PENTEST_VULNERABILITIES = '@pentests/FETCH_PENTEST_VULNERABILITIES';
export const CREATE_PENTEST_VULNERABILITY = '@pentests/CREATE_PENTEST_VULNERABILITY';
export const UPDATE_PENTEST_VULNERABILITY = '@pentests/UPDATE_PENTEST_VULNERABILITY';
export const DELETE_PENTEST_VULNERABILITY = '@pentests/DELETE_PENTEST_VULNERABILITY';

export function fetchPentestVulnerabilities(accountId, pentestId, reportId) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/${reportId}/vulnerability`;

    const request = fetch(url).then(res => res.json());

    return {
        type: FETCH_PENTEST_VULNERABILITIES,
        payload: request,
    };
}

export function createPentestVulnerability(accountId, pentestId, reportId, data) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/${reportId}/vulnerability`;

    return (dispatch) => {
        const request = fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(res => {
            dispatch(setMessage(SUCCESS, 'Vulnerabilidade cadastrada com sucesso!'));

            return res.json();
        }).catch(err => {
            const message = err.message || 'Erro ao cadastrar Vulnerabilidade';

            dispatch(setMessage(ERROR, message));

            throw err;
        });

        dispatch({
            type: CREATE_PENTEST_VULNERABILITY,
            payload: request,
        });
    };
}
export function updatePentestVulnerability(accountId, pentestId, reportId, vulnerabilityId, data) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/${reportId}/vulnerability/${vulnerabilityId}`;

    return (dispatch) => {
        const request = fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(res => {
            dispatch(setMessage(SUCCESS, 'Vulnerabilidade atualizada com sucesso!'));

            return res.json();
        }).catch(err => {
            const message = err.message || 'Erro ao atualizar Vulnerabilidade';

            dispatch(setMessage(ERROR, message));

            throw err;
        });

        dispatch({
            type: UPDATE_PENTEST_VULNERABILITY,
            payload: request,
        });
    };
}

export function deletePentestVulnerability(accountId, pentestId, reportId, vulnerabilityId) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/${reportId}/vulnerability/${vulnerabilityId}`;

    return (dispatch) => {
        const request = fetch(url, { method: 'DELETE' })
            .then(() => dispatch(setMessage(SUCCESS, 'Vulnerabilidade removida com sucesso!')))
            .catch(err => {
                const message = err.message || 'Erro ao remover Vulnerabilidade';

                dispatch(setMessage(ERROR, message));

                // throw err;
            });

        dispatch({
            type: DELETE_PENTEST_VULNERABILITY,
            payload: request,
        });
    };
}
