import React, { memo } from 'react'
import { ownerAuth } from '../../../services/auth'
import {
  // Popover,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  // Grid,
} from '@mui/material'

import {
  // Delete,
  // Description,
  // Edit,
  // PlayArrow,
  Assessment,
  // Pause,
  // ArrowLeft,
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  row: {
    backgroundColor: '#f6f6f6',
    height: 90,
    margin: '10px 0',
    border: '12px solid #fff',
    borderRadius: '10px 10px 10px 10px',
    fontWeight: 'normal',
  },
  tableItem: {
    textAlign: 'left !important',
    color: '#717171',
    fontSize: '1.3rem',
  },
  retestRow: {
    paddingBottom: 0,
    paddingTop: 0,
    margin: '10px 0',
    border: '12px solid #fff',
    borderRadius: '10px 10px 10px 10px',
    backgroundColor: '#ffff',
    fontWeight: 'normal',
    transition: '2s',
  },
})

// const PentestAdminActions = userIsAdmin(props => {
//   const {
//     data,
//     handleChangeStatus,
//     handleClick,
//     modalOpen,
//     setModalOpen,
//     handleDelete,
//     handleClose,
//   } = props

//   return (
//     <>
//       {data.status === 'to_start' &&
//         <Link
//           to="#"
//           className="table-icons"
//           onClick={() => handleChangeStatus(data, 'in_progress')}
//         >
//           <Tooltip title="Iniciar Pentest" >
//             <PlayArrow fontSize="large" />
//           </Tooltip>
//         </Link>
//       }

//       {data.status === 'to_start' &&
//         <Link
//           className="table-icons"
//           to={`/pentest/${data.id}/edit`}
//           disabled={data.status === 'done' || data.status === 'in_progress'}
//           aria-label='Editar solicitação'
//         >
//           <Tooltip title="Editar solicitação">
//             <Edit fontSize="large" />
//           </Tooltip>
//         </Link>
//       }

//       {data.status === 'to_start' &&
//         <a className="table-icons" aria-label='Deletar solicitação'>
//           <Delete onClick={handleClick} style={{ fontSize: '2rem' }} />

//           <Popover
//             id={modalOpen ? 'simple-popover' : undefined}
//             onClose={() => setModalOpen(null)}
//             anchorEl={modalOpen}
//             open={!!modalOpen}
//             anchorOrigin={{
//               vertical: 'bottom',
//               horizontal: 'left'
//             }}
//           >
//             <Grid>
//               <Grid className="remove-item">
//                 <h1 className="remove-title">
//                   Remover Pentest
//                 </h1>

//                 <p className="remove-text">
//                   Este Pentest será removido e não há como reverter esta ação.
//                 </p>

//                 <Grid className="remove-actions">
//                   <a href='/#' onClick={() => handleDelete(data.id)}>
//                     Remover
//                   </a>

//                   <a href='/#' onClick={handleClose}>
//                     Cancelar
//                   </a>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Popover>
//         </a>
//       }

//       {data.status === 'paused' &&
//         <Link
//           to="#"
//           className="table-icons"
//           onClick={() => handleChangeStatus(data, 'in_progress')}
//         >
//           <Tooltip title="Retomar Pentest">
//             <ArrowLeft fontSize="large" style={{ fontSize: '3rem' }} />
//           </Tooltip>
//         </Link>
//       }

//       {data.status === 'in_progress' &&
//         <Link
//           className="table-icons"
//           to={`/pentest/${data.id}/report/${data.report_id}`}
//         >
//           <Tooltip title='Editar relatório'>
//             <Description fontSize="large" />
//           </Tooltip>
//         </Link>
//       }

//       {
//         data.status === 'in_progress' &&
//         <Link
//           to="#"
//           className="table-icons"
//           onClick={() => handleChangeStatus(data, 'paused')}
//         >
//           <Tooltip title="Pausar pentest">
//             <Pause fontSize="large" />
//           </Tooltip>
//         </Link>
//       }
//     </>
//   )
// })

// const RetestAdminActions = userIsAdmin(props => {
//   const {
//     pentestData,
//     retestData,
//     handleChangeStatus,
//   } = props

//   return (
//     <>
//       {retestData.status === 'to_start' &&
//         <Tooltip title="Iniciar Pentest" >
//           <Link
//             to="#"
//             className="table-icons"
//             onClick={() => handleChangeStatus(pentestData, 'in_progress', retestData)}
//           >
//             <PlayArrow fontSize="large" />
//           </Link>
//         </Tooltip>
//       }

//       {retestData.status === 'to_start' &&
//         <Tooltip title="Editar solicitação">
//           <Link
//             className="table-icons"
//             to={`/pentest/${pentestData.id}/edit`}
//           >
//             <Edit fontSize="large" />
//           </Link>
//         </Tooltip>
//       }

//       {retestData.status === 'paused' &&
//         <Tooltip title="Retomar Pentest">
//           <Link
//             to="#"
//             className="table-icons"
//             onClick={() => handleChangeStatus(pentestData, 'in_progress', retestData)}
//           >
//             <ArrowLeft fontSize="large" style={{ fontSize: '3rem' }} />
//           </Link>
//         </Tooltip>
//       }

//       {retestData.status === 'in_progress' &&
//         <Tooltip title='Editar relatório'>
//           <Link
//             className="table-icons"
//             to={`/pentest/${retestData.id}/report/${retestData.report_id}`}
//           >
//             <Description fontSize="large" />
//           </Link>
//         </Tooltip>
//       }

//       {retestData.status === 'in_progress' &&
//         <Tooltip title="Pausar pentest">
//           <Link
//             to="#"
//             className="table-icons"
//             onClick={() => handleChangeStatus(pentestData, 'paused', retestData)}
//           >
//             <Pause fontSize="large" />
//           </Link>
//         </Tooltip>
//       }
//     </>
//   )
// })

const OwnerActions = ownerAuth(({ id, report_id }) =>
  <Tooltip title="Relatório">
    <Link
      className="table-icons"
      to={`/pentest/${id}/dashboard/${report_id}`}
    >
      <Assessment fontSize="large" />
    </Link>
  </Tooltip>
)

const ListRow = ({ data, handleDelete, handleChangeStatus, summary, }) => {
  const classes = useStyles()
  // const [open, setOpen] = useState(undefined)
  // const [modalOpen, setModalOpen] = useState(null)

  // const openValidation = oldValue => {
  //   const value = oldValue === data.id
  //     ? undefined
  //     : data.id

  //   return value
  // }

  return (
    <React.Fragment>
      <TableRow className={classes.row} key={data.id}>
        <TableCell className="text-center">
          {/* {data.has_retest &&
            <IconButton
              onClick={() => setOpen(oldOpen => openValidation(oldOpen))}
            >
              {open === data.id
                ? <ExpandLess fontSize="large" />
                : <ExpandMore fontSize="large" />
              }
            </IconButton>
          } */}
        </TableCell>

        <TableCell>
          <Tooltip title={summary.status.filter(status => status.value === data.status)[0].label}>
            <span className={`table-list-status status-${data.status}`} />
          </Tooltip>
        </TableCell>

        <TableCell>
          <Typography className={classes.tableItem}>
            {data.url}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography className={classes.tableItem}>
            {summary.modality.filter(modality => modality.value === data.modality)[0].label}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography className={classes.tableItem}>
            {summary.pentester.filter(modality => modality.value === data.pentester)[0].label}
          </Typography>
        </TableCell>

        <TableCell align='center'>
          <Typography className={classes.tableItem}>
            {moment(data.exec_start).format('DD/MM/YYYY')}
          </Typography>
        </TableCell>

        <TableCell align='center'>
          <Typography className={classes.tableItem}>
            {moment(data.exec_end).format('DD/MM/YYYY')}
          </Typography>
        </TableCell>

        <TableCell align='left'>
          {data.status === 'done' &&
            <OwnerActions id={data.id} report_id={data.report_id} />
          }
        </TableCell>
      </TableRow>
{/* 
      {open === data.id && data.has_retest &&
        data.retest.map(retest =>
          <Fade key={retest.id} in={open === data.id}>
            <TableRow className={classes.retestRow}>
              <TableCell className={classes.tableItem}>
                <SubdirectoryArrowRight
                  style={{ marginLeft: 20 }}
                  fontSize="large"
                />
              </TableCell>

              <TableCell>
                <Tooltip title={`Reteste - ${summary.status.filter(status => status.value === retest.status)[0].label}`}>
                  <span className={`table-list-status status-${retest.status}`}></span>
                </Tooltip>
              </TableCell>

              <TableCell>
                <Typography className={classes.tableItem}>
                  {data.url}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.tableItem}>
                  {summary.modality.filter(modality => modality.value === data.modality)[0].label}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.tableItem}>
                  {summary.pentester.filter(pentester =>
                    pentester.value === retest.retest_pentester)[0].label
                  }
                </Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.tableItem}>
                  {moment(retest.retest_exec_start).format('DD/MM/YYYY')}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.tableItem}>
                  {moment(retest.retest_exec_end).format('DD/MM/YYYY')}
                </Typography>
              </TableCell>

              <TableCell>
                {data.status === 'done' && retest.status === 'done' &&
                  <OwnerActions
                    id={retest.id}
                    report_id={retest.report_id}
                  />
                }
              </TableCell>
            </TableRow>
          </Fade>
        )
      } */}
    </React.Fragment>
  )
}

ListRow.propTypes = {
  auth: PropTypes.any,
  data: PropTypes.object,
  open: PropTypes.string,
  handleOpen: PropTypes.func,
  handleDelete: PropTypes.func,
  handleStart: PropTypes.func,
  summary: PropTypes.object,
}

export default memo(ListRow)
