export default (errs = []) => {
    let errors = {};

    errs.map(err => {
        errors = { ...errors, [err.field]: err.message };
        return err;
    });

    return errors;
};
