import PropTypes from 'prop-types';
import React from 'react';
import { CheckboxItem } from './CheckboxItem';

export class CheckboxField extends React.Component {
    renderCheckboxs() {
        return this.props.choices.map((choice) => (
            <CheckboxItem
                {... this.props}
                key={choice.label}
                label={choice.label}
                name={this.props.name || choice.name || choice.value}
                value={choice.value}
                inline={this.props.inline || (this.props.choices.length === 1)}
            />
        ));
    }

    render() {
        return (
            <div className="field">
                {this.renderCheckboxs()}
            </div>
        );
    }
}

CheckboxField.propTypes = {
    choices: PropTypes.array,
    name: PropTypes.string,
    inline: PropTypes.bool,
};

