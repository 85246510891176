import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Wootric extends Component {
    constructor(props) {
        super(props);
        this.state = { clientEmail: this.props.email, created_at: Math.trunc(new Date() / 1000) };
    };

    componentDidMount() {
        const setupScript = document.createElement('script');
        setupScript.type = 'text/javascript';
        setupScript.async = true;
        setupScript.innerHTML = `
            wootric_survey_immediately = true;
            wootric_no_surveyed_cookie = false;
            window.wootricSettings = {
            email: '${this.state.clientEmail}',
            created_at: '${this.state.created_at}',
            account_token: 'NPS-5df086ef'
            };
        `;
        document.body.appendChild(setupScript);

        // Beacon
        const beacon = document.createElement('script');
        beacon.type = 'text/javascript';
        beacon.async = true;

        beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
        beacon.onload = () => {
            window.wootric('run');
        };
        document.body.appendChild(beacon);
    };

    componentWillUnmount() {
        // Remove o setupScript
        const setupScript = document.querySelector('script[wootric-setup="true"]');
        if (setupScript) setupScript.remove();
    
        // Remove o beacon
        const beaconScript = document.querySelector('script[src="https://cdn.wootric.com/wootric-sdk.js"]');
        if (beaconScript) beaconScript.remove();

        
        // Remove configurações globais se necessário
        delete window.wootricSettings;
        delete window.wootric_survey_immediately;
        delete window.wootric_no_surveyed_cookie;
        
        this.removeWootricElement();
    };
    
    removeWootricElement() {
        if (document.getElementById('wootric-modal')) {
            document.getElementById('wootric-modal').remove()
        };
    };

    render() {
        return (
            <div />
        );
    }
}

Wootric.propTypes = {
    email: PropTypes.string,
};

const mapStateToProps = ({ auth }) => ({
    email: auth.data.user.email,
});

export default connect(mapStateToProps)(Wootric);
