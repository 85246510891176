import { css } from 'styled-components';

import { $lightred500 } from '../settings/variables';

export default css`
    .scan-status {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .scan-status > i {
        font-size: 1.6rem;
        margin: 0 .5rem;
        position: relative;
    }

    .scan-status.error {
        color: ${$lightred500};
    }
`;
