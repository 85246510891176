import PropTypes from 'prop-types'
import React from 'react'
import { Card } from '../../components'
import { FormActivePromo } from '../../containers'

export class Rescue extends React.Component {
  constructor(props) {
    super(props)
    this.title = 'Recuperação de Código Promocional'
  }

  componentDidMount() {
    this.props.setTitle(this.props.title)
  }


  render() {
    return (
      <section className="content">
        <Card>
          <div className="content">
            <FormActivePromo {...this.props} />
          </div>
        </Card>
      </section>
    )
  }
}

Rescue.propTypes = {
  setTitle: PropTypes.func,
}
