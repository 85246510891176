import { css } from 'styled-components';

export default css`
    @media (min-width: 992px) {
        .customModal {
            max-height: 600px;
            overflow-y: scroll;
            width: 800px
        }
    }
`;
