import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchProductByPromoCode } from '../../actions/products';

class ProductInfo extends React.Component {
    componentDidMount() {
        this.props.fetchProductByPromoCode(this.props.promoCodeId);
    }

    render() {
        return (
            <section className="content">
                <h2>{this.props.results.product_type}</h2>
            </section>
        );
    }
}

ProductInfo.propTypes = {
    promoCodeId: PropTypes.string.isRequired,
    fetchProductByPromoCode: PropTypes.func,
    results: PropTypes.array,
};

const mapStateToProps = ({ productInfo }) => ({
    ...productInfo,
});

export default connect(mapStateToProps, { fetchProductByPromoCode })(ProductInfo);
