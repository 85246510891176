import { css } from 'styled-components';

export default css`
    .loader {
        display: flex;
        align-content: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        background: rgba(255,255,255,.8);
        z-index: 11;
        padding: 10px;
    }

    .loader > img {
        width: 5rem;
    }

    .loader.inverse {
        background: rgba(238,238,238,0.6);
    }

    .infinity-loader {
        padding: 3rem 0;
        margin: 0 auto;
        display: table;
    }
`;
