import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableWrap, TableWrapActions, TableWrapLeft } from '../../components';
import { fetchEvidences } from '../../actions/evidences';

class EvidencesList extends React.PureComponent {
    // eslint-disable-next-line no-undef
    model = {
        created: {
            name: 'Data de Solicitação',
        },
        scan_date: {
            name: 'Data do Scan',
        },
        client: {
            name: 'Cliente',
        },
        application: {
            name: 'Aplicação',
        },
        vulnerability: {
            name: 'Vulnerabilidade',
        },
        status: {
            name: 'Status',
        },
        // 'family.title': {
        //     name: 'Família',
        //     style: { textAlign: 'left' },
        // },
        // title: {
        //     name: 'Titulo',
        //     renderer: (item, obj) => (
        //         <React.Fragment>
        //             <span>{obj.title}</span><br />
        //             <span><i>{obj.title_pt}</i></span>
        //         </React.Fragment>
        //     ),
        //     style: { textAlign: 'left' },
        // },
        // updated: {
        //     name: 'Atualizado em',
        //     renderer: formatDate,
        // },
        // translate: {
        //     name: 'Tradução',
        //     renderer: this.checkLanguages,
        // },
        // reviewed: {
        //     name: 'Revisado',
        //     filterItems: {
        //         y: 'Revisado',
        //         n: 'Não Revisado',
        //     },
        //     renderer: item => (!!item ? (
        //         <Icon name="check" className="check" />
        //     ) : <Icon name="block" style={{ color: '#ccc' }} />),
        // },
        actions: {
            name: ' ',
            renderer: (item, obj) => (
                <Link to={`/system/false-positive/${obj.id}`}>
                    Editar
                </Link>
            ),
        },
    }


    render() {
        return (
            <TableWrap
                model={this.model}
                source={this.props.evidences}
                location={this.props.location}
                actionFetch={this.props.fetchEvidences}
                hasSearch
            >
                <TableWrapActions>

                    <TableWrapLeft>
                        Lista de Evidências - Falso positivo
                    </TableWrapLeft>

                </TableWrapActions>
            </TableWrap>
        );
    }
}

EvidencesList.propTypes = {
    evidences: PropTypes.object,
    location: PropTypes.object,
    fetchEvidences: PropTypes.func,
};

const mapStateToProps = ({ evidences }) => ({ evidences });

export default connect(mapStateToProps, { fetchEvidences })(EvidencesList);
