import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

import { Card, Loading, Button } from '../../../../components'
import { Field } from '../../../../components-v2'

import {
  fetchKnowledgebaseVulnerability,
  createKnowledgebaseVulnerability,
  editKnowledgebaseVulnerability,
} from '../../../../actions/system/pentest-knowledgebase'
import { Grid } from '@mui/material'

function PentestKnowledgebaseForm(props) {
  const dispatch = useDispatch()
  const pentestKnowledgebaseVulnerability = useSelector(
    (state) => state.pentestKnowledgebaseVulnerability
  )
  const { handleSubmit, formState: control, setValue } = useForm()
  const { id } = props.match.params
  const [results, setResults] = useState({})
  const [risk, setRisk] = useState('')

  useEffect(() => {
    props.setTitle(props.title)

    async function getVulnerability() {
      await dispatch(fetchKnowledgebaseVulnerability(id))
    }

    if (id) {
      getVulnerability()
    }
  }, [dispatch, id, props])

  useEffect(() => {
    if (id) {
      setResults(pentestKnowledgebaseVulnerability)

      setRisk(pentestKnowledgebaseVulnerability.risk)

      Object.keys(pentestKnowledgebaseVulnerability).map(field =>
        setValue(field, pentestKnowledgebaseVulnerability[field]))
    } else {
      setResults({})

      setRisk('')
    }
  }, [id, pentestKnowledgebaseVulnerability, pentestKnowledgebaseVulnerability.risk, setValue])

  async function onSubmit(data) {
    if (id) {
      await dispatch(editKnowledgebaseVulnerability(id, data))
    } else {
      await dispatch(createKnowledgebaseVulnerability(data))
    }
  }

  useEffect(() => {
    props.setTitle(props.title)
  }, [props])

  return (
    <section className="content">
      <Card>
        <div className="card-content">
          <Loading
            loading={
              pentestKnowledgebaseVulnerability.isLoading ||
              (id && !pentestKnowledgebaseVulnerability.risk)
            }
          />

          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid
              style={{ padding: '2rem' }}
              container
              spacing={3}
            >

              <Grid item xs={12}>
                <Field
                  name='vulnerability_id'
                  label='Código *'
                  type='text'
                  defaultValue={results.vulnerability_id || ''}
                  onChange={({ target }) => setValue('vulnerability_id', target.value)}
                  // disabled={!!id}
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='title'
                  label='Título *'
                  type='text'
                  defaultValue={results.title || ''}
                  onChange={({ target }) => setValue('title', target.value)}
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='description'
                  label='Descrição *'
                  type='text'
                  multiline
                  defaultValue={results.description || ''}
                  onChange={({ target }) => setValue('description', target.value)}
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='ports'
                  label='Portas *'
                  type='text'
                  defaultValue={results.ports || ''}
                  onChange={({ target }) => setValue('ports', target.value)}
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='recommendation'
                  label='Recomendação *'
                  type='text'
                  multiline
                  defaultValue={results.recommendation || ''}
                  onChange={({ target }) => setValue('recommendation', target.value)}
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='references'
                  label='Referências *'
                  type='text'
                  multiline
                  defaultValue={results.references || ''}
                  onChange={({ target }) => setValue('references', target.value)}
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='risk'
                  label='Risco *'
                  type='select'
                  options={risk}
                  defaultValue={results.risk || ''}
                  onChange={({ target }) => setValue('risk', target.value)}
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>

              <Grid className='buttons' item xs={12}>
                <Button loading={props.submitting}>Salvar</Button>

                <Link to="/system/vulnerabilities-pentest/">
                  Cancelar
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Card>
    </section>
  )
}

PentestKnowledgebaseForm.propTypes = {
  params: PropTypes.object,
  route: PropTypes.object,
  submitting: PropTypes.bool,
  setBreadcrumbName: PropTypes.func,
}

export default PentestKnowledgebaseForm
