import { FETCH_PENTEST_EVIDENCE } from '../../../actions/pentests/evidence'
import { INITIAL_LIST } from '../../../services/initial-states'
import normalizedState from '../../../services/normalize'

export default (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_PENTEST_EVIDENCE}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${FETCH_PENTEST_EVIDENCE}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload)

      return { ...state, ...action.payload, results, allIds, isLoading: false }
    }
    case `${FETCH_PENTEST_EVIDENCE}_REJECTED`:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
