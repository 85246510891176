import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class InputField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: this.props.value };
        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value });
        }
    }

    handleChange(e) {
        const { value } = e.target;
        this.setState({ value });

        if (this.props.onChange) {
            return this.props.onChange(value);
        }
    }

    isRequired() {
        return !!this.props.required;
    }

    render() {
        const markRequired = (!!this.props.required ? '*' : '');
        const inputClass = classnames('input', this.props.className, {
            'input-error': !!this.props.error,
        });

        const { label, error, ...rest } = this.props;

        return (
            <div className="field">
                {label &&
                    <label htmlFor="">{`${label} ${markRequired}`}</label>
                }
                <input
                    type="text"
                    {...rest}
                    className={inputClass}
                    onChange={this.handleChange}
                    value={this.state.value}
                />

                {error &&
                    <span className="input-error">{error}</span>
                }
            </div>
        );
    }
}

InputField.defaultProps = {
    value: '',
    className: '',
};

InputField.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    required: PropTypes.bool,
    label: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
};
