import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Button, PasswordField } from '../../components'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { toFormErrors } from '../../services/utils'
import { changePassword } from '../../actions/users'

class ChangePassword extends React.Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(data) {
    return this.props.changePassword(data, this.props.userId)
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="form form-new"
      >
        <h2>
          Alterar Senha
        </h2>

        <p>
          Sua senha deve ter no mínimo 9 caracteres, contendo<br />
          número, letras, sendo pelo menos uma maiúscula e sinais especiais como #@$%!.
        </p>

        <Field
          component={TextField}
          type="password"
          name="old_password"
          label="Senha atual"
          required
          error={this.props.errors.old_password || ''}
        />

        <Field
          component={PasswordField}
          name="new_password"
          label="Nova senha"
          required
          error={this.props.errors.new_password || ''}
        />

        <Field
          component={TextField}
          type="password"
          name="repeat_password"
          label="Digite a nova senha novamente"
          required
          error={this.props.errors.repeat_password || ''}
        />

        <div className="buttons">
          <Button loading={this.props.submitting}>
            Salvar
          </Button>
        </div>
      </form>
    )
  }
}

const Form = reduxForm({
  form: 'formChangePassword',
  validate: (values) => {
    const errors = {}
    if (!values.old_password) {
      errors.old_password = 'Este campo é obrigatório'
    }
    if (!values.new_password) {
      errors.new_password = 'Este campo é obrigatório'
    }
    if (!values.repeat_password) {
      errors.repeat_password = 'Este campo é obrigatório'
    }
    if (!!values.repeat_password && values.repeat_password !== values.new_password) {
      errors.repeat_password = 'As senhas não conferem'
    }

    return errors
  },
})(ChangePassword)

ChangePassword.propTypes = {
  submitting: PropTypes.bool,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  changePassword: PropTypes.func,
  className: PropTypes.string,
  userId: PropTypes.string,
}

const mapStateToProps = ({ auth, users }) => ({
  userId: auth.data.user.id,
  errors: toFormErrors(users.errors),
})

export default connect(mapStateToProps, { changePassword })(Form)
