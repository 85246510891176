import { OPEN, CLOSE, CLEAR } from '../actions/slider'
import { LOCATION_CHANGE } from 'connected-react-router'

const INITIAL_STATE = {
  isOpen: false,
  title: '',
  content: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN:
      return { ...state, ...action.payload, isOpen: true }
    case CLOSE:
      return { ...state, isOpen: false }
    case CLEAR:
      return { ...state, content: '' }
    case LOCATION_CHANGE:
      return { ...state, isOpen: false }
    default:
      return state
  }
}
