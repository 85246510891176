import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reduxForm, reset } from 'redux-form';
import { toFormErrors } from '../../services/utils';
import { connect } from 'react-redux';
import { Button, Loading } from '../../components';
import { createPromoCodes } from '../../actions/promocode';
import { SelectAccountsField, ProductInfo } from '../../containers';

class FormActivePromo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = { promoCodeId: this.props.match.params.id };
    }

    // componentDidMount() {
    //     if (this.props.edit) {
    //         this.props.setBreadcrumbName(<Loading loading />);
    //     }
    // }

    onSubmit(data) {
        return this.props.createPromoCodes({
            ...data,
            expiration: this.state.date,
            product_type_id: data.product_type,
        });
    }

    onChange(date) {
        this.setState({ date: new Date(date).toISOString() });
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="form">

                <Loading loading={this.props.isLoading} />

                <ProductInfo promoCodeId={this.state.promoCodeId} />
                <SelectAccountsField
                    label="Em qual conta deseja utilizar o cupom?"
                    name="account"
                    promoCodeId={this.state.promoCodeId}
                    error={this.props.errors.product_type || ''}
                />

                <div className="buttons">
                    <Button
                        loading={this.props.submitting}
                    >
                        Resgatar
                    </Button>
                    <Link to="/">Cancelar</Link>
                </div>
            </form>
        );
    }
}

FormActivePromo.propTypes = {
    setBreadcrumbName: PropTypes.func,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    submitting: PropTypes.bool,
};

const Form = reduxForm({
    form: 'formPromo',
})(FormActivePromo);

const mapStateToProps = ({ form }) => ({
    isLoading: form.formPromo.isLoading,
    errors: toFormErrors(form.formPromo.errors),
});

export default connect(mapStateToProps, { createPromoCodes, reset })(Form);
