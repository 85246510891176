import styled from 'styled-components'

export const Container = styled.section`
  .description {
      .badge {
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 10rem;
          margin: .2rem 1rem 0 0;
          padding: 0;
      }
  }
  .MuiTypography-root > p > img {
      max-width: 100%;
      height: auto;
  }
`
