import { css } from 'styled-components';

import { $level1, $level2, $level3, $level4, $level5 } from '../settings/variables';

export default css`
    .vulnerability-level {
        margin-bottom: 2.5rem;
    }

    .vulnerability-level > .title {
        display: flex;
        justify-content: space-between;
    }

    .vulnerability-level > .title i {
        font-size: 2rem;
    }

    .vulnerability-level > .numbers {
        text-align: center;
        display: flex;
        font-size: 1.2rem;
    }

    .vulnerability-level > .numbers.with-box {
        font-size: 1rem;
        justify-content: flex-start;
        margin-bottom: .5rem;
        color: #fff;
        font-weight: bold;
        line-height: 1;
    }

    .vulnerability-level > .numbers.with-box span {
        min-width: 2rem;
        height: 1.1rem;
        padding: 0 .5rem;
    }

    .vulnerability-level > .numbers.with-box span:not(:first-child) {
        margin-left: .5rem;
    }

    .vulnerability-level > .numbers.with-box .level1 {
        background-color: ${$level1};
    }

    .vulnerability-level > .numbers.with-box .level2 {
        background-color: ${$level2};
    }

    .vulnerability-level > .numbers.with-box .level3 {
        background-color: ${$level3};
    }

    .vulnerability-level > .numbers.with-box .level4 {
        background-color: ${$level4};
    }

    .vulnerability-level > .numbers.with-box .level5 {
        background-color: ${$level5};
    }

    .vulnerability-level > .description {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
    }

    .vulnerability-level > .description > * {
        flex: 3;
    }

    .vulnerability-level > .description > *:last-child {
        text-align: right;
    }

    .vulnerability-level > .description > .total {
        text-align: center;
        flex: 1;
    }

    .vulnerability-level > .description .looping {
        left: initial;
        margin: 0 0 0 .3rem;
        font-size: 1.7rem;
        color: #74B61B;
    }

    .vulnerability-level .last-scan i {
        font-size: 1.8rem;
    }

    .vulnerability-level .next-scan > a {
        margin-left: .5rem;
    }

    .vulnerability-level > .vulnerability-bar {
        box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.26);
        background-color: #F7F7F7;
        border-radius: 0.25rem;
        display: flex;
        height: .7rem;
        width: 100%;
        overflow: hidden;
        margin: 0 0 1rem 0;
    }

    .vulnerability-level > .vulnerability-bar > span {
        height: .7rem;
        mix-blend-mode: multiply;
    }

    .vulnerability-level > .vulnerability-bar > span.level1 {
        background-color: ${$level1};
    }

    .vulnerability-level > .vulnerability-bar > span.level2 {
        background-color: ${$level2};
    }

    .vulnerability-level > .vulnerability-bar > span.level3 {
        background-color: ${$level3};
    }

    .vulnerability-level > .vulnerability-bar > span.level4 {
        background-color: ${$level4};
    }

    .vulnerability-level > .vulnerability-bar > span.level5 {
        background-color: ${$level5};
    }
`;
