import { fetch } from '../../../services/fetch';
import { setMessage, SUCCESS, ERROR } from '../../../modules/messages';

export const FETCH_PENTEST_REPORT = '@pentests/FETCH_PENTEST_REPORT';
export const CREATE_PENTEST_REPORT = '@pentests/CREATE_PENTEST_REPORT';
export const UPDATE_PENTEST_REPORT = '@pentests/UPDATE_PENTEST_REPORT';

export function fetchPentesReport(accountId, pentestId, reportId) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/${reportId}`;

    const request = fetch(url).then(res => res.json());

    return {
        type: FETCH_PENTEST_REPORT,
        payload: request,
    };
}

export function createPentestReport(accountId, pentestId, reportId, data) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/${reportId}`;

    return (dispatch) => {
        const request = fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(res => {
            dispatch(setMessage(SUCCESS, 'Evidência cadastrada com sucesso!'));

            return res.json();
        }).catch(err => {
            const message = err.message || 'Erro ao cadastrar Evidência';

            dispatch(setMessage(ERROR, message));

            throw err;
        });

        dispatch({
            type: CREATE_PENTEST_REPORT,
            payload: request,
        });
    };
}
export function updatePentestReport(accountId, pentestId, reportId, data) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/${reportId}`;

    return (dispatch) => {
        const request = fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(res => {
            dispatch(setMessage(SUCCESS, 'Relatório atualizado com sucesso!'))
            return res.json()
        })
        .catch(err => {
            const message = err.message || 'Erro ao atualizar';

            dispatch(setMessage(ERROR, message));

            throw err;
        });

        dispatch({
            type: UPDATE_PENTEST_REPORT,
            payload: request,
        });
    };
}
