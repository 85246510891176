import {FETCH_SUMMARY_PRODUCTS,} from '../actions/products'
import {LOGOUT_USER} from '../actions/auth'


const INITIAL_STATE = {
  currentProduct: {},
  results: [],
  chart: [],
  isLoading: false
}

export const summaryProducts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_SUMMARY_PRODUCTS}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_SUMMARY_PRODUCTS}_FULFILLED`: {
      return {...state, results: action.payload, isLoading: false}
    }
    case LOGOUT_USER:
      return INITIAL_STATE
    default:
      return state
  }
}
