import React, { useState } from 'react'
import { Grid, Menu } from '@mui/material'
import PropTypes from 'prop-types'

export const Dropdown = props => {
  const { children, button, style } = props

  const [open, setOpen] = useState(false)

  const handleOpen = e => {
    e.preventDefault()
    setOpen(e.currentTarget)
  }

  const handleClose = e => {
    e.preventDefault()
    setOpen(false)
  }

  return (
    <div className="dropdown dropdown-right">
      <Grid onClick={handleOpen}>
        {button}
      </Grid>

      <Menu
        style={style}
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
      >
        {children && (
          React.Children.map(children, child => (
            React.cloneElement(
              <div onClick={handleClose}>
                {child}
              </div>
            )
          )))
        }
      </Menu>
    </div>
  )
}

Dropdown.propTypes = {
  classes: PropTypes.string,
  button: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.any,
}
