import { StaticDateRangePicker, DateRangePickerDay } from '@mui/lab'
import { Box, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'

const DateRange = styled(DateRangePickerDay)(
  ({ theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting }) => ({
    ...(isHighlighting && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.light,
        borderColor: `${theme.palette.primary.light} !important`
      },
    }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      borderColor: theme.palette.primary.light
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      borderColor: theme.palette.primary.light
    }),
  }),
)

const CalendarDatePicker = props => {
  const [value, setValue] = useState([null, null])
  const [minDate, setMinDate] = useState(null)

  useEffect(() => {
    const formattedDate = date => date.format('DD/MM/YYYY')

    value[0] && value[1]
      ? formattedDate(value[0]) === formattedDate(value[1])
        ? setMinDate(null)
        : setMinDate(value[0])
      : value[0] && setMinDate(value[0])
  }, [value])

  const renderWeekPickerDay = (date, dateRangePickerDayProps) =>
    <DateRange {...dateRangePickerDayProps} />

  const handleChange = date => {
    props.handleChange(date)
    setValue(date)
  }

  const disableWeekends = date =>
    date.day() === 6 ||
    date.day() === 0 ||
    date < moment().subtract(1, 'day') ||
    date < minDate

  return (
    <StaticDateRangePicker
      {...props}
      displayStaticWrapperAs="desktop"
      label="date range"
      value={value}
      onChange={handleChange}
      renderDay={renderWeekPickerDay}
      shouldDisableDate={disableWeekends}
      renderInput={(startProps, endProps) => (
        <Fragment>
          <TextField {...startProps} error={props.error} style={{ fontSize: '1.4rem' }} />
          <Box sx={{ mx: 2 }}> to </Box>
          <TextField {...endProps} error={props.error} style={{ fontSize: '1.4rem' }} />
        </Fragment>
      )}
    />
  )
}

export default CalendarDatePicker
