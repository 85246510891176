import { css } from 'styled-components';

export default css`
    .field-group {
        display: inline-flex !important;
        flex-flow: row nowrap;
        vertical-align: middle;
        width: 100%;
    }

    .field-group button {
        width: initial;
        line-height: 0rem;
        white-space: nowrap;
        padding: 1.7rem;
        text-align: center;
        border-left-color: #d9d9d9;
        cursor: pointer;
        order: 0;

        border-radius: .4rem;
    }

    .field-group .file {
        display: none;
        order: -1;
    }

    .field-group.field-group-normal .input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        order: 1;
    }

    .field-group.field-group-normal button {
        border-left-color: #d9d9d9;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        order: 0;
    }

    .field-group.field-group-reverse .input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        order: 0;
    }

    .field-group.field-group-reverse button {
        border-left-color: #d9d9d9;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        order: 1;
    }
`;
