import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import { connect } from 'react-redux'

const Menu = ({ items, roleUser }) => {
  if (!items) {
    return false
  }

  return (
    <nav className="submenu">
      <ul className="list">
        {items
          .filter(item => !(item.authorized === false))
          .map((item) => (
            (item.beta && roleUser !== 'admin') ? '' :
              <li key={item.route} className={classnames({ beta: item.beta })}>
                <NavLink exact to={item.route} activeClassName="active">{item.name}</NavLink>
              </li>
          )
          )}
      </ul>
    </nav>
  )
}

Menu.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  roleUser: PropTypes.string,
}

const mapStateToProps = ({ auth }) => ({ roleUser: auth.data.role })
const SubMenuWrapper = (props) => (
  <Menu items={props.items} roleUser={props.roleUser} />
)

SubMenuWrapper.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  roleUser: PropTypes.string,
}

export const SubMenu = connect(mapStateToProps)(SubMenuWrapper)
