import React, {memo, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {Content} from "../../components";
import store from 'store';


export function IndexSettings(props) {
  const [title, setTitle] = useState('Configurações');
  const submenu = [
    {
      route: '/settings/organization',
      name: 'Dados da Organização',
      authorized: (store.get('current_client_type') === 'organization'),
    },
    {
      route: '/settings/users',
      name: 'Meus Usuários',
      authorized: (store.get('current_client_type') === 'organization'),
    },
    {route: '/settings/profile', name: 'Meus Dados'},
    {route: '/settings/security', name: 'Senha'},
    {route: '/settings/two-factor-auth', name: 'Segurança'},
  ];

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}
