import { useEffect } from 'react'
import { ScanApiList } from '../../../containers'

export function ScanApi(props) {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <article className="content">
      <ScanApiList {...props} />
    </article>
  )
}

