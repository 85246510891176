import React from 'react';
import { userIsAdmin } from '../../services/auth';
import { SliderButton, FormApplication } from '../../containers';
import { Icon } from '../Icon';

export const ApplicationDetails = userIsAdmin(props => (
    <SliderButton
        button={<Icon name="settings" />}
        sliderTitle="Configuração da aplicação"
    >
        <FormApplication {...props} />

    </SliderButton>
));
