import { SET_TITLE } from '../actions/title';

const INITITAL_STATE = [];

export default(state = INITITAL_STATE, action) => {
    switch (action.type) {
        case SET_TITLE:
            return { ...action.payload };
        default:
            return { ...state };
    }
};
