import { css } from 'styled-components';

import { $blue400, $lightred400, $green400 } from '../settings/variables';

export default css`
    .alert, .alert-info, .alert-error, .alert-success, .alert-default {
        position: fixed;
        width: 100%;
        top: 1rem;
        right: 0;
        display: flex;
        justify-content: center;
        z-index: 3000;
        color: #fff;
        pointer-events: none;
        transition: all 0.5s ease-out;
    }

    .alert > .content,
    .alert-info > .content,
    .alert-error > .content,
    .alert-success > .content,
    .alert-default > .content {
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        pointer-events: visible;
        border-radius: .4rem;
        box-shadow: 0 .3rem .3rem rgba(0, 0, 0, 0.2);
    }

    .alert > .content > span,
    .alert-info > .content > span,
    .alert-error > .content > span,
    .alert-success > .content > span,
    .alert-default > .content > span {
        padding: 2rem;
        font-size: 1.6rem;
    }

    .alert > .content .message,
    .alert-info > .content .message,
    .alert-error > .content .message,
    .alert-success > .content .message,
    .alert-default > .content .message {
        display: flex;
        min-width: 25rem;
        margin: 0;
    }

    .alert > .content .message i,
    .alert-info > .content .message i,
    .alert-error > .content .message i,
    .alert-success > .content .message i,
    .alert-default > .content .message i {
        margin-right: 10px;
    }

    .alert > .content .close,
    .alert-info > .content .close,
    .alert-error > .content .close,
    .alert-success > .content .close,
    .alert-default > .content .close {
        cursor: pointer;
    }

    .alert-info > .content {
        background-color: ${$blue400};
    }

    .alert-info > .content .close {
        background-color: darken(${$blue400}, 10%);
    }

    .alert-error > .content {
        background-color: ${$lightred400};

    }

    .alert-error > .content .close {
        background-color: darken(${$lightred400}, 10%);
    }

    .alert-success > .content {
        background-color: ${$green400};
    }

    .alert-success > .content .close {
        background-color: darken(${$green400}, 10%);
    }

    .alert-default > .content {
        background-color: ${$blue400};
    }

    .alert-default > .content .close {
        background-color: darken(${$blue400}, 10%);
    }
`;
