import PropTypes from 'prop-types';
import React from 'react';
import { SwitchButton, Button, TextField, QRCodeGenerator } from '../../components';
import { Field, reduxForm, reset, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { setTwoFactorAuth } from '../../actions/two-factor-auth';
import appstore from '../../../assets/images/appstore.png';
import gplay from '../../../assets/images/gplay.png';

class TwoFactorAuth extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            otpEnabled: this.props.otpEnabled,
            onAuth: false,
            secret: '',
        };

        this.state = this.initialState;
        this.onSubmit = this.onSubmit.bind(this);
        this.handleActive = this.handleActive.bind(this);
    }

    onSubmit(items) {
        const data = { ...items, otp_enabled: this.state.otpEnabled };
        return this.props.setTwoFactorAuth(this.props.userId, data)
        .then(({ value }) => {
            if (value.secret) {
                this.setState({ secret: value.secret, onAuth: false });
            }

            if (value.finished) {
                this.setState(() => ({ ...this.initialState, otpEnabled: !this.props.otpEnabled }));
                this.props.reset('formTwoFactorAuth');
            }
        });
    }

    handleActive(otpEnabled) {
        this.setState({
            otpEnabled,
            onAuth: true,
        });
    }

    render() {
        return (
            <div className="two-factor">
                <article>
                    <h2>Autenticação em 2 passos</h2>
                    <p className="description">
                        A autenticação em dois passos adiciona um nível extra de proteção à sua conta.<br />
                        Sempre que você acessar o portal da Site Blindado precisará digitar a sua senha<br />
                        e também um código de segurança do <strong>Google Authenticator</strong>.
                    </p>

                    <p className="description">
                        Caso não possua o Google Authenticator clique abaixo na loja de aplicativos <br />
                        do seu celular e o instale antes de continuar.
                    </p>

                    <div className="mobile-icons">
                        <a
                            href="//play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                            className="item"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={gplay} alt="Disponível no Google Play" />
                        </a>
                        <a
                            href="//itunes.apple.com/br/app/google-authenticator/id388497605?mt=8"
                            className="item"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={appstore} alt="Disponível na App Store" />
                        </a>
                    </div>

                    <form className="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <p>
                            <strong>Autenticação em 2 passos</strong>
                        </p>

                        <span className="enable-step">
                            <SwitchButton
                                enabled={this.state.otpEnabled}
                                onClick={this.handleActive}
                            />

                            <span>{this.state.otpEnabled ? 'Ativado' : 'Desativado'}</span>
                        </span>

                        {this.state.onAuth &&
                            <article>
                                <Field
                                    type="password"
                                    name="password"
                                    label="Digite sua senha"
                                    // error={this.props.errors.password || ''}
                                    component={TextField}
                                />
                                <Button type="submit" loading={this.props.submitting} disabled={this.props.pristine}>
                                    Confirmar senha
                                </Button>
                            </article>
                        }

                        {(this.state.secret && this.state.otpEnabled) &&
                            <article>
                                <h3>Passo a passo leitor QRCode</h3>

                                <p>1 - Abra o Google Authenticator no seu celular;</p>
                                <p>2 - Clique no ícone de + para adiconar uma nova conta;</p>
                                <p>3 - Selecione ler código de barras;</p>
                                <p>4 - Mire a camera do seu celular para o QR Code abaixo.</p>

                                <QRCodeGenerator
                                    issuer="Site Blindado"
                                    label={`${encodeURI('Site Blindado')}:${encodeURI(this.props.email)}`}
                                    secret={this.state.secret}
                                />

                                <Field
                                    label="Digite o código de 6 digitos exibido no app"
                                    name="otp"
                                    component={TextField}
                                    // error={this.props.errors.otp || ''}
                                />

                                <Button disabled={this.props.emptyOtp} loading={this.props.submitting}>
                                    Enviar
                                </Button>
                            </article>
                        }
                    </form>


                </article>
            </div>
        );
    }
}

TwoFactorAuth.propTypes = {
    setTwoFactorAuth: PropTypes.func,
    reset: PropTypes.func,
    handleSubmit: PropTypes.func,
    otpEnabled: PropTypes.bool,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    emptyOtp: PropTypes.bool,
    errors: PropTypes.object,
    userId: PropTypes.string,
    email: PropTypes.string,
};

const Form = reduxForm({
    form: 'formTwoFactorAuth',
})(TwoFactorAuth);

const selector = formValueSelector('formTwoFactorAuth');

const mapStateToProps = (state) => ({
    otpEnabled: state.auth.data.user.otp,
    email: state.auth.data.user.email,
    userId: state.auth.data.user.id,
    // errors: toFormErrors(state.form.formTwoFactorAuth.errors),
    emptyOtp: !selector(state, 'otp'),
});

export default connect(mapStateToProps, { setTwoFactorAuth, reset })(Form);
