import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import AlertDialog from '../AlertDialog'
import {Controller, useForm} from 'react-hook-form'
import {Button, Loading, WysiwygEditor} from '../../../../components'
import {updatePentestReport} from '../../../../actions/pentests/report'
import {Card} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Container} from './styles'

const useStyles = makeStyles({
  cardContent: {
    overflowX: 'auto !important',
    boxShadow: 'none',
  },
})

function PenTestConclusion(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const accountId = useSelector(state => state.account[0].id)
  const pentestReport = useSelector(state => state.pentestReport)

  const [openDialog, setOpenDialog] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: {errors},
  } = useForm()

  const {id: pentestId, report_id: reportId} = props.match.params

  useEffect(() => {
    if (pentestReport.results.final_resume) {
      setValue('final_resume', pentestReport.results.final_resume)
    }
  }, [pentestReport.results.final_resume])

  const goBack = event => {
    event.preventDefault()

    const {final_resume: validateFinalResume} = getValues()
    const {final_resume: oldFinalResume} = pentestReport.results

    oldFinalResume !== validateFinalResume
      ? setOpenDialog(true)
      : props.backStep()
  }

  const onSubmit = async (data) => {
    await dispatch(updatePentestReport(accountId, pentestId, reportId, data))
  }

  const finishReport = async event => {
    event.preventDefault()

    await props.finishWizard()
  }

  return (
    <Container>
      <Card className={classes.cardContent}>
        <div className="card-content">
          <Loading loading={pentestReport.isLoading}/>

          <form
            className="form"
            onSubmit={handleSubmit(onSubmit)}
            style={{maxWidth: '100%'}}
          >
            <div className="field">
              <label>
                Considerações finais *
              </label>

              <Controller
                render={({field}) =>
                  <WysiwygEditor
                    {...field}
                    {...register('final_resume', {
                      required: true,
                      validate: value => value !== ' ',
                    })}
                    text={getValues().final_resume}
                    setText={value => setValue('final_resume', value)}
                  />
                }
                defaultValue={pentestReport.results.final_resume}
                name='final_resume'
                control={control}
              />

              {errors.final_resume && (
                <span className="input-error">
                  Este campo é obrigatório *
                </span>
              )}
            </div>

            <div className="buttons">
              <Button onClick={goBack}>
                Anterior
              </Button>

              <Button
                style={{marginLeft: 10}}
                type="submit"
              >
                Salvar
              </Button>

              <Button
                style={{marginLeft: 10}}
                onClick={finishReport}
              >
                Finalizar Relatório
              </Button>
            </div>

            <AlertDialog
              open={openDialog}
              keepEditing={() => setOpenDialog(false)}
              onClose={props.backStep}
            />
          </form>
        </div>
      </Card>
    </Container>
  )
}

PenTestConclusion.propTypes = {
  params: PropTypes.object,
  prevStep: PropTypes.func,
  finishWizard: PropTypes.func,
}

export default PenTestConclusion
