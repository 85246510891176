import { useEffect } from 'react'
import { FormServer } from '../../containers'
import { Card } from '../../components'

export const Server = (props) => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <div className="card-content">
          <FormServer {...props} />
        </div>
      </Card>
    </section>
  )
}
