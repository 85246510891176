import React, { useState } from "react";
import { Content, Card } from "../../components";
import { IconMdLock } from "../../sb2w-icons/md";

export function ListManuals(props) {
    const [title] = useState("Lista de Manuais");

    const [manuals] = useState([
        {
            id: 1,
            title: "Ativação de Certificado",
            description:
                "Este manual fornece um guia passo a passo detalhado para a ativação autônoma de certificados SSL pelo cliente no portal. Cobrindo desde a seleção do produto desejado até a validação final, inclui instruções para gerar a chave CSR no webserver, detalhes sobre o tamanho da chave e informações necessárias para a validação. Além disso, aborda os diferentes métodos de validação disponíveis, como e-mail, hash e DNS, garantindo um processo de ativação seguro e eficiente.",
            link: "/manuals/how-to-activate-ssl",
            icon: IconMdLock,
        },
        {
            id: 2,
            title: "Scan de SSL - Notas",
            description:
                "Este manual aborda metodologias de avaliação de segurança de certificados SSL. Ele detalha a verificação da validade e confiabilidade dos certificados, a inspeção da configuração do servidor em suporte de protocolo, troca de chaves e suporte de cifras, além de fornecer uma estrutura de pontuação que combina esses aspectos em uma nota final. A ênfase é colocada na importância de certificados confiáveis para a segurança da comunicação de rede e na identificação de configurações inseguras que poderiam comprometer a criptografia SSL.",
            link: "/manuals/ssl-report",
            icon: IconMdLock,
        }
    ]);

    const styles = {
        container: {
            padding: "2rem",
        },
        grid: {
            display: "grid",
            gridTemplateColumns:
                "repeat(auto-fill, minmax(calc(33.333% - 2rem), 1fr))",
            gap: "2rem",
        },
        card: {
            padding: "1.5rem",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center"
        },
        link: {
            textDecoration: "none",
            color: "inherit",
        },
    };

    return (
        <Content title={title} {...props}>
            <div style={styles.container}>
                <div style={styles.grid}>
                    {manuals.map((manual) => (
                        <a
                            href={manual.link}
                            style={styles.link}
                            key={manual.id}
                        >
                            <Card
                                style={styles.card}
                                className="text-center mb-2 card-manuals"
                            >
                                <manual.icon size={40} className="mb-2" />
                                <h3>{manual.title}</h3>
                                <p>{manual.description}</p>
                            </Card>
                        </a>
                    ))}
                </div>
            </div>
        </Content>
    );
}
