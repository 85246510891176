import React, { memo, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Content } from "../../components";


export function IndexArmour (props) {
  const [title, setTitle] = useState('Blindagem de Sites');
  const location = useLocation()
  const history = useHistory()
  //Pegar user staff
  const submenu = (location.pathname === '/armour/applications' || location.pathname === '/armour/scan-api')
    ? [
      {
        route: '/armour/applications',
        name: 'Blindagem de Sites'
      },
      {
        route: '/armour/scan-api',
        name: 'Blindagem de API',
        beta: true
      },
    ]
    : []

  useEffect(() => {
    if (location.pathname === '/armour') {
      history.push('armour/applications')
    }
  }, [])

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}

