import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import serialize from 'form-serialize'
import { Link } from 'react-router-dom'
import { GenericContainer } from '../../containers'
import { InputField, PasswordStrengthField, Button } from '../../components'
import { loginUserSuccess } from '../../actions/auth'
import { fetchMruAccounts } from '../../actions/account'
import { setMessage, SUCCESS, ERROR } from '../../modules/messages'
import get from 'lodash/get'

// TODO: Precisa tirar o GenericContainer e refatorar o component.
export class FormPassword extends GenericContainer {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      recoverSend: false,
    }

    this.endpoint = '/auth/password/reset/confirm'
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    const data = serialize(this.form, { hash: true })

    if (data.new_password === data.cover) {
      delete data.cover

      data.uid = this.props.match.params.id
      data.token = this.props.match.params.hash

      this.send(data)
    } else {
      this.props.setMessage(ERROR, 'As senhas precisam estar idênticas em ambos os campos')
    }
  }

  send(data) {
    const endpoint = this.props.endpoint || this.endpoint

    this.post({ endpoint, data })
      .then(({ access_token }) => {
        this.props.setMessage(SUCCESS, 'Senha criada com sucesso!')

        this.setState({ recoverSend: true })

        this.props.loginUserSuccess(access_token)
        this.props.fetchMruAccounts(access_token)
        this.props.push('/')
      })
      .catch((err) => {
        const message = err.message || get(err, 'errors[0].message', 'Erro ao alterar senha')
        this.props.setMessage(ERROR, message)
      })
  }

  render() {
    return (
      <article>
        <div className="center-items">
          <h1 className="title">Definir Senha</h1>
          {!this.state.recoverSend &&
            <p className="description">Sua senha deve ter no mínimo 9 caracteres contendo <br />
              número, letras, sendo pelo menos uma maiúscula e sinais  especiais como #@$%&amp;.</p>
          }
        </div>
        {!this.state.recoverSend &&
          <form onSubmit={this.handleSubmit} ref={(form) => { this.form = form }} className="form">

            <PasswordStrengthField
              name="new_password"
              placeholder="Digite sua nova senha"
            />

            <InputField
              type="password"
              name="cover"
              className="input-lg"
              placeholder="Digite sua nova senha mais uma vez"
              required
            />

            <Button>Salvar Senha</Button>
            <Link to="/login">Retornar para a página de login</Link>
          </form>
        }

        {this.state.recoverSend &&
          <Link to="/login" className="button-primary" style={{
            width: '30rem',
            margin: '0 auto',
            display: 'block',
          }}>
            Entrar no portal
          </Link>
        }
      </article>
    )
  }
}

FormPassword.propTypes = {
  endpoint: PropTypes.string,
  loginUserSuccess: PropTypes.func,
  push: PropTypes.func,
}

export default connect(null, { loginUserSuccess, fetchMruAccounts, push, setMessage })(FormPassword)
