import {css} from 'styled-components';

export default css`
  .panel {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    z-index: 10;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.24), 0 9px 18px 0 rgba(0, 0, 0, 0.5);
  }

  .panel-content {
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .input-container {
    padding: 10px;
  }
   
  .panel-content p {
    margin-bottom: 1rem;
  }

  .panel-content > .panel-header {
    position: relative;
    text-align: center;
    height: 50px;
  }

  .panel-content > .panel-header > h1 {
    font-size: 2.5rem;
    margin: 0;
    position: relative;
  }

  .panel-content > .close {
    position: relative;
    text-align: right;
    cursor: pointer;
    top: 1%;
    right: -45%;
    transform: translate(0px, -50%);
    line-height: normal;
    height: 3rem;
    color: #21272E;
  }

  .panel-content > .close i {
    font-size: 3rem;
  }

  .panel > .panel-content {
    padding: 1rem 2rem;
    overflow: auto;
    position: relative;
    height: calc(100% - 50px);
  }

  .panel > .panel-content .side {
    display: inline-flex;
    width: 27rem;
  }

  .panel > .panel-content .inline-items {
    display: flex;
  }

  .panel > .panel-content .inline-items > div {
    flex: 1 1 auto;
  }

  .panel .code-block > pre {
    max-width: 42rem;
  }

  .panel .product-details h3,
  .panel .product-details p {
    margin-bottom: 2rem;
  }
`;
