import { css } from 'styled-components';

import {
    $bodyBgColor,
    $bodyFontColor,
    $fontFamilySansSerif,
    $fontSizeBase,
    $spaceBase,
    spaceBase,
} from '../settings/variables';

export default css`
    html, body {
        min-height: 100%;
        display: table;
        width: 100%;
    }

    body {
        font-family: ${$fontFamilySansSerif};
        font-size: ${$fontSizeBase};
        padding: 0;
        margin: 0;
        background-color: ${$bodyBgColor};
        color: ${$bodyFontColor};
        background-position: center center;
        background-size: cover;
    }

    h2 {
        margin: ${spaceBase * 2}rem 0 ${$spaceBase} 0;
    }

    a {
        color: #357623;
        cursor: pointer;
    }

    a:hover,
    a:active,
    a:visited, s
    a:focus {
        text-decoration: none;
    }

    a:focus {
        color: inherit;
    }

    a[disabled],
    button[disabled] {
        pointer-events: none;
        opacity: .6;
    }

    [hidden] {
        display: none !important;
    }

    .card-manuals:hover {
        color: #357623;
        transition: all .3s ease-out;
    }
`;
