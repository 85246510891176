import { useState, useEffect } from 'react'
import { PageTitle } from '../../../components'
import {
  Card,
  Grid,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
} from '@mui/material'
import PenTestForm from '../Form'
import PenTestTechnicalDetails from './TechnicalDetails'
import PenTestDescriptionTexts from './DescriptionTexts'
import PenTestVulnerabilities from './Vulnerabilities'
import PenTestConclusion from './Conclusion'
import { makeStyles } from '@material-ui/styles'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPentest, updatePentest } from '../../../actions/pentests'
import { fetchPentesReport } from '../../../actions/pentests/report'
import { ERROR, setMessage } from '../../../modules/messages'
import { statusList } from '../../../services/pentest-fields'
import _ from 'lodash';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
  container: {
    width: '96%',
    margin: '0 auto',
  },
  card: {
    margin: '15px auto',
    boxShadow: 'none !important',
  },
  cardContent: {
    height: 'calc(100vh - 22rem)',
    backgroundColor: '#ffff',
    boxShadow: 'none !important',
    color: '#95959c !important',
  },
  formTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '30px 20px',
  },
  itemContainer: {
    padding: '5px 20px',
    marginBottom: 15,
  },
  wizardSideBar: {
    height: 'calc(100vh - 22rem)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wizardSetpsContainer: {
    backgroundColor: '#71717100',
  },
  collapse: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
})

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7DC671',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7DC671',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#c1c1c3',
    borderTopWidth: 3,
    borderLeftWidth: 2,
    borderRadius: 1,
  },
  [`& .MuiStepIcon-root`]: {
    color: 'rgb(172 172 172 / 38%)',

  },
}))

export default function PenTestEdit(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { id: pentestId, report_id: reportId } = props.match.params

  const account = useSelector(state => state.account)
  const pentestReport = useSelector(state => state.pentestReport)
  const pentest = useSelector(state => state.pentest)
  const reportUpdated = useSelector(state => state.pentestReportUpdate)
  const [step, setStep] = useState(0)

  useEffect(() => {
    if (account[0]) {
      const id = account[0].id

      const getReport = async () => {
        await dispatch(fetchPentesReport(id, pentestId, reportId))
      }

      getReport()
    } else {
      return props.history.push('/pentest/list')
    }
  }, [step, reportUpdated.isLoading])

  useEffect(() => {
    props.setTitle(props.title)

    if (account[0]) {
      const id = account[0].id

      const getPentest = async () =>
        await dispatch(fetchPentest(id, pentestId))

      getPentest()
    }
  }, [])

  const steps = [
    {
      name: 'Textos descritivos',
      complete:
        pentestReport.results.exec_summary &&
        pentestReport.results.metodology &&
        pentestReport.results.about_sb &&
        pentestReport.results.about_report
    },
    {
      name: 'Detalhamento Técnico',
      complete:
        pentestReport.results.technical_doc,
    },
    {
      name: 'Vulnerabilidades',
      complete:
        !_.isEmpty(pentestReport.results.vulnerability)
    },
    {
      name: 'Conclusão',
      complete:
        pentestReport.results.final_resume
    },
  ]

  const finishWizard = async () => {
    const {
      about_report,
      about_sb,
      exec_summary,
      final_resume,
      metodology,
      technical_doc,
      vulnerability
    } = pentestReport.results

    if (!exec_summary || !metodology || !about_sb || !about_report) {
      await dispatch(setMessage(ERROR, 'Textos descritivos devem ser preenchidos'))
      return setStep(0)
    }

    if (!technical_doc) {
      await dispatch(setMessage(ERROR, 'Detalhamento técnico é obrigatório'))
      return setStep(1)
    }

    if (_.isEmpty(vulnerability)) {
      await dispatch(setMessage(ERROR, 'É necessário adicionar pelo menos uma vulnerabilidade'))
      return setStep(2)
    }

    if (!final_resume) {
      await dispatch(setMessage(ERROR, 'Conclusão deve ser preenchida'))
      return setStep(3)
    }

    let FormattedSendData = {
      ...pentest.results,
    }

    if (pentest.results.has_retest) {
      FormattedSendData = {
        ...FormattedSendData,
        retest_exec_start: pentest.results.retest[0].retest_exec_start,
        retest_exec_end: pentest.results.retest[0].retest_exec_end,
        retest_pentester: pentest.results.retest[0].retest_pentester,
      }
    }

    delete FormattedSendData.retest
    delete FormattedSendData.id

    await dispatch(updatePentest(account[0].id, pentestId,
      {
        ...FormattedSendData,
        status: statusList.done.value,
      }
    ))

    return history.push('/pentest/list')
  }

  const handleChangeStep = index => setStep(index)

  const mountStepContent = item => {
    switch (item) {
      case 0:
        return (
          <PenTestDescriptionTexts
            nextStep={() => setStep(step + 1)}
            backStep={() => history.push('/pentest/list')}
            {...props}
          />
        )
      case 1:
        return (
          <PenTestTechnicalDetails
            nextStep={() => setStep(step + 1)}
            backStep={() => setStep(step - 1)}
            {...props}
          />
        )
      case 2:
        return (
          <PenTestVulnerabilities
            nextStep={() => setStep(step + 1)}
            backStep={() => setStep(step - 1)}
            {...props}
          />
        )
      case 3:
        return (
          <PenTestConclusion
            backStep={() => setStep(step - 1)}
            finishWizard={finishWizard}
            {...props}
          />
        )
      default:
        return <PenTestForm {...props} />
    }
  }

  return (
    <Grid className={classes.container}>
      <Card className={classes.card}>
        <PageTitle title="Edição de relatório" />
      </Card>

      <Grid item xs>
        <Card className={classes.cardContent}>
          <Grid container>
            <Grid
              className={classes.wizardSideBar}
              item
              xs={2}
            >
              <Stepper
                className={classes.wizardSetpsContainer}
                activeStep={step}
                nonLinear
                orientation="vertical"
                connector={<QontoConnector />}
              >
                {steps.map((s, index) =>
                  <Step
                    key={index}
                    onClick={() => handleChangeStep(index)}
                    completed={s.complete}
                    sx={{
                      '& .MuiStepIcon-root.Mui-active': {
                        color: '#ebc924a6'
                      },
                      '& .MuiStepIcon-root': {
                        ':hover': {
                          color: '#7dc67185',
                        }
                      }
                    }}
                  >
                    <StepLabel style={{
                      color: 'black !important',
                      cursor: 'pointer',
                    }}>
                      {s.name}
                    </StepLabel>
                  </Step>
                )}
              </Stepper>
            </Grid>

            <Grid item xs={10}>
              {mountStepContent(step)}
            </Grid>
          </Grid>
        </Card>
      </Grid>

    </Grid>
  )
}
