import PropTypes from 'prop-types'
import React from 'react'
import { setMessage, SUCCESS, ERROR } from '../modules/messages'
import { connect } from 'react-redux'
import { Icon, InputGroup, Loading, Button } from '../components'
import { fetch } from '../services/fetch'

class UsersBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      users: [],
      isLoading: false,
      errors: [],
      firstLoading: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.initialList = this.initialList.bind(this)
    this.updateList = this.updateList.bind(this)
  }

  componentDidMount () {
    this.initialList();
  }

  initialList = async () => {
    this.setState({ isLoading: true })
    fetch(`/organizations/${this.props.organizationId}`)
      .then(res => res.json())
      .then(res => this.setState({ users: res.notification_emails, isLoading: false }))
      .catch(({ errors }) => this.setState({ errors, isLoading: false }))
  }

  updateList (organizationId) {
    this.setState({ isLoading: true })

    fetch(`/organizations/${organizationId}`, {
      method: 'PUT',
      body: JSON.stringify({ notification_emails: this.state.users }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.title === '403 Forbidden') {
          this.props.setMessage(ERROR, 'Você não tem acesso para alterar a lista de notificações.')
        } else {
          this.props.setMessage(SUCCESS, 'A lista de notificações atualizada com sucesso.')
        }
        return this.setState({ isLoading: false })
      })
      .catch(({ errors }) => {
        this.props.setMessage(ERROR, 'A lista de notificações não foi atualizada.')
        return this.setState({ errors, isLoading: false })
      })
  }

  addItem (user) {
    this.setState({ users: [...this.state.users, user], errors: [] })
  }

  removeItem (user) {
    const filteredList = this.state.users.filter(filterUser => filterUser !== user)

    this.setState(oldState => ({ ...oldState, users: filteredList, errors: [] }))
  }

  usersList () {
    return this.state.users.map((user, key) => (
      <li key={`${user}-${key}`}>
        {user}
        <span onClick={() => this.removeItem(user)}>
          <Icon name="cancel" />
        </span>
      </li>
    ))
  }

  handleClick (input) {
    const { value } = input

    if (value.length > 3) {
      this.addItem(value)
      this.setState({ value: '' })
    }
  }

  render () {
    return (
      <div className="users-box">
        <Loading loading={this.state.isLoading} />

        <p>
          Além dos usuários acima você pode adicionar abaixo emails extras para receber estas notificações.
          <br />
          <strong>Ao terminar de adicionar e remover emails</strong>, clique em <strong>Salvar
            Lista de Emails</strong> para concluir a operação.
        </p>
        <br />

        <InputGroup
          value={this.state.value}
          buttonClick={this.handleClick}
          placeholder="Adicionar e-mail"
          buttonName="Adicionar"
        />

        {
          !!this.usersList().length &&
          <ul className="users-list">
            {this.usersList()}
          </ul>
        }

        {
          this.state.errors.length > 0 &&
          <ul className="errors-list">
            {this.state.errors.map((error, key) => (
              <li key={key}>
                {`O ${error.key + 1}º email da lista possui um erro: ${error.message[0]}`}
              </li>
            ))}
          </ul>
        }

        <div>
          <Button onClick={() => this.updateList(this.props.organizationId)}>
            Salvar Lista de Emails
          </Button>
        </div>
      </div >
    )
  }
}

UsersBox.propTypes = {
  label: PropTypes.string,
  organizationId: PropTypes.string,
  setMessage: PropTypes.func,
}

export default connect(null, { setMessage })(UsersBox)
