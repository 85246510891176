import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Card, Loading, VulnerabilitiesChart, VulnerabilitiesHistoryChart} from '../../components'
import {fetchDashboardApplication} from '../../actions/dashboards'
import {withAccountId} from '../AccountContainer'
import find from 'lodash/find'

class DashboardApplication extends React.Component {
  constructor(props) {
    super(props)
    this.getChart = this.getChart.bind(this)
  }

  componentDidMount() {
    if (!this.props.charts) {
      this.props.fetchDashboardApplication(this.props.id)
    }
  }

  getChart(name) {
    if (!this.props.charts) {
      return []
    }

    const charts = find(this.props.charts, {name})
    return charts
  }

  render() {
    return (
      <div className="dashboard">
        <Loading loading={this.props.isLoading}/>
        <Card>
          <div className="inline-items">
            {this.props.charts &&
              <React.Fragment>
                <VulnerabilitiesChart
                  title={this.getChart('vulnerable').title}
                  data={this.getChart('vulnerable').data || []}
                />

                <VulnerabilitiesHistoryChart
                  title={this.getChart('vulnerable-history').title}
                  data={this.getChart('vulnerable-history').data || []}
                />
              </ React.Fragment>
            }
          </div>
        </Card>
      </div>
    )
  }
}

DashboardApplication.propTypes = {
  fetchDashboardApplication: PropTypes.func,
  id: PropTypes.string,
  charts: PropTypes.array,
  isLoading: PropTypes.bool,
}

const mapStateToProps = ({dashboards}) => ({
  isLoading: dashboards.isLoading,
  ...dashboards.armour,
})

export default connect(mapStateToProps, {fetchDashboardApplication})(withAccountId(DashboardApplication))
