import { memo } from 'react'
import { Skeleton, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

const SmartRow = memo(({ columns, data, model }) => {
  return (
    <TableRow>
      {columns.map((column, index) =>
        <TableCell key={index}>
          {data
            ? model[column].field(data)
            : <Skeleton />
          }
        </TableCell>
      )}
    </TableRow>
  )
})

SmartRow.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  model: PropTypes.object,
}

export default SmartRow
