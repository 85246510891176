import { FETCH } from '../actions/business-categories';

const INITIAL_STATE = {
    items: [],
    isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${FETCH}_PENDING`:
            return { ...state, isLoading: true };
        case `${FETCH}_REJECTED`:
            return { ...state, ...action.payload, isLoading: false };
        case `${FETCH}_FULFILLED`:
            return { ...state, items: action.payload, isLoading: false };
        default:
            return state;
    }
};
