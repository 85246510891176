import { fetch } from '../services/fetch';
import { setMessage, SUCCESS, ERROR } from '../modules/messages';
import { push } from 'connected-react-router';

export const SHAREREPORT = 'sharereport/SHAREREPORT';
export const AWREPORT = 'sharereport/AWREPORT';

export function shareReport(scanId) {
    return dispatch => {
        const request = fetch(`/aw-scans/${scanId}/report/share`, {
            method: 'POST',
        })
        .then(res => {
            dispatch(push(`/armour/scan-details/${scanId}`));
            dispatch(setMessage(SUCCESS, 'Relatório compartilhado com sucesso!'));

            return res.json();
        })
        .catch(error => error);

        return dispatch({
            type: SHAREREPORT,
            payload: request,
        });
    };
}

export function cancelShareReport(scanId) {
    return dispatch => {
        const request = fetch(`/aw-scans/${scanId}/report/share`, {
            method: 'DELETE',
        })
        .then(res => {
            dispatch(push(`/armour/scan-details/${scanId}`));
            dispatch(setMessage(SUCCESS, 'Compartilhamento de relatório cancelado com sucesso!'));

            return res.json();
        })
        .catch(error => error);

        return dispatch({
            type: SHAREREPORT,
            payload: request,
        });
    };
}

export function AwReport(scanId, password) {
    return dispatch => {
        const request = fetch(`/reports/${scanId}`, {
            method: 'POST',
            body: JSON.stringify({ key: password }),
        })
        .then(res => {
            if (res.status === 200) {
                dispatch(push(`/armour/scan-details/${scanId}`));
                dispatch(setMessage(SUCCESS, 'Relatório recuperado com sucesso!'));
            } else {
                dispatch(push('/devices/domains'));
                dispatch(setMessage(ERROR, 'Relatório não encontrado!'));
            }

            return res.json();
        })
        .catch(error => error);

        return dispatch({
            type: AWREPORT,
            payload: request,
        });
    };
}

export function AwReportPDF(scanId, applicationName, scanProfileName) {
    console.log(scanId, applicationName, scanProfileName)
    return async dispatch => {
        try {
            const response = await fetch(`/aw-scans/${scanId}/report`, {
                method: 'POST'
            });
            const blob = await response.blob();
            if (response.status === 200) {
                dispatch(setMessage(SUCCESS, 'Relatório recuperado com sucesso!'));
                // Aqui começa o código para download do PDF
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${applicationName}-${scanProfileName}.pdf`;

                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                dispatch(setMessage(ERROR, 'Relatório não encontrado!'));
                throw new Error('Relatório não encontrado!');
            }
            return blob;
        } catch (error) {
            throw error;
        }
    };
}