import { fetch } from '../services/fetch';
export const ADD = 'domains-box/ADD';
export const DELETE = 'domains-box/DELETE';
export const CLEAR = 'domains-box/CLEAR';

export function clearDomains() {
    return {
        type: CLEAR,
    };
}

export function removeDomain(domain) {
    return {
        type: DELETE,
        payload: { domain },
    };
}

export function addDomain(domain) {
    const request = fetch(`/domains/${domain}/validation-info`)
    .then(res => res.json());

    return {
        type: ADD,
        payload: request,
        meta: { domain },
    };
}
