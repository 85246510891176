import PropTypes from 'prop-types'
import React from 'react'
import { Card, Loading } from '../../components'
import { fetch } from '../../services/fetch'

export class EvidenceResponse extends React.Component {
  constructor(props) {
    super(props)

    this.state = { result: '', isLoading: true }
  }

  componentDidMount() {
    this.props.setTitle(this.props.title)

    fetch(`/evidences/${this.props.match.params.evidenceId}/http-response`)
      .then(res => res.json())
      .then(res => this.setState({ result: res, isLoading: false }))
      .catch(() => this.setState({ result: 'Não há resposta', isLoading: false }))
  }

  render() {
    return (
      <article className="page">
        <div className="page-content">
          <div className="content">
            <Card className="evidence-response">
              <Loading loading={this.state.isLoading} />

              <div className="header">HTTP Response</div>
              <div className="content">
                <div dangerouslySetInnerHTML={{ __html: this.state.result }} />
              </div>
            </Card>
          </div>
        </div>
      </article>
    )
  }
}

EvidenceResponse.propTypes = {
  getHttpResponse: PropTypes.func,
  setBreadcrumbName: PropTypes.func,
  params: PropTypes.object,
  name: PropTypes.string,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
