import { useEffect } from 'react'
import { Button, Card } from '../../../components'
import { IconSbPenTest } from '../../../sb2w-icons/sb'

const mountProducts = (prod, key) => (
  <div key={key} className="pentest-items pentest-item-1 col-md-4 col-xs-12">
    <div
      className={`header-pentest-items ${prod.title === 'Quick Pentest' ? 'header-item-2' : ''
        }`}
    >
      <h3 className="title pentest-card-title">
        <div>{prod.icons.map((icon) => icon)}</div>
        {prod.title}
      </h3>
    </div>
    <div className="content-pentest-items">{prod.description}</div>
    <div className="footer-items">
      <Button
        onClick={() =>
          window.open(
            'https://www.siteblindado.com/solucoes/pentest/#quero-ser-blindado',
            '_blank'
          )
        }
      >
        Saiba Mais
      </Button>
    </div>
  </div>
)

export default function PentestLandingPage(props) {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  const products = [
    {
      title: 'Pentest Black Box',
      description: (
        <p>
          Nesta modalidade, o analista de segurança da Site Blindado
          realiza o processo de análise da aplicação web, coleta
          informações, identifica e explora vulnerabilidades, porém,
          sem possuir conhecimento prévio de quaisquer informações
          sobre o alvo. Este tipo de teste representa perfeitamente
          uma simulação de ataque real e é indicado quando o intuito é
          testar os controles de segurança internos e externos da
          aplicação. <br />
          <br />
          <strong>Indicado para:</strong> empresas que buscam uma
          varredura de segurança bem mais profunda e específica para a
          sua aplicação. Além disso, é altamente recomendado para
          todos que tenham sistemas logados.
        </p>
      ),
      icons: [<IconSbPenTest fill="#ffffff" />],
    },
    {
      title: 'Quick Pentest',
      description: (
        <p>
          O Quick Pentest consiste em um teste rápido dividido em duas
          grandes etapas. Na primeira etapa é executado o Scan
          automatizado de vulnerabilidades e na segunda ocorre uma
          validação manual, pela nossa equipe, das vulnerabilidades
          encontradas pelo scan. <br />
          <br />
          <strong>Indicado para: </strong>
          empresas que utilizam sistemas com um nível de complexidade
          baixo ou médio.
        </p>
      ),
      icons: [
        <IconSbPenTest fill="#ffffff" />,
        <IconSbPenTest fill="#ffffff" />,
        <IconSbPenTest fill="#ffffff" />,
      ],
    },
    {
      title: 'Pentest Grey Box',
      description: (
        <p>
          Nesta modalidade de teste de invasão, o analista de
          segurança responsável tem conhecimento prévio do sistema que
          está invadindo, ou seja, informações privilegiadas como
          estrutura ou credenciais (login e senha) de uma área
          restrita, por exemplo. Tende a ser um teste que entrega uma
          melhor relação entre exploração do escopo por tempo
          disponível para as atividades. <br />
          <br />
          <strong>Indicado para:</strong> empresas que utilizam
          sistemas com um nível de complexidade médio ou alto.
        </p>
      ),
      icons: [
        <IconSbPenTest fill="#ffffff" />,
        <IconSbPenTest fill="#ffffff" />,
      ],
    },
  ]

  return (
    <section className="content">
      <Card className="pentest-description">
        <h1 className="mt-2 font-weight-bold text-danger" style={{ textTransform: 'uppercase' }}>
          Você ainda não possui Pentest
        </h1>
        <p>
          Verificamos que você ainda não possui um Pentest contratado
          conosco. <br />
          Aproveite e adquira para maior proteção da sua aplicação.
        </p>
      </Card>

      <Card className="pentest-description">
        <h2 className="mt-2" style={{ textTransform: 'uppercase' }}>Você sabe o que é Pentest?</h2>
        <p>
          No Pentest (Teste de invasão), ocorre uma análise de vulnerabilidades por um
          especialista de segurança certificado e habilitado na adoção
          de técnicas utilizadas por hackers reais, porém aplicadas de
          forma ética e balizadas por um escopo pré-definido. O
          Pentest é um registro, uma fotografia da aplicação em
          determinado período de tempo. {' '} A premissa do
          pentest é ajudar as empresas a identificarem o grau de
          maturidade e exposição de suas aplicações, corrigir falhas
          que possam causar prejuízos financeiros e de imagem àquele
          negócio e principalmente evidenciar que segurança é
          investimento e não custo.
        </p>
      </Card>

      <Card className="pentest-explanation">
        <div className="row row-pentest">
          {products.map((product, index) => mountProducts(product, index))}
        </div>
      </Card>
    </section>
  )
}
