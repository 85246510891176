import { css } from 'styled-components';

import { $light$blue400, $greenSb } from '../settings/variables';

export default css`
    .badge {
        padding: .3rem 1.5rem;
        border-radius: .5rem;
    }

    .badge.pending {
        background-color: #FCE93C;
        display: initial;
    }

    .badge.denied {
        background-color: #EB323D;
        color: #fff;
        display: initial;
    }

    .badge.expires-in-90 {
        background-color: #FCE93C;
    }

    .badge.expires-in-60 {
        background-color: #F18647;
        color: #fff;
    }

    .badge.expires-in-30 {
        background-color: #EB323D;
        color: #fff;
    }

    .badge.expired {
        background-color: #22272F;
        color: #fff;
    }

    .badge.to_start {
        background-color: ${$light$blue400};
        color: #fff;
    }

    .badge.in_progress {
        background-color: #FCE93C;
    }

    .badge.done {
        background-color: ${$greenSb};
        color: #fff;
    }
`;
