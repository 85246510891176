import { css } from 'styled-components';

import { $borderRadiusBase, $green700 } from '../settings/variables';

export default css`
    .card {
        background-color: #fff;
        margin-bottom: 1rem;
        position: relative;
        border-radius: ${$borderRadiusBase};
        box-shadow: 0 0 1px 0 rgba(0,0,0,0.3), 0 1px 1px 0 rgba(0,0,0,0.3);
    }

    .card.fake {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .5;
    }

    .card-title {
        padding: 1.5rem;
        background-color: #F7F7F7;
        font-weight: bold;
    }

    .card-content {
        padding: 3rem;
    }

    .card-content > .form {
        margin: 0;
    }

    .card-group {
        display: flex;
        flex-wrap: wrap;
    }

    .card-group > .card {
        flex-grow: 1;
        flex: 1;
        margin-right: 2.5rem;
    }

    .card-group > .card:last-child {
        margin-right: 0;
    }

    .card .inline-items {
        display: flex;
        justify-content: space-around;
    }

    .card-accordion > .accordion > .header >.icon {
        background-color: ${$green700};
        color: #fff;
        width: 4rem;
        height: 4rem;
    }
`;
