import React, {memo, useEffect, useState} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {Content} from "../../components";


export function IndexDevices(props) {
  const [title, setTitle] = useState('Meus Domínios');
  const location = useLocation()
  const history = useHistory()
  const submenu =
    props.location.pathname === '/devices/domains'
    || props.location.pathname === '/devices/servers'
      ? [
        { route: '/devices/domains', name: 'Meus Domínios' },
        { route: '/devices/servers', name: 'Meus Servidores', beta: true },
      ]
      : []

  useEffect(() => {
    if (location.pathname === '/devices') {
      history.push('/devices/domains')
    }
  }, [])

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}
