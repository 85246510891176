import PropTypes from 'prop-types';
import React from 'react';
import {Cell, Legend, Pie, PieChart, Tooltip} from 'recharts';
import {listColors} from '../Score';
import sumBy from 'lodash/sumBy';

const ChartTooltip = ({payload}) => {
  if (!payload.length) {
    return false;
  }

  const {value, name, content} = payload[0];

  return (
    <div className="chart-tooltip">
      <strong>
        {`${value} '${name}'`}
      </strong>
      <ul>
        {content && content.map((item, key) =>
          <li key={key}>{item}</li>
        )}
      </ul>
    </div>
  );
};

export const GenericPieChart = (props) => (
  <div className="chart">
    <h1 className="chart-title">{props.title}</h1>

    <PieChart width={props.width} height={props.height}>
      <Pie
        dataKey="value"
        data={props.data}
        cx={props.cx}
        cy={props.cy}
        startAngle={180}
        endAngle={0}
        innerRadius={110}
        outerRadius={150}
        isAnimationActive={false}
      >

        {props.data.map((entry, index) =>
          <Cell
            key={index}
            fill={listColors[entry.name.toUpperCase()] || props.colors[entry.name.toUpperCase()]}
          />
        )}

      </Pie>
      <Tooltip content={<ChartTooltip/>}/>
      <Legend/>
    </PieChart>
    <div className="chart-pie chart-sub-title">
      <div className="number">{sumBy(props.data, 'value')}</div>
      {props.subtitle.toUpperCase()}
    </div>
  </div>
);

GenericPieChart.defaultProps = {
  colors: Object.keys(listColors).map(key => listColors[key]),
  cx: 200,
  cy: 180,
  height: 230,
  width: 400,
  subtitle: '',
};

GenericPieChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cx: PropTypes.number,
  cy: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

ChartTooltip.propTypes = {
  payload: PropTypes.array,
};
