import { css } from 'styled-components';

export default css`
    .full-report .summary {
        display: flex;
        padding: 2rem;
    }

    .full-report .summary > .score {
        width: 10rem;
        height: 10rem;
        margin-right: 2rem;
    }

    .full-report .summary > .score > span {
        font-size: 8rem;
    }

    .full-report .summary > .details {
        width: calc(100% - 10rem);
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .full-report .summary > .details > .title {
        display: flex;
        justify-content: space-between;
    }

    .full-report .summary > .details > .resume {
        display: flex;
    }

    .full-report .summary > .details > .resume > div {
        margin-right: 10rem;
    }

    .full-report .report-content {
        padding: 0 2rem;
    }

    .full-report .report-content > .title {
        margin-top: 1rem;
    }

    .full-report .report-content > .list {
        padding: 0 2rem;
    }

    .full-report .report-content > .list > div {
        display: flex;
        margin: 2rem 0;
    }

    .full-report .report-content > .list > div > strong {
        width: 25%;
        margin-right: 5rem;
        word-wrap: break-word;
    }

    .full-report .report-content > .list > div > div {
        width: 75%;
    }

    .full-report .report-content > .list > .report-content {
        display: block;
    }

    .full-report .report-content > .list > .report-content > .list > div {
        display: flex;
    }

    .full-report .report-content > .list > .report-content > .list > div > strong,
    .full-report .report-content > .list > .report-content > .list > div > div {
        width: 50%;
    }
`;
