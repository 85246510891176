import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Revoke } from '../views/certificates';
import { revokeCertificate } from '../actions/certificates';
import { closeSlider } from '../actions/slider';
import { Loading } from '../components';

class RevokeContainer extends React.Component {
    constructor(props) {
        super(props);

        this.revokeCertificate = this.revokeCertificate.bind(this);
    }

    revokeCertificate(order, reason) {
        if (reason) {
            this.props.revokeCertificate(order, reason)
            .then(() => this.props.closeSlider());
        }
    }

    render() {
        return (
            <article>
                <Loading loading={this.props.isLoading} />

                <Revoke
                    order={this.props.order}
                    onRevoke={this.revokeCertificate}
                    closeSlider={this.props.closeSlider}
                    choices={[
                        'O certificado foi comprometido',
                        'O certificado foi criado erradamente',
                        'Os detalhes do certificado foram alterados',
                        'A politica de segurança da organização foi alterada',
                        'Outro',
                    ]}
                />
            </article>
        );
    }
}

RevokeContainer.propTypes = {
    order: PropTypes.number.isRequired,
    revokeCertificate: PropTypes.func,
    closeSlider: PropTypes.func,
    isLoading: PropTypes.bool,
};

const mapStateToProps = ({ revoke }) => ({ ...revoke });

export default connect(mapStateToProps, { closeSlider, revokeCertificate })(RevokeContainer);
