import { css } from 'styled-components';

import { $green300 } from '../settings/variables';

export default css`
    .buy-box {
        width: 30rem;
    }

    .buy-box > .title {
        width: 100%;
        font-size: 2rem;
        font-weight: 300;
        padding: 1.5rem 2rem;
    }

    .buy-box > .form > .content-inner {
        padding: 2rem;
    }

    .buy-box > .form .button-buy {
        border: none;
        width: 100%;
        background-color: ${$green300};
        color: #fff;
        padding: 1.7rem;
        display: block;
        font-size: 1.4rem;
        text-align: center;
    }
`;
