import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Countdown, TextField } from '../../components'
import { Field, reduxForm, SubmissionError, isSubmitting } from 'redux-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { loginUser } from '../../actions/auth'
import _ from 'lodash'
import { useLocation } from "react-router-dom"

function FormLogin(props) {
  const [otpView, setotpView] = useState(false)

  const location = useLocation()
  const redirect = location.pathname || '/'

  const onSubmit = (data) => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    return sleep(1000).then(() => {
      let errors = {}

      if (!data.username) {
        errors = {
          ...errors,
          username: 'Campo obrigatório',
          _error: 'Campo obrigatório',
        }
      }

      if (!data.password) {
        errors = {
          ...errors,
          password: 'Campo obrigatório',
          _error: 'Campo obrigatório',
        }
      }

      if (_.isEmpty(errors)) {
        return props.loginUser(data, redirect)
          .then(res => {
            if (res && res.code === 171) {
              setotpView(true)
            }
          })
      }

      throw new SubmissionError(errors)
    })
  }

  if (props.unlock_date) {
    return (
      <div className="field center-items" style={{ display: 'flex', flexDirection: 'column' }}>
        <h1>Usuário bloqueado</h1>
        <p>
          <strong>
            Aguarde <Countdown end={props.unlock_date} /> para ser liberado.
          </strong>
        </p>
      </div>
    )
  }

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>

      {otpView &&
        <article className="login-otp">
          <h2>Entrar</h2>
          <h3>Autenticação em 2 passos</h3>
          <p>Digite o código de 6 dígitos que aparece no app Google Authenticator em seu celular.</p>
          <Field
            name="otp"
            placeholder="Digite o código"
            component={TextField}
          />
          <p>
            Caso não possa utilizar o Google Authenticator entre<br />
            em contato com o suporte da Site Blindado.
          </p>
        </article>
      }

      {!otpView &&
        <article>
          <Field
            name="username"
            placeholder="E-mail *"
            component={TextField}
            required
          />

          <Field
            type="password"
            name="password"
            placeholder="Senha *"
            component={TextField}
            required
          />

        </article>
      }

      <Button loading={props.submitting}>
        ENTRAR
      </Button>

      {otpView &&
        <footer>
          <Link to="/">Voltar para o login</Link>
        </footer>
      }

      {!otpView &&
        <footer>
          <Link to="/recovery">Esqueci minha senha</Link>
          <Link to="/signup">N&atilde;o possuo conta</Link>
        </footer>
      }
    </form>
  )

}

FormLogin.propTypes = {
  location: PropTypes.object,
  loginUser: PropTypes.func,
  unlock_date: PropTypes.string,
  handleSubmit: PropTypes.func,
}

FormLogin.contextTypes = {
  router: PropTypes.object,
}

const Form = reduxForm({
  form: 'formLogin',
})(FormLogin)

const mapStateToProps = (state) => ({
  unlock_date: state.auth.unlock_date,
  submitting: isSubmitting('formLogin')(state)
})

export default connect(mapStateToProps, { loginUser })(Form)
