import PropTypes from 'prop-types'
import React from 'react'
import serialize from 'form-serialize'
import { Link } from 'react-router-dom'
import { GenericContainer } from '../../containers'
import { InputField, Button } from '../../components'
import { connect } from 'react-redux'
import { ERROR, setMessage, SUCCESS } from '../../modules/messages'

// TODO: Precisa tirar o GenericContainer e refatorar o component.
class FormRecovery extends GenericContainer {
  constructor(props) {
    super(props)
    this.state = {
      recoverMsg: 'Aguarde ...',
      email: '',
      isLoading: false,
    }
    this.endpoint = '/auth/password/reset'

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    const data = serialize(this.form, { hash: true })
    this.setState({ isLoading: true })

    this.post({ data })
      .then(() => {
        this.setState({ email: data.email, isLoading: false })
        this.props.setMessage(SUCCESS, 'Solicitação enviada com sucesso!')
      })
      .catch(() => {
        this.setState({ isLoading: false })
        this.props.setMessage(ERROR, 'Ocorreu um erro.')
      })
  }

  render() {
    return (
      <article>
        <div className="center-items">
          <h1 className="title">
            Esqueci minha senha
          </h1>
        </div>

        <form
          onSubmit={this.handleSubmit}
          ref={(form) => { this.form = form }}
          className="form"
        >
          <InputField
            type="email"
            name="email"
            className="input-lg"
            placeholder="Informe seu email"
            required
          />
          <Button loading={this.state.isLoading}>
            Enviar E-mail
          </Button>

          <p>
            <Link to="/login">
              Retornar para a página de login
            </Link>
          </p>

          {this.state.email &&
            <div>
              <p>
                <strong>
                  E-mail enviado para
                </strong>
                <br />

                {this.state.email}
              </p>
              <p>
                <strong>
                  Não recebeu o e-mail
                </strong>
              </p>

              <ol>
                <li>1 - Confira se escreveu o e-mail corretamente;</li>
                <li>2 - Aguarde alguns minutos;</li>
                <li>3 - Confira sua caixa de Spam;</li>
              </ol>
            </div>
          }
        </form>


      </article>
    )
  }
}

FormRecovery.propTypes = {
  setMessage: PropTypes.func,
}

export default connect(null, { setMessage })(FormRecovery)
