import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { Controller } from 'react-hook-form'

import { Icon } from '../Icon'
import { countries } from '../fields/CountryField'

export default function NewAddressFields({ disabled, isLoading, errors, control }) {
  const renderCountryOptions = () => (
    countries.map((item) => (
      <option key={item.sigla} value={item.sigla}>{item.nome_pais}</option>
    )
    ))

  return (
    <article className="field-address">
      <div className="row">
        <div className="col-md-4">
          {isLoading &&
            <Icon className="cep-loading looping" name="autorenew" />
          }
          <div className="field">
            <label htmlFor="zipcode">
              CEP *
            </label>

            <Controller
              render={({ field }) =>
                <InputMask
                  className={`input ${errors.zipcode ? 'input-error' : ''}`}
                  type="text"
                  mask="99999-999"
                  disabled={disabled}
                  {...field}
                />
              }
              name="address.zipcode"
              defaultValue=""
              rules={{ required: true }}
              control={control}
            />

            {errors.address &&
              errors.address.zipcode && <span className="input-error">Este campo é obrigatório</span>}
          </div>
        </div>
        <div className="col-md-8">
          <div className="field">
            <label htmlFor="street">
              Endereço *
            </label>

            <Controller
              render={({ field }) =>
                <input
                  className={`input ${errors.street ? 'input-error' : ''}`}
                  type="text"
                  disabled={disabled}
                  {...field}
                />
              }
              name="address.street"
              defaultValue=""
              rules={{ required: true }}
              control={control}
            />

            {errors.address &&
              errors.address.street &&
              <span className="input-error">
                Este campo é obrigatório
              </span>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="field">
            <label htmlFor="number">
              Número *
            </label>

            <Controller
              render={({ field }) =>
                <input
                  className={`input ${errors.city ? 'input-error' : ''}`}
                  type="text"
                  disabled={disabled}
                  {...field}
                />
              }
              name="address.number"
              rules={{ required: true }}
              defaultValue=""
              control={control}
            />

            {errors.address &&
              errors.address.number &&
              <span className="input-error">
                Este campo é obrigatório
              </span>
            }
          </div>
        </div>

        <div className="col-md-4">
          <div className="field">
            <label htmlFor="complement">
              Complemento
            </label>

            <Controller
              render={({ field }) =>
                <input
                  className='input'
                  type="text"
                  disabled={disabled}
                  {...field}
                />
              }
              name="address.complement"
              defaultValue=""
              control={control}
            />

            {errors.address &&
              errors.address.complement &&
              <span className="input-error">
                Este campo é obrigatório
              </span>
            }
          </div>
        </div>

        <div className="col-md-4">
          <div className="field">
            <label htmlFor="district">
              Bairro *
            </label>

            <Controller
              render={({ field }) =>
                <input
                  className={`input ${errors.district ? 'input-error' : ''}`}
                  type="text"
                  disabled={disabled}
                  {...field}
                />
              }
              name="address.district"
              defaultValue=""
              rules={{ required: true }}
              control={control}
            />

            {errors.address &&
              errors.address.district &&
              <span className="input-error">
                Este campo é obrigatório
              </span>
            }
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="field">
            <label htmlFor="city">
              Cidade *
            </label>

            <Controller
              render={({ field }) =>
                <input
                  className={`input ${errors.city ? 'input-error' : ''}`}
                  type="text"
                  disabled={disabled}
                  {...field}
                />
              }
              name="address.city"
              defaultValue=""
              rules={{ required: true }}
              control={control}
            />

            {errors.address &&
              errors.address.city &&
              <span className="input-error">
                Este campo é obrigatório
              </span>
            }
          </div>
        </div>

        <div className="col-md-2">
          <div className="field">
            <label htmlFor="state">
              Estado *
            </label>

            <Controller
              render={({ field }) =>
                <input
                  className={`input ${errors.state ? 'input-error' : ''}`}
                  disabled={disabled}
                  type="text"
                  {...field}
                />
              }
              name="address.state"
              defaultValue=""
              rules={{ required: true }}
              control={control}
            />

            {errors.address &&
              errors.address.state &&
              <span className="input-error">
                Este campo é obrigatório
              </span>
            }
          </div>
        </div>
        <div className="col-md-4">
          <div className="field">
            <label htmlFor="country">
              País *
            </label>

            <Controller
              render={({ field }) =>
                <select className="combo" disabled={disabled} {...field}>
                  {renderCountryOptions()}
                </select>
              }
              name="address.country"
              defaultValue="BR"
              rules={{ required: true }}
              control={control}
            />

            {errors.address &&
              errors.address.country &&
              <span className="input-error">
                Este campo é obrigatório
              </span>
            }
          </div>
        </div>
      </div>
    </article>
  )
}

NewAddressFields.propTypes = {
  address: PropTypes.object,
  isLoading: PropTypes.any,
  disabled: PropTypes.any,
  errors: PropTypes.object,
  register: PropTypes.func,
  control: PropTypes.any,
  setValue: PropTypes.func,
}
