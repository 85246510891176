import PropTypes from 'prop-types';
import React from 'react';
import { Header, Alert } from './';

export class Outside extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            alert: {
                show: false,
            },
        };

        this.alert = this.alert.bind(this);
    }

    getChildContext() {
        return {
            alert: this.alert,
        };
    }

    setTitle(title) {
        document.title = (title) ? `Site Blindado - ${title}` : 'Site Blindado';
    }

    alert({ text = '', type = 'error', show = true }) {
        this.setState({
            alert: { show, text, type },
        });
    }

    resetAlertState() {
        const { alert } = this.state;
        alert.show = false;
        this.setState({ alert });
    }

    render() {
        return (
            <section className="app-outside">
                <Header />

                <main className="main-content">
                    <section className="main-section">
                        {this.props.children && React.cloneElement(this.props.children, {
                            setTitle: this.setTitle,
                        })}
                    </section>
                </main>

                <Alert {...this.state.alert} close={() => this.resetAlertState()} autoClose />
            </section>
        );
    }
}

Outside.childContextTypes = {
    alert: PropTypes.func,
};

Outside.propTypes = {
    children: PropTypes.object,
};
