import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types'
import { Card, Loading } from '../../components'

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Container, Link } from '@mui/material';
import { getContentfulAssetsByTag } from '../../services/contentful';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Documents = () => {

  const [docsAtivacao, setDocsAtivacao] = useState([])
  const [docsCriacao, setDocsCriacao] = useState([])
  const [docsInstalacao, setDocsInstalacao] = useState([])
  const [docsExportacao, setDocsExportacao] = useState([])
  const [load, setLoad] = useState(false)

  const getDocuments = useCallback(async () => {
    setLoad(true)
    try {
      const [ativacao, criacao, instalacao, exportacao] = await Promise.all([
        getContentfulAssetsByTag('ativacaoSsl'),
        getContentfulAssetsByTag('criacaoCsr'),
        getContentfulAssetsByTag('instalacaoSsl'),
        getContentfulAssetsByTag('exportacaoSsl'),
      ])

      setDocsAtivacao(ativacao.items)
      setDocsCriacao(criacao.items)
      setDocsInstalacao(instalacao.items)
      setDocsExportacao(exportacao.items)

      console.log({
        docsAtivacao, docsCriacao, docsExportacao, docsInstalacao
      })
      setLoad(false)
    } catch (error) {
      console.log(error)
      setLoad(false)
    }
  }, [])

  useEffect(() => {
    document.title = 'Site Blindado - Materiais Ricos'
    getDocuments();
  }, [])

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


  const DocumentItem = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', paddingY: '1rem' }}>
        <PictureAsPdfIcon fontSize='large' sx={{ marginRight: '5px' }} />
        <Link variant='h5' href={props.link} target="_blank">{props.name}</Link>
      </Box>
    )

  }

  return (
    <section className="content">
      <Card>
        <Container maxWidth="xl">
          <Box sx={{ padding: '4rem' }}>

            <Typography variant="h3"><strong>Documentos de Instrução</strong></Typography>
            <Loading loading={load} />

            <Box sx={{ marginTop: '4rem' }}>
              <Accordion defaultExpanded>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Ativação do SSL</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    docsAtivacao.map((doc, index) => (
                      <DocumentItem key={index} link={doc.fields.file.url} name={doc.fields.title} />
                    ))
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>Criação de Chaves CSR</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {
                      docsCriacao.map((doc, index) => (
                        <DocumentItem key={index} link={doc.fields.file.url} name={doc.fields.title} />
                      ))
                    }
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>Instalação de Certificados SSL</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {
                      docsInstalacao.map((doc, index) => (
                        <DocumentItem key={index} link={doc.fields.file.url} name={doc.fields.title} />
                      ))
                    }
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>Exportação de Certificados SSL</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {
                      docsExportacao.map((doc, index) => (
                        <DocumentItem key={index} link={doc.fields.file.url} name={doc.fields.title} />
                      ))
                    }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Container>
      </Card>
    </section>
  )

}

Documents.propTypes = {
  setTitle: PropTypes.func,
}

export default Documents;
