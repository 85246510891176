import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

// import { Container } from './styles';
import { Button, WysiwygEditor } from '../../../../../components'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  createPentestVulnerability,
  updatePentestVulnerability,
} from '../../../../../actions/pentests/vulnerabilities'
import { risks } from '../../../../../services/pentest-fields'

const useStyles = makeStyles({
  item: {
    padding: 15,
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    '.input': {
      color: 'rgba(0, 0, 0, 0.87)',
    }
  },
})

function PentestVulnerabilityForm(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { accountId, pentestId, reportId, id: vulnerabilityId, onCloseModal } = props
  const { control, handleSubmit, formState: { errors } } = useForm()

  const [evidences, setEvidences] = useState(props.evidences ? props.evidences : '')
  const [evidenceIsEmpty, setEvidenceIsEmpty] = useState(false)

  async function onSubmit(data) {
    if (evidences) {
      setEvidenceIsEmpty(false)

      if (props.new) {
        await dispatch(createPentestVulnerability(accountId, pentestId, reportId, { ...data, evidences }))
      } else {
        await dispatch(updatePentestVulnerability(accountId, pentestId, reportId, vulnerabilityId, {
          ...data, evidences,
        }))
      }

      onCloseModal()
    }
  }

  return (
    <section className="content">
      <Grid>
        <div className="card-content">
          <form className={classes.formContainer} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" item xs={3} className={classes.item}>
              <label htmlFor="vulnerability_id">
                Código *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.vulnerability_id ? 'input-error' : ''}`}
                    type="text"
                    disabled
                  />
                }
                name="vulnerability_id"
                defaultValue={props.vulnerability_id || ''}
                rules={{ required: true }}
                control={control}
              />

              {errors.vulnerability_id &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </Grid>

            <Grid container direction="column" item xs={9} className={classes.item}>
              <label htmlFor="title">
                Título *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.title ? 'input-error' : ''}`}
                    style={{ color: 'rgba(0, 0, 0, 0.67)'}}
                    type="text"
                  />
                }
                name="title"
                defaultValue={props.title || ''}
                rules={{ required: true }}
                control={control}
              />

              {errors.title &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </Grid>

            <Grid container direction="column" item xs={12} className={classes.item}>
              <label htmlFor="description">
                Descrição *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.description ? 'input-error' : ''} textarea`}
                    style={{ color: 'rgba(0, 0, 0, 0.67)' }}
                    type="text"
                  />
                }
                name="description"
                defaultValue={props.description || ''}
                rules={{ required: true }}
                control={control}
              />

              {errors.description &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </Grid>

            <Grid container direction="column" item xs={6} className={classes.item}>
              <label htmlFor="ports">
                Portas *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.ports ? 'input-error' : ''}`}
                    style={{ color: 'rgba(0, 0, 0, 0.67)' }}
                    type="text"
                  />
                }
                defaultValue={props.ports || ''}
                name="ports"
                rules={{ required: true }}
                control={control}
              />

              {errors.ports &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </Grid>

            <Grid container direction="column" item xs={12} className={classes.item}>
              <label htmlFor="recommendation">
                Recomendação *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.recommendation ? 'input-error' : ''} textarea`}
                    style={{ color: 'rgba(0, 0, 0, 0.67)' }}
                    type="text"
                  />
                }
                name="recommendation"
                defaultValue={props.recommendation || ''}
                rules={{ required: true }}
                control={control}
              />

              {errors.recommendation &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </Grid>

            <Grid container direction="column" item xs={12} className={classes.item}>
              <label htmlFor="references">
                Referências *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.references ? 'input-error' : ''} textarea`}
                    style={{ color: 'rgba(0, 0, 0, 0.67)' }}
                    type="text"
                  />
                }
                name="references"
                defaultValue={props.references || ''}
                rules={{ required: true }}
                control={control}
              />

              {errors.references &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </Grid>

            <Grid container direction="column" item xs={3} className={classes.item}>
              <label htmlFor="risk">
                Risco *
              </label>

              <Controller
                render={({ field }) =>
                  <select
                    {...field}
                    className={`combo ${errors.risk ? 'input-error' : ''}`}
                    style={{ color: 'rgba(0, 0, 0, 0.67)' }}
                  >
                    <option value='' />

                    {risks.map(option => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                }
                name="risk"
                defaultValue={props.risk || ''}
                rules={{ required: true }}
                control={control}
              />

              {errors.risk &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </Grid>

            <Grid container direction="column" item xs={9} className={classes.item}>
              <label htmlFor="url">
                URL *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.url ? 'input-error' : ''}`}
                    style={{ color: 'rgba(0, 0, 0, 0.67)' }}
                    type="text"
                  />
                }
                name="url"
                defaultValue={props.url || ''}
                rules={{ required: true }}
                control={control}
              />

              {errors.url &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </Grid>

            <Grid container direction="column" item xs={12} className={classes.item}>
              <label htmlFor="evidence">
                Evidências *
              </label>

              <div style={evidenceIsEmpty ? { border: '1px solid #b71c1c' } : {}}>
                <WysiwygEditor text={evidences} setText={setEvidences} />
              </div>

              {evidenceIsEmpty && <span className="input-error">Este campo é obrigatório</span>}
            </Grid>

            <div className="buttons">
              <Button style={{ margin: 15 }} onClick={onCloseModal}>
                Voltar
              </Button>

              <Button style={{ margin: 15 }} type="submit" onClick={() => {
                setEvidenceIsEmpty(evidences === null || evidences.length <= 1)
              }}>
                {props.new ? 'Adicionar' : 'Salvar'}
              </Button>
            </div>
          </form>
        </div>
      </Grid>
    </section>
  )
}

PentestVulnerabilityForm.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  ports: PropTypes.string,
  recommendation: PropTypes.string,
  references: PropTypes.string,
  risk: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
  vulnerability_id: PropTypes.string,
  evidences: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  pentestId: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
  new: PropTypes.bool,
  onCloseModal: PropTypes.func,
}

export default PentestVulnerabilityForm
