import { css } from 'styled-components';

import {
    $borderRadiusBase,
    $fontSizeSmaller,
    $lightgrey300,
    $lightgrey600,
    $lightred400,
    spaceBase,
} from '../settings/variables';

export default css`
    .input {
        padding: 1.2rem;
        background-color: #fff;
        border: .1rem solid #ccc;
        outline: none;
        font-size: 1.2rem !important;
        line-height: normal !important;
        box-shadow: inset 0 0.1rem 0.3rem rgba(0,0,0,.2);
        border-radius: .4rem;
        transition: all .2s ease-in-out;
    }

    .input:not(.input-error):not([readonly]):focus {
        border-color: #6a5;
    }

    .input.input-error {
        border-color: #b71c1c;
    }

    .input:read-only {
        opacity: 1;
    }

    .input:disabled {
        background-color: #f4f4f4;
        color: #c6c6c6 !important;
        cursor: not-allowed;
    }

    .input + .clear-search {
        position: absolute;
        cursor: pointer;
        right: .5rem;
        top: .5rem;
    }

    .input + .clear-search > i {
        font-size: 1.8rem;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        font-size: 1.2rem;
        padding: ${spaceBase / 2}rem;
        background-color: #fff;
        border: .1rem solid ${$lightgrey600};
        box-shadow: none;
        border-radius: ${$borderRadiusBase};
        height: 3.5rem;
    }

    input[type="text"]:disabled,
    input[type="email"]:disabled,
    input[type="password"]:disabled {
        background-color: ${$lightgrey300};
    }

    input.input-lg {
        padding: 0 1rem;
        height: 5.3rem;
    }

    .input-error {
        color: ${$lightred400};
        display: block;
        font-size: ${$fontSizeSmaller};
        margin-top: 0.3rem;
        border-color: ${$lightred400} !important;
    }

    .input-error-v2 {
        color: ${$lightred400};
        display: block;
        font-size: ${$fontSizeSmaller};
        margin-left: 5px;
        border-color: ${$lightred400} !important;
        // position: absolute;
    }

    .input-error.float-message {
        position: absolute;
        background: #F7F7F7;
        width: 100%;
        padding: 7px;
        margin-top: 1rem;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.24);
    }

    .input-error.float-message:after {
        content: "";
        position: absolute;
        top: -5px;
        left: 2rem;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #f7f7f7;
    }

    .input-error:before {
        content: '*';
    }
`;
