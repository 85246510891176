import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { DomainsBox } from '../components';
import { addDomain, removeDomain } from '../actions/domains-box';

class DomainsBoxList extends React.Component {
    constructor(props) {
        super(props);
        this.addDomain = this.addDomain.bind(this);
        this.removeDomain = this.removeDomain.bind(this);
        this.isLoadingDomain = this.isLoadingDomain.bind(this);
    }

    addDomain(domain) {
        this.props.addDomain(domain);
    }

    removeDomain(domain) {
        this.props.removeDomain(domain);
        this.forceUpdate();
    }

    isLoadingDomain() {
        return !Object.keys(this.props.domains).every(
            domain => !this.props.domains[domain].loading
        );
    }

    render() {
        return (
            <DomainsBox
                label="Domínios"
                loading={this.isLoadingDomain()}
                domains={Object.keys(this.props.domains)}
                addDomain={this.addDomain}
                removeDomain={this.removeDomain}
                maxDomains={this.props.maxDomains}
            />
        );
    }
}

DomainsBoxList.propTypes = {
    domains: PropTypes.object,
    maxDomains: PropTypes.number,
    addDomain: PropTypes.func,
    removeDomain: PropTypes.func,
};

const mapStateToProps = ({ emailsByDomains }) => ({ domains: emailsByDomains });

export default connect(mapStateToProps, { addDomain, removeDomain })(DomainsBoxList);
