

import { css } from 'styled-components';

import {
    $borderRadiusBase,
    $fontSizeTiny,
    $lightgrey600,
    $lightred400,
    fontSizeSmaller,
    lightgrey,
} from '../settings/variables';

export default css`
    .code-block {
        /* background-color: ${$lightgrey600}; */
        padding: 1rem 0;
        /* margin: 2rem 0 .5rem; */
        display: table;
    }

    .code-block > pre {
        font-size: ${fontSizeSmaller * 0.95}rem;
        border: .1rem solid ${$lightgrey600};
        border-radius: ${$borderRadiusBase};
        white-space: pre-wrap;
        padding: 1rem;
        background-color: ${lightgrey - 300};
        word-break: break-word;
    }

    .code-block-obs {
        font-size: ${$fontSizeTiny};
        /* TODO: Definir cor para OBS */
        color: ${$lightred400};
    }
`;
