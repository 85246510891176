import { css } from 'styled-components';

import {
    $lightgrey300,
    $lightgrey800,
    $green500,
    $level1,
    $level2,
    $level3,
    $level4,
    $level5,
    $level0,
    $lightred500,
} from '../settings/variables';

function loadLevels() {
    const styles = [1, 2, 3, 5, 5].map(i => `
        .family, .vulnerable-list .level${i} {
            display: flex;
            flex-direction: column;
            color: black;
            background-color: lookup($level${i});
        }
    `).reduce((accumulator, currentValue) => accumulator + currentValue, '');

    return css`${styles}`;
}

export default css`
    .cardheader {
        display: flex;
        align-items: center;
        background-color: ${$lightgrey300};
        color: ${$lightgrey800};
    }

    .cardheader-name {
        font-size: 2rem;
        white-space: nowrap;
        border-right: 1px solid #ccc;
        padding: 0 3rem 0;
        font-weight: bold;
    }

    .cardheader-description {
        padding: 2rem;
    }

    .card.family {
        width: 100%;
    }

    .scan-details-header {
        display: flex;
        justify-content: space-between;
        border-left: .7rem solid #8BC34A;
        /* margin-bottom: 4rem; */
        padding: 1.4rem 2.5rem 1.4rem 1rem;
    }

    .scan-details-header.column {
        flex-direction: column;
    }

    .scan-details-header > .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .scan-details-header > .title > h2,
    .scan-details-header > .title > .icons > a {
        padding: 0;
        margin: 0;
    }


    .swal2-popup {
        width: 45em;
    }
    .swal2-html-container {
        font-size: 1.40em;
    }

    .scan-details-header > .inner {
        padding: 0;
        display: flex;
        justify-content: space-between;
    }

    .scan-details-header > .item,
    .scan-details-header > .inner > .item {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .scan-details-header > .item > .type ,
    .scan-details-header > .inner > .item > .type {
        font-weight: bold;
        font-size: 1.5rem;
    }

    .scan-details-header > .item > .description,
    .scan-details-header > .inner > .item > .description {
        line-height: 1.7rem;
        display: flex;
        margin: .5rem 0;
    }

    .scan-details-header > .item > .description [class*="mark-"],
    .scan-details-header > .inner > .item > .description [class*="mark-"] {
        margin-top: .2rem;
    }

    .scan-details-header > .item > .description:last-child [class*="mark-"],
    .scan-details-header > .inner > .item > .description:last-child [class*="mark-"] {
        margin-left: 0;
    }

    .scan-details-header > .item .application-name,
    .scan-details-header > .inner > .item .application-name {
        font-weight: 500;
        font-size: 1.6rem;
    }

    .scan-details-header > .item .application-name + span,
    .scan-details-header > .inner > .item .application-name + span {
        color: #999;
        display: block;
    }

    .scan-details .table-info {
        margin: 0 0 3.5rem 0;
        border: .4rem solid #eee;
    }

    .scan-details .table-info .title {
        width: 13rem;
    }

    .scan-details .table-info td {
        vertical-align: top;
        font-style: normal;
    }

    .scan-details .table-info td:first-child {
        padding: 1rem;
    }

    .scan-details-content .accordion:not(:last-child) {
        border-bottom: 1px solid #e3e3e3;
    }

    .scan-details-content .accordion >.header >.icon {
        background-color: #fff;
        color: #333;
        width: 1rem;
    }

    .scan-details-content .accordion >.header >.title {
        font-weight: normal;
        font-size: 1.5rem;
    }

    .scan-details-content .accordion > .container-box >.content-box {
        padding: 2rem;
    }

    .scan-details table {
        margin: 1.5rem 0;
    }

    .scan-details table td:first-child {
        padding: .2rem;
        padding-right: 1.2rem;
    }

    .scan-details .code-block {
        width: 100%;
    }

    .scan-details .vulnerable-list {
        position: relative;
        // min-height: 20rem;
    }

    .scan-details .empty-details {
        text-align: center;
    }

    .family, .vulnerable-list > .card-accordion .header > .title {
        width: 96%;
        display: flex;
        justify-content: space-between;
        // padding-right: 3rem;
    }

    .family, .vulnerable-list > .card-accordion .tag {
        // padding: .1rem 2rem;
        border-radius: .5rem;
        color: #fff;
        font-size: 1.3rem;
    }

    .tag {
        width: 8rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tag.passed {
        background-color: ${$green500};
    }
    .tag.failed {
        background-color: ${$lightred500};
    }
    .tag.level0 {
        background-color: ${$level0};
    }
    .tag.level1 {
        background-color: ${$level1};
    }
    .tag.level2 {
        background-color: ${$level2};
    }
    .tag.level3 {
        background-color: ${$level3};
    }
    .tag.level4 {
        background-color: ${$level4};
    }
    .tag.level5 {
        background-color: ${$level5};
    }

    /* .family, .vulnerable-list-header {
        display: flex;
        align-items: center;
        background-color: ${$lightgrey300};
        margin-bottom: 4rem;
    }

    .family, .vulnerable-list-name {
        font-size: 2rem;
        white-space: nowrap;
        border-right: 1px solid #ccc;
        padding: 0 3rem 0;
        font-weight: bold;
    }

    .family, .vulnerable-list-description {
        padding: 2rem;
    } */

    ${loadLevels()}

    .family > .card-accordion {
        box-shadow: none;
        margin-bottom: 0;
        border-top: 1px solid #eee;
    }

    .family > .card-accordion > .accordion > .header > .title {
        font-weight: normal;
    }
`;
