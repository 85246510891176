import {fetch} from '../services/fetch';
import {ERROR, setMessage, SUCCESS} from '../modules/messages';
import {refreshToken} from './auth';

export const SET = 'two_factor_auth/SET_TWO_FACTOR_AUTH';

export const setTwoFactorAuth = (userId, data) => (dispatch) => {
  const request = fetch(`/users/${userId}/otp`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(res => {
      if (res.id) {
        dispatch(refreshToken());
        dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'));
        res.finished = true;
      }

      return res;
    })
    .catch(() => setMessage(ERROR, 'Ocorreu um erro.'));

  return dispatch({
    type: SET,
    payload: request,
  });
};
