import { css } from 'styled-components';

export default css`
    .well {
        padding: 1rem 2rem;
        background-color: #eee;
        display: table;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-weight: bold;
        margin: 2rem 0;
        word-break: break-all;
    }
`;
