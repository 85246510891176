import React, {memo, useEffect, useState} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {Content} from "../../components";


export function IndexPentest(props) {
  const [title, setTitle] = useState('Pentest');
  const location = useLocation()
  const history = useHistory()
  const submenu = [
    {
      route: '/pentest/new',
      name: 'Novo PenTest',
    },
    {
      route: '/pentest/list',
      name: 'PenTests realizados',
    },
    {
      route: `/pentest/${props.match.params.id}`,
      name: 'Editar PenTest',
    },
    {
      route: `/pentest/${props.match.params.id}`,
      name: 'Relatório',
    },
    {
      route: `/dashboard/${props.match.params.id}`,
      name: 'PenTest Dashboard',
    },
  ]

  useEffect(() => {
    if (location.pathname === '/pentest') {
      history.push('pentest/list')
    }
  }, [])

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}
