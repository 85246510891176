import { css } from 'styled-components';

import {
    $black,
    $bluegray600,
    $darkgrey200,
    $fontSizeSmaller,
    $greenSb,
    $lightgrey300,
    $lightgrey600,
    $lightgrey700,
} from '../settings/variables';

export default css`
    .notifications {
        margin-top: 0px;
        position: relative;
    }

    .notifications i {
        top: 0.2rem;
        position: relative;
        color: ${$lightgrey700};
        font-size: 2.8rem;
    }

    .notifications .counter {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.8rem;
        height: 1.8rem;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${$fontSizeSmaller};
        color: #fff;
        background: ${$greenSb};
        border-radius: 100%;
        border: solid 2px ${$bluegray600};
    }

    .notifications-list {
        width: 35rem;
        color: ${$black};
        background-color: ${$darkgrey200};
    }

    .notifications-list > .item {
        padding: 1rem;
        background-color: ${$lightgrey300};
    }

    .notifications-list > .item:not(:last-child) {
        border-bottom: .1rem ${$lightgrey600} solid;
    }

`;
