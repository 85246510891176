import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Card } from '../../components'
import { FormProfile } from '../../containers'

export const User = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <div className="card-content">
          <FormProfile
            userId={props.match.params.id}
          />
        </div>
      </Card>
    </section>
  )
}

User.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    })
  }),
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
