import PropTypes from 'prop-types';
import React from 'react';
import { CnameValidation, FileValidation } from '../../components';

export const ValidationMethod = (props) => (
    <section className="content validation-method">
        {props.domains.map((item, key) =>
            <div key={key} className="accordion">
                <div className="accordion-header">
                    <span
                        className={`mark-${item.validated ? 'ok' : 'warn'}`}
                        title={item.validated ? 'Válido' : 'Pendente'}
                    ></span>
                    <span>{item.domain}</span>
                </div>
                <div className="accordion-container">
                    <div className="accordion-content">
                        {item.value === 'hash' &&
                            <FileValidation {...props.hash} />
                        }

                        {item.value === 'dns' &&
                            <CnameValidation domain={item.domain} {...props.hash} />
                        }

                        {item.value.includes('@') &&
                            `EMAIL (${item.value})`
                        }
                    </div>
                </div>
            </div>
        )}
    </section>
);

ValidationMethod.propTypes = {
    domains: PropTypes.array,
    hash: PropTypes.shape({
        sha256: PropTypes.string,
        md5: PropTypes.string,
    }),
};
