import { css } from 'styled-components';

export default css`
    .applications-list {
        position: relative;
        min-height: 7rem;
    }

    .applications-title-container {
        margin: 20px 0;
        background-color: white;
        box-shadow: 0 5px 5px 0px rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 30%);
        border-radius: 7px 7px 7px 7px;
    }

    .applications-list .card-group .card {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 1.25rem);
    }

    .applications-list .card-group .card:nth-child(2n+0) {
        margin-right: 0;
    }

    pre {
        background: #f4f4f4;
        border: 1px solid #ddd;
        border-left: 5px solid #74B61B;
        color: #666;
        page-break-inside: avoid;
        font-family: monospace;
        font-size: 15px;
        line-height: 1.6;
        margin-bottom: 1.6em;
        overflow: auto;
        padding: 1em 1.5em;
        display: block;
        word-wrap: break-word;
        margin: 15px 10px;
        max-height: 40vh;
        max-width: 85vw;
    }
`;
