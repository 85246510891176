import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import { FormPassword } from '../../components';

export const Activate = (props) => {
    useEffect(() => {
        if (window.top !== window.self) {
          window.top.location.href = document.location.href;
        }
    })

    return (
        <FormPassword {...props} />
    )
};

Activate.propTypes = {
    setTitle: PropTypes.func,
    params: PropTypes.shape({
        id: PropTypes.string,
        hash: PropTypes.string,
    }),
};
