import { css } from 'styled-components';

const raio = '3rem';

export default css`
    .score-box {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        position: relative;
    }

    .score-box .progress-circle > svg {
        position: absolute;
        top: 50%;
        margin-top: -2.5rem;
        right: 50%;
        margin-right: -2.5rem;
        width: 5rem;
        height: 5rem;
    }

    .score {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: ${raio};
        height: ${raio};
        border-radius: 100%;
        background-color: #333;
        color: #fff;
    }

    .score.scan-failed {
        border: 2px solid #EF2A2A;
    }

    .score > img {
        width: 5rem;
        position: absolute;
    }

    .score.in-progress img {
        filter: grayscale(100%);
        opacity: .2;
    }
`;
