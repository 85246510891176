import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSync } from "@fortawesome/free-solid-svg-icons"
export const Button = (props) => {
  const {
    loading,
    className,
    onClick,
    disabled,
    children,
    pure,
    secondary,
    ...otherProps
  } = props

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      {...otherProps}
      className={classnames(className, {
        'button-pure': pure,
        'button-secondary': secondary,
        'button-primary': !pure && !secondary,
        'soft-disabled': loading,
      })}
    >
      {loading && <FontAwesomeIcon className="looping" icon={faSync} />
      }
      {children}
    </button>
  )
}

Button.propTypes = {
  pure: PropTypes.bool,
  secondary: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
