import { css } from 'styled-components';

import { $lightgrey300 } from '../settings/variables';

export default css`
    .table {
        width: 100%;
        border: .1rem solid #ddd;
    }

    .table th {
        background-color: #fff;
        color: #484848;
        font-size: 1.3rem;
        padding: 1.05rem 1.5rem;
        font-weight: bold;
        border-bottom: 1px solid #ddd;
        text-align: left;
    }

    .table td {
        padding: 1.05rem 1.5rem;
        font-size: 1.3rem;
        border-bottom: 1px solid #ddd;
    }

    .table-prefix {
        width: 1px;
        background-color: ${$lightgrey300};
        text-align: right;
        padding-right: .7rem !important;
        white-space: nowrap;
    }

    .table-prefix + td {
        padding-left: .7rem !important;
    }

    .table.table-no-border {
        border: none;
    }

    .table.table-sortable th {
        position: relative;
    }

    .table.table-sortable ::after {
        position: absolute;
        top: .8rem;
        right: .8rem;
        font-size: 1rem;
    }

    .table.table-sortable .asc::after {
        content: '\\25B2';
    }

    .table.table-sortable .desc::after {
        content: '\\25BC';
    }

    .table.table-sortable :not(.no-sort):hover {
        background-color: #e5e5e5;
        cursor: pointer;
    }
`;

