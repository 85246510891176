import { css } from 'styled-components';

export default css`
    .beta {
        border: 1px solid red;
        position: relative;
        width: 100%;
    }

    .beta > span.str {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        padding: .2rem .5rem;
        background-color: red;
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
    }
`;
