import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ContentFilterItem } from '../../components'
import { IconSbFilter } from '../../sb2w-icons/sb'
import { Popover } from '@mui/material'

export const ContentFilter = props => {
  const { handleContentFilter, name, items, title } = props
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState({})

  const handleFilterItems = (item, isChecked) => {
    setSelectedItems({
      ...selectedItems,
      [item]: isChecked,
    })
  }

  const handleOpen = (event) => {
    setIsOpen(event.currentTarget)
  }

  useEffect(() => {
    const keys = Object.keys(selectedItems)
    const checkedItems = keys.filter(key => selectedItems[key])

    handleContentFilter({ [name]: checkedItems })
  }, [handleContentFilter, name, selectedItems])

  return (
    <div className='filter-select'>
      <div
        className='filter-icon'
        onClick={handleOpen}
      >
        {title}
        <IconSbFilter />
      </div>

      <Popover
        id={isOpen ? 'filters' : undefined}
        open={!!isOpen}
        anchorEl={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div
          className="filter-dropdown"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 20
          }}>
          {
            Object.keys(items).map((keyName, key) =>
              <div key={key} style={{ margin: '10px 0' }}>
                <ContentFilterItem
                  key={keyName+key}
                  filterItems={handleFilterItems}
                  value={items[keyName]}
                  isChecked={selectedItems[keyName]}
                  filterName={keyName}
                />
              </div>
            )}
        </div>
      </Popover >
    </div >
  )
}

ContentFilter.propTypes = {
  items: PropTypes.object,
  handleContentFilter: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
}
