import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { Container } from './styles'
import { WysiwygEditor, Button, Loading } from '../../../../components'
import { updatePentestReport } from '../../../../actions/pentests/report'

import { Card, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import AlertDialog from '../AlertDialog'

const useStyles = makeStyles({
  cardContent: {
    height: 'calc(100vh - 22rem)',
    padding: '3rem',
    overflowX: 'auto !important',
    boxShadow: 'none'
  },
})
function PenTestTechnicalDetails(props) {
  const classes = useStyles()

  const { id: pentestId, report_id: reportId } = props.match.params

  const dispatch = useDispatch()

  const account = useSelector(state => state.account)
  const pentestReport = useSelector(state => state.pentestReport)

  const [openDialog, setOpenDialog] = useState(false)

  const accountId = account[0] ? account[0].id : undefined

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm()

  useEffect(() => {
    if (!_.isEmpty(pentestReport.results)) {
      setValue('technical_doc', pentestReport.results.technical_doc)
    }
  }, [pentestReport.results])

  const updateReport = async data => {
    await dispatch(updatePentestReport(
      accountId,
      pentestId,
      reportId,
      data
    ))
  }

  const goBack = async event => {
    event.preventDefault()

    const { technical_doc: validateTechnicalDoc } = getValues()

    if (_.isEmpty(pentestReport.results)) {
      validateTechnicalDoc
        ? setOpenDialog(true)
        : props.backStep()
    } else {
      const { technical_doc: oldTechnicalDoc } = pentestReport.results

      validateTechnicalDoc !== oldTechnicalDoc
        ? setOpenDialog(true)
        : props.backStep()
    }
  }

  const onSubmit = async data => {
    await updateReport(data)

    await props.nextStep()
  }

  return (
    <Container>
      <Card className={classes.cardContent}>
        <Loading loading={pentestReport.isLoading} />

        <form
          className="form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ maxWidth: '100%' }}
        >
          <Grid className="field" style={{ height: '65vh' }}>
            <label>
              Detalhamento técnico *
            </label>

            <Controller
              render={({ field }) =>
                <WysiwygEditor
                  {...field}
                  {...register('technical_doc', {
                    required: true,
                    validate: value => value !== ' ',
                  })}
                  text={getValues().technical_doc}
                  setText={value => setValue('technical_doc', value)}
                />
              }
              defaultValue={pentestReport.results.technical_doc}
              name='technical_doc'
              control={control}
            />

            {errors.technical_doc && (
              <span className="input-error">
                Este campo é obrigatório *
              </span>
            )}
          </Grid>

          <div className="buttons">
            <Button onClick={goBack}>
              Anterior
            </Button>

            <Button
              style={{ marginLeft: '10px' }}
              type="submit"
              loading={isSubmitting}
            >
              Próximo
            </Button>
          </div>

          <AlertDialog
            open={openDialog}
            keepEditing={() => setOpenDialog(false)}
            onClose={props.backStep}
          />
        </form>
      </Card>
    </Container>
  )
}

PenTestTechnicalDetails.propTypes = {
  prevStep: PropTypes.func,
  nextStep: PropTypes.func,
  params: PropTypes.object,
}

export default PenTestTechnicalDetails
