import PropTypes from 'prop-types'
import { useEffect } from 'react'

import { Grid } from '@material-ui/core'
import { FormArmour } from '../../containers'

export const NewArmour = (props) => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Grid>
        <FormArmour {...props} />
      </Grid>
    </section>
  )
}

NewArmour.propTypes = {
  params: PropTypes.object,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
