import { css } from 'styled-components';

import {
    $black,
    $fontSizeLarger,
    $fontSizeSmall,
    $fontSizeSmaller,
    $lightgrey700,
    borderRadiusBase,
    spaceBase,
} from '../settings/variables';

export default css`
    .login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    @media (max-width: 480px) {
        .login > section {
            padding: 3rem 0;
        }
    }

    .login .countdown {
        color: red;
    }

    .login-modal {
        background: #fff;
        box-shadow: 0px 0px 30px ${$black};
        border-radius: ${borderRadiusBase * 2}rem;
        padding: 0px;
        width: 39rem;
        min-height: 25rem;
    }

    @media (max-width: 480px) {
        .login-modal {
            width: initial;
        }
    }

    .login-modal > .form-box {
        width: 100%;
        overflow: hidden;
    }

    .login-modal > .form-box > .brand {
        display: flex;
        justify-content: center;
        padding: 3rem 0 2.2rem;
    }

    .login-modal > .form-box > .brand img {
        height: 5.6rem;
    }

    .login-modal > .form-box > .brand .symbol {
        width: 10%;
    }

    .login-modal > .form-box > .brand .label {
        width: 60%;
    }

    .login-modal > .form-box .description {
        margin-bottom: 2rem;
    }

    .login-modal > .form-box form {
        padding: 0 3rem 2rem;
        margin: 0 auto;
        position: relative;
    }

    .login-modal > .form-box form footer {
        display: flex;
        justify-content: space-between;
    }

    .login-modal > .form-box .field {
        margin-bottom: ${spaceBase * 2}rem;
    }

    .login-modal > .form-box .field input[type=text],
    .login-modal > .form-box .field input[type=password],
    .login-modal > .form-box button {
        width: 100%;
        height: 5rem;
    }

    .login-modal > .form-box button {
        height: 5rem;
        margin-bottom: ${spaceBase / 1.5}rem;
    }

    .login-modal > .form-box .actions {
        display: flex;
        justify-content: space-between;
    }

    .login-modal > .banner {
        width: 42rem;
        overflow: hidden;
    }


    @media (max-width: 767px) {
        .login-modal > .banner {
            display: none !important;
        }
    }

    .login-modal > .banner img {
        width: 100%;
    }

    .login-modal .success-signup {
        display: table;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        padding: 0 3rem 3rem;
    }

    .login-modal .success-signup h2 {
        margin-top: 0rem;
        color: #7DC671;
        font-weight: 400;
    }

    .login-modal .success-signup h3 {
        font-size: ${$fontSizeLarger};
        margin-bottom: .5rem;
    }

    .login-modal .success-signup p {
        padding: 1rem 0;
    }

    .login-copyright {
        display: flex;
        justify-content: space-between;
        height: 30px;
        font-size: ${$fontSizeSmaller};
        padding: .5rem;
        color: ${$lightgrey700};
    }

    .login-copyright img {
        height: 15px;
    }

    .login-modal .title {
        margin-top: 0;
        text-align: center;
        font-size: 2.4rem;
        margin-bottom: 2rem;
    }

    .login-modal .text-alert {
        text-align: right;
        color: #E32828;
        font-size: ${$fontSizeSmall};
        font-weight: 400;
    }

    .login-modal .field, .password-strength input[type=password],
    .login-modal .field, .password-strength input[type=text] {
        margin: 0;
    }

    .login-modal footer i {
        margin-right: 1rem;
    }

    .login-otp {
        text-align: center;
        margin-bottom: 3rem;
    }

    .login-otp .field {
        margin: 2.5rem 0;
    }

    .login-otp .field input[type=text] {
        text-align: center;
        margin: 0 !important;
    }
`;
