import React, { useCallback, useEffect, useState } from 'react'
import useStyles from './style'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { useSelector, useDispatch } from 'react-redux'
import { fetchPentesReport } from '../../../actions/pentests/report'
import { fetchPentest, fetchSummaryPentests } from '../../../actions/pentests'
import { risks } from '../../../services/pentest-fields'

import { Container } from './styles'
import { Loading, PageTitle } from '../../../components'
import { IconButton, Grid, Typography, Skeleton } from '@mui/material'
import { PictureAsPdf, Visibility } from '@mui/icons-material'

import SummarySideBar from './SummarySideBar'
import VulnerabilityCard from './VulnerabilityCard'
import VulnerabilityDetail from './VulnerabilityDetail'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF0000']

const Dashboard = (props) => {
  const { id: pentestId, report_id: reportId } = props.match.params

  let vulnerabilities = []

  const dispatch = useDispatch()
  const classes = useStyles()

  const [open, setOpen] = useState(undefined)
  const [vulnerabilityData, setVulnerabilityData] = useState(undefined)
  const [summaryData, setSummaryData] = useState({
    modality: undefined,
    pentester: undefined,
  })
  const [dashboardData, setDashboardData] = useState({
    title: 'Site Blindado',
    vulnerabilities: [],
  })

  const account = useSelector((state) => state.account)
  const summary = useSelector((state) => state.pentestSummary)
  const pentest = useSelector((state) => state.pentest)
  const pentestReport = useSelector((state) => state.pentestReport)

  const getDashboardData = useCallback(() => {
    const { modality, pentester } = summary.filters

    const gets = async accountId => {
      dispatch(fetchPentesReport(accountId, pentestId, reportId))
      dispatch(fetchPentest(accountId, pentestId))

      if (!modality && !pentester) {
        await dispatch(fetchSummaryPentests(accountId))
      }
    }

    if (_.isEmpty(pentest.results) && account[0]) {
      gets(account[0].id)
    }

    if (!_.isEmpty(pentest.results) && account[0]) {
      if (pentest.results.report_id === reportId) {
        setDashboardData({ title: 'Site Blindado', vulnerabilities: [] })
      } else {
        gets(account[0].id)
      }
    }
  }, [account, pentestId, reportId])

  const mountSummary = useCallback(() => {
    const { modality, pentester } = summary.filters
    const { modality: dataModality, pentester: dataPentester } =
      pentest.results

    if (dataModality && dataPentester && modality && pentester) {
      setSummaryData({
        modality: modality.filter((m) => m.value === dataModality)[0]
          .label,
        pentester: pentester.filter((p) => p.value === dataPentester)[0]
          .label,
      })
    }
  }, [pentest.results, summary.filters])

  const vulnerabilityByLevel = useCallback(() => {
    if (pentestReport.results.vulnerability) {
      for (let i = 1; i < risks.length; i++) {
        vulnerabilities.push({
          name: risks[i].label,
          id: risks[i].value,
          value: 0,
        })
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      vulnerabilities = vulnerabilities.map((vulnerability) => {
        let riskPercentage = 0

        pentestReport.results.vulnerability.forEach((item) => {
          if (item.risk === vulnerability.id) riskPercentage++
        })

        vulnerability.value = riskPercentage

        return vulnerability
      })

      setVulnerabilityData(vulnerabilities)
    }
  }, [pentestReport.results])

  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  useEffect(() => {
    getDashboardData()
  }, [getDashboardData])

  useEffect(() => {
    mountSummary()
  }, [mountSummary])

  useEffect(() => {
    vulnerabilityByLevel()
  }, [vulnerabilityByLevel])

  const mountReportContentData = (risk, type) => {
    if (type === 'vulnerability') {
      const vulnerabilitiesFiltered =
        pentestReport.results.vulnerability.filter(
          (v) => v.risk === risk
        )

      return setDashboardData((state) => ({
        ...state,
        title: 'Vulnerabilidades',
        vulnerabilities: vulnerabilitiesFiltered,
      }))
    }

    if (type === 'final-considerations') {
      return setDashboardData((state) => ({
        ...state,
        title: 'Considerações Finais',
        finalConsiderations: pentestReport.results.final_resume,
      }))
    }

    if (type === 'technical-detailing') {
      return setDashboardData((state) => ({
        ...state,
        title: 'Detalhamento Técnico',
        technicalDetailing: pentestReport.results.technical_doc,
      }))
    }
  }

  const handleExpandVulnerability = useCallback((id) => {
    setOpen((state) => {
      if (state !== id) return id

      return undefined
    })
  }, [])

  const dashboardContent = () => {
    switch (dashboardData.title) {
      case 'Vulnerabilidades':
        if (_.isEmpty(dashboardData.vulnerabilities)) {
          return (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              Não há vulnerabilidades para este nível.
            </Grid>
          )
        }

        return dashboardData.vulnerabilities.map((row) => (
          <VulnerabilityDetail
            key={row.id}
            data={row}
            expand={open === row.id}
            handleExpand={() => handleExpandVulnerability(row.id)}
          />
        ))
      case 'Detalhamento Técnico':
        return (
          <Grid>
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: dashboardData.technicalDetailing,
              }}
            />
          </Grid>
        )
      case 'Considerações Finais':
        return (
          <Grid>
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: dashboardData.finalConsiderations,
              }}
            />
          </Grid>
        )
      default:
        return undefined
    }
  }

  return (
    <Container className="content">
      <Grid className={classes.container}>
        <Loading loading={pentest.isLoading || pentestReport.isLoading} />

        <Grid>
          <Grid className={classes.headerContainer}>
            <PageTitle
              title={
                !summaryData.modality
                  ? undefined
                  : `Relatório ${summaryData.modality}`
              }
            >
              {summaryData.modality ? (
                <IconButton>
                  <PictureAsPdf fontSize="large" style={{ color: 'red' }} />
                </IconButton>
              ) : (
                <Skeleton
                  variant="circle"
                  height={40}
                  width={40}
                />
              )}
            </PageTitle>
          </Grid>

          <Grid item xs container justifyContent="space-between">
            <SummarySideBar
              date={
                _.isEmpty(pentest.results)
                  ? undefined
                  : pentest.results.exec_start
              }
              url={
                _.isEmpty(pentest.results)
                  ? undefined
                  : pentest.results.url
              }
              pentester={
                summaryData.pentester
                  ? summaryData.pentester
                  : undefined
              }
              vulnerabilityLength={
                vulnerabilityData
                  ? pentestReport.results.vulnerability.length
                  : undefined
              }
              isLoading={pentest.isLoading || pentestReport.isLoading}
              {...props.match.params}
            >
              <Grid className={classes.optionsContainer}>
                {pentest.isLoading || pentestReport.isLoading || !vulnerabilityData
                  ? COLORS.map((i) => (
                    <Skeleton
                      key={i}
                      height={35}
                      style={{ margin: '10px 0' }}
                    />
                  ))
                  : vulnerabilityData.map((item) => (
                    <Grid
                      key={item.id}
                      className={classes.options}
                      style={{
                        borderLeft: `5px solid ${COLORS[item.id - 1]
                          }`,
                      }}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <VulnerabilityCard
                        title={item.name}
                        size={item.value}
                        onClick={() =>
                          mountReportContentData(
                            item.id,
                            'vulnerability'
                          )
                        }
                        icon={
                          <Visibility fontSize="large" />
                        }
                      />
                    </Grid>
                  ))}

                <hr className={classes.divider} />

                <Grid className={classes.optionsContainer}>
                  {pentest.isLoading || pentestReport.isLoading || !vulnerabilityData ? (
                    <Skeleton
                      height={35}
                      style={{ margin: '10px 0' }}
                    />
                  ) : (
                    <Grid
                      className={classes.options}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <VulnerabilityCard
                        title="Detalhamento Técnico"
                        icon={
                          <Visibility fontSize="large" />
                        }
                        onClick={() =>
                          mountReportContentData(
                            55,
                            'technical-detailing'
                          )
                        }
                      />
                    </Grid>
                  )}

                  {pentest.isLoading || pentestReport.isLoading || !vulnerabilityData ? (
                    <Skeleton
                      height={35}
                      style={{ margin: '10px 0' }}
                    />
                  ) : (
                    <Grid
                      className={classes.options}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <VulnerabilityCard
                        title="Considerações Finais"
                        icon={
                          <Visibility fontSize="large" />
                        }
                        onClick={() =>
                          mountReportContentData(
                            55,
                            'final-considerations'
                          )
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </SummarySideBar>

            <Grid item xs={9}>
              <Grid className={classes.dataContainer}>
                <Typography
                  className={classes.subtitle}
                  variant="h4"
                >
                  {dashboardData.title}
                </Typography>

                {dashboardContent()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

Dashboard.propTypes = {
  params: PropTypes.object,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}

export default Dashboard
