import PropTypes from 'prop-types'
import React from 'react'
// import ReactDOM from 'react-dom'
import classnames from 'classnames'

export class Popover extends React.Component {
    constructor(props) {
        super(props)
        this.state = { open: this.props.open }
        this.handleClick = this.handleClick.bind(this)
        this.setBoxPosition = this.setBoxPosition.bind(this)
        this.isRight = this.isRight.bind(this)
        this.isTop = this.isTop.bind(this)
        this.refPopover = React.createRef()
    }

    componentDidMount() {
        // this.obj = ReactDOM.findDOMNode(this)
        this.box = this.refPopover.querySelector('.popover-box')
        this.toggle = this.refPopover.firstElementChild
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick)
    }

    setBoxPosition() {
        if (!this.refPopover) {
            return
        }

        const square = 40
        const posX = (this.refPopover.offsetHeight / 2) - square
        const posY = this.isRight() ?
            this.toggle.offsetWidth + (square / 1.5) :
            -(this.box.offsetWidth + square / 1.5)

        const style = {}
        style[this.isTop() ? 'top' : 'bottom'] = posX
        style.left = posY

        return style
    }

    isRight() { return this.props.right || !this.props.left }

    isTop() { return this.props.top || !this.props.bottom }

    handleClick(e) {
        if (this.box.contains(e.target)) {
            return
        }

        // if (!this.state.open) {
        //     document.addEventListener('click', this.handleClick);
        // } else {
        //     document.removeEventListener('click', this.handleClick);
        // }

        this.setState(oldState => ({ ...oldState, open: !oldState.open }))
    }

    render() {
        const popClasses = classnames('popover', {
            open: this.state.open,
            right: this.isRight(),
            top: this.isTop(),
            bottom: this.props.bottom,
            left: this.props.left,
        })

        return (
            <span className={popClasses}>
                <span className="toggle" ref={(toggle) => { this.toggle = toggle }} onClick={this.handleClick}>
                    {this.props.toggle}
                </span>

                <div className="popover-box" style={this.setBoxPosition()}>
                    <div className="content-inner">
                        {this.props.children}
                    </div>
                </div>
            </span>
        )
    }
}

Popover.propTypes = {
    open: PropTypes.bool,
    right: PropTypes.bool,
    left: PropTypes.bool,
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    toggle: PropTypes.any,
    children: PropTypes.any,
}
