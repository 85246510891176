import { css } from 'styled-components';

import { $green300 } from '../settings/variables';

export default css`
    .filter-select {
        display: inline-block;
        margin-left: .5rem;
        position: relative;
    }

    .filter-select.open > .filter-icon {
        background: #f7f7f7;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24);
    }

    .filter-select.open > .filter-dropdown {
        display: flex;
        flex-direction: column;
        width: auto;
        z-index: 1000;
        min-width: 15rem;
        right: 0;
    }

    .filter-select > .filter-icon {
        padding: .4rem;
        cursor: pointer;
    }

    .filter-select > .filter-icon .sb2wicon {
        font-size: 1.3rem;
        margin: 0 0.2rem;
    }

    .filter-select > .filter-icon.active > svg {
        fill: ${$green300};
    }

    .filter-select > .filter-dropdown {
        display: none;
        padding: 1rem;
        background: #F7F7F7;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
        position: absolute;
        text-align: left;
    }

    .filter-select > .filter-dropdown > .checkbox {
        margin-bottom: .5rem;
    }
`;
