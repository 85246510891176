import PropTypes from 'prop-types'
import React from 'react'
import {Card, ExpiringCertificates, GenericPieChart, Loading, MyProducts} from '../../components'
import {CertificatesFilters} from '../../containers'
import {fetchDashboard} from '../../actions/dashboards'
import {connect} from 'react-redux'
import find from 'lodash/find'
import {withAccountId} from '../AccountContainer'
import {fetchSummaryProducts} from "../../actions/products";

class DashboardSSL extends React.Component {
  constructor(props) {
    super(props)
    this.getChartData = this.getChartData.bind(this)
  }

  componentDidMount() {
    const accountId = this.props.accountId

    if (!this.props.isLoading) {
      this.props.fetchSummaryProducts(accountId)
      this.props.fetchDashboard('ssl', accountId)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.accountId !== newProps.accountId) {
      this.props.fetchDashboard('ssl', newProps.accountId)
    }
  }

  getChartData(name) {
    const chart = this.getChart(name)

    if (!chart.data) {
      return []
    }

    return chart.data
  }

  getChart(name) {
    if (!this.props.charts) {
      return []
    }

    const charts = find(this.props.charts, {name})

    return charts
  }

  render() {
    return (
      <article className="dashboard">
        <Loading loading={this.props.isLoading}/>

        <Card>
          <div className="inline-items">
            <GenericPieChart
              title="Nota"
              subtitle={this.getChart('rating').subtitle}
              data={this.getChartData('rating')}
            />

            <ExpiringCertificates
              data={this.getChartData('certificates-expiring')}
            />

            <MyProducts productType="ssl"/>
          </div>
        </Card>

        <CertificatesFilters
          accountId={this.props.accountId}
          filters={this.props.filters}
        />
      </article>
    )
  }
}

DashboardSSL.propTypes = {
  accountId: PropTypes.string.isRequired,
  fetchDashboard: PropTypes.func,
  charts: PropTypes.array,
  isLoading: PropTypes.bool,
  filters: PropTypes.array,
}

const mapStateToProps = ({dashboards, fetchSummaryProducts}) => {
  return ({
    isLoading: dashboards.isLoading,
    ...dashboards.ssl,
    dashboards,
    fetchSummaryProducts
  })
}

export default connect(mapStateToProps, {fetchDashboard, fetchSummaryProducts})(withAccountId(DashboardSSL))
