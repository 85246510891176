import PropTypes from 'prop-types';
import React from 'react';

export const ValidField = ({ valid, name }) => {
    const clname = (valid) ? 'valid' : 'invalid';

    return (
        <div className="valid-field">
            <span className={clname}>{name}</span>
        </div>
    );
};

ValidField.propTypes = {
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    valid: PropTypes.bool,
};
