import { useEffect, useState } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Link } from 'react-router-dom'
import { Button, Loading, PageTitle } from '../../components'
import {
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  FormControlLabel,
  RadioGroup,
  Collapse,
  TextField,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDevices } from '../../actions/domains'
import { fetchProducts } from '../../actions/products'
import { createApplication, fetchApplication } from '../../actions/applications'
import { useForm, Controller } from 'react-hook-form'

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: 3,
  },
  titleContainer: {
    width: '98%',
    margin: '20px auto',
    backgroundColor: 'white',
    boxShadow: '0 5px 5px 0px rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 30%)',
    borderRadius: '7px 7px 7px 7px',
  },
  formContainer: {
    width: '98%',
    padding: '15px 50px',
    paddingTop: 40,
    minHeight: 'calc(100vh - 60rem)',
    margin: '0 auto',
    backgroundColor: 'white',
    boxShadow: '0 5px 5px 0px rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 30%)',
    borderRadius: '7px 7px 7px 7px',
  },
  item: {
    margin: '10px 0 !important',
  },
  itemCollapse: {
    paddingRight: 10,
    margin: '5px 0',
  },
  collapse: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: 15,
  },
  portContainer: {
    display: 'flex',
  },
})

export default function FormArmour (props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { match, history } = props
  const { params } = match

  const account = useSelector(state => state.account)
  const devices = useSelector(state => state.devices.results)
  const products = useSelector(state => state.clientProducts)
  const formProduct = useSelector(state => state.formArmour)

  const accountId = account[0] ? account[0].id : undefined

  const { handleSubmit, formState: { errors, isSubmitting }, control, setValue, getValues, register } = useForm()

  useEffect(() => {
    if (params.id && accountId) {
      dispatch(fetchDevices(accountId, { page_size: 99999 }))
      dispatch(fetchProducts(accountId, { page_size: 99999 }))

      return dispatch(fetchApplication(params.id))
    }

    if (accountId) {
      dispatch(fetchDevices(accountId, { page_size: 99999 }))
      dispatch(fetchProducts(accountId, { page_size: 99999 }))
    }

    if (!accountId) {
      return history.push('/store/products')
    }
  }, [accountId, dispatch, history, params.id])

  useEffect(() => {
    if (!params.id && !_.isEmpty(products.results) && !_.isEmpty(formProduct)) {
      const { productId } = formProduct

      setValue('product_id', products.results[productId].product_type.name)
    }
  }, [formProduct, params.id, products.results, setValue])

  const onSubmit = (data) => {
    !params.id &&
      dispatch(createApplication({
        ...data,
        product_id: products.results[formProduct.productId].id,
        date: moment(data.date).format('YYYY-MM-DD HH:mm')
      }))
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.titleContainer}>
        <PageTitle
          title={params.id
            ? 'Edição de Aplicação'
            : 'Criação de Aplicação'
          }
        />
      </Grid>

      <Grid className={classes.formContainer}>
        <Loading loading={devices.isLoading || products.isLoading || formProduct.isLoading} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container alignItems="center" item xs={12}>
            <Grid className={classes.item} item xs={3}>
              <TextField
                label="Produto"
                variant='outlined'
                defaultValue=""
                name="product_id"
                rules={{ required: true }}
                fullWidth
                disabled
              />
            </Grid>

            <Grid className={classes.item} item xs={12}>
              <Controller
                render={({ field }) =>
                  <TextField
                    {...field}
                    label="Dominio *"
                    color="primary"
                    variant="outlined"
                    error={!!errors.domain_id}
                    select
                    fullWidth
                  >
                    <MenuItem value="">
                      Selecione um domínio
                    </MenuItem>

                    {!_.isEmpty(devices) && devices.map(option =>
                      <MenuItem
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    )}
                  </TextField>
                }
                defaultValue=""
                name="domain_id"
                {...register("domain_id")}
                rules={{ required: true }}
                control={control}
              />
            </Grid>

            {!params.id &&
              <Grid className={classes.item} item xs={3}>
                <Controller
                  render={({ field }) =>
                    <DateTimePicker
                      {...field}
                      id="date"
                      label="Agendamento do primeiro scan *"
                      format="DD/MM/YYYY HH:mm"
                      value={getValues('date') || null}
                      minDate={moment(new Date())}
                      inputVariant="outlined"
                      variant="inline"
                      renderInput={params =>
                        <TextField
                          {...params}
                          {...field}
                          fullWidth
                          error={!!errors.date}
                          helperText={null}
                        />
                      }
                    />
                  }
                  name="date"
                  {...register("date")}
                  defaultValue={null}
                  control={control}
                  rules={{
                    required: true,
                    validate: value => value !== '',
                    onChange: ({ target }) => setValue('date', moment(target.value).format('YYYY-MM-DD HH:mm')),
                  }}
                />
              </Grid>
            }
            <Grid className={classes.item} item xs={12}>
              <Button
                style={{ marginRight: '15px' }}
                type="submit"
                disabled={params.id}
                loading={isSubmitting}
              >
                Salvar
              </Button>

              <Link to="/armour/scan-api">
                Cancelar
              </Link>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

FormArmour.propTypes = {
  params: PropTypes.object,
  route: PropTypes.object,
}
