import PropTypes from 'prop-types';
import React from 'react';
import { WizardSteps } from '../components';

export class Wizard extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(props = undefined) {
        this.props.onSubmit(props);
    }

    render() {
        const { children, step } = this.props;

        const steps = children.map((child, id) => (
            { title: child.props.stepTitle, active: id < this.props.step }
        ));
        const childrens = children.map((child, id) => (
            React.cloneElement(child, {
                key: id + 1,
                prevStep: this.props.prevStep,
                nextStep: this.props.nextStep,
                onSubmit: this.onSubmit,
            })
        ));

        return (
            <article className="wizard">
                <WizardSteps
                    steps={steps}
                />
                <div className="wizard-content">
                    {childrens.filter((child) =>
                        (Number(child.key) === step)
                    ).shift()}
                </div>
            </article>
        );
    }
}

Wizard.defaultProps = {
    step: 1,
};

Wizard.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    step: PropTypes.number,
    children: PropTypes.array,
};
