import { css } from "styled-components";

export default css`
    .order-tracker {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        max-width: 1000px;
        margin: auto;
        padding: 5rem;
    }

    .order-tracker-step {
        width: 170px;
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .order-tracker-status {
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 8rem;
        height: 8rem;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        background: rgb(224 224 224);
        background: linear-gradient(
                137deg,
                rgb(224 224 224) 0%,
                rgb(251 251 251) 100%
        );
        padding: 1rem;
        border-radius: 100%;
        border: 3px solid #ffffff;
        box-shadow: 0 2px 9px -3px #ccc;

        color: #BBB;
    }

    .order-tracker-status-active {
        background: rgb(127, 255, 172);
        background: linear-gradient(
                137deg,
                rgba(127, 255, 172, 1) 0%,
                rgba(254, 255, 211, 1) 100%
        );

        color: #000;
    }

    .order-tracker-status-cancelled {
        background: rgb(255 127 127);
        background: linear-gradient(
                137deg,
                rgb(255 127 127) 0%,
                rgb(255 211 254) 100%
        );

        color: #000;
    }

    .order-tracker-status-undone {
        background: rgb(255 217 127);
        background: linear-gradient(
                137deg,
                rgb(255 217 127) 0%,
                rgb(254, 255, 211, 1) 100%
        );

        color: #000;
    }

    .order-tracker-description {
        font-size: 0.9rem;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        width: 150px;
        text-align: center;
    }

    .order-tracker-date {
        position: absolute;
        top: -2rem;
        font-size: 0.8rem;
    }

    .order-tracker-line {
        content: " ";
        z-index: 9;
        position: absolute;
        left: 0;
        top: 10rem;
        display: table;
        width: 100%;
        border: 0 none;
        border-radius: 10rem;
        background: #ccc;
        color: #ccc;
        font-size: 0.3rem;
        text-align: center;
    }

    .order-tracker-buttons {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        width: 100%;
        margin-top: 2rem;
    }

    .order-tracker-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #74b61b;
        color: #fff;
        padding: 0.5rem;
        font-size: 1rem;
        width: 100%;
        border-radius: 5px;
        border: 0 none;
        margin-bottom: 0.5rem;
    }

    .order-tracker-button-cancel {
        background: #b6411b;
    }

    .order-tracker-button-warn {
        background: #d5ab18;
    }
`;
