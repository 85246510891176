import MaterialTable from '@material-table/core';
import tableIcons from '../../styles/components/material-table-icons';
import React from "react";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  root: {
    "& .Component-horizontalScrollContainer-17": {
      padding: '1% 1% 0 1%',
    },
    "& .MuiSelect-root": {
      fontSize: '1.4rem',
    },
    "& .MuiTypography-root": {
      fontSize: '1.3rem',
    },
    "& .MuiToolbar-root": {
      backgroundColor: '#f7f7f7',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: '#2D343D',
      minHeight: '5rem',
      fontFamily: 'Lato,sans-serif'
    },
    "& h6": {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#2D343D',
      fontFamily: 'Lato,sans-serif'
    }
  }
}));


export function MaterialTableWrap(props) {
  // const [data, setData] = useState(props.source);
  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  // const [totalCount, setTotalCount] = useState(1);
  // const [iserror, setIserror] = useState(false)
  // const [errorMessages, setErrorMessages] = useState([])
  const tableRef = React.useRef();
  const accountId = props.accountId;
  const classes = useStyles();

  const getData = (query) => {
    return new Promise((resolve, reject) => {
      props.actionFetch(accountId, {
        page: query.page + 1,
        page_size: query.pageSize,
        search: query.search
      })
        .then(result => {
          const tableData = result.value.results.map(r => ({ ...r }))
          const totalCount = result.value.count;
          const pageSize = result.value.page_size;

          resolve({
            data:tableData,
            page: query.page,
            pageSize,
            totalCount
          })
        })
    });
  }

  return (
    <div className={classes.root}>
      <MaterialTable
        title={props.title}
        tableRef={tableRef}
        columns={props.columns}
        icons={tableIcons}
        data={getData}
        // onPageChange={(page, pageSize) => {
        //   setPageSize(pageSize);
        //   setPage(page);
        //   // this.setState({query: {...query, pageSize}}, this.fetchData);
        // }}
        // onRowsPerPageChange={(pageSizeNumber) => {
        //   setPageSize(pageSizeNumber);
        //   // this.setState({query: {...query, pageSize}}, this.fetchData);
        // }}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          headerStyle: {
            fontSize: '1.3rem',
            fontWeight: 'bold',
            color: '#2D343D',
            fontFamily: 'Lato,sans-serif'
          },
          rowStyle: {
            fontSize: '1.3rem',
          },
          searchFieldStyle: {
            width: '25rem',
            margin: '1rem 0 1rem 0'
          }
        }}
        style={{
          borderRadius: '.25rem .25rem 0 0',
          borderColor: '#2D343D',

        }}
        localization={{
          header: {
            actions: null,
          },
          toolbar: {
            searchTooltip: 'Pesquisar...',
            searchPlaceholder: 'Pesquisar...'
          },
          body: {
            emptyDataSourceMessage: 'Nenhum registro encontrado.',
            editRow: {
              deleteText: 'Você tem certeza que gostaria de apagar este registro?',
              cancelTooltip: 'Cancelar',
              saveTooltip: 'Confirmar'
            },
            deleteTooltip: 'Apagar',
            editTooltip: 'Editar',
          },
          pagination: {
            labelRowsSelect: 'linhas',
            labelRowsPerPage: 'Linhas por página',
            firstAriaLabel: 'Primeira página',
            firstTooltip: 'Primeira página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Próxima página',
            nextTooltip: 'Próxima página',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
          },
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              props.handleRowUpdate(oldData, newData, resolve);
            }),
          // onRowAdd: (newData) =>
          //   new Promise((resolve) => {
          //     handleRowAdd(newData, resolve)
          //   }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              props.handleRowDelete(oldData.id, resolve)
            }),
        }}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Atualizar',
            isFreeAction: true,
            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
          }
        ]}
      />
    </div>
  )
}
