import React, { memo } from 'react'
import PropTypes from 'prop-types'
import useStyles from '../styles'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

export const TextBlockField = ({ name, label, defaultValue, control, error, rules }) => {
  const classes = useStyles()

  return (
    <Controller
      render={({ field }) =>
        <TextField
          id="outlined-multiline-static"
          className={classes.textBlock}
          variant="outlined"
          label={label}
          name={name}
          multiline
          rows={8}
          fullWidth
          error={error}
          {...field}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
    />
  )
}

TextBlockField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  control: PropTypes.object,
  error: PropTypes.bool,
  rules: PropTypes.object,
}

export default memo(TextBlockField)
