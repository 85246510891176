import { useEffect } from 'react'
import { Card } from '../../components'
import { FormOrganization } from '../../containers'

export const Company = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <div className="card-content">
          <FormOrganization edit />
        </div>
      </Card>
    </section>
  )
}
