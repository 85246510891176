import { css } from 'styled-components';

import {
    $bodyFontColor,
    $borderRadiusBase,
    $darkgrey800,
    $fontSizeSmall,
    $fontSizeTiny,
    $lightgrey400,
    $lightgrey600,
    spaceBase,
} from '../settings/variables';

export default css`
    .page {
        display: flex;
        flex-flow: column nowrap;
        min-height: 100%;
        width: 100%;
    }

    .page > .page-content {
        flex-grow: 1;
        z-index: 1;
    }

    .page > .page-content .content {
        padding: 2rem;
        position: relative;
    }

    .page > .page-footer {
        display: flex;
        justify-content: space-between;
        margin: 0px;
        padding: ${spaceBase / 2}rem ${spaceBase / 2}rem;
        width: 98%;
        font-size: ${$fontSizeTiny};
    }

    .page > .page-footer img {
        height: 17px;
    }

    .page .page-title-controls {
        display: flex;
        justify-content: space-between;
    }

    .page .page-title-controls .controls-links {
        position: relative;
        display: inline-block;
        border-radius: ${$borderRadiusBase};
        border: none;
        width: auto;
        height: 3rem;
        min-width: auto;
        padding: .5rem 3.5rem;
        cursor: pointer;
        color: #fff;
        font-size: ${$fontSizeSmall};
        text-align: center;
        text-decoration: none;
        text-transform: none;
        outline: none;
        transition: all .2s ease-out;

        color: ${$bodyFontColor} !important;
        border: .1rem solid ${$lightgrey600};
        border-right: none;
        border-radius: 0px;
    }

    .page .page-title-controls .controls-links:hover {
        border: .1rem solid ${$lightgrey600};
        border-right: none;
        background-color: ${$lightgrey400};
        color: ${$darkgrey800} !important;
    }

    .page .page-title-controls .controls-links:first-child {
        border-radius: ${$borderRadiusBase} ${$borderRadiusBase} 0 0;
    }

    .page .page-title-controls .controls-links:last-child {
        border-radius: 0 0 ${$borderRadiusBase} ${$borderRadiusBase};
        border-right: .1rem solid ${$lightgrey600};
    }
`;
