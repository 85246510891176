import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {SelectField} from '../../components';
import {fetchProducts} from '../../actions/products';
import {withAccountId} from '../AccountContainer';

class SelectProductsField extends React.PureComponent {
  // eslint-disable-next-line no-undef
  static defaultProps = {
    name: 'product',
  }

  constructor(props) {
    super(props);
    this.addSelectText = this.addSelectText.bind(this);
  }

  componentWillMount() {
    this.props.fetchProducts(this.props.accountId, {
      page_size: 99999, /* TODO: temporário */
      category: this.props.category,
    });
  }

  addSelectText(product) {
    return [{id: '', description: 'Selecione um Produto'}].concat(product);
  }

  render() {
    return (
      <Field
        {...this.props}
        options={this.addSelectText(this.props.productsOptions)}
        component={SelectField}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {clientProducts} = state;
  return {
    productsOptions: clientProducts.availablesIds.map(id => ({
      id: clientProducts.results[id].id,
      description: clientProducts.results[id].description,
    })),
  };
};

export default connect(mapStateToProps, {fetchProducts})(withAccountId(SelectProductsField));
