import PropTypes from 'prop-types'
import React from 'react'
import TweenMax from 'gsap'

export class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }

    this.toClose = this.toClose.bind(this)
    this.toOpen = this.toOpen.bind(this)
    this.toStart = this.toStart.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.toStart()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({ open: nextProps.open }, this.toggle)
    }
  }

  toStart() {
    this.setState({ open: this.props.open }, () => {
      this.toggle(0)
    })
  }

  toOpen(speed) {
    TweenMax.to(this.refs.content, { height: 'auto' })
    TweenMax.to(this.refs.icon, speed, { rotation: 90 })
  }

  toClose(speed) {
    TweenMax.to(this.refs.content, speed, { height: 0 })
    TweenMax.to(this.refs.icon, speed, { rotation: 0 })
  }

  toggle(speed = 0.5) {
    const act = this.state.open ? this.toOpen : this.toClose
    act(speed)
  }

  handleClick() {
    this.setState({ open: !this.state.open }, this.toggle)
  }

  render() {
    return (
      <section className="accordion">
        <div className="header" onClick={this.handleClick}>
          <span className="icon" style={{ backgroundColor: this.props.color }}>
            <span ref="icon" />
          </span>
          <span className="title">
            {this.props.title}
          </span>
        </div>

        <div className="container-box" ref="content">
          <div className="content-box">
            {this.props.children}
          </div>
        </div>
      </section>
    )
  }
}

Accordion.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.any,
  children: PropTypes.any,
}
