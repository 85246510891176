import {fetch} from '../services/fetch'
import {push} from 'connected-react-router'
import {setMessage, SUCCESS} from '../modules/messages'
import {refreshToken} from './auth'

export const ACCEPT = 'invite/ACCEPT'

export function acceptInvite(inviteId) {
  return dispatch => {
    const request = fetch(`/invites/${inviteId}`, {method: 'PUT'})
      .then(res => {
        dispatch(refreshToken())
        dispatch(push('/'))
        dispatch(setMessage(SUCCESS, 'Convite aceito com sucesso!'))

        return res.json()
      })

    return {
      type: ACCEPT,
      payload: request,
    }
  }
}
