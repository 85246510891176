import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  Loading,
  Button,
} from '../../components'
import NewAddressFields from '../../components/forms/newAdressFields'
import InputMask from 'react-input-mask'
import { saveUser, fetchUser } from '../../actions/users'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Gravatar from 'react-gravatar'
import _ from 'lodash'
import { Grid } from '@mui/material'

const MessageUpdate = (update) => {
  if (!update.update) {
    return ''
  }
  return (
    <p style={{
      color: '#50AFE4',
    }}>
      *Verifique se seu perfil está atualizado. Caso seja necessário, atualize suas
      informações e clique no botão
      <strong> SALVAR</strong>.
    </p>
  )
}

const FormProfile = (props) => {
  const [isUser, setIsUser] = useState(true)

  const { register, handleSubmit, formState: { errors, isSubmitting }, control, setValue } = useForm()

  const data = useSelector(state => state.user)
  const auth = useSelector(state => state.auth)

  const dispatch = useDispatch()

  const photoEditHref = 'https://signup.wordpress.com/signup/?ref=oauth2&oauth2_redirect=' +
    '71eaeadf41ee7bc78414c12a85be4d38%40https%3A%2F%2Fpublic-api.wordpress.com%2Foauth2%' +
    '2Fauthorize%2F%3Fclient_id%3D1854%26response_type%3Dcode%26blog_id%3D0%26state%' +
    '3Deddea8a067a06719af1f7fbceea7c43fd172621949a994afd2494ecedda73d8b%26redirect_uri%' +
    '3Dhttps%253A%252F%252Fen.gravatar.com%252Fconnect%252F%253Faction%253Drequest_access_token%' +
    '26jetpack-code%26jetpack-user-id%3D0%26action%3Doauth2-login&wpcom_connect=1'

  useEffect(() => {
    if (!data.isLoading) {
      dispatch(fetchUser(props.userId))
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(data.results)) {
      setValue('cpf', data.results.cpf)
      setValue('email', data.results.email)
      setValue('first_name', data.results.first_name)
      setValue('last_name', data.results.last_name)
      setValue('phone', data.results.phone)

      auth.data.role === 'admin' &&
        setIsUser(false)

      if (data.results.address) {
        setValue('address.city', data.results.address.city)
        setValue('address.complement', data.results.address.complement)
        setValue('address.district', data.results.address.district)
        setValue('address.number', data.results.address.number)
        setValue('address.state', data.results.address.state)
        setValue('address.street', data.results.address.street)
        setValue('address.zipcode', data.results.address.zipcode)
        setValue('address.country', data.results.address.country)
      }
    }
  }, [data.isLoading, auth.data, data.results, setValue])

  const onSubmit = async (data) => {
    await dispatch(saveUser(props.userId, data))
  }

  return (
    <>
      {(!_.isEmpty(data.results) && isUser && !data.isLoading) &&
        <Grid style={{ color: 'red', padding: 10, width: '20vw' }}>
          <span>
            * Para alterar os dados entre em contato com nosso time de relacionamento pelo e-mail: <a href="mailto:relacionamento@siteblindado.com.br">
              relacionamento@siteblindado.com.br
            </a>
          </span>
        </Grid>
      }


      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <MessageUpdate update={data.results.updateInfo} />

        <Loading loading={data.isLoading} />
        <div className="field">
          <label htmlFor="email">
            E-mail *
          </label>

          <Controller
            render={({ field }) =>
              <input
                className="input"
                type="text"
                disabled
                {...field}
              />
            }
            name="email"
            defaultValue=""
            rules={{
              required: true,
              pattern: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            }}
            control={control}
          />
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="field">
              <label htmlFor="first_name">
                Nome *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    className={`input ${errors.first_name ? 'input-error' : ''}`}
                    type="text"
                    disabled={isUser}
                    {...field}
                  />
                }
                name="first_name"
                defaultValue=""
                rules={{ required: true }}
                control={control}
              />

              {errors.first_name &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </div>
          </div>

          <div className="col-md-6">
            <div className="field">
              <label htmlFor="last_name">
                Sobrenome *
              </label>

              <input
                className={`input ${errors.last_name ? 'input-error' : ''}`}
                name="last_name"
                type="text"
                defaultValue=""
                disabled={isUser}
                {...register('last_name', { required: true })}
              />

              {errors.last_name &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="field">
              <label htmlFor="cpf">
                CPF *
              </label>

              <Controller
                render={({ field }) =>
                  <InputMask
                    className={`input ${errors.cpf ? 'input-error' : ''}`}
                    type="text"
                    pattern="([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})"
                    mask="999.999.999-99"
                    disabled={isUser}
                    {...field}
                  />
                }
                name="cpf"
                defaultValue=""
                rules={{ required: true }}
                control={control}
              />

              {errors.cpf &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </div>
          </div>

          <div className="col-md-6">
            <div className="field">
              <label htmlFor="phone">
                Telefone *
              </label>

              <Controller
                render={({ field }) =>
                  <InputMask
                    className={`input ${errors.phone ? 'input-error' : ''}`}
                    type="text"
                    mask="(99) 9999-99999"
                    disabled={isUser}
                    {...field}
                  />
                }
                name="phone"
                defaultValue=""
                rules={{ required: true }}
                control={control}
              />

              {errors.phone &&
                <span className="input-error">
                  Este campo é obrigatório
                </span>
              }
            </div>
          </div>
        </div>

        <h2>
          Endereço
        </h2>

        <NewAddressFields
          isLoading={data.isLoading}
          disabled={isUser}
          errors={errors}
          control={control}
        />

        <div className="row">
          <div className="col-md-6">
            <div className="field">
              <Gravatar
                className="gravatar"
                email={props.email || 'admin@siteblindado.com.br'}
                size={60}
                default="mm"
              />

              <a
                href={photoEditHref}
                target="_blank"
                rel="noreferrer"
                disabled={isUser}
                style={{ marginLeft: '10px' }}
              >
                Editar foto
              </a>
            </div>
          </div>
        </div>

        <div className="buttons">
          <Button type="submit" loading={isSubmitting} disabled={isUser}>
            Salvar
          </Button>
        </div>
      </form >
    </>
  )
}

FormProfile.propTypes = {
  saveUser: PropTypes.func,
  fetchUser: PropTypes.func,
  handleSubmit: PropTypes.func,
  goBack: PropTypes.func,
  email: PropTypes.string,
  errors: PropTypes.object,
  userId: PropTypes.string,
  isLoading: PropTypes.bool,
  submitting: PropTypes.bool,
  updateInfo: PropTypes.bool,
}

export default FormProfile
