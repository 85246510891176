import { css } from 'styled-components';

export default css`
    .vulnerability-card {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding: 1rem;
    }

    .vulnerability-card > .header {
        display: flex;
        justify-content: space-between;
        border-bottom: .1rem solid #E3E3E3;
        padding: .5rem;
    }

    .vulnerability-card > .header .status {
        text-align: right;
        display: flex;
        align-items: center;
        line-height: 1.5rem;
        font-weight: bold;
    }

    .vulnerability-card > .header .status small {
        font-weight: normal;
    }

    .vulnerability-card > .header .status [class*="mark-"] {
        margin: 0 0 0 1rem;
        width: 2.3rem;
        height: 2.3rem;
    }

    .vulnerability-card footer {
        display: flex;
        justify-content: space-between;
        border-top: 1px #eee solid;
        padding-top: 1rem;
        margin-bottom: 0;
    }

    .vulnerability-card footer i {
        padding-top: .8rem;
    }

    .vulnerability-card footer .delete {
        color: red;
    }
`;
