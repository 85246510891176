import head from 'lodash/head'
import { fetch } from '../services/fetch'
import { toParams } from '../services/utils'
import { setMessage, SUCCESS, ERROR } from '../modules/messages'
import { closeSlider } from './slider'
import { fetchDashboard } from './dashboards'
import { push } from 'connected-react-router'
import { tokenHandler } from './auth'

export const FETCH_UNIQUE = 'applications/FETCH_UNIQUE'
export const FETCH_ALL = 'applications/FETCH_ALL'
export const FETCH_DETAILS = 'applications/FETCH_DETAILS'
export const FETCH_DETAILS_PROFILE = 'applications/FETCH_DETAILS_PROFILE'
export const VERIFY_PDF = 'applications/VERIFY_PDF'
export const GET_SCAN = 'applications/GET_SCAN'
export const CLEAR = 'applications/CLEAR'
export const CREATE = 'applications/CREATE'
export const SCHEDULING = 'applications/SCHEDULING'
export const DELETE = 'aw_scan/DELETE'
export const SAVE = 'seal/SAVE'
export const DELETE_APPLICATION = 'applications/DELETE'


export function fetchApplications(accountId, params = {}) {
  const query = toParams(params)
  const request = fetch(`/accounts/${accountId}/applications${query}`).then(res => res.json())

  return {
    type: FETCH_ALL,
    payload: request,
    meta: { filters: params },
  }
}

export function fetchApplication(applicationId) {
  const request = fetch(`/applications/${applicationId}`).then(res => res.json())

  return {
    type: FETCH_UNIQUE,
    payload: request,
  }
}

export function fetchApplicationDetails(applicationId, params = {}) {
  const query = toParams(params)
  const request = fetch(`/applications/${applicationId}/aw_scans${query}`).then(res => res.json())

  return {
    type: !params.scan_profiles ? FETCH_DETAILS : FETCH_DETAILS_PROFILE,
    payload: request,
    meta: { profile: params.scan_profiles },
  }
}

export const removeAWScan = (scanId, cb) => (
  (dispatch) => {
    const request = fetch(`/aw-scans/${scanId}`, { method: 'DELETE' })
      .then(() => {
        dispatch(setMessage(SUCCESS, 'Removido com sucesso!'))
        cb()
      })
      .catch(res => {
        dispatch(setMessage(ERROR, res.message))
      })

    return dispatch({
      type: DELETE,
      payload: request,
    })
  }
)

export function clear() {
  return {
    type: CLEAR,
  }
}

// TODO: Pode ter uma forma melhor de separar o scan das actions/reducers de application
export function getScanProfile(id) {
  const request = fetch(`/aw-scans/${id}/report`)
    .then(res => res.json())
    .catch(e => {
      return e.errors
    })

  return {
    type: GET_SCAN,
    payload: request,
  }
}

export function createApplication(data, type) {
  delete data.product
  return dispatch => {
    const request = fetch('/device_scan_profiles', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => {
        if (type) {
          dispatch(setMessage(SUCCESS, 'O Scan foi criado com sucesso!'))
          return dispatch(push('/armour/scan-api'))
        }

        dispatch(setMessage(SUCCESS, 'Aplicação criada com sucesso!'))
        dispatch(push('/armour/applications'))

        return res
      })
      .catch(res => {
        dispatch(setMessage(ERROR, 'Ocorreu um erro'))

        return res
      })

    return dispatch({
      type: CREATE,
      payload: request,
    })
  }
}

export const saveApplication = (applicationId, data) => (
  (dispatch) => {
    const request = fetch(`/applications/${applicationId}`, { method: 'PUT', body: JSON.stringify(data) })
      .then(tokenHandler(dispatch))
      .then(res => {
        dispatch(push('/armour/applications'))
        dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'))
        return res.json()
      })
      .catch(res => res)

    return dispatch({
      type: SAVE,
      payload: request,
    })
  }
)

export function deleteApplication(applicationId, type) {
  return (dispatch, getState) => {
    const request = fetch(`/applications/${applicationId}`, { method: 'DELETE' })
    return dispatch({
      type: DELETE_APPLICATION,
      payload: request,
    }).then(() => {
      const account = head(getState().account) || {}

      if (type) {
        dispatch(fetchApplications(account.id, type))
        return dispatch(setMessage(SUCCESS, 'O Scan foi removido com sucesso.'))
      }

      dispatch(fetchApplications(account.id))
      dispatch(fetchDashboard('aw', account.id))
      dispatch(setMessage(SUCCESS, 'A applicação foi removida com sucesso.'))
    })
  }
}

export function sendScheduling(scanProfileId, data) {
  return (dispatch, getState) => {
    const url = data.schedule ?
      `/device_scan_profiles/${scanProfileId}` :
      `/device_scan_profiles/${scanProfileId}/aw_scans`

    const options = {
      method: data.schedule ? 'PUT' : 'POST',
      body: JSON.stringify(data),
    }


    const request = fetch(url, options)
      .then(res => {
        dispatch(setMessage(SUCCESS, 'Agendamento criado!'))
        res.json()
      })
      .then(() => {
        const account = head(getState().account) || {}
        dispatch(fetchApplications(account.id))
        dispatch(closeSlider())
      })
      .catch(res => {
        if (res.message) {
          dispatch(setMessage(ERROR, res.message))
        }
        if (res.errors) {
          dispatch(setMessage(ERROR, res.errors[0].message))
        }

        return res
      })
    return {
      type: SCHEDULING,
      payload: request,
    }
  }
}

export const saveSeal = (applicationId, data) => (
  (dispatch) => {
    const request = fetch(`/seal/aw/${applicationId}`, { method: 'PUT', body: JSON.stringify(data) })
      .then(res => {
        dispatch(push('/armour/applications'))
        dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'))
        return res.json()
      })
      .catch(res => res)

    return dispatch({
      type: SAVE,
      payload: request,
    })
  }
)
