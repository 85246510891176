import PropTypes from 'prop-types';
import React from 'react';
import { IconMdContentCopy } from '../sb2w-icons/md';

export const CopyToClipBoardInList = ({ children }, context) => {
    const id = children.slice(-12);

    const copyClipboard = () => {
        const range = document.createRange();
        const selection = window.getSelection();
        const mark = document.querySelector(`#${id}`);

        selection.removeAllRanges();
        range.selectNode(mark);
        selection.addRange(range);

        const copy = document.execCommand('copy');

        if (copy) {
            context.alert({ text: 'Copiado com sucesso!', type: 'success' });
        }
    };

    return (
        <article>
            <div className="link-icon" onClick={copyClipboard} title="Copiar para a área de transferência">
                <div className="code-block">
                    <pre id={id}>
                        {children}
                    </pre>
                </div>
                <IconMdContentCopy />
            </div>
        </article>
    );
};

CopyToClipBoardInList.contextTypes = {
    alert: PropTypes.func,
};

CopyToClipBoardInList.propTypes = {
    children: PropTypes.any,
    id: PropTypes.string,
};
