import {
  Dialog,
  DialogActions,
  // DialogContent,
  // DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { Button } from '@mui/material'

const AlertDialog = ({ open, keepEditing, onClose }) => {
 
  return (
    <Dialog open={open} onClose={keepEditing}>
      <DialogTitle>
        Existem dados que não foram salvos
      </DialogTitle>

      <DialogActions>
        <Button onClick={keepEditing}>
          Continuar editando
        </Button>

        <Button onClick={onClose} autoFocus>
          Sair
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog