import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableWrap, TableWrapActions, TableWrapLeft, TableWrapRight } from '../../components';
import { fetchFamilies } from '../../actions/families';

class FamiliesList extends React.PureComponent {
    // eslint-disable-next-line no-undef
    model = {
        title: {
            name: 'Título',
        },
        description: {
            name: 'Descrição',
            style: { textAlign: 'left' },
        },
        actions: {
            name: ' ',
            renderer: (item, obj) => (
                <Link to={`/system/families/${obj.id}`}>
                    Editar
                </Link>
            ),
        },
    }

    render() {
        return (
            <TableWrap
                model={this.model}
                source={this.props.families}
                location={this.props.location}
                actionFetch={this.props.fetchFamilies}
            >
                <TableWrapActions>

                    <TableWrapLeft>
                        Lista de Famílias de Vulnerabilidades
                    </TableWrapLeft>

                    <TableWrapRight>
                        <Link to="/system/families/new">
                            Adicionar Família
                        </Link>
                    </TableWrapRight>

                </TableWrapActions>
            </TableWrap>
        );
    }
}

FamiliesList.propTypes = {
    families: PropTypes.object,
    location: PropTypes.object,
    fetchFamilies: PropTypes.func,
};

const mapStateToProps = ({ families }) => ({ families });

export default connect(mapStateToProps, { fetchFamilies })(FamiliesList);
