import PropTypes from 'prop-types';
import React from 'react';
import { PopoverReport } from './PopoverReport';

export const AwReportBox = (props) => {
    const { children, url, password } = props;

    return (
        <PopoverReport
            toggle={children}
            url={url}
            password={password}
        />
    );
};

AwReportBox.propTypes = {
    children: PropTypes.any,
    url: PropTypes.string,
    password: PropTypes.string,
};
