import PropTypes from 'prop-types'
import React from 'react';
import { connect } from 'react-redux';
import {
  TableWrap,
  TableWrapActions,
  TableWrapLeft
} from '../../components';
import {
  fetchUsersOrganizations
} from '../../actions/users';

const orgRoles = {
  owner: 'Proprietário',
  admin: 'Administrador',
  user: 'Usuário',
}

const roles = {
  admin: 'Staff',
  user: 'Cliente'
}

const status = {
  active: 'Ativa',
  cancelled: 'Cancelada',
  suspended: 'Suspensa',
}

class UsersOrganizationsList extends React.Component {
  constructor(props) {
    super(props)
    this.model = {
      'user.full_name': { name: 'Nome' },
      'user.email': { name: 'E-mail' },
      'organization.legal_name': { name: 'Organização' },
      'organization.status': { 
        name: 'Status da Organização',
        renderer: (item, obj) => status[obj.organization.status]
      },
      'role': {
        name: 'Perfil na Organização',
        renderer: (item, obj) => orgRoles[obj?.role] || 'Não Definido'
      },
      'user.role': {
        name: 'Papel no Sistema',
        renderer: (item, obj) => roles[obj.user?.role] || 'Não Definido'
      }
    }
  };

  render() {
    const model = () => {
      return this.model 
    }

    return (
      <div>
        <TableWrap
          model={model()}
          source={this.props.usersOrganizations}
          actionFetch={this.props.fetchUsersOrganizations}
          location={this.props.location}
          accountId={this.props.match.params.id}
        >
          <TableWrapActions>
            <TableWrapLeft>
              Organizações do Usuário
            </TableWrapLeft>
          </TableWrapActions>
        </TableWrap>
      </div>
    )
  }
};

UsersOrganizationsList.propTypes = {
  id: PropTypes.string,
  userId: PropTypes.string,
  params: PropTypes.object,
  location: PropTypes.object,
  usersOrganizations: PropTypes.object,
  fetchUsersOrganizations: PropTypes.func,
  userData: PropTypes.object,
}

const mapStateToProps = ({ auth, usersOrganizations }) => {
  return ({
    userId: auth.data.user.id,
    userData: auth.data,
    usersOrganizations,
  })
};

export default connect(mapStateToProps, {
  fetchUsersOrganizations,
})(UsersOrganizationsList);