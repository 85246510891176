import { css } from 'styled-components';

import { $lightgrey700, $greenSb, $yellow600 } from '../settings/variables';

export default css`
    .valid-field > span {
        margin-left: 2rem;
        position: relative;
    }

    .valid-field > span:before {
        content: ' ';
        position: absolute;
        background-color: #333;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        bottom: .3rem;
        margin-left: -2rem;
        border: solid 1px ${$lightgrey700};
    }

    .valid-field > .valid:before {
        background-color: ${$greenSb};
    }

    .valid-field > .invalid:before {
        background-color: ${$yellow600};
    }
`;
