import { css } from 'styled-components';

import { $bodyFontColor, $linkColorHover, $greenSb } from '../settings/variables';

export default css`
    .submenu {
        background-color: #f7f7f7;
        border-bottom: .1rem solid #dddddd;
    }

    .submenu > .list {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 0px;
        justify-content: center;
    }

    .submenu > .list > li {
        text-align: center;
        padding: 0.7rem 1.5rem 0.9rem 1.5rem;
    }

    .submenu > .list > li > a {
        color: ${$bodyFontColor};
        padding: 1rem 1rem .7rem;
    }

    .submenu > .list > li > a :hover {
        color: ${$linkColorHover};
    }

    .submenu > .list > li > .active {
        color: ${$bodyFontColor};
        border-bottom: 4px solid ${$greenSb};
        font-weight: bold;
    }

    .submenu > .list > li > .active:hover {
        color: ${$bodyFontColor};
    }

    .submenu > .list > li.beta {
        width: initial;
    }

    .submenu > .list > li.beta:before {
        content: 'beta';
        color: #fff;
        background-color: red;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1rem;
        padding: 0 .3rem;
    }
`;

