import { css } from 'styled-components';

export default css`
    .gravatar-group {
        display: flex;
        align-items: center;
    }

    .gravatar-group > .gravatar {
        margin-right: 10px;
    }

    .gravatar {
        border-radius: 100%;
        background-color: #fff;
    }
`;
