import { Cancel } from '@material-ui/icons'
import PropTypes from 'prop-types';
import React from 'react';
import { InputGroup, Loading } from '../components';

export class DomainsBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };

        this.handleClick = this.handleClick.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    removeItem(i) {
        this.props.removeDomain(i);
    }

    domainList() {
        const { domains, maxDomains } = this.props;
        const limitDomains = domains.slice(0, maxDomains);

        return limitDomains.map((item, i) => (
            <li key={`${item}-${i}`}>
                {item}
                {i === 0 &&
                    <span disabled>CN</span>
                }
                {i !== 0 &&
                    <span onClick={() => this.removeItem(item)}>
                        <Cancel />
                    </span>
                }
            </li>
        ));
    }

    handleClick(input) {
        const { value } = input;

        if (value.length > 3) {
            this.props.addDomain(value);
            this.setState({ value: '' });
        }
    }

    render() {
        const max = this.props.domains.length >= this.props.maxDomains;
        return (
            <div className="field domains-box">

                <Loading loading={this.props.loading} />

                {this.props.label &&
                    <label>{this.props.label}</label>
                }


                <InputGroup
                    value={this.state.value}
                    buttonClick={this.handleClick}
                    placeholder={max ? `Atingiu o limite de ${this.props.maxDomains} domínios` : 'Adicionar domínio'}
                    buttonName="Adicionar"
                    disabled={max || this.props.loading}
                />

                {this.props.domains.length &&
                    <ul className="domains-list">
                        {this.domainList()}
                    </ul>
                }
            </div>
        );
    }
}

DomainsBox.propTypes = {
    label: PropTypes.string,
    loading: PropTypes.bool,
    domains: PropTypes.array,
    addDomain: PropTypes.func,
    removeDomain: PropTypes.func,
    maxDomains: PropTypes.number,
};
