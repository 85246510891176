import PropTypes from 'prop-types';
import React from 'react';
import { fetch } from '../services/fetch';
import { toFormErrors } from '../services/utils';

export class GenericContainer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.endpoint = '';
    }

    getRedirectPath(redirect, id) {
        if (redirect.pathname) {
            redirect.pathname = redirect.pathname.replace(':id', id);
            return redirect;
        }

        return redirect.replace(':id', id);
    }

    get(endpoint = this.endpoint) {
        return fetch(endpoint, {
            method: 'GET',
        })
        .then(res => res.json())
        .catch(res => this.error(res));
    }

    fetch(url, options = {}) {
        return fetch(url, options);
    }

    post({ data = {}, endpoint = this.endpoint }) {
        return fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(data),
        })
        .then((res) => {
            if ([404, 204].includes(res.status)) {
                return res;
            }
            return res.json();
        });
    }

    put({ data = {}, endpoint = this.endpoint }) {
        return fetch(endpoint, {
            method: 'PUT',
            body: JSON.stringify(data),
        })
        .then(res => res.json());
    }

    options() {
        return fetch(this.endpoint, {
            method: 'OPTIONS',
        })
        .then(res => res.json());
    }

    delete({ id, redirect = false, endpoint = this.endpoint }) {
        endpoint += `${id}/`;
        return fetch(endpoint, {
            method: 'DELETE',
        })
        .then(res => this.success(res, redirect, 'Removido com sucesso'))
        .catch(res => this.error(res));
    }

    save({ data = {}, redirect = false, endpoint = this.endpoint, successText = 'Adicionado com sucesso' }) {
        return this.post({ data, endpoint })
        .then(res => this.success(res, redirect, successText))
        .catch(res => this.error(res));
    }

    update({
        id = null,
        data = {},
        redirect = false,
        endpoint = this.endpoint,
        successText = 'Atualizado com sucesso',
    }) {
        if (id) {
            endpoint += `${id}/`;
        }

        return this.put({ id, data, endpoint })
        .then(res => this.success(res, redirect, successText))
        .catch(res => this.error(res));
    }

    success(response, redirect, successText) {
        this.context.alert({ text: successText, type: 'success' });

        if (redirect) {
            const path = this.getRedirectPath(redirect, response.id);
            this.context.router.push(path);
        }

        return response;
    }

    error(response) {
        if (response.errors) {
            return toFormErrors(response.errors);
        }

        this.context.alert({ text: response.message });
    }
}

GenericContainer.contextTypes = {
    router: PropTypes.object,
    alert: PropTypes.func,
};
