import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PAGE } from '../constants'
import { Message, FormAwReport } from '../containers'
import { clearMessages } from '../modules/messages'
import { connect } from 'react-redux'
// import { AwReport } from 'viewsreport/AWReport';

import Logo from '../../assets/images/logo-site-blindado.svg'
import BGLogin from '../../assets/images/back-portal-site.png'
class MainAwReport extends Component {
  componentDidMount() {
    this.props.clearMessages()
    PAGE.classList.add('login')
    document.body.style.backgroundImage = `url(${BGLogin})`
  }

  componentWillUnmount() {
    this.props.clearMessages()
    PAGE.classList.remove('login')
    document.body.style.backgroundImage = ''
  }

  inRecovering() {
    const { id } = this.props.match.params
    return id
  }

  render() {
    return (
      <section>
        <Message />
        <div className="login-modal">
          <article className="form-box">
            <div className="brand">
              <img
                src={Logo}
                className="brand"
                alt="Site Blindado"
              />
            </div>

            <FormAwReport
              scanId={this.inRecovering()}
            />
          </article>
        </div>

        <footer className="login-copyright">
          <div>
            &copy; Copyright {new Date().getFullYear()} Site Blindado.
          </div>
          {/* <img src="@assets/images/.png" alt="logo-site-blindado-poweredBy" /> */}
        </footer>
      </section>
    )
  }
}

MainAwReport.propTypes = {
  clearMessages: PropTypes.func,
  location: PropTypes.object,
  children: PropTypes.object,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export default connect(null, { clearMessages })(MainAwReport)

