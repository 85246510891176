import { css } from 'styled-components';

export default css`
    .center-items, .center-items > * {
        display: table;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .content-center {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 5rem 0;
        min-height: 80vh;
    }
`;
