import React, { memo, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import {
  MenuItem,
  TableCell,
  TableRow,
  IconButton,
  FormControl,
  Grid,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Search } from '@material-ui/icons'
import { Button } from '../../../components'


const useStyles = makeStyles({
  item: {
    textAlign: 'left',
    border: 'none',
    color: '#95959c !important',
    fontSize: '1.5rem !important',
  },
  input: {
    border: 'none',
  },
  icon: {
    margin: '15 -30px',
    position: 'absolute',
  },
  inputValue: {
    fontSize: '1.5rem',
    width: '100%',
    [`& input`]: {
      fontSize: '1.5rem !important',
      padding: '1rem !important',
    }
  },
})

function Filters({ data, filtersValues, handleChange, clearFilters }) {
  const classes = useStyles()
  const { exec_start, exec_end } = filtersValues

  const [url, setUrl] = useState('')
  const [startValue, setStartValue] = useState(null)
  const [endValue, setEndValue] = useState(null)

  const momentDate = date => moment(date)

  useEffect(() => {
    if (!exec_start || exec_start === '') {
      setStartValue(null)
    } else {
      setStartValue(moment(exec_end))
    }
  }, [exec_end, exec_start])

  useEffect(() => {
    if (exec_end || exec_end === '') {
      setEndValue(null)
    } else {
      setEndValue(momentDate(exec_end))
    }
  }, [exec_end])


  const handleChangeStart = value => {
    const target = {
      target: {
        name: 'exec_start',
        value: value === null
          ? ''
          : value.format('YYYY-MM-DD'),
      },
    }
    handleChange(target)
  }

  const handleChangeEnd = value => {
    const target = {
      target: {
        name: 'exec_end',
        value: value === null ? '' : value.format('YYYY-MM-DD'),
      },
    }
    handleChange(target)
  }

  return (
    <TableRow>
      <TableCell />

      <TableCell >
        <FormControl
          variant="outlined"
          fullWidth
        >
          <TextField
            className={classes.inputValue}
            name="status"
            label="Status"
            select
            onChange={handleChange}
            value={filtersValues.status ? filtersValues.status : ''}
            variant="outlined"
          // fullWidth
          >
            <MenuItem value="">
              Selecione um status
            </MenuItem>

            {data.status.map(option =>
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )}
          </TextField>
        </FormControl>
      </TableCell>

      <TableCell >
        <FormControl
          variant="outlined"
          fullWidth
        >
          <TextField
            className={classes.inputValue}
            label="Url"
            name="url"
            onChange={({ target }) => setUrl(target.value)}
            endadornment={
              <IconButton onClick={() => handleChange({
                target: {
                  name: 'url',
                  value: url,
                },
              })}>
                <Search />
              </IconButton>
            }
            inputProps={{ style: { border: 'none' } }}
          />
        </FormControl>
      </TableCell>

      <TableCell >
        <FormControl
          variant="outlined"
          fullWidth
        >
          <TextField
            className={classes.inputValue}
            label="Modalidade"
            name="modality"
            variant="outlined"
            color="primary"
            select
            onChange={handleChange}
            value={filtersValues.modality ? filtersValues.modality : ''}
            fullWidth
          >
            <MenuItem value="">
              Selecione um tipo
            </MenuItem>

            {data.modality.map(option =>
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )}
          </TextField>
        </FormControl>
      </TableCell>

      <TableCell>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <TextField
            className={classes.inputValue}
            label="Pentester"
            name="pentester"
            variant="outlined"
            color="primary"
            select
            onChange={handleChange}
            value={filtersValues.pentester ? filtersValues.pentester : ''}
            fullWidth
          >
            <MenuItem
              value=""
            >
              Selecione um pentester
            </MenuItem>

            {data.pentester.map(option =>
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )}
          </TextField>
        </FormControl>
      </TableCell>

      <TableCell width={210}>
        <Grid item xs={12}>
          <DatePicker
            id="initial_date"
            label="Data inicial"
            mask='__/__/____'
            name="exec_start"
            value={filtersValues.exec_start ? moment(filtersValues.exec_start) : null}
            maxDate={endValue ? filtersValues.exec_end : undefined}
            onChange={handleChangeStart}
            renderInput={(params) =>
              <TextField {...params} helperText={null} />
            }
          />
        </Grid>
      </TableCell>

      <TableCell width={210}>
        <Grid item xs={12}>
          <DatePicker
            id="end_date"
            label="Data final"
            name="exec_end"
            value={filtersValues.exec_end ? moment(filtersValues.exec_end) : null}
            minDate={startValue ? moment(filtersValues.exec_start) : undefined}
            onChange={handleChangeEnd}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </Grid>
      </TableCell>

      <TableCell>
        <Grid item xs={12} textAlign='left'>
          <Button
            onClick={clearFilters}
            disabled={_.isEmpty(filtersValues)}
          >
            Limpar
          </Button>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default memo(Filters)
Filters.propTypes = {
  data: PropTypes.object,
  filtersValues: PropTypes.object,
  handleChange: PropTypes.func,
  clearFilters: PropTypes.func,
}
