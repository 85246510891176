import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import loadingSvg from '../../assets/images/loading.svg';

export const Loading = ({ loading, inverse }) => {
    if (!loading) {
        return false;
    }

    return (
        <div className={classnames('loader', { inverse })}>
            <img src={loadingSvg} alt="Loading" />
        </div>
    );
};

Loading.propTypes = {
    loading: PropTypes.bool,
    inverse: PropTypes.bool,
};
