import { css } from 'styled-components';

export default css`
    * {
        padding: 0;
        margin: 0;
        outline: none;
        box-sizing: border-box;
    }

    html {
        font-size: 10px;
        font-family: Helvetica, arial, freesans, clean, sans-serif;
        height: 100%;
    }

    body {
        height: 100%;
    }

    ol, ul {
        list-style: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 100;
    }

    input[type=checkbox],
    input[type=radio] {
        vertical-align: middle;
    }
`;
