import { Tooltip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import classnames from "classnames";
import debounce from "debounce";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import {
  ColumnOrderBy,
  ContentFilter,
  Loading,
  Pagination,
} from "../../components";
import { IconMdRefresh } from "../../sb2w-icons/md/";

export class TableWrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      columnFilter: {},
      orderBy: "",
      page: 1,
    };
    this.initialPage = 1;
    this.hasOrder = this.hasOrder.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.hasPagination = this.hasPagination.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleContentFilter = this.handleContentFilter.bind(this);
    this.doFetch = this.doFetch.bind(this);
    this.doSearch = debounce(this.doSearch, 600);
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  componentDidMount() {
    return !this.props.source.isLoading && this.doFetch();
  }

  handleSearch({ target }) {
    this.setState({ search: target.value }, () => {
      this.doSearch();
    });
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage }, () => {
      this.doSearch();
    });
  }

  doSearch() {
    if (!this.props.source.isLoading) {
      this.doFetch();
    }
  }

  clearSearch() {
    this.doSearch();
    this.setState({ search: "" });
  }

  hasPagination() {
    const { source } = this.props;
    return this.props.pagination && !!source.count && !!source.page_size;
  }

  hasOrder(model, key) {
    if (!this.props.orderBy) {
      return false;
    }

    return !!this.props.orderBy.includes(model[key].fieldName || key);
  }

  handleOrder(item) {
    let orderBy = "";

    if (`-${item}` !== this.state.orderBy) {
      orderBy = item;
    }

    if (item === this.state.orderBy) {
      orderBy = `-${item}`;
    }

    this.setState({ orderBy }, this.doFetch);
  }

  handleContentFilter(item) {
    this.setState(
      {
        columnFilter: {
          ...this.state.columnFilter,
          ...item,
        },
      },
      this.doFetch,
    );
  }

  doFetch() {
    if (!this.props.source.isLoading) {
      this.props.actionFetch(this.props.accountId, this.queryParams());

      this.props.hasSummary && this.props.summaryFetch(this.props.accountId);
    }
  }

  queryParams() {
    const params = {};

    if (this.hasPagination) {
      params.page =
        (this.props.location.state ? this.props.location.state.page : false) ||
        this.state.page ||
        this.props.source.page ||
        this.initialPage;
    }

    if (this.props.source.filter) {
      params.filter = this.props.source.filter;
    }

    if (this.state.search) {
      params.search = this.state.search;
    }

    if (Object.keys(this.state.columnFilter).length) {
      params.columnFilter = this.state.columnFilter;
    }

    if (this.state.orderBy) {
      params.order_by = this.state.orderBy;
    }

    return params;
  }

  render() {
    return (
      <div className="table-wrap">
        <Loading loading={this.props.source.isLoading} />

        {this.props.children &&
          React.cloneElement(this.props.children, {
            search: this.props.hasSearch ? (
              <div className="table-search">
                {this.state.search && (
                  <span className="clear-search" onClick={this.clearSearch}>
                    <Cancel />
                  </span>
                )}
                <input
                  type="text"
                  placeholder="Pesquisar..."
                  value={this.state.search}
                  onChange={this.handleSearch}
                />
              </div>
            ) : (
              false
            ),
            refresh: (
              <span className="refresh-button" onClick={this.doFetch}>
                <IconMdRefresh />
              </span>
            ),
          })}

        <table className="table table-no-border">
          <thead>
            <tr>
              {Object.keys(this.props.model).map((key, index) => {
                if (this.props.model[key].visible === false) {
                  return null;
                }

                return (<th key={key + index}>
                  <div className="table-header">
                    {this.hasOrder(this.props.model, key) ? (
                        <ColumnOrderBy
                            name={this.props.model[key].name || key}
                            fieldName={this.props.model[key].fieldName || key}
                            currentOrder={this.state.orderBy}
                            onClick={this.handleOrder}
                        />
                    ) : (
                        <span>{this.props.model[key].name || key}</span>
                    )}

                    {this.props.model[key].filterItems && (
                        <ContentFilter
                            name={key}
                            items={this.props.model[key].filterItems}
                            handleContentFilter={this.handleContentFilter}
                        />
                    )}
                  </div>
                </th>);
              })}
            </tr>
          </thead>

          <tbody>
            {!get(this.props.source, this.props.mapField, []).length && (
              <EmptyRow
                colspan={Object.keys(this.props.model).length + 1}
                isLoading={this.props.source.isLoading}
              />
            )}

            {get(this.props.source, this.props.mapField, []).map(
              (data, index) => (
                <Rows
                  key={index}
                  data={this.props.source.results[data]}
                  model={this.props.model}
                />
              ),
            )}
          </tbody>
        </table>

        <Pagination
          count={this.props.source.count || 0}
          pageSize={this.props.source.page_size || 0}
          page={this.props.source.page || 0}
          onPageChange={(newPage) => {
            this.handleChangePage(newPage);
          }}
        />
      </div>
    );
  }
}

export const Rows = ({ model, data }) => {
  const rows = Object.keys(model).map((key) => {
    const rendererContent =
      model[key].renderer && model[key].renderer(get(data, key), data, key);
    const tooltipContent =
      model[key].tooltip && model[key].tooltip(undefined, data, key);

    if (model[key].visible === false) {
      return null;
    }

    return (
      <td
        key={key}
        className={classnames(model[key].className)}
        style={model[key].style}
      >
        {model[key].tooltip ? (
          <Tooltip title={tooltipContent}>
            <p>{rendererContent}</p>
          </Tooltip>
        ) : model[key].renderer ? (
          rendererContent
        ) : (
          get(data, key) || ""
        )}
      </td>
    );
  });

  return (
    <tr id={data.id}>{rows}</tr>
  );
};

export const EmptyRow = ({ colspan, isLoading }) => (
  <tr>
    <td colSpan={colspan} style={{ textAlign: "center" }}>
      {!isLoading && <span>Não existem dados para essa lista</span>}
    </td>
  </tr>
);

EmptyRow.propTypes = {
  colspan: PropTypes.number,
  isLoading: PropTypes.bool,
};

Rows.propTypes = {
  model: PropTypes.object,
  data: PropTypes.object,
};

TableWrap.defaultProps = {
  pagination: true,
  hasSearch: false,
  mapField: "allIds",
};

TableWrap.propTypes = {
  children: PropTypes.any,
  model: PropTypes.object,
  source: PropTypes.object,
  mapField: PropTypes.string,
  location: PropTypes.object,
  pagination: PropTypes.bool,
  hasSearch: PropTypes.bool,
  orderBy: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  actionFetch: PropTypes.func,
  accountId: PropTypes.string,
  summaryFetch: PropTypes.func,
  summarySouce: PropTypes.object,
};
