import {
  FETCH_CLIENT_PRODUCTS,
  FETCH_PRODUCT_BY_PROMOCODE,
  FETCH_PRODUCT_TYPES,
  FETCH_STORE_PRODUCTS,
  PRODUCT_FORM,
  REQUEST_PRODUCT,
  SET_PRODUCT_STATUS,
} from '../actions/products'
import {LOGOUT_USER} from '../actions/auth'
import {INITIAL_LIST} from '../services/initial-states'
import normalizedState from '../services/normalize'

const INITIAL_PRODUCTS_STATE = {
  ...INITIAL_LIST,
  availablesIds: [],
}

const INITIAL_STATE = {
  currentProduct: {},
  results: [],
  chart: [],
}


export const productInfo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_PRODUCT_BY_PROMOCODE}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_PRODUCT_BY_PROMOCODE}_FULFILLED`: {
      const results = action.payload

      return {...state, results, isLoading: false}
    }
    case LOGOUT_USER:
      return INITIAL_STATE
    case PRODUCT_FORM:
      return {...state, currentProduct: action.payload}
    default:
      return state
  }
}

export const clientProducts = (state = INITIAL_PRODUCTS_STATE, action) => {
  switch (action.type) {
    case `${FETCH_CLIENT_PRODUCTS}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_CLIENT_PRODUCTS}_FULFILLED`: {
      const {results, allIds} = normalizedState(action.payload.results)

      const availablesIds = allIds.filter(id =>
        results[id].status === 'available'
      )

      return {...state, ...action.payload, results, allIds, availablesIds, isLoading: false}
    }
    case `${SET_PRODUCT_STATUS}_PENDING`: {
      return {...state, isLoading: true}
    }
    case `${SET_PRODUCT_STATUS}_FULFILLED`: {
      const results = state.results

      if (action.payload.id) {
        results[action.payload.id] = action.payload
      }

      return {...state, results, isLoading: false}
    }
    case LOGOUT_USER:
      return INITIAL_PRODUCTS_STATE
    default:
      return state
  }
}

export const storeProducts = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_STORE_PRODUCTS}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_STORE_PRODUCTS}_FULFILLED`: {
      const {results, allIds} = normalizedState(action.payload.product_types)

      return {
        ...state,
        isLoading: false,
        banner: action.payload.banner,
        slug: action.payload.slug,
        name: action.payload.name,
        website: action.payload.website,
        websiteTitle: action.payload.website_title,
        description: action.payload.description,
        results,
        allIds,
      }
    }
    default:
      return state
  }
}

export const productTypes = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_PRODUCT_TYPES}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_PRODUCT_TYPES}_FULFILLED`: {
      const {results, allIds} = normalizedState(action.payload)

      return {
        ...state,
        isLoading: false,
        name: action.payload.name,
        description: action.payload.description,
        results,
        allIds,
      }
    }
    default:
      return state
  }
}

export const requestProduct = (state = {isLoading: false, errors: []}, action) => {
  switch (action.type) {
    case `${REQUEST_PRODUCT}_PENDING`:
      return {...state, isLoading: true}
    case `${REQUEST_PRODUCT}_FULFILLED`:
      return {...state, ...action.payload, isLoading: false}
    case `${REQUEST_PRODUCT}_REJECTED`:
      return {...state, ...action.payload, isLoading: false}
    default:
      return state
  }
}
