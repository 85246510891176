import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Help, AboutSealStatus, RemoveApplication } from '../../components'
// import {ApplicationDetails} from '../../containers';
import { humanize, formatDate } from '../../services/utils'
import { SliderButton } from '../../containers'
import { InstallStampArmour } from '../../views'
import { userIsAdmin } from '../../services/auth'
import LogoSB from '../../../assets/images/logo-site-blindado.svg'

const RemoveApplicationAdmin = userIsAdmin((props) => (
  <RemoveApplication section="Aplicação" id={props.id} onRemove={props.onRemove} />
))

export class VulnerabilityCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      position: this.props.position,
      side: this.props.side,
    }
    this.onSetPosition = this
      .onSetPosition
      .bind(this)
    this.onSetSide = this
      .onSetSide
      .bind(this)
    this.onRemove = this
      .onRemove
      .bind(this)
  }

  onSetPosition(choice) {
    this.setState({ position: choice })
  }

  onSetSide(choice) {
    this.setState({ side: choice })
  }

  onRemove(id) {
    this
      .props
      .deleteApplication(id)
  }

  render() {
    const seal = {
      ok: 'Ativo',
      suspended: 'Suspenso',
      vulnerable: 'Expira em: ',
    }

    return (
      <Card>
        <article className="vulnerability-card">
          <div className="header">
            <span className="title">
              <strong>{this.props.name}</strong><br /> {this.props.url}
            </span>

            <div className="status">
              <div className="description" style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Help title="Status do Selo" style={{ position: 'relative', top: 5, marginRight: 5 }}>
                  <AboutSealStatus />
                </Help>

                <div>
                  <p>
                    Status do selo<br />
                  </p>

                  <small>
                    {seal[this.props.sealStatus]}

                    {this.props.sealStatus === 'vulnerable' &&
                      <strong>
                        {humanize(this.props.expirationDate)}
                      </strong>
                    }
                  </small>
                </div>
              </div>
              <div className={`mark-${this.props.sealStatus}`}></div>
            </div>
          </div>

          <div className="content" style={{ minHeight: '37vh' }}>
            {this.props.children}
          </div>

          <small>
            <strong>
              Script do Selo:
            </strong>
          </small>

          <footer className="footer-selo" style={{ alignItems: 'center' }}>
            <SliderButton
              button={
                <img
                  src={LogoSB}
                  alt="Selo Blindado"
                  width="150"
                  height="auto"
                />
              }
              sliderTitle="Selo de Blindagem"
            >
              <InstallStampArmour
                id={this.props.id}
                path={this.props.path}
                position={this.props.position}
                side={this.props.side}
                saveSeal={this.props.saveSeal}
                onSetPosition={this.onSetPosition}
                onSetSide={this.onSetSide}
                enabled={this.enabled}
                priority={this.priority}
              />
            </SliderButton>
            <div>
              <b>Data de ativação:</b> {formatDate(this.props.source.created)}
            </div>
            <div>
              <RemoveApplicationAdmin id={this.props.id} onRemove={this.onRemove} />

              <Link
                to={`applications/${this.props.id}`}
                className="button-primary right"
              >
                Detalhes
              </Link>
              {/* <ApplicationDetails {...this.props} />
                        //Icone de Configuração, removido (solicitado por produto)
                        */}
            </div>
          </footer>
        </article>
      </Card >
    )
  }
}
