import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export const ErrorsProduct = (props) => (
    <span>
        {!!props.notProduct && !props.renew &&
            <span>Você não possui produtos para emitir certificado,
                <strong>
                    <Link to="/store/site-blindado"> clique aqui para solicitar</Link>
                </strong>
            </span>
        }

        {!!props.notAvailable &&
            <span>Você não possui produto ativo para emitir certificado,
                <strong>
                    <Link to="/store/products"> clique aqui para ativar</Link>
                </strong>
            </span>
        }

        {!!props.renew &&
            <span>Você não possui produtos para renovar certificado,
                <strong>
                    <Link to="/store/site-blindado"> clique aqui para solicitar</Link>
                </strong>
            </span>
        }
    </span>
);

ErrorsProduct.defaultProps = {
    notProduct: false,
    notAvailable: false,
    renew: false,
};

ErrorsProduct.propTypes = {
    notProduct: PropTypes.bool,
    notAvailable: PropTypes.bool,
    renew: PropTypes.bool,
};
