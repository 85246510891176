import React, { memo, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Content } from "../../components";

export function IndexStore(props) {
  const [title, setTitle] = useState("Loja");
  const location = useLocation();
  const history = useHistory();
  const submenu = [
    {
      route: "/store/site-blindado",
      name: "Certificados Site Blindado",
    },
    {
      route: "/store/trust-sign",
      name: "Certificados TrustSign",
    },
    {
      route: "/store/blindagem",
      name: "Blindagem de Sites",
    },
    // {
    //   route: '/store/api',
    //   name: 'Blindagem de APIs',
    //   beta: true,
    // },
    {
      route: "/store/products",
      name: "Meus Produtos",
    },
    {
      route: "/store/orders",
      name: "Meus Pedidos",
    },
  ];

  useEffect(() => {
    if (location.pathname === "/store") {
      history.push("store/site-blindado");
    }
  }, []);

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) => (
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo((props) => {
              return (
                <route.component
                  routes={route.routes}
                  title={route.name}
                  setTitle={setTitle}
                  {...props}
                />
              );
            })}
          />
        ))}
      </Switch>
    </Content>
  );
}
