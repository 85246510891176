import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    width: '98%',
    margin: '10px auto',
    height: 'calc(100vh - 14rem)',
    color: '#95959c !important',
  },
  detailsContainer: {
    height: 'calc(100vh - 210px)',
    padding: 15,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    margin: '10px 0px',
    backgroundColor: '#fff',
  },
  subtitle: {
    margin: '10px 0',
    fontWeight: 'bold !important',
    fontSize: '2rem !important',
    textTransform: 'uppercase',
  },
  dataContainer: {
    height: 'calc(100vh - 21rem)',
    overflow: 'overlay',
    marginLeft: '15px',
    padding: 15,
    backgroundColor: '#fff',
  },
  text: {
    fontSize: '1.6rem',
  },
  contentTitle: {
    margin: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  contentContainer: {
    height: 'calc(100vh - 30rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentItem: {
    margin: '10px 0',
    padding: 10,
    backgroundColor: '#f8f8f8',
    display: 'flex',
    flexDirection: 'row',
    width: '98%'
  },
  descriptionItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: 15,
  },
  contentItemRow: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
  },
  optionsContainer: {
    margin: '20px 0',
  },
  options: {
    height: 35,
    padding: '0 20px',
    margin: '10px 0',
    backgroundColor: '#f8f8f8',
    textTransform: 'uppercase',
  },
  divider: {
    margin: '20px 0px',
  }
})

export default useStyles
