import { css } from 'styled-components';

import { $fontSizeLarge, $darkgrey600 } from '../settings/variables';

export default css`
    .chart {
        padding-bottom: 1rem;
        position: relative;
    }

    .chart-title {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: ${$fontSizeLarge};
        font-weight: 700;
    }

    .chart-pie.chart-sub-title {
        text-align: center;
        margin: 0 auto;
        margin-top: -11.7rem;
        font-size: 1.8rem;
        line-height: 3.7rem;
        display: flex;
        flex-direction: column;
        align-items: inherit;
        width: 22rem;
    }

    .chart-pie.chart-sub-title .number {
        font-size: 4.5rem;
    }

    .chart-pie-total {
        position: absolute;
        width: 100%;
        font-size: 5.5rem;
        text-align: center;
        margin-top: 5rem;
        color: ${$darkgrey600};
    }
`;
