/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { useSelector, useDispatch } from 'react-redux'
import { fetchOrganization, saveOrganization, createOrganization } from '../../actions/organizations'
import { fetchBusinessCategories } from '../../actions/business-categories'

import { Button, Loading } from '../../components'
import NewAddressFields from '../../components/forms/newAdressFields'

const pattersForm = {
  // eslint-disable-next-line no-useless-escape
  cnpj: '([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})',
  // eslint-disable-next-line no-useless-escape
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
}
export default function FormOrganization(props) {
  const { edit, match } = props

  const data = useSelector(state => state.organization)
  const userData = useSelector(state => state.auth)
  const businessCategoryOptions = useSelector(state => state.businessCategories.items)

  const { register, handleSubmit, formState: { errors, isSubmitting } ,control, setValue } = useForm()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!data.isLoading) {
      dispatch(fetchBusinessCategories())

      if (edit) {
        dispatch(fetchOrganization(match.params.id))
      }
    }
  }, [])

  useEffect(() => {
    if (match.params.id) {
      setValue('full_name', data.full_name)
      setValue('legal_name', data.legal_name)
      setValue('cnpj', data.cnpj)
      setValue('phone', data.phone)
      setValue('url', data.url)
      setValue('business_category', data.business_category)

      setValue('address.zipcode', data.address ? data.address.zipcode : '')
      setValue('address.city', data.address ? data.address.city : '')
      setValue('address.complement', data.address ? data.address.complement : '')
      setValue('address.country', data.address ? data.address.country : '')
      setValue('address.district', data.address ? data.address.district : '')
      setValue('address.number', data.address ? data.address.number : '')
      setValue('address.state', data.address ? data.address.state : '')
      setValue('address.street', data.address ? data.address.street : '')
    }
  }, [data])


  const onSubmit = (dataForm) => {
    const BusinessCategory = parseInt(dataForm.business_category, 10)

    const formData = {
      ...dataForm,
      business_category: BusinessCategory,
    }
    return edit
      ? dispatch(saveOrganization(match.params.id, formData))
      : dispatch(createOrganization(formData))
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      {data.isLoading
        ? <Loading loading />
        : (
          <div>
            <div className="field">
              <label htmlFor="full_name">
                Nome Fantasia *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.full_name ? 'input-error' : ''}`}
                    type="text"
                    disabled={edit && userData.data.role === 'user'}
                  />
                }
                defaultValue=""
                name="full_name"
                rules={{ required: true }}
                control={control}
              />
            </div>

            <div className="field">
              <label htmlFor="legal_name">
                Razão Social *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.legal_name ? 'input-error' : ''}`}
                    type="text"
                    disabled={edit && userData.data.role === 'user'}
                  />
                }
                name="legal_name"
                defaultValue=""
                rules={{ required: true }}
                control={control}
              />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="field">
                  <label htmlFor="cnpj">
                    CNPJ *
                  </label>

                  <Controller
                    render={({ field }) =>
                      <InputMask
                        {...field}
                        className={`input ${errors.cnpj ? 'input-error' : ''}`}
                        type="text"
                        pattern={pattersForm.cnpj}
                        mask="99.999.999/9999-99"
                        disabled={edit && userData.data.role === 'user'}
                      />
                    }
                    name="cnpj"
                    defaultValue=""
                    rules={{ required: true }}
                    control={control}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="field">
                  <label htmlFor="phone">
                    Telefone *
                  </label>

                  <Controller
                    render={({ field }) =>
                      <InputMask
                        {...field}
                        className={`input ${errors.phone ? 'input-error' : ''}`}
                        type="text"
                        mask="(99) 9999-99999"
                        disabled={edit && userData.data.role === 'user'}
                      />
                    }
                    defaultValue=""
                    name="phone"
                    rules={{ required: true }}
                    control={control}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="url">
                URL *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.url ? 'input-error' : ''}`}
                    type="text"
                    disabled={edit && userData.data.role === 'user'}
                  />
                }
                defaultValue=""
                name="url"
                rules={{ required: true, pattern: pattersForm.url }}
                control={control}
              />
            </div>

            <div className="field">
              <label htmlFor="business_category">
                Categoria *
              </label>

              <Controller
                render={({ field }) =>
                  <select
                    {...field}
                    className="combo"
                    disabled={edit && userData.data.role === 'user'}
                  >
                    {businessCategoryOptions.map(item =>
                      <option key={item.id} value={item.id}>
                        {item.description}
                      </option>
                    )}
                  </select>
                }
                name="business_category"
                defaultValue=""
                control={control}
              />
            </div>

            <NewAddressFields
              isLoading={data.isLoading}
              register={register}
              errors={errors}
              control={control}
              disabled={edit && userData.data.role === 'user'}
            />

            <div className="buttons">
              <Button disabled={edit && userData.data.role === 'user'} loading={isSubmitting}>
                {match.params.id
                  ? 'Salvar'
                  : 'Criar'
                }
              </Button>

              <Link to="/organizations/">
                Cancelar
              </Link>
            </div>
          </div>
        )
      }
    </form>
  )
}

FormOrganization.propTypes = {
  edit: PropTypes.bool,
  match: PropTypes.object,
  fetchOrganization: PropTypes.func,
  fetchData: PropTypes.func,
  setBreadcrumbName: PropTypes.func,
  value: PropTypes.string || PropTypes.number,
  target: PropTypes.string,
  businessCategory: PropTypes.any,
  address: PropTypes.object,
  newFormData: PropTypes.object,
}

