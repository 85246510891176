import PropTypes from 'prop-types'
import {ProductsSummary} from '../containers'

export const MyProducts = ({productType}) => {

  return (
    <article>
      <h1 className="chart-title">
        Meus Produtos
      </h1>
      <ProductsSummary productType={productType}/>
    </article>
  )
}

MyProducts.propTypes = {
  productType: PropTypes.any,
}
