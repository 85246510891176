import { List as list, Map as map } from 'immutable';

// actions

const ADD = 'sb/messages/ADD';
const SET = 'sb/messages/SET';
const DISMISS = 'sb/messages/DISMISS';
const CLEAR = 'sb/messages/CLEAR';

export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';
export const WARNING = 'WARNING';
export const ERROR = 'ERROR';

// succinct hack for generating passable unique ids
const uid = () => Math.random().toString(34).slice(2);

const EMPTY_LIST = list([]);

// reducer

export default function reduce(messages = EMPTY_LIST, action = {}) {
    switch (action.type) {
        case CLEAR:
            return messages.clear();
        case ADD:
            return messages.push(map(action.payload));
        case SET:
            return list([map(action.payload)]);
        case DISMISS:
            return messages.delete(action.payload);
        default:
            return messages;
    }
}

// actions creators

export function addMessage(level, message) {
    return {
        type: ADD,
        payload: {
            key: uid(),
            level,
            message,
        },
    };
}

export function setMessage(level, message) {
    return {
        type: SET,
        payload: {
            key: uid(),
            level,
            message,
        },
    };
}

export function dismissMessage(index) {
    return {
        type: DISMISS,
        payload: index,
    };
}

export function clearMessages() {
    return {
        type: CLEAR,
    };
}
