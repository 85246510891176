import { css } from 'styled-components';

export default css`
    .card-product {
        padding: 2rem;
        min-height: 35rem;
    }

    .card-product > .title {
        display: inline-flex;
        margin-bottom: 1rem;
    }

    .card-product > .title > h2 {
        margin: 0 0 0 1rem;
        align-self: center;
        font-weight: bold;
    }

    .card-product > .title > .icon-circle {
        width: 3rem;
        height: 3rem;
    }

    .card-product > .title > .icon-circle > i {
        font-size: 1.6rem;
    }

    .card-product > .description {
        margin: 1rem 0 0;
        height: 10rem;
        max-height: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: inherit;
    }

    .card-product > .see-more {
        position: absolute;
        bottom: 2.5rem;
        right: 2rem;
    }

    .card-product > .details {
        margin: 3rem 0;
        min-height: 25rem;
    }

    .card-product .button-product {
        clear: both;
        margin-top: 3rem;
    }

    .card-product > .popover {
        position: absolute;
        bottom: 2rem;
    }
`;
