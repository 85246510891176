import { useState, useEffect, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { WysiwygEditor, Button, Loading } from '../../../../components'
import { Card, Grid, } from '@material-ui/core'
import { Container } from './styles'
import { makeStyles } from '@material-ui/core/styles'
import AlertDialog from '../AlertDialog'
import { updatePentestReport } from '../../../../actions/pentests/report'
import PropTypes from 'prop-types'
import _ from 'lodash'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: 'calc(100vh - 22rem)',
    boxShadow: 'none'
  },
  cardContent: {
    height: 'calc(100vh - 22rem)',
    padding: '3rem',
    overflowX: 'auto !important',
  },
  title: {
    margin: '30px 0px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#95959c',
  },
  Divider: {
    width: '80%',
    color: '#717171',
  },
})
function PenTestDescriptionTexts(props) {
  const { id: pentestId, report_id: reportId } = props.match.params

  const classes = useStyles()
  const dispatch = useDispatch()

  const account = useSelector(state => state.account)
  const pentestReport = useSelector(state => state.pentestReport)

  const [openDialog, setOpenDialog] = useState(false)

  const accountId = account[0] ? account[0].id : undefined
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (!_.isEmpty(pentestReport.results)) {
      setValue('exec_summary', pentestReport.results.exec_summary)
      setValue('about_report', pentestReport.results.about_report)
      setValue('about_sb', pentestReport.results.about_sb)
      setValue('metodology', pentestReport.results.metodology)
    }
  }, [pentestReport.results, setValue])

  async function updateReport(data) {
    await dispatch(updatePentestReport(accountId, pentestId, reportId, data))
  }

  async function goBack(event) {
    event.preventDefault()

    const {
      exec_summary: validateExecSummary,
      metodology: validateMetodology,
      about_sb: validateAboutSb,
      about_report: validateAboutReport,
    } = getValues()

    const {
      exec_summary: oldExecSummary,
      metodology: oldMetodology,
      about_sb: oldAboutSb,
      about_report: oldAboutReport,
    } = pentestReport.results

    if (!validateExecSummary || !validateMetodology || !validateAboutSb || !validateAboutReport) {
      props.backStep()
    } else {
      validateExecSummary !== oldExecSummary ||
        validateMetodology !== oldMetodology ||
        validateAboutSb !== oldAboutSb ||
        validateAboutReport !== oldAboutReport
        ? setOpenDialog(true)
        : props.backStep()
    }
  }

  async function onSubmit(data) {
    await updateReport(data)
    await props.nextStep()
  }

  const EditorField = ({ name }) =>
    <Controller
      render={({ field }) =>
        <WysiwygEditor
          {...field}
          {...register(name, {
            required: true,
            validate: value => value !== ' ',
          })}
          text={getValues()[name]}
          setText={value => setValue(name, value)}
        />
      }
      defaultValue={pentestReport.results[name]}
      name={name}
      control={control}
    />

  return (
    <Container>
      <Grid>
        <Card className={classes.container}>
          <div className={classes.cardContent}>
            <Loading loading={pentestReport.isLoading} />

            <form
              className="form"
              onSubmit={handleSubmit(onSubmit)}
              style={{ maxWidth: '100%' }}
            >
              <Grid className="field">
                <label>
                  Sumário executivo *
                </label>

                <div
                  style={{ height: '50vh' }}
                >
                  <EditorField name="exec_summary" />

                  {errors.exec_summary && (
                    <span className="input-error">
                      Este campo é obrigatório *
                    </span>
                  )}
                </div>
              </Grid>

              <Grid className="field">
                <label>
                  Metodologia *
                </label>

                <div
                  style={{ height: '50vh' }}
                >
                  <EditorField name="metodology" />

                  {errors.metodology && (
                    <span className="input-error">
                      Este campo é obrigatório *
                    </span>
                  )}
                </div>
              </Grid>

              <Grid className="field">
                <label>
                  Sobre a site blindado *
                </label>

                <div
                  style={{ height: '50vh' }}
                >
                  <EditorField name="about_sb" />

                  {errors.about_sb && (
                    <span className="input-error">
                      Este campo é obrigatório *
                    </span>
                  )}
                </div>
              </Grid>

              <Grid className="field">
                <label>
                  Sobre este report *
                </label>

                <div
                  style={{ height: '50vh' }}
                >
                  <EditorField name="about_report" />

                  {errors.about_report && (
                    <span className="input-error">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </Grid>

              <div className="buttons">
                <Button onClick={goBack}>
                  Voltar
                </Button>

                <Button style={{ marginLeft: 10 }} type="submit">
                  Próximo
                </Button>
              </div>

              <AlertDialog
                open={openDialog}
                keepEditing={() => setOpenDialog(false)}
                onClose={props.backStep}
              />
            </form>
          </div>
        </Card>
      </Grid>
    </Container>
  )
}

PenTestDescriptionTexts.propTypes = {
  prevStep: PropTypes.func,
  nextStep: PropTypes.func,
  params: PropTypes.object,
}

export default memo(PenTestDescriptionTexts)
