import PropTypes from 'prop-types';
import React from 'react';
import { Legend, PieChart, Pie, Cell, Tooltip } from 'recharts';
import sumBy from 'lodash/sumBy';
import { Help, AboutSealStatus } from '../../components';
import { fetchChartUptimeOnline } from '../../actions/uptime-chart';
import { connect } from 'react-redux';


class UptimeStatusChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    componentDidMount() {
        this.props.fetchChartUptimeOnline();
    }

    render() {
        const colors = ['#7dc671', '#fd4d4d'];

        return (
            <div className="chart">
                <h1 className="chart-title">
                    URLs Monitoradas
                    <Help title="Status da URL">
                        <AboutSealStatus />
                    </Help>
                </h1>
                {!!this.props.dataOnline &&
                    <div className="chart-pie-total">{sumBy(this.props.dataOnline, 'value')}</div>
                }
                <PieChart width={400} height={230}>
                    <Pie
                        dataKey="value"
                        data={this.props.dataOnline}
                        cy={90}
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={0}
                    >
                        {
                            !!this.props.dataOnline &&
                            this.props.dataOnline.map((entry, index) =>
                                <Cell key={index} fill={colors[index % colors.length]} />)
                        }
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </div>
        );
    }
}

UptimeStatusChart.propTypes = {
    fetchChartUptimeOnline: PropTypes.func,
    isLoading: PropTypes.bool,
    dataOnline: PropTypes.array,
};

const mapStateToProps = ({ uptimechart }) => ({
    isLoading: uptimechart.isLoading,
    dataOnline: uptimechart.dataOnline,
});

export default connect(mapStateToProps, { fetchChartUptimeOnline })(UptimeStatusChart);
