import { css } from 'styled-components';

export default css`
    .qr-code {
        margin: 3rem 0;
        display: flex;
        align-items: center;
    }

    .qr-code span {
        text-align: center;
        margin-left: 3rem;
    }
`;
