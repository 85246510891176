import axios from 'axios'

const URL_CONTENTFUL_ASSETS = 'https://preview.contentful.com/spaces/a9mooz6ri0vn/environments/master/assets/'
const CONTENTFUL_TOKEN = process.env.REACT_APP_CONTENTFUL_TOKEN;

export async function getContentfulAssetsByTag (tag) {
  try {
    const { data } = await axios.get(`${URL_CONTENTFUL_ASSETS}`, {
      params: {
        'access_token': CONTENTFUL_TOKEN,
        'metadata.tags.sys.id[in]': tag
      }
    })
    return data
  } catch (error) {
    console.error('Error fetching media:', error);
    throw error;
  }
}
