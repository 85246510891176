import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Icon, Loading } from '../../components';
import { setTranslatedValue } from '../../actions/forms';
import translate from '../../services/translate';

const mapStateToProps = ({ form }, ownProps) => ({
    text: get(form, `${ownProps.form}.values.${ownProps.in}`, ''),
});

class TranslateButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };

        this.toTranslate = this.toTranslate.bind(this);
    }

    toTranslate() {
        this.setState({ isLoading: true });
        translate({
            text: this.props.text,
            format: this.props.type,
        }).then(res => {
            this.props.setTranslatedValue(this.props.form, this.props.out, res.translatedText);
            this.setState({ isLoading: false });
        });
    }

    render() {
        return (
            <div className="translate-button">
                <a
                    href='/#'
                    className="link-icon"
                    onClick={this.toTranslate}
                >
                    <React.Fragment>
                        <Loading loading={this.state.isLoading} />
                        <Icon name="g_translate" /> &#8594;
                    </React.Fragment>
                </a>
            </div>
        );
    }

}

TranslateButton.propTypes = {
    in: PropTypes.string,
    out: PropTypes.string,
    type: PropTypes.string,
    form: PropTypes.string,
};

export default connect(mapStateToProps, { setTranslatedValue })(TranslateButton);
