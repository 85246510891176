import React from 'react';
import PropTypes from 'prop-types';

export const ProgressCircle = (props) => (
    <div className="progress-circle">
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <rect x="0" y="0" width="100" height="100" fill="none"></rect>
            <circle
                cx="50"
                cy="50"
                r="40"
                stroke="transparente"
                fill="none"
                strokeWidth="10"
                strokeLinecap="round"
            />
            <circle
                id="circle2"
                cx="50"
                cy="50"
                r="40"
                stroke="#74B61B"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                strokeDasharray="250"
                style={{ strokeDashoffset: 250 - ((250 * props.percent) / 100) }}
            />
        </svg>
        {!!props.percent &&
            <span className="status">{props.percent}%</span>
        }
    </div>
);

ProgressCircle.defaultProps = {
    percent: 0,
};

ProgressCircle.propTypes = {
    percent: PropTypes.number,
};
