import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { SelectField } from '../../components';
import { fetchFamilies } from '../../actions/families';

class ChoiceFamily extends React.PureComponent {

    componentDidMount() {
        this.props.fetchFamilies();
    }

    render() {
        return (
            <Field
                component={SelectField}
                name="family.id"
                options={this.props.options}
            />
        );
    }
}

ChoiceFamily.propTypes = {
    fetchFamilies: PropTypes.func,
    options: PropTypes.array,
};

const mapStateToProps = ({ families }) => ({
    options: families.allIds.map(item => ({
        id: families.results[item].id,
        description: families.results[item].title,
    })),
});

export default connect(mapStateToProps, { fetchFamilies })(ChoiceFamily);
