import { css } from 'styled-components'

export default css`
    .table-container {
        height: calc(100vh - 6rem);
        overflow: overlay;
        background-color: #fff;
    }

    .table-list {
        width: 95%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 0 10px;
    }

    .table-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .table-list-header {
        height: 40px;
        width: 100%;
        margin-top: 10px;
        text-align: left;
        text-transform: uppercase;
        color: #95959c;
        display: flex;
        flex-direction: row;
    }

    .table-search-input {
        text-align: left !important;
        color: grey;
    }

    .table-list-status {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        display: inline-block;
    }

    .status-done {
        background-color: #00d200;
    }

    .status-in_progress {
        background-color: #fce93c;
    }

    .status-to_start {
        background-color: #50afe4;
    }

    .status-retest {
        background-color: #ff914d;
    }

    .status-paused {
        background-color: #ff870b;
    }

    .table-list-row {
        display: flex;
        align-items: center;
        height: 65px;
        color: #666666;
    }

    .table-list-content {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        background-color: #f6f6f6;
        border-radius: 10px 10px 10px 10px;

    }

    .table-list-row div {
        padding: 5px;
    }

    .table-list-header list-item {
        font-weigth: bold;
    }

    .list-item {
        width: 12%;
        display: flex;
        justify-content: space-between;
    }

    .list-divider-row {
        margin-left: 120px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .list-divider {
        margin: 0;
    }

    .table-icons {
        color: #5f5f5f;
        margin-left: 10px;
    }

    #initial_date-label {
        font-size: 1.6rem;
    }

    #end_date-label {
        font-size: 1.35rem;
    }
    #initial_date-label {
        font-size: 1.35rem;
    }
`
