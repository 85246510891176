import React from 'react';
import PropTypes from 'prop-types';
import { TextField, EditorField } from '../../components';
import { TranslateButton } from '../../containers';
import { Field } from 'redux-form';

export const TranslateField = (props) => {
    const types = {
        html: EditorField,
        text: TextField,
    };

    return (
        <article className="translate-field">
            <Field
                name={props.input.name}
                label="Inglês"
                required={false}
                component={types[props.type]}
            />

            <TranslateButton
                in={props.input.name}
                out={`${props.input.name}_pt`}
                type={props.type}
                form={props.meta.form}
            />

            <Field
                name={`${props.input.name}_pt`}
                label="Português"
                required={false}
                component={types[props.type]}
            />
        </article>
    );
};

TranslateField.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    type: PropTypes.string,
};
