import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { TranslateField, Button, RadiosField, Loading } from '../../components';
import { ChoiceFamily } from '../../containers';
import { fetchVulnerability, saveVulnerability } from '../../actions/vulnerabilities';

class FormVulnerabilityTranslate extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.setBreadcrumbName(<Loading loading />);
        this.props.dispatch(fetchVulnerability(this.props.vulnerabilityId))
        .then(({ value }) => {
            this.props.setBreadcrumbName(value.title_pt || value.title);
        });
    }

    onSubmit(data) {
        return this.props.saveVulnerability(this.props.vulnerabilityId, {
            ...data,
            family: data.family.id,
        });
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="form-translate">

                <Loading loading={this.props.isLoading} />

                <h2>Família</h2>
                <ChoiceFamily />

                <h2>Titulo</h2>
                <Field
                    name="title"
                    type="text"
                    component={TranslateField}
                />

                <h2>Descrição</h2>
                <Field
                    name="description"
                    type="html"
                    component={TranslateField}
                />

                <h2>Impacto</h2>
                <Field
                    name="consequence"
                    type="html"
                    component={TranslateField}
                />

                <h2>Solução</h2>
                <Field
                    name="solution"
                    type="html"
                    component={TranslateField}
                />

                <h2>Como funciona</h2>
                <Field
                    name="how_it_works"
                    type="html"
                    component={TranslateField}
                />

                <Field
                    title="Revisado?"
                    name="reviewed"
                    component={RadiosField}
                    items={[
                        { label: 'Sim', value: 'true' },
                        { label: 'Não', value: 'false' },
                    ]}
                />

                <div className="buttons">
                    <Button
                        loading={this.props.submitting}
                    >
                        Salvar
                    </Button>
                    <Link to="/system/vulnerabilities/">Cancelar</Link>
                </div>
            </form>
        );
    }
}

FormVulnerabilityTranslate.propTypes = {
    fetchVulnerability: PropTypes.func,
    saveVulnerability: PropTypes.func,
    setBreadcrumbName: PropTypes.func,
    vulnerabilityId: PropTypes.string,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    submitting: PropTypes.bool,
};

const Form = reduxForm({
    form: 'formVulnerabilityTranslate',
})(FormVulnerabilityTranslate);

const mapStateToProps = (state) => ({
    isLoading: state.vulnerabilities.isLoading,
});

export default connect(mapStateToProps, { fetchVulnerability, saveVulnerability, reset })(Form);
