import { useEffect } from "react";
import { DashboardProducts, ProductsList } from "../../containers";

export const Products = (props) => {
  useEffect(() => {
    props.setTitle(props.title);
  }, []);

  return (
    <section className="content">
      <DashboardProducts />

      <ProductsList {...props} />
    </section>
  );
};
