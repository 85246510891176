import PropTypes from 'prop-types';
import React from 'react';
import { IconMdContentCopy } from '../sb2w-icons/md';

export const CopyToClipBoard = ({ children }, context) => {
    const copyClipboard = () => {
        const range = document.createRange();
        const selection = window.getSelection();
        const mark = document.querySelector('.code-block pre');

        selection.removeAllRanges();
        range.selectNode(mark);
        selection.addRange(range);

        const copy = document.execCommand('copy');

        if (copy) {
            context.alert({ text: 'Copiado com sucesso!', type: 'success' });
        }
    };

    return (
        <article>
            <div className="link-icon" onClick={copyClipboard}>
                <IconMdContentCopy /> Copiar código
            </div>

            <div className="code-block">
                <pre>
                    {children}
                </pre>
            </div>
        </article>
    );
};

CopyToClipBoard.contextTypes = {
    alert: PropTypes.func,
};

CopyToClipBoard.propTypes = {
    children: PropTypes.any,
};
