import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

export class Choices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      itemChecked: this.props.itemChecked,
    }
  }

  handleClick(itemChecked) {
    this.setState({ itemChecked })
    if (this.props.onClick) {
      this.props.onClick(itemChecked)
    }
  }

  render() {
    return (
      <div className="choices">
        {this.props.items.map(item =>
          <div
            key={item.value}
            className={classnames('item', { checked: item.value === this.props.itemChecked })}
            onClick={() => this.handleClick(item.value)}
          >
            {item.label}
          </div>
        )}
      </div>
    )
  }
}

Choices.defaultProps = {
  items: ['admin', 'user'],
}

Choices.propTypes = {
  items: PropTypes.array,
  itemChecked: PropTypes.string,
  onClick: PropTypes.func,
}
