import { useEffect } from 'react'
import { Card } from '../../components'
import { FormChangePassword } from '../../containers'

export const Security = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <div className="card-content">
          <FormChangePassword {...props} />
        </div>
      </Card>
    </section>
  )
}
