import PropTypes from 'prop-types'
import React from 'react'
import { Card, Icon } from '../components'
import classnames from 'classnames'

export const AlertCard = (props) => (
  <Card>
    <div className="alert-card">
      <div className={classnames('title', {
        error: props.error,
        warning: props.warning,
      })}>
        <Icon name="error_outline" />

        <h2>
          {props.title}
        </h2>
      </div>

      <p className="text">
        {props.children}
      </p>
    </div>
  </Card>
)

AlertCard.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  children: PropTypes.any,
}
