import PropTypes from 'prop-types';
import React from 'react';
import { Content } from '../../components';

export class IndexRescue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Recuperação de Código Promocional',
            authorized: false,
        };

        this.setTitle = this.setTitle.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ authorized: !!nextProps.params.id });
    }

    setTitle(title) {
        this.setState({ title });
        this.props.setTitle(title);
    }

    render() {
        const submenu = [
            {
                route: `/rescue/${this.props.match.params.id}`,
                name: 'Rescue',
                authorized: this.state.authorized,
            },
        ];

        return (
            <Content submenu={submenu} title={this.state.title} {...this.props}>
                {this.props.children && React.cloneElement(this.props.children, {
                    setTitle: this.setTitle,
                })}
            </Content>
        );
    }
}

IndexRescue.propTypes = {
    children: PropTypes.element.isRequired,
    setTitle: PropTypes.func,
    params: PropTypes.object,
};
