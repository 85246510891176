import { css } from 'styled-components';

export default css`
    .checkbox {
        display: inline-block !important;
    }

    .checkbox input[type="checkbox"] {
        display: none;
    }

    .checkbox input[type="checkbox"] + span {
        cursor: pointer;
    }

    .checkbox input[type="checkbox"] + span::before {
        content: '\\2713';
        display: inline-block;
        margin-right: .5rem;
        width: 2rem;
        height: 2rem;
        color: transparent;
        font-size: 2.2rem;
        line-height: 1.7rem;
        text-align: center;
        border: .1rem solid #ccc;
        vertical-align: bottom;
        overflow: hidden;
        box-shadow: inset 0 0.1rem 0.3rem rgba(0,0,0,.2);
        transition: all .2s ease-out;
        border-radius: .2rem;
    }

    .checkbox input[type="checkbox"]:checked + span::before {
        color: #fff;
        background-color: #6a5;
        border-color: #6a5;
        box-shadow: none;
    }

    .checkbox input[type="checkbox"]:focus + span::before {
        border-color: #6a5;
        box-shadow: none;
    }
`;
