import PropTypes from 'prop-types'
import React from 'react'

export class CheckboxItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { checked: false }

    this.handleClick = this.handleClick.bind(this)
    this.initialState = this.initialState.bind(this)
  }

  componentDidMount() {
    this.initialState()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ checked: !!nextProps.value })
  }

  initialState() {
    this.setState({ checked: this.props.value })
  }

  handleClick(e) {
    e.persist()
    this.setState({ checked: !this.state.checked }, () => {
      if (this.props.onClick) {
        this.props.onClick(this.state.checked, e)
      }
    })
  }

  renderItem() {
    return (
      <label className="checkbox">
        <input
          type="checkbox"
          name={this.props.name}
          value={this.props.value}
          onChange={this.handleClick}
          checked={this.state.checked}
        />
        <span>{this.props.label}</span>
      </label>
    )
  }

  renderBreakLine() {
    return (
      <div>{this.renderItem()}</div>
    )
  }

  render() {
    const item = (this.props.inline) ? this.renderItem() : this.renderBreakLine()
    return item
  }
}

CheckboxItem.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  inline: PropTypes.bool,
}
