import React from 'react';

import PropTypes from 'prop-types';
import { DatePicker } from '../../DatePicker';
import { FormScheduling } from '../../../containers';

export default function Edit(props) {
    return (
        <div className="scheduling__form">
            <div className="scheduling__step">
                <h3>
                    Escolha um dia e hora para o agendamento:
                </h3>
            </div>

            <div className="scheduling__calendar">
                <DatePicker
                    onChange={props.onChange}
                    options={{
                        inline: true,
                        enableTime: true,
                        altInputClass: '.hide-picker',
                        minDate: 'today',
                        defaultDate: props.strDate,
                    }}
                />
            </div>

            <FormScheduling
                type="edit"
                scanProfileId={props.scanProfileId}
                scanType={props.scanType}
                date={props.strDate}
                source={props.source}
            />
        </div>
    );
}

Edit.propTypes = {
    scanType: PropTypes.string,
    onChange: PropTypes.func,
    strDate: PropTypes.string,
    scanProfileId: PropTypes.string,
    source: PropTypes.object,
};
