import React, {memo, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {Content} from "../../components";


export function IndexSystem(props) {
  const [title, setTitle] = useState('Configurações');

  const submenu = [
    {route: '/system/pentest', name: 'Relação de Pentests'},
    {route: '/system/vulnerabilities-pentest', name: 'Relação de Vulnerabilidades'},
    {route: '/system/pentest/new', name: 'Solicitação de Pentest'},
    // { route: '/system/vulnerabilities', name: 'Vulnerabilidades' },
    // { route: '/system/families', name: 'Famílias de Vulnerabilidades' },
    // { route: '/system/false-positive', name: 'Falso Positivo' },
    // { route: '/system/promocode/', name: 'Códigos Promocionais' },
  ]

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}
