import { useEffect, useState } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Link } from 'react-router-dom'
import { Button, Loading, PageTitle } from '../../components'
import {
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  FormControlLabel,
  RadioGroup,
  Collapse,
  TextField,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { fetchDevices } from '../../actions/domains'
import { createApplication, fetchApplication } from '../../actions/applications'

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: 3,
  },
  titleContainer: {
    width: '98%',
    margin: '20px auto',
    backgroundColor: 'white',
    boxShadow: '0 5px 5px 0px rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 30%)',
    borderRadius: '7px 7px 7px 7px',
  },
  formContainer: {
    width: '98%',
    padding: '15px 50px',
    paddingTop: 40,
    minHeight: 'calc(100vh - 60rem)',
    margin: '0 auto',
    backgroundColor: 'white',
    boxShadow: '0 5px 5px 0px rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 30%)',
    borderRadius: '7px 7px 7px 7px',
  },
  item: {
    margin: '10px 0 !important',
  },
  itemCollapse: {
    paddingRight: 10,
    margin: '5px 0',
  },
  collapse: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: 15,
  },
  portContainer: {
    display: 'flex',
  },
})

export default function FormArmour (props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { match, history } = props
  const { params } = match

  const account = useSelector(state => state.account)
  const devices = useSelector(state => state.devices)
  const products = useSelector(state => state.clientProducts)
  const currentProduct = useSelector(state => state.formArmour)

  const [restrictedArea, setRestrictedArea] = useState(false)
  const [editPort, setEditPort] = useState(false)

  const accountId = account[0] ? account[0].id : undefined

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    getValues,
    setError
  } = useForm()

  useEffect(() => {
    if (accountId) {
      dispatch(fetchDevices(accountId, { page_size: 99999 }))
      // dispatch(fetchProducts(accountId, { page_size: 99999 }))

      params.id && dispatch(fetchApplication(params.id))
    } else {
      history.push('/store/products')
    }
  }, [accountId, dispatch, history, params.id])

  useEffect(() => {
    if (!params.id && currentProduct.productId) {
      products.results[currentProduct.productId] &&
        setValue('product_id', products.results[currentProduct.productId].product_type.name)
    }
  }, [currentProduct, params.id, products, setValue])


  const onSubmit = (data) => {
    if (restrictedArea && data.password !== data.confirm_password) {
      setError('password', { message: 'Senha não confere' })
      setError('confirm_password', { message: 'Senha não confere' })

      return null
    }

    !params.id &&
      dispatch(createApplication({
        ...data,
        product_id: products.results[currentProduct.productId].id,
        date: data.date.format('YYYY-MM-DD HH:mm')
      }))
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.titleContainer}>
        <PageTitle
          title={params.id
            ? 'Edição de Aplicação'
            : 'Criação de Aplicação'
          }
        />
      </Grid>

      <Grid className={classes.formContainer}>
        <Loading loading={isSubmitting || devices.isLoading || products.isLoading} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container alignItems="center" item xs={12}>
            <Grid className={classes.item} item xs={3}>
              <Controller
                render={({ field }) =>
                  <TextField
                    {...field}
                    label="Produto"
                    variant='outlined'
                    fullWidth
                    disabled
                  />
                }
                defaultValue=""
                name="product_id"
                rules={{ required: true }}
                control={control}
              />
            </Grid>

            <Grid className={classes.item} item xs={12}>
              <Controller
                render={({ field }) =>
                  <TextField
                    {...field}
                    label="Dominio *"
                    color="primary"
                    variant="outlined"
                    error={!!errors.domain_id}
                    select
                    fullWidth
                  >
                    <MenuItem value="">
                      Selecione um domínio
                    </MenuItem>

                    {!_.isEmpty(devices.results) && devices.results.map(option =>
                      <MenuItem
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    )}
                  </TextField>
                }
                defaultValue=""
                name="domain_id"
                rules={{ required: true }}
                control={control}
              />
            </Grid>

            {!params.id &&
              <Grid className={classes.item} item xs={3}>
                <Controller
                  render={({ field }) =>
                    <DateTimePicker
                      {...field}
                      id="date"
                      label="Agendamento do primeiro scan *"
                      format="DD/MM/YYYY HH:mm"
                      value={getValues('date') || null}
                      minDate={moment(new Date())}
                      inputVariant="outlined"
                      variant="inline"
                      renderInput={params =>
                        <TextField
                          {...params}
                          {...field}
                          error={!!errors.date}
                          fullWidth
                          helperText={null}
                        />
                      }
                    />
                  }
                  name="date"
                  defaultValue={null}
                  control={control}
                  rules={{
                    required: true,
                    validate: value => value !== '',
                    onChange: ({ target }) => {
                      setValue('date', target.value)
                    },
                  }}
                />
              </Grid>
            }

            <Grid className={classes.item} item xs={12}>
              <Button
                style={{ marginRight: '15px' }}
                onClick={handleSubmit}
                disabled={params.id}
                loading={!!isSubmitting}
              >
                Salvar
              </Button>

              <Link to="/armour/applications">
                Cancelar
              </Link>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

FormArmour.propTypes = {
  params: PropTypes.object,
  route: PropTypes.object,
}
