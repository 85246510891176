import { replace } from 'connected-react-router'
import { connectedReduxRedirect, connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'

const locationHelper = locationHelperBuilder({})

const loginRoute = '/login'
const defaultRoute = '/store/site-blindado'

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state => state.auth.data !== null && state.auth.isAuthenticated,
  authenticatingSelector: state => state.auth.isAuthenticating,
  wrapperDisplayName: 'userIsAuthenticated'
}
const userIsAdminDefaults = {
  authenticatedSelector: state =>
    state.auth.data !== null &&
    state.auth.isAuthenticated &&
    state.auth.data.role === 'admin',
  // authenticatingSelector: state => !state.auth.isAuthenticating,
  wrapperDisplayName: 'userIsAdmin'
}
const userIsNotAuthenticatedDefaults = {
  authenticatedSelector: state => state.auth.data === null || !state.auth.isAuthenticated,
  wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults)
export const userIsAdmin = connectedAuthWrapper(userIsAdminDefaults)
export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults)

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  // AuthenticatingComponent: Loading,
  redirectPath: loginRoute
})
export const userIsAdminRedir = connectedReduxRedirect({
  ...userIsAdminDefaults,
  redirectPath: loginRoute,
  allowRedirectBack: false,
  authenticatedSelector: state => state.auth.data !== null && state.auth.isAdmin,
  predicate: auth => auth.isAdmin,
  wrapperDisplayName: 'UserIsAdmin'
})
export const userIsNotAuthenticatedRedir = connectedReduxRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/login',
  allowRedirectBack: false
})

export const OnlyOrganizationAdmin = (Component, FailureComponent = () => <> </>) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const auth = state.auth;
    const account = state.account[0]; 

    if (
      auth.data &&
      auth.isAuthenticated &&
      account &&
      auth.data.organizations
    ) {
      return (
        auth.isAdmin ||
        ['owner', 'admin'].includes(auth.data.organizations[account.id].role)
      );
    }
    return false;
  },
  FailureComponent,
})(Component);

export const onlyAdmin = (Component, FailureComponent = () => <> </>) => connectedAuthWrapper({
  authenticatedSelector: state => state.auth.data !== null && state.auth.isAdmin,
  redirectAction: replace,
  failureRedirectPath: '/store/site-blindado',
  allowRedirectBack: false,
  wrapperDisplayName: 'onlyAdmin',
  FailureComponent
})(Component)

export const ownerView = (Component, FailureComponent) => connectedAuthWrapper({
  authenticatedSelector: state => {
    const auth = state.auth
    const account = state.account[0]
    return state.auth !== null
      && account
      && account.type !== 'user'
      && (auth.data.role === 'admin'
        || (auth.data.organizations[account.id] && auth.data.organizations[account.id].role === 'owner'))
  },
  wrapperDisplayName: 'ownerView',
  FailureComponent
})(Component)

export const ownerAuth = connectedAuthWrapper({
  authenticatedSelector: state => {
    const auth = state.auth
    const account = state.account[0]
    return state.auth !== null
      && account
      && account.type !== 'user'
      && (auth.data.role === 'admin'
        || (auth.data.organizations[account.id] && auth.data.organizations[account.id].role === 'owner'))
  },
  authenticatingSelector: state => state.auth.isAuthenticating,
  wrapperDisplayName: 'ownerAuth',
})

export const requireAuth = (Component, FailureComponent) => connectedAuthWrapper({
  authenticatedSelector: state => state.auth.data !== null && state.auth.isAuthenticated,
  failureRedirectPath: '/login',
  wrapperDisplayName: 'requireAuth',
  FailureComponent
})(Component)
