import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

export const TextField = (props) => {
  const { input, label, className, required, error, meta, disabled } = props
  const { floatMessage, type, handleBlur, placeholder, noMessage } = props
  const { error: metaError, touched } = meta

  const markRequired = (!!required ? '*' : '')

  const inputClass = classnames('input', className, {
    'input-error': (!!error || !!metaError) && touched,
  })

  const spanClass = classnames('input-error', className, {
    'float-message': !!floatMessage && touched,
  })

  return (
    <div className="field">
      {label &&
        <label htmlFor="">{`${label} ${markRequired}`}</label>
      }

      <props.selector
        {...input}
        type={type || 'text'}
        className={inputClass}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={event => {
          if (handleBlur) {
            handleBlur(input.value)
          }

          input.onBlur(event)
        }}
      />

      {(metaError || error) && touched && !noMessage &&
        <span className={spanClass}>{metaError || error}</span>
      }
    </div>
  )
}

TextField.defaultProps = {
  className: '',
  selector: 'input',
}

TextField.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func,
  placeholder: PropTypes.string,
  noMessage: PropTypes.bool,
  type: PropTypes.string,
  selector: PropTypes.string,
  floatMessage: PropTypes.bool,
}
