import { css } from 'styled-components';

export default css`
    .order-success > .content-inner {
        padding: 1.2rem 2rem 3rem;
        background: linear-gradient(to bottom,  #74b61b 0%,#74b61b 11%,#ffffff 5%);
    }

    .order-success > .content-inner > .title {
        background: #74b61b;
        display: inline-flex;
        width: 100%;
        margin-bottom: 4rem;
    }

    .order-success > .content-inner > .title > h2 {
        margin: 0 0 0 1rem;
        align-self: center;
        font-weight: bold;
        color: white;
    }

    .order-success > .content-inner > .details {
        margin: 4rem 0 3rem;
    }

    .order-success > .content-inner > .details > .products-step {
        margin: 1rem 0 3rem;
        display: flex;
        width: 60%;
        justify-content: space-around;
        align-items: center;
    }

    .order-success > .content-inner > .details > .products-step > .step {
        text-align: center;
    }

    .order-success > .content-inner > .details > .products-step > .step img {
        display: block;
        margin: 0 auto 1.5rem;
    }

    .order-success > .content-inner > .details > .products-step > .step i {
        display: block;
        font-size: 4.5rem;
        margin-bottom: 1rem;
    }

    .order-success > .content-inner > .details > .brand > img {
        margin-top: .5rem;
        height: 3.5rem;
    }
`;
