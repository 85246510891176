import React, { useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Card } from "../../components";
import pdfDocument from "../../../assets/documents/manual_da_nota.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const SSLNoteManual = (props) => {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const options = useMemo(() => {
        return {
            cMapUrl: "cmaps/",
            cMapPacked: true,
        };
    }, []);

    const documentStyles = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    const pageContainerStyle = {
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
    };

    const style = {
        cardPdf: {
            padding: "1.5rem",
        },
    };

    return (
        <section className="content">
            <Card style={style.cardPdf}>
                <h1 className="text-center font-weight-bold">
                    Manual da Nota - SSL
                </h1>
                <div style={documentStyles}>
                    <Document
                        file={pdfDocument}
                        options={options}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                            <div className="container">
                                <h2 className="text-center">
                                    Carregando PDF...
                                </h2>
                            </div>
                        }
                    >
                        <div style={pageContainerStyle}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <div key={`page_container_${index + 1}`} style={{ border: '1px solid #dedede'}} className="mb-3">
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        width={
                                            window.innerWidth > 768
                                                ? window.innerWidth * 0.4
                                                : window.innerWidth * 0.8
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </Document>
                </div>
            </Card>
        </section>
    );
};
