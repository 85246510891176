import { css } from 'styled-components';

import { $lightgrey600, $borderRadiusBase, $lightgrey300 } from '../settings/variables';

import 'flatpickr/dist/themes/airbnb.css';

export default css`
    .scheduling__calendar {
        margin: 2rem 0 4rem;
        display: flex;
        justify-content: center;
    }

    .scheduling__step > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        background-color: #74c776;
        border-radius: 100%;
        color: #fff;
        margin-right: 1rem;
    }

    .scheduling__obs {
        border: .1rem solid ${$lightgrey600};
        border-radius: ${$borderRadiusBase};
        padding: 1rem;
        background-color: ${$lightgrey300};
        margin: 1.5rem 0 3rem;
    }

    .scheduling .radio {
        margin: .5rem 0;
    }
  
    .scheduling .choices {
        margin-top: 10%;
    }

    .scheduling__form {
        //width: 96%;
        margin-top: 10%;
        height: 100%;
        position: relative;
        //bottom: 70px;
        overflow: inherit;
        //padding-right: 30px;
    }

    .flatpickr-input {
        display: none;
    }

    .flatpickr-calendar,
    .flatpickr-days,
    .dayContainer {
        min-width: initial;
        width: 100%;
        max-width: 45rem;
    }

    .numInputWrapper {
        width: 7rem;
    }

    .datepicker-wrapper.hide-picker .flatpickr-input {
        display: none;
    }

    .fields-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }
    .scheduling__info {
        color: #74B61B;
    }

    .scheduling__field__icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
