import PropTypes from 'prop-types'
import React from 'react'
import { CertificatesInstalled, DashboardSSL } from '../../containers'
import { Card } from '../../components'

export class Domains extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.setTitle(this.props.title)
  }

  render() {
    return (
      <section className="content">
        <DashboardSSL />

        <Card>
          <CertificatesInstalled {...this.props} />
        </Card>
      </section>
    )
  }
}

Domains.propTypes = {
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
