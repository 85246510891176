import PropTypes from 'prop-types';
import React from 'react';

export const TableWrapActions = ({ children, search, refresh }) => {
    const getChildren = (name) => (
        React.Children.map(children, (child) => (
            (child.props.name === name) ? child : false
        ))
    );

    return (
        <div className="actions">
            <div className="left">{getChildren('left')}</div>
            <div className="right">
                {search}
                {getChildren('right')}
                {refresh}
            </div>
        </div>
    );
};

TableWrapActions.propTypes = {
    search: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object,
    ]),
    refresh: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
    ]),
};
