import { fetch } from '../services/fetch';

export const SEEK_ADDRESS = 'SEEK_ADDRESS';

export const seekAddress = (cep) => {
    const request = fetch(`/address/fetch/${cep.replace(/\./g, '')}`).then(res => res.json());
    return {
        type: SEEK_ADDRESS,
        payload: request,
    };
};
