import { css } from 'styled-components';

import {
    $borderRadiusBase,
    $darkgrey200,
    $lightgrey300,
    $shadowTooltip,
    $spaceBase,
    spaceBase,
} from '../settings/variables';

export default css`
    .tooltip {
        position: relative;
        display: inline-block;
    }

    .tooltip-content {
        transition: all .6s;
        position: absolute;
        left: 50%;
        bottom: 4rem;
        transform: translate(-50%);
        min-width: 20rem;
        padding: 0;
        background: rgba(51, 51, 51, .95);
        border-radius: ${$borderRadiusBase};
        text-align: left;
        visibility: hidden;
        opacity: 0;
        box-shadow: ${$shadowTooltip};
        z-index: 10;
        color: ${$lightgrey300};
    }

    .tooltip-content .tooltip-arrow {
        height: 2rem;
        position: absolute;
        border-style: solid;
        left: 50%;
        transform: translate(-50%);
        z-index: 0;
        margin-top: 0rem;
        border-width: 0.8rem 0.9rem 0 0.9rem;
        border-color: rgba(51, 51, 51, .9) transparent transparent transparent;
    }

    .tooltip-content:before {
        content: ' ';
        position: absolute;
        left: 50%;
        bottom: -1rem;
        transform: translate(-50%);
        border-style: solid;
        border-width: 1rem 1rem 0 1rem;
        border-color: ${$darkgrey200} transparent transparent transparent;
    }

    .tooltip-content.open {
        visibility: visible;
        opacity: 1;
    }

    .tooltip-content.right {
        transform: translate(0);
        left: initial;
        right: -.8rem;
    }

    .tooltip-content.right:before {
        bottom: -1rem;
        left: initial;
        right: 0;
        left: initial;
    }

    .tooltip-content.right .tooltip-arrow {
        right: 1.1rem;
        left: initial;
        transform: translate(0);
    }

    .tooltip-content.left {
        transform: translate(0);
        left: 0;
    }

    .tooltip-content.left:before {
        bottom: -1rem;
        left: 1.9rem;
    }

    .tooltip-content.left .tooltip-arrow {
        left: 1.1rem;
        transform: translate(0);
    }

    .tooltip-content .tooltip-text {
        padding: 1rem;
    }

    .tooltip-label {
        cursor: pointer;
        text-align: center;
        height: 2.5rem;
        display: block;
    }

    .tooltip .button-group {
        padding: ${$spaceBase} 0 ${spaceBase / 2}rem;
    }
`;
