import React, { useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Card } from "../../components";
// import pdfDocument from "../../../assets/documents/ativacao_ssl.pdf";
import pdfDocument from "../../../assets/documents/ssl_activation.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const HowToActivateSSL = (props) => {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const options = useMemo(() => {
        return {
            cMapUrl: "cmaps/",
            cMapPacked: true,
        };
    }, []);

    const documentStyles = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    const style = {
        cardPdf: {
            padding: "1.5rem",
        },
    };

    return (
        <section className="content">
            <Card style={style.cardPdf}>
                <h1 className="text-center font-weight-bold">
                    Ativação do SSL
                </h1>
                <div style={documentStyles}>
                    <Document
                        file={pdfDocument}
                        options={options}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                        <div className="container">
                            <h2 className="text-center">Carregando PDF...</h2>
                        </div>
                        }
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                width={window.innerWidth * 0.85}
                            />
                        ))}
                    </Document>
                </div>
            </Card>
        </section>
    );
};
