/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import {Link, useHistory} from 'react-router-dom';
import {Button, PageTitle} from '../../components';
import {Grid, InputLabel, MenuItem, OutlinedInput, Select,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {useDispatch, useSelector} from 'react-redux';

import {fetchDevices} from '../../actions/domains';
import {fetchProducts} from '../../actions/products';
import {createApplication} from '../../actions/waf';
import {fetchApplication} from '../../actions/applications';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'calc(100vh - 11rem);',
    overflow: 'overlay',
    borderRadius: 3,
  },
  titleContainer: {
    width: '98%',
    margin: '20px auto',
    backgroundColor: 'white',
    boxShadow: '0 5px 5px 0px rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 30%)',
    borderRadius: '7px 7px 7px 7px',
  },
  formContainer: {
    width: '98%',
    padding: '15px 50px',
    margin: '0 auto',
    backgroundColor: 'white',
    boxShadow: '0 5px 5px 0px rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 30%)',
    borderRadius: '7px 7px 7px 7px',
  },
  formItem: {
    padding: 10,
  },
  itemCollapse: {
    paddingRight: 10,
  },
  collapse: {
    display: 'flex',
  },
  button: {
    marginRight: 15,
  },
  portContainer: {
    display: 'flex',
  },
});

export default function FormSerasa(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {params, route} = props;

  const account = useSelector(state => state.account);
  const application = useSelector(state => state.applicationDetails.application);
  const productInfo = useSelector(state => state.productInfo.currentProduct);
  const devices = useSelector(state => state.devices.results);
  const products = useSelector(state => state.clientProducts);
  const errors = useSelector(state => state.formArmour);

  const [restrictedArea, setRestrictedArea] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [data, setData] = useState({
    product_id: '',
    domain_id: '',
    account_id: account[0] ? account[0].id : undefined,
  });

  const accountId = account[0] ? account[0].id : undefined;

  useEffect(() => {
    if (params.id && accountId) {
      dispatch(fetchDevices(accountId, {page_size: 99999}));
      dispatch(fetchProducts(accountId, {page_size: 99999}));

      return dispatch(fetchApplication(params.id));
    }
    if (accountId) {
      dispatch(fetchDevices(accountId, {page_size: 99999}));
      dispatch(fetchProducts(accountId, {page_size: 99999}));
    }

    if (!accountId) {
      return history.push('/store/products');
    }
  }, [accountId, dispatch, params.id]);

  useEffect(() => {
    if (params.id) {
      setData({
        ...data,
        domain_id: application.url,
        domain: application.url,
        path: application.path,
        port: application.port,
        product_id: application.url,
      });
    }
  }, [application, data, params.id]);

  useEffect(() => {
    if (errors.errors) {
      let mountErrors = {};

      errors.errors.map(
        // eslint-disable-next-line no-return-assign
        error => mountErrors = {...mountErrors, [error.field]: error.message}
      );

      return setFormErrors(mountErrors);
    }

    return setFormErrors({});
  }, [errors]);

  const handleChange = ({target}) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (restrictedArea && data.password !== data.confirm_password) {
      return setPasswordError(true);
    }

    if (!params.id) {
      setPasswordError(false);
      return dispatch(createApplication({...data, product_id: productInfo.id}));
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.titleContainer}>
        <PageTitle title={route.name}/>
      </Grid>

      <Grid className={classes.formContainer}>
        <Grid container alignItems="center" item xs={12}>
          <Grid className={classes.formItem} item xs={6}>
            <InputLabel>Produto</InputLabel>
            <OutlinedInput
              name="product_id"
              defaultValue={!_.isEmpty(products.results)
                ? products.results[productInfo.id].description
                : ''
              }
              fullWidth
              disabled

            />
          </Grid>

          <Grid className={classes.formItem} item xs={6}>
            <InputLabel>Dominio *</InputLabel>
            <Select
              name="domain_id"
              variant="outlined"
              defaultValue=""
              value={data.domain}
              onChange={handleChange}
              fullWidth
              error={formErrors.domain_id}
            >
              {!_.isEmpty(devices) && devices.map(option =>
                <MenuItem value={option.id} key={option.id}>
                  {option.name}
                </MenuItem>
              )}
            </Select>

            {!_.isEmpty(errors) && errors.errors[0].field === 'domain_id' &&
              <span className="input-error">{errors.errors[0].message}</span>
            }
          </Grid>

          <Grid className={classes.formItem} item xs={12}>
            <Button
              style={{marginRight: '15px'}}
              onClick={handleSubmit}
              disabled={params.id}
            >
              Salvar
            </Button>
            <Link onClick={() => history.push('/waf/applications')}>Cancelar</Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

FormSerasa.propTypes = {
  params: PropTypes.object,
  route: PropTypes.object,
};
