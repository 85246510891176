import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Loading } from '../components'
import { connect } from 'react-redux'
import { domainValidation } from '../actions/domains'

class Validation extends Component {
  componentDidMount() {
    this.props.domainValidation(this.props.match.params.id,
      this.props.location.search.slice(6))

    this.props.setTitle(this.props.title)
  }

  render() {
    return (
      <Loading loading />
    )
  }
}

Validation.propTypes = {
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  domainValidation: PropTypes.func,
}

export default connect(null, { domainValidation })(Validation)
