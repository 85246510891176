import { css } from 'styled-components';

export default css`
    .slide-confirmation > p > strong {
        color: #e80f21;
    }

    .slide-confirmation > .field {
        margin: 2rem 0;
    }

    .slide-confirmation > .field > .checkbox {
        margin: 2rem 0;
        color: #e80f21;
        font-weight: bold;
    }

    .slide-confirmation >.buttons {
        margin-top: 2rem;
    }

    .slide-confirmation >.buttons > * {
        margin-right: 3rem;
    }

    .slide-confirmation .textarea {
        width: 100%;
    }
`;
