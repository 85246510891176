import { css } from 'styled-components';

import { $lightgrey700 } from '../settings/variables';

export default css`
    .users-notify {
        width: 100%;
    }

    .users-notify .item-user {
        display: flex;
        padding: .5rem;
        justify-content: flex-start;
        align-items: center;
    }

    .users-notify .item-user:not(:last-child) {
        border-bottom: .1rem solid ${$lightgrey700};
    }

    .users-notify .item-user .user-name {
        font-weight: bold;
    }

    .users-notify .item-user .user-email {
        font-size: 1.2rem;
    }

    .users-notify .item-user .info {
        width: 35rem;
    }


    .users-notify .button {
        padding-right: 0;
    }
`;
