import { css } from 'styled-components'

import {
  $lightgrey300,
  $darkgrey700,
  $fontSizeSmall,
  $greenSb,
  $linkColorHover,
  $lightgrey700,
} from '../settings/variables'

export default css`
  .sidebar {
    width: 12rem;
    min-height: calc(100vh - 60px);
    position: relative;
    z-index: 10;
    background-color: #f7f7f7;
    border-right: .1rem solid #dddddd;
  }

  .sidebar > .nav {
    width: 100%;
    padding: 0px;
  }

  .sidebar > .nav > li {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: ${$fontSizeSmall};
    text-align: center;
    border-bottom: solid 1px ${$lightgrey300};
  }

  .sidebar > .nav > li img {
    margin-bottom: 0.5rem;
    position: relative;
  }

  .sidebar > .nav .sb2wicon {
    margin-right: 0;
  }

  .sidebar > .nav a {
    transition: all .3s ease-out;
    padding: 1.2rem 1rem 0.4rem .7rem;
    margin: 1.2rem 0;
    color: ${$darkgrey700};
    font-weight: bold;
    border-left: .5rem solid transparent;
  }

  .sidebar > .nav a.beta {
    position: relative;
    border: 1px solid rgba(255, 0, 0, 0.3);
  }

  .sidebar > .nav a.beta.active {
    border-left: .5rem solid ${$greenSb};
  }

  .sidebar > .nav a.beta:after {
    content: 'beta';
    position: absolute;
    top: 0;
    right: 0;
    padding: .2rem .5rem;
    background-color: red;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
  }

  .sidebar > .nav a p {
    position: relative;
  }

  .sidebar > .nav a:hover {
    color: ${$linkColorHover};
  }

  .sidebar > .nav .menu-divider {
    border-bottom: .1rem solid ${$lightgrey700};
    margin: 0 2rem;
  }

  .sidebar > .nav .active {
    color: ${$darkgrey700};
    border-left: .5rem solid ${$greenSb};
    background-color: #fff;
  }

  .sidebar > .nav .active i {
    position: relative;
  }

  .sidebar > .nav .active:hover {
    color: ${$darkgrey700};
  }
`
