import { css } from 'styled-components';

import { $lightgrey300, $lightgrey600, $borderRadiusBase, spaceBase, $fontColorDisabled } from '../settings/variables';

export default css`
    .textarea {
        min-height: 23rem;
        padding: ${spaceBase / 2}rem;
        background-color: #fff;
        border: .1rem solid ${$lightgrey600};
        box-shadow: none;
        border-radius: ${$borderRadiusBase};
        outline: none;
        transition: all .2s ease-in-out;
    }

    .textarea:not(.textarea-error):focus {
        border-color: #7fb93a;
    }

    .textarea.textarea-error {
        border-color: #b71c1c;
    }

    .textarea:disabled {
        background-color: ${$lightgrey300};
        color: ${$fontColorDisabled};
        cursor: not-allowed;
    }

    .textarea[readonly] {
        resize: none;
    }

    .textarea.certificate {
        font-family: monospace;
        font-size: 1.2rem;
        padding: 1rem;
        resize: none;
    }

    .textarea.certificate:disabled {
        background-color: ${$lightgrey300};
        color: ${$fontColorDisabled} !important;
    }
`;
