export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export function openDialog(content) {
    return {
        type: OPEN_DIALOG,
        payload: { content },
    };
}

export function closeDialog() {
    return {
        type: CLOSE_DIALOG,
    };
}
