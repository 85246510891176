import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { SliderButton } from '../../containers'
import { IconCircle, ProductDetails, BuyBox } from '../../components'
import { Lock, Security } from '@material-ui/icons'

export const CardProduct = (props) => {
  const title = props.name
  const config = {
    isMultdomain: props.is_multidomain,
    years: props.years_of_validity || [],
  }
  
  const [openPopover, setOpenPopover] = useState(null)

  return (
    <div className="card-product">
      <div className="title">
        <IconCircle color={props.brand === 'trustsign' ? '#072944' : '#74B61B'}>
          {props.category === 'waf'
            ? <Security />
            : <Lock />
          }
        </IconCircle>

        <h2>
          {title}
        </h2>
      </div>

      <div
        className="details"
        dangerouslySetInnerHTML={{ __html: props.resume }}
      />

      <SliderButton
        button="Ver mais"
        className="see-more"
        sliderTitle="Detalhes do Produto"
      >
        <ProductDetails
          catalog={props.catalog}
          config={config}
          {...props}  
        />
      </SliderButton>

      <BuyBox
        id={props.id}
        title={title}
        catalog={props.catalog}
        config={config}
        open={openPopover}
        handleOpen={event => setOpenPopover(event.currentTarget)}
        onClose={() => setOpenPopover(null)}
        {...props.position}
      />
    </div>
  )
}

CardProduct.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  validation_type: PropTypes.string,
  is_multidomain: PropTypes.bool,
  resume: PropTypes.string,
  position: PropTypes.object,
  years_of_validity: PropTypes.array,
  brand: PropTypes.string,
  catalog: PropTypes.string,
  category: PropTypes.string,
}
