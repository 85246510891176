import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

export class Tooltip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }

    this.close = this.close.bind(this)
    this.handleMouse = this.handleMouse.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.open) {
      document.addEventListener('click', this.close)
    } else {
      document.removeEventListener('click', this.close)
    }
  }

  handleClick() {
    if (this.props.click) {
      this.setState({ open: !this.state.open })
    }
  }

  handleMouse() {
    if (!this.props.click) {
      this.setState({ open: !this.state.open })
    }
  }

  close() {
    this.setState({ open: false })
  }

  renderChildren() {
    if (this.props.click) {
      return this.props.children
    }

    return (
      <div className="tooltip-text">
        {this.props.children}
      </div>
    )
  }

  render() {
    const classes = classnames({
      'tooltip-content': true,
      right: this.props.right || false,
      left: this.props.left || false,
      open: this.state.open || false,
    })

    return (
      <div className="tooltip">
        <div
          ref={(content) => { this.content = content }}
          className={classes}
          style={this.state.styles}
        >
          {this.renderChildren()}
          <div className="tooltip-arrow"></div>
        </div>

        <a href='/#' className="tooltip-label table-icons"
          onClick={this.handleClick}
          onMouseEnter={this.handleMouse}
          onMouseLeave={this.handleMouse}
        >
          {this.props.label}
        </a>
      </div>
    )
  }
}

Tooltip.propTypes = {
  label: PropTypes.element.isRequired,
  children: PropTypes.object,
  click: PropTypes.bool,
  right: PropTypes.bool,
  left: PropTypes.bool,
}
