import { css } from 'styled-components';

export default css`
    .evidence > h2 {
        font-weight: bold;
        border-bottom: 1px solid #eee;
        padding-bottom: 3px;
        word-break: break-all;
    }

    .evidence > .inner-content {
        padding: 0 2rem;
    }

    .evidence > .inner-content > h2 {
        font-weight: 500;
        font-size: 1.3rem;
        margin: 0;
    }

    .evidence > .inner-content > .code-block {
        margin-bottom: 1rem;
    }

    .evidence > .inner-content > .link {
        margin-bottom: 2rem;
        display: inline-block;
    }

    .evidence-response > .header {
        display: block;
        padding: 1rem 2rem;
        border-bottom: 1px solid #ccc;
        font-weight: bold;
    }
`;
