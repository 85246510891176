import React from 'react';
import { Button, Loading } from '../components';
import PropTypes from "prop-types";

export class ShowMoreWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            page: this.props.startPage || 1,
            pageSize: this.props.pageSize || 10,
            isLoading: false,
        };

        this.doFetch = this.doFetch.bind(this);
        this.initialState = this.initialState.bind(this);
    }

    componentDidMount() {
        if (this.props.children) {
            return this.initialState();
        }

        return this.doFetch();
    }

    initialState() {
        this.setState({ items: this.props.children });
    }

    doFetch() {
        this.setState({ page: this.state.page + 1, isLoading: true });

        this.props.loadMore(this.state.page, this.state.pageSize, this.props.fetchProps).then(data => {
            this.setState({ items: [...this.state.items, ...data], isLoading: false });
        });
    }

    render() {
        if (!this.state.items.length) {
            return <div>Não há resultados</div>;
        }

        return (
            <div>
                {this.state.isLoading &&
                    <Loading loading={this.state.isLoading} />
                }

                <div>
                    {this.state.items}
                </div>

                {this.props.itemsCount !== this.state.items.length &&
                    <Button onClick={this.doFetch}>
                        Ver Mais
                    </Button>
                }
            </div>
        );
    }
}

ShowMoreWrapper.propTypes = {
    children: PropTypes.array,
    loadMore: PropTypes.func,
    fetchProps: PropTypes.object,
    isLoading: PropTypes.bool,
    startPage: PropTypes.number,
    pageSize: PropTypes.number,
    itemsCount: PropTypes.number,
};
