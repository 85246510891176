import PropTypes from 'prop-types'
import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {Field, reduxForm} from 'redux-form'
import {addDomain, clearData} from '../../actions/domains'
import {Button, TextField} from '../../components'
import {toFormErrors} from '../../services/utils'
import {withAccountId} from '../AccountContainer'

class FormDomain extends React.Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentWillUnmount() {
    this.props.clearData()
  }

  onSubmit(domain) {
    return this.props.addDomain(this.props.accountId, domain.name)
  }

  render() {
    return (
      <form
        ref={form => form}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="form form-new"
      >
        <Field
          component={TextField}
          name="name"
          label="Nome *"
          error={this.props.errors.name || ''}
        />

        <div className="buttons">
          <Button loading={this.props.submitting}>
            Salvar
          </Button>

          <Link to="/devices/domains">
            Cancelar
          </Link>
        </div>
      </form>
    )
  }
}

FormDomain.propTypes = {
  accountId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  isLoading: PropTypes.bool,
  addDomain: PropTypes.func,
  clearData: PropTypes.func,
  submitting: PropTypes.bool,
}

const Form = reduxForm({
  form: 'addDomain',
  validate: (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Este campo é obrigatório'
    }

    return errors
  },
})(FormDomain)

const mapStateToProps = ({domain}) => ({
  isLoading: domain.isLoading,
  errors: toFormErrors(domain.errors),
})

export default withRouter(connect(mapStateToProps, {addDomain, clearData})(withAccountId(Form)))
