import PropTypes from 'prop-types';
import React from 'react';

export const CnameValidation = ({ domain, uniqueHash, sha256, md5 }) => {
    // precisa dividir sha256 em duas partes
    const sha2561 = sha256.substring(0, sha256.length / 2);
    const sha2562 = sha256.substring(sha256.length / 2);
    const domainDNS = domain.replace('*.', '');

    return (
        <article>
            <h2>Validação por DNS</h2>
            <p>Para validar seu domínio você deve criar uma entrada CNAME nos registros de DNS conforme abaixo.</p>

            <table className="table-info">
                <tbody>
                    <tr>
                        <td className="title">Nome</td>
                        <td>_{md5}.{domainDNS}</td>
                    </tr>
                    <tr>
                        <td className="title">Tipo</td>
                        <td>CNAME</td>
                    </tr>
                    <tr>
                        <td className="title">Valor</td>
                        <td style={{ wordBreak: 'break-all' }}>{sha2561}.{sha2562}.{uniqueHash}.sectigo.com.</td>
                    </tr>
                </tbody>
            </table>
        </article>
    );
};

CnameValidation.propTypes = {
    uniqueHash: PropTypes.string,
    domain: PropTypes.string,
    sha256: PropTypes.string,
    md5: PropTypes.string,
};
