import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { TextField, Button, Loading, Card, Code, SelectField } from '../../components';
import { fetchEvidence, saveEvidence } from '../../actions/evidences';

class FormEvidences extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.state = { application: '', client: '', status: '' };
    }

    componentDidMount() {
        this.props.setBreadcrumbName(<Loading loading />);
        this.props.dispatch(fetchEvidence(this.props.evidenceId))
        .then(({ value }) => {
            this.setState({
                application: value.application,
                client: value.client,
                scan_date: value.scan_date,
                created: value.created,
                url: value.url,
                status: value.status,
                false_positive_motive: value.false_positive_motive,
            });
            this.props.setBreadcrumbName(value.application);
        });
    }

    onSubmit(data) {
        return this.props.saveEvidence(this.props.evidenceId, data);
    }

    onChangeStatus(data, strStatus) {
        this.setState({ status: strStatus });
    }

    render() {
        return (
            <div>
                <Loading loading={this.props.isLoading} />

                <Card className="scan-details-header column">
                    <div className="title">
                        <h2>Detalhes da Evidência</h2>
                    </div>
                    <div className="inner">
                        <div className="item">
                            <div className="type">Aplicação</div>
                            <div className="description">
                                <span className="application-name">{this.state.application}</span> <br />
                            </div>
                        </div>
                        <div className="item">
                            <div className="type">Cliente</div>
                            <div className="description">
                                <span className="application-name">{this.state.client}</span> <br />
                            </div>
                        </div>
                        <div className="item">
                            <div className="type">Data de Solicitação</div>
                            <div className="description">
                                <span className="application-name">{this.state.scan_date}</span> <br />
                            </div>
                        </div>
                        <div className="item">
                            <div className="type">Data do Scan</div>
                            <div className="description">
                                <span className="application-name">{this.state.created}</span> <br />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="inner">
                        <div className="item">
                            <div className="type">URL</div>
                            <Code>{this.state.url}</Code>
                        </div>
                    </div>
                </Card>

                <Card>
                    <div className="content">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="form">
                            <Field
                                label="URL BASE"
                                name="url"
                                component={TextField}
                            />
                            <Field
                                component={SelectField}
                                name="status"
                                label="Status"
                                selected={this.state.status}
                                onChange={this.onChangeStatus}
                                options={[
                                    { id: 'valid', description: 'Solicitação Reprovada', selected: 'selected' },
                                    { id: 'false_positive', description: 'Solicitação Aprovada' },
                                ]}
                                error={''}
                            />
                            <Field
                                label="Observações"
                                name="false_positive_motive"
                                selector="textarea"
                                className="textarea"
                                component={TextField}
                            />

                            <div className="buttons">
                                <Button
                                    loading={this.props.submitting}
                                >
                                    Salvar
                                </Button>
                                <Link to="/system/evidences/">Cancelar</Link>
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        );
    }
}

FormEvidences.propTypes = {
    fetchEvidence: PropTypes.func,
    saveEvidence: PropTypes.func,
    setBreadcrumbName: PropTypes.func,
    familyId: PropTypes.string,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    submitting: PropTypes.bool,
};

const Form = reduxForm({
    form: 'formEvidence',
})(FormEvidences);

const mapStateToProps = (state) => ({
    isLoading: state.evidences.isLoading,
});

export default connect(mapStateToProps, { fetchEvidence, saveEvidence, reset })(Form);
