import { css } from 'styled-components';

import { $lightgrey600, $borderRadiusBase, $green700, $lightgrey300 } from '../settings/variables';

export default css`
    .pagination {
        text-align: right;
        padding: 0 4rem 2rem;
    }

    .pagination .records {
        margin-right: 2rem;
    }

    .pagination .pageCount {
        margin: 0 5px;
    }

    .pagination .buttons {
        display: inline-block;
    }

    .pagination .buttons button {
        width: 30px;
        background: ${$lightgrey300};
        box-shadow: 0 0 0;
        padding: 5px;
        margin: 0 5px;
        border: 1px solid ${$lightgrey600};
        border-radius: ${$borderRadiusBase};
        color: ${$green700};
    }

    .pagination .buttons button:hover {
        background: #ddd !important;
    }

    .pagination .buttons .currentPage {
        height: 30px;
        width: 80px;
        margin: 0 5px;
        padding: 0 5px;
        border-radius: ${$borderRadiusBase};
        border: 1px solid ${$lightgrey600};
        text-align: center;
    }
`;
