import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Close} from '@material-ui/icons'

import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
import {closeSlider} from "../actions/slider";

export default function Slider(props) {
  const [panelType, setPanelType] = useState("right")
  const [panelSize, setPanelSize] = useState(40)
  const [noBackdrop, setNoBackdrop] = useState(false)
  const slider = useSelector(state => state.slider)
  const dispatch = useDispatch();

  function onClose() {
    dispatch(closeSlider())
  }

  return (
    <section>
      <SlidingPanel
        type={panelType}
        isOpen={slider.isOpen}
        backdropClicked={onClose}
        panelContainerClassName="panel"
        panelClassName="panel-content"
        size={panelSize}
        noBackdrop={noBackdrop}
      >
        <a onClick={onClose} className="close">
          <Close/>
        </a>
        <div className="panel-header">
          <h1 id="slider-title">
            {slider.title}
          </h1>
        </div>
        {slider.content}
      </SlidingPanel>
    </section>
  );
}
