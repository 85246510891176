import { css } from 'styled-components';

export default css`
    .errors-list {
        width: 35rem;
        padding: .5rem;
        border-radius: .2rem;
        background: #f73e3e;
        border: 1px solid red;
        color: white;
        margin-bottom: 1rem;
    }
`;
