import { fetch } from '../services/fetch';
import { push } from 'connected-react-router';
import { setMessage, SUCCESS } from '../modules/messages';
import { toParams } from '../services/utils';

export const FETCH_ALL = 'servers/FETCH_ALL';
export const FETCH = 'servers/FETCH';
export const ADD = 'servers/ADD';
export const UPDATE = 'servers/UPDATE';
export const DELETE = 'servers/DELETE';
export const CLEAR = 'servers/CLEAR';

export function fetchServers(accountId, params = {}) {
    const query = toParams(params);
    const request = fetch(`/accounts/${accountId}/servers${query}`).then(res => res.json());

    return {
        type: FETCH_ALL,
        payload: {
            promise: request,
            data: { accountId, ...params },
        },
    };
}

export function fetchServer(serverId) {
    const request = fetch(`/servers/${serverId}`).then(res => res.json());
    return {
        type: FETCH,
        payload: {
            promise: request,
            data: { serverId },
        },
    };
}

export function updateServer(serverId, data) {
    return dispatch => {
        const request = fetch(`/servers/${serverId}`, {
            method: 'PUT',
            body: JSON.stringify(data),
        })
        .then(res => {
            dispatch(push('/devices/servers'));
            dispatch(setMessage(SUCCESS, 'Servidor alterado com sucesso!'));

            return res.json();
        })
        .catch(error => error);

        return dispatch({
            type: UPDATE,
            payload: request,
        });
    };
}

export function addServer(accountId, data) {
    return dispatch => {
        const request = fetch(`/accounts/${accountId}/servers`, {
            method: 'POST',
            body: JSON.stringify(data),
        })
        .then(res => {
            dispatch(setMessage(SUCCESS, 'Servidor adicionado com sucesso!'));
            return dispatch(push('/devices/servers'));
        })
        .catch(error => error);

        return dispatch({
            type: ADD,
            payload: request,
        });
    };
}

export function deleteServer(accountId, serverId, page) {
    return dispatch => {
        const request = fetch(`/servers/${serverId}`, { method: 'DELETE' });
        return dispatch({
            type: DELETE,
            payload: request,
        }).then(() => {
            if (page > 1) {
                dispatch(push('/devices/servers'));
            } else {
                dispatch(fetchServers(accountId, 1));
            }

            dispatch(setMessage(SUCCESS, 'O servidor foi removido com sucesso.'));
        });
    };
}
