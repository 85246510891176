import React from 'react'
import { CopyToClipBoard, Choices } from '../../components'
import AwSeal from '../../../assets/images/aw_seal.png';

export class InstallStampArmour extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      position: this.props.position,
      side: this.props.side,
    }
    this.onChangePosition = this.onChangePosition.bind(this)
    this.onChangeSide = this.onChangeSide.bind(this)
  }

  onChangePosition(choice) {
    this.props.saveSeal(this.props.id, { position: choice })
    this.props.onSetPosition(choice)
  }
  onChangeSide(choice) {
    this.props.saveSeal(this.props.id, { side: choice })
    this.props.onSetSide(choice)
  }

  render() {
    return (
      <div>
        {/* TODO: Evoluir visualmente essa sessão */}
        <section>
          <div className="slider-title">Configurações:</div>

          <div style={{ display: 'flex', width: '55%', justifyContent: 'space-between', position: 'relative', top: 5 }}>
            <Choices
              items={[
                { label: 'Fixo', value: 'fixed' },
                { label: 'Flutuante', value: 'float' },
              ]}
              itemChecked={this.props.position}
              onClick={this.onChangePosition}
            />

            <Choices
              items={[
                { label: 'Esquerda', value: 'left' },
                { label: 'Direita', value: 'right' },
              ]}
              itemChecked={this.props.side}
              onClick={this.onChangeSide}
            />
          </div>
        </section>
        <br />

        <section>
          <div className="slider-title">Como Utilizar o Selo:</div>
          <p>
            <img src={AwSeal} alt="Selo de Blindagem"
              style={{ float: 'right', marginLeft: '10rem' }}
            />
            Copie e cole o código abaixo no seu site para obter a imagem do Selo de Blindagem:
          </p>

          <CopyToClipBoard>
            {'<div id="armored_website">'}
            {'\n    <param id="aw_preload" value="true" />'}
            {'\n    <param id="aw_use_cdn" value="true" />'}
            {!!this.props.path && `\n    <param id="aw_app_path" value="${this.props.path}" />`}
            {'\n</div>'}
            {'\n\n<script type="text/javascript" src="//cdn.siteblindado.com/aw.js"></script>'}
          </CopyToClipBoard>

          <span>
            <small className="code-block-obs">
              O SELO DE BLINDAGEM APENAS FICARÁ VISÍVEL SE NÃO HOUVER VULNERABILIDADES CRÍTICAS
            </small>
          </span>

        </section>
      </div>
    )
  }
}

// InstallStampArmour.propTypes = {
//     id: PropTypes.string,
//     path: PropTypes.string,
//     position: PropTypes.string,
//     side: PropTypes.string,
//     saveSeal: PropTypes.object,
// };
