import { FETCH_ALL, FETCH } from '../../../actions/system/pentest-knowledgebase'
import { INITIAL_LIST } from '../../../services/initial-states'
import normalizedState from '../../../services/normalize'

export const pentestKnowledgebase = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${FETCH_ALL}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)

      return { ...state, ...action.payload, results, allIds, isLoading: false }
    }
    case `${FETCH_ALL}_REJECTED`:
      return state
    default:
      return state
  }
}

export const pentestKnowledgebaseVulnerability = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case `${FETCH}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${FETCH}_FULFILLED`: {
      // const { results, allIds } = normalizedState(action.payload.results)

      return { ...state, ...action.payload, isLoading: false }
    }
    case `${FETCH}_REJECTED`:
      return state
    default:
      return state
  }
}
