import MomentUtils from '@date-io/moment'

import {ThemeProvider} from '@mui/material/styles'
import theme from './theme'
import GlobalStyle from './styles/global'

import localStorage from 'store'
import store from './store'

import Routes from './routes'

import {loginUserSuccess} from './actions/auth'
import {fetchMruAccounts} from './actions/account'
import {modalTrue} from './actions/modal'
import { PrivacyPolicy } from './components'

import moment from 'moment'
import {LocalizationProvider} from '@mui/x-date-pickers'

export const App = ({history, context}) => {
  const token = localStorage.get('token')

  if (token) {
    store.dispatch(modalTrue())
    store.dispatch(loginUserSuccess(token))
    store.dispatch(fetchMruAccounts(token))
  }

  moment.locale('pt-BR')

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <ThemeProvider theme={theme}>
        <Routes/>
        <GlobalStyle/>
        <PrivacyPolicy />
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
