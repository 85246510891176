import {fetch} from '../services/fetch'
import {toParams} from '../services/utils'
import {ERROR, setMessage, SUCCESS} from '../modules/messages'
import {CONSOLIDATED_ACCOUNT} from './account'

export const FETCH_CERTIFICATES = 'certificates/FETCH_CERTIFICATES'
export const RUN_SCAN = 'certificates/RUN_SCAN'
export const GET = 'report/GET'
export const REVOKE_CERTIFICATE = 'certificate/REVOKE'
export const UPDATE_CERTIFICATE = 'certificate/UPDATE'

// TODO: passar para domains
export function fetchCertificates(accountId, params) {
  const defaultArgs = {tree: false, certificate: true}
  const query = toParams({...defaultArgs, ...params})
  const url = accountId === CONSOLIDATED_ACCOUNT.id ? `/domains${query}` : `/accounts/${accountId}/domains${query}`
  const request = fetch(url).then(res => res.json())

  return {
    type: FETCH_CERTIFICATES,
    payload: {
      promise: request,
      data: {accountId, ...params},
    },
  }
}

export function fetchCertificateByFilter(accountId, filters = {}) {
  return dispatch => dispatch(fetchCertificates(accountId, filters))
}

export function runScan(certificateId) {
  return dispatch => {
    const request = fetch(`/domains/${certificateId}/ssl-scans`, {method: 'POST'})
      .then(() => dispatch(setMessage(SUCCESS, 'Scan solicitado com sucesso!')))
      .catch(({message}) => dispatch(setMessage(ERROR, message)))

    return dispatch({
      type: RUN_SCAN,
      payload: request,
    })
  }
}

export function getReport(scanId, ipAddress) {
  return dispatch => {
  const request = fetch(`/ssl-scans/${scanId}?ip=${ipAddress}`).then(res => res.json())
  return dispatch({
    type: GET,
    payload: request,
  })
}}

export function revokeCertificate(order, reason) {
  return dispatch => {
    const request = fetch(`/certificate-orders/${order}/revoke`, {
      method: 'POST',
      body: JSON.stringify({reason}),
    })
      .then(() => dispatch(setMessage(SUCCESS, 'Revogação solicitada com sucesso!')))
      .catch(({message}) => dispatch(setMessage(ERROR, message)))

    return dispatch({
      type: REVOKE_CERTIFICATE,
      payload: request,
    })
  }
}
