import { fetch } from '../services/fetch';

export const FETCH_UPTIME = 'uptime/FETCH_UPTIME';


export function fetchUptime(params = {}) {
    const url = '/uptime';
    const request = fetch(url).then(res => res.json());

    return {
        type: FETCH_UPTIME,
        payload: {
            promise: request,
            data: { ...params },
        },
    };
}
