import PropTypes from 'prop-types'
import React from 'react'

export const Icon = ({ className, name, size, style = {} }) => (
  <i className={`material-icons ${className || name}`} style={{
    ...!!size && { fontSize: size },
    ...style,
  }} >{name}</i>
)

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  style: PropTypes.object,
}

export const IconCircle = ({ name, color = 'green', children }) => (
  <span className="icon-circle" style={{ backgroundColor: color }}>
    {children}
  </span>
)

IconCircle.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
  children: PropTypes.element,
}
