import {fetch} from '../services/fetch';
import {CONSOLIDATED_ACCOUNT} from './account';

export const FETCH_DASHBOARD = 'dashboards/FETCH';

export function fetchDashboard(section, accountId, status = false, sealStatus = false, loading = true) {
  const url = accountId === CONSOLIDATED_ACCOUNT.id
    ? `/dashboards/${section}`
    : `/accounts/${accountId}/dashboards/${section}`;
  const request = fetch(url).then(res => res.json()).catch(err => err);
  return {
    type: FETCH_DASHBOARD,
    payload: {
      promise: request,
      data: {
        section,
        loading,
      },
    },
  };
}

// TODO: Refatorar isso, que não está legal. Talvez um Dashboard generico que
// receba proprieades com os valores do chart

export function fetchDashboardApplication(applicationId, status = false, sealStatus = false, loading = true) {
  const request = fetch(`/applications/${applicationId}/charts`).then(res => res.json());
  return {
    type: FETCH_DASHBOARD,
    payload: {
      promise: request,
      data: {
        loading,
      },
    },
  };
}
