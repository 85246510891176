import {
  FETCH_SUMMARY,
  CREATE_PENTEST,
  FETCH_PENTEST,
  FETCH_PENTESTS,
  FETCH_PENTESTS_USER,
  UPDATE_PENTEST,
} from '../../actions/pentests'
import { INITIAL_LIST } from '../../services/initial-states'
import normalizedState from '../../services/normalize'
import mountError from '../../services/mountError'

export const pentests = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_PENTESTS}_PENDING`:
    case `${FETCH_PENTESTS_USER}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${FETCH_PENTESTS_USER}_FULFILLED`: {
      const userResults = action.payload.results.filter(pentest => pentest.status === 'done')

      const count = userResults.length

      const userHasNoPentests = count === 0

      const { results, allIds } = normalizedState(userResults)

      return { ...state, ...action.payload, results, allIds, isLoading: false, count, userHasNoPentests }
    }
    case `${FETCH_PENTESTS}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)

      return { ...state, ...action.payload, results, allIds, isLoading: false }
    }
    case `${FETCH_PENTESTS}_REJECTED`:
    case `${FETCH_PENTESTS_USER}_REJECTED`:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export const pentest = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case '@pentest/CLEAR_PENTEST':
      return { ...state, results: action.payload, isLoading: false }
    case `${FETCH_PENTEST}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${FETCH_PENTEST}_FULFILLED`: {
      return {
        ...state,
        results: action.payload,
        isLoading: false,
      }
    }
    case `${FETCH_PENTEST}_REJECTED`:
      return state
    case `${CREATE_PENTEST}_PENDING`:
      return {
        ...state,
        isLoading: true
      }
    case `${CREATE_PENTEST}_FULFILLED`:
      return {
        ...state,
        resuls: {},
        errors: {},
        isLoading: false
      }
    case `${CREATE_PENTEST}_REJECTED`: {
      const createErrors = mountError(action.payload.errors)

      return {
        ...state,
        errors: createErrors,
        isLoading: false
      }
    }
    case `${UPDATE_PENTEST}_PENDING`:
      return {
        ...state,
        errors: {},
        isLoading: true
      }
    case `${UPDATE_PENTEST}_FULFILLED`:
      return {
        ...state,
        resuls: action.payload,
        errors: {},
        isLoading: false
      }
    case `${UPDATE_PENTEST}_REJECTED`: {
      const updateErrors = mountError(action.payload.errors)

      return {
        ...state,
        error: updateErrors,
        isLoading: false
      }
    }
    default:
      return { ...state, isLoading: false }
  }
}

export const pentestSummary = (state = { filters: {}, isLoading: false }, action) => {
  switch (action.type) {
    case `${FETCH_SUMMARY}_FULFILLED`:
      return { ...action.payload, isLoading: false }
    case `${FETCH_SUMMARY}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${FETCH_SUMMARY}_REJECTED`: {
      return state
    }
    default:
      return state
  }
}
