import { css } from 'styled-components';

import { $darkgrey200, $lightgrey400 } from '../settings/variables';

export default css`
    .accordion {
        position: relative;

    }

    .accordion-header {
        padding: 1rem 2rem;
        border: .1rem solid ${$darkgrey200};
        border-bottom: none;
        background-color: ${$lightgrey400};
        cursor: pointer;
        display: flex;
        align-items: center;

    }

    .accordion-header [class*='mark-'] {
        margin-left: -1rem;
    }

    .accordion-header > div {
        width: 100%;
        display: flex;
        align-items: center;

        > .score {
            margin: 0 1rem;
        }
    }

    .accordion-container {
        border: .1rem solid ${$darkgrey200};
        border-top: none;
        overflow: hidden;
    }

    .accordion-content {
        padding: 1rem 2rem;
    }
`;
