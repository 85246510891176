import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { GenericContainer } from '../containers'
import { Icon, SwitchAccount } from '../components'
import { Link } from 'react-router-dom'
import debounce from 'debounce'
import { switchAccount } from '../actions/account'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import unionBy from 'lodash/unionBy'
import { toParams } from '../services/utils'
import { createSelector } from 'reselect'

export class AccountControl extends GenericContainer {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      isLoading: false,
    }

    this.endpoint = '/accounts'
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.fetchSearch = debounce(this.fetchSearch, 400)
  }

  handleChange(e) {
    const { value } = e.target
    if (value) {
      this.fetchSearch(value)
    }
  }

  clearSearch() {
    this.setState({ results: [] })
  }

  handleClick(item) {
    this.props.switchAccount(item)
  }

  fetchSearch(value) {
    this.setState({ isLoading: true })

    this.get(`${this.endpoint}${toParams({ search: value })}`)
      .then(({ results }) => {
        this.setState({ results, isLoading: false })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    return (
      this.props.showControl
        ?
        <SwitchAccount
          currentAccount={this.props.account}
          // user={this.props.user}
          // organizations={this.props.organizations}
          searchItems={this.state.results}
          lastAccounts={this.props.previousAccounts}
          onChange={this.handleChange}
          onClick={this.handleClick}
          isLoading={this.state.isLoading}
          clearSearch={this.clearSearch}
        />
        :
        <Link to="/organizations/new" className="btn-new-org">
          <Icon name="add" />
          Criar Organização
        </Link>
    )
  }
}

AccountControl.propTypes = {
  showControl: PropTypes.bool,
  account: PropTypes.object,
  previousAccounts: PropTypes.array,
  user: PropTypes.object,
  organizations: PropTypes.array,
  switchAccount: PropTypes.func,
}

const authSelector = (state) => (state.auth.data)
const userSelector = createSelector(
  authSelector,
  (data) => (data ? {
    id: data.user.id,
    name: `${data.user.first_name} ${data.user.last_name || ''}`,
    type: 'user',
  } : null)
)
const organizationsSelector = createSelector(
  authSelector,
  (data) => (data ? map(data.organizations, (organization, id) => (
    {
      id,
      name: organization.full_name,
      type: 'organization',
    })) : [])
)
const mruSelector = (state) => (state.account)

const accountsSelector = (state) => {
  const mru = mruSelector(state)
  const user = userSelector(state)
  const organizations = organizationsSelector(state)
  return unionBy(mru, [user], organizations, (account) => (account.id))
}

const mapStateToProps = (state) => ({
  showControl: state.auth.data ? state.auth.data.role === 'admin' || !isEmpty(state.auth.data.organizations) : false,
  account: head(state.account) || {},
  previousAccounts: accountsSelector(state),
  user: userSelector(state),
  organizations: organizationsSelector(state),
})

export default connect(mapStateToProps, { switchAccount })(AccountControl)
