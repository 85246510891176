import PropTypes from 'prop-types'
import React from 'react'
import {Button} from './Button'

export class EmailValidation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {email: ''}
    this.sendValidation = this.sendValidation.bind(this)
  }

  handleClick(email) {
    this.setState({email, formSubmitting: false})
    if (this.props.handleClick) {
      this.props.handleClick(email)
    }
  }

  sendValidation(e) {
    e.preventDefault()

    this.props.sendValidation(this.state.email)
    this.setState({formSubmitting: false})
  }

  renderFields() {
    if (this.props.children) {
      return this.props.children
    }

    this.radios = []

    return this.props.emails.map((email, key) => (
      <div key={key} className="field-radio">
        <label className="radio">
          <input
            type="radio"
            onClick={() => this.handleClick(email)}
            name={this.props.name || 'email'}
            ref={(ref) => this.radios.push(ref)}
            value={email}
          />
          <span>{email}</span>
        </label>
      </div>
    ))
  }

  render() {
    return (
      <article>
        <h2>
          Validação por E-mail
        </h2>
        <p>
          Será enviado um e-mail de validação de domínio para a conta de e-mail padrão
          escolhida na lista abaixo:
        </p>

        {this.renderFields()}

        <p>
          <strong>
            Importante
          </strong>
        </p>

        <ol>
          <li>1. Caso não possua acesso a um desses e-mails acima,
            será necessário realizar a Validação por HTTP ou por DNS;
          </li>

          <li>2. Tenha em mente que nosso e-mail pode ter caído no SPAM.
            Se ainda não recebeu, entre em contato.
          </li>
        </ol>

        <div hidden={this.props.hiddenButton} className="space-button">
          <Button
            loading={this.state.formSubmitting}
            disabled={!this.state.email}
            onClick={this.sendValidation}
          >
            Enviar Email
          </Button>
        </div>
      </article>
    )
  }
}

EmailValidation.defaultProps = {
  emails: [],
}

EmailValidation.propTypes = {
  name: PropTypes.string,
  handleClick: PropTypes.func,
  sendValidation: PropTypes.func,
  emails: PropTypes.array,
  children: PropTypes.object,
  hiddenButton: PropTypes.bool,
  checkedEmail: PropTypes.string,
}
