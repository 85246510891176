import { push } from 'connected-react-router';

import { fetch } from '../../../services/fetch';
import { toParams } from '../../../services/utils';
import { setMessage, SUCCESS, ERROR } from '../../../modules/messages';

export const FETCH_ALL = '@pentest_knowledgebase/FETCH_ALL';
export const FETCH = '@pentest_knowledgebase/FETCH';
export const SAVE = '@pentest_knowledgebase/SAVE';
export const CREATE = '@pentest_knowledgebase/CREATE';
export const DELETE = '@pentest_knowledgebase/DELETE';

export function fetchKnowledgebase(params = {}) {
    const query = toParams(params);
    const request = fetch(`/pentest-knowledgebase${query}`).then(res =>
        res.json()
    );

    return {
        type: FETCH_ALL,
        payload: request,
    };
}

export function fetchKnowledgebaseVulnerability(id) {
    const request = fetch(`/pentest-knowledgebase/${id}`).then(res => res.json());

    return {
        type: FETCH,
        payload: request,
    };
}

export const createKnowledgebaseVulnerability = data => dispatch => {
    const request = fetch('/pentest-knowledgebase', {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(res => {
            dispatch(push('/system/vulnerabilities-pentest'));

            dispatch(setMessage(SUCCESS, 'Vulnerabilidade criada com sucesso!'));

            return res.json();
        })
        .catch(res => res);

    return dispatch({
        type: CREATE,
        payload: request,
    });
};

export function editKnowledgebaseVulnerability(id, data) {
    return dispatch => {
        const request = fetch(`/pentest-knowledgebase/${id}`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => {
            dispatch(push('/system/vulnerabilities-pentest'));

            dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'));

            return res.json();
        });

        return dispatch({
            type: SAVE,
            payload: request,
        });
    };
}

export function deleteKnowledgebaseVulnerability(id) {
    return (dispatch) => {
        const request = fetch(`/pentest-knowledgebase/${id}`, { method: 'DELETE' })
            .then(() => {
                dispatch(push('/system/vulnerabilities-pentest'));

                dispatch(setMessage(SUCCESS, 'Vulnerabilidade removida com sucesso!'));
            })
            .catch(err => {
                const message = err.message || 'Erro ao remover Vulnerabilidade';

                dispatch(setMessage(ERROR, message));

                throw err;
            });

        dispatch({
            type: DELETE,
            payload: request,
        });
    };
}
