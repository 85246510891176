import { css } from 'styled-components';

import { $fontSizeLarge, $darkgrey500, $yellow400, $lightred400, $petroleum400 } from '../settings/variables';

export default css`
    .filter-box {
        display: flex;
        border-bottom: .3rem solid #74b61b;
        cursor: pointer;
        transition: all .5s ease-out;
    }

    .filter-box:hover, .filter-box.active {
        border-bottom: .3rem solid #21272e;
        /* bg-page copy: */
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.12), 0 2px 10px 0 rgba(0,0,0,0.24);

        opacity: 1;
    }

    .filter-box.unfocused {
        opacity: .6;
    }

    .filter-box.unfocused:hover,
    .filter-box.unfocused.active {
        opacity: 1;
    }

    .filter-box > .number {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 20%;
        padding: 0 0.7rem;
        font-size: 4.5rem;
    }

    .filter-box > .text {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        font-size: ${$fontSizeLarge};
        width: 70%;
    }

    /* Border Colors */
    .filter-box.expired {
        border-bottom-color: ${$darkgrey500};
    }

    .filter-box.expiring {
        border-bottom-color: ${$yellow400};
    }

    .filter-box.vulnerable {
        border-bottom-color: ${$lightred400};
    }

    .filter-box.unshielded {
        border-bottom-color: ${$petroleum400};
    }
`;
