import PropTypes from 'prop-types'
import React from 'react'
import TweenMax from 'gsap'

export class SwitchButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { enabled: !!this.props.enabled }
  }

  componentDidMount() {
    this.setStyle(0)
  }

  setStyle = (speed) => {
    const cx = this.state.enabled ? 30 : 10
    const fill = this.state.enabled ? '#74B61B' : '#F3F3F3'
    const fillBar = this.state.enabled ? '#74B61B' : '#ccc'

    TweenMax.to(this.refs.circle, speed, { attr: { cx }, fill })
    TweenMax.to(this.refs.bar, speed, { fill: fillBar })
  }

  handleClick = () => {
    this.setState(oldState => ({ ...oldState, enabled: !oldState.enabled }), () => {
      this.setStyle(0.3)
      if (this.props.onClick) {
        this.props.onClick(this.state.enabled)
      }
    })
  }

  render() {
    return (
      <svg
        height="25"
        viewBox="-5 0 50 26"
        onClick={this.handleClick}
        style={{ cursor: 'pointer' }}
        className={`switch-button ${this.state.enabled ? 'enabled' : 'disabled'}`}
      >
        <defs>
          <circle id="b" cy="10" r="10" ref="circle" />
          <filter x="-30%" y="-20%" width="160%" height="160%" filterUnits="objectBoundingBox" id="a">
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.237601902 0" in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            />
            <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
            <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter2"
              result="shadowMatrixOuter2"
            />
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1" />
              <feMergeNode in="shadowMatrixOuter2" />
            </feMerge>
          </filter>
        </defs>

        <g fill="none" fillRule="evenodd">
          <path d="M34 12c0 4-3 7-7 7H7c-4 0-7-3-7-7s3-7 7-7h20c4 0 7 3 7 7z" opacity=".5" ref="bar" />
          <g transform="translate(-3 2)">
            <use filter="url(#a)" xlinkHref="#b" />
            <use xlinkHref="#b" />
          </g>
        </g>
      </svg>
    )
  }
}

SwitchButton.defaultProps = {
  enabled: false,
}

SwitchButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
}
