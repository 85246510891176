import React, { useEffect } from 'react'
import { FormLogin } from '../../containers'
import { removeFreshserviceElements } from '../../middlewares/freshServices'

export const Login = () => {
  useEffect(() => {
    if (window.top !== window.self) {
      window.top.location.href = document.location.href;
    }

    removeFreshserviceElements();
  })

  return (
    <FormLogin />
  )
}
