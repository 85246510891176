import {useEffect} from 'react'
import {FormDomain} from '../../containers'
import {Card} from "@mui/material";

export const NewDomain = (props) => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <div className="card-content">
          <FormDomain/>
        </div>
      </Card>
    </section>
  )
}
