import React, { useEffect, memo, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Content } from "../../components";

export function IndexManuals(props) {
    const [title, setTitle] = useState("");
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
      if (location.pathname === '/manuals') {
        history.push('manuals/list')
      }
      if (location.pathname === '/manuals/') {
        history.push('list')
      }
    }, [])

    return (
        <Content title={title} {...props}>
            <Switch>
                {props.routes.map((route, index) => (
                    <Route
                        key={index}
                        exact={route.exact}
                        path={route.path}
                        component={memo((props) => {
                            return (
                                <route.component
                                    routes={route.routes}
                                    title={route.name}
                                    setTitle={setTitle}
                                    {...props}
                                />
                            );
                        })}
                    />
                ))}
            </Switch>
        </Content>
    );
}
