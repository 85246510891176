import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'react-recompose';
import head from 'lodash/head';

export const withAccount = compose(
    connect(({ account }) => ({ account: head(account) || {} })),
    branch(props => !props.account.id, renderNothing, t => t),
);

export const withAccountId = compose(
    connect(({ account }) => ({ accountId: (head(account) || {}).id })),
    branch(props => !props.accountId, renderNothing, t => t),
);
