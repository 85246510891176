import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

export const QRCodeGenerator = ({ label, secret, issuer, type }) => (
    <div className="qr-code">
        <QRCode value={`otpauth://${type}/${label}?secret=${secret}&issuer=${encodeURI(issuer)}`} />
        <span>ou utilize o código abaixo:<br /><strong>{secret}</strong></span>
    </div>
);

QRCodeGenerator.defaultProps = {
    type: 'totp',
};

QRCodeGenerator.propTypes = {
    label: PropTypes.string,
    secret: PropTypes.string,
    issuer: PropTypes.string,
    type: PropTypes.string,
};
