import {onlyAdmin} from "../services/auth";
import React from "react";
import PropTypes from "prop-types";

const AccountOriginText = onlyAdmin(props => {
        if (props.origin === 'COMMERCIAL') {
            return <small style={{marginTop: 5, color: "#00819c"}}>{props.origin}</small>
        } else {
            return <small style={{marginTop: 5, color: '#9c0084'}}>{props.origin}</small>
        }

        return <small style={{marginTop: 5, color: '#999999'}}>NÃO INFORMADO</small>;
    }, props => (<small></small>)
)

AccountOriginText.propTypes = {
    origin: PropTypes.string,
}

export default AccountOriginText;
