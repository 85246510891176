import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { openDialog } from '../actions/dialog';

const DialogButton = (props) => (
    <a
        href='/#'
        className={props.className}
        onClick={() => props.dispatch(openDialog(props.component))}
    >
        {props.children}
    </a>
);

DialogButton.propTypes = {
    openDialog: PropTypes.func,
    component: PropTypes.object,
    children: PropTypes.string,
};

const mapStateToProps = ({ dispatch }) => ({ dispatch });

export default connect(mapStateToProps)(DialogButton);
