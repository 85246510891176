import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Icon } from '../components'
import { closeDialog } from '../actions/dialog'
import classnames from 'classnames'

export class Dialog extends React.Component {
  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        this.props.closeDialog()
      }
    })
  }

  render() {
    return (
      <section
        className={classnames('dialog', { open: !!this.props.isOpen })}
      >
        <article className="content-inner">
          <a
            className="close"
            onClick={this.props.closeDialog}
          >
            <Icon name="close" />
          </a>

          {this.props.content}
        </article>
      </section>
    )
  }
}

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  dialog: PropTypes.object,
  closeDialog: PropTypes.func,
  content: PropTypes.any,
}

const mapStateToProps = ({ dialog }) => ({ ...dialog })

export default connect(mapStateToProps, { closeDialog })(Dialog)
