import { css } from 'styled-components';

import { $safeAreaH } from '../settings/variables';

export default css`
    .waf-banner {
        height: ${$safeAreaH};
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        width: 100%;
        padding: 7rem 0rem;
    }

    .waf-banner > .content-inner {
        display: flex;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        z-index: 10;
        width: 70rem;
        margin-left: 5rem;
    }

    .waf-banner > .content-inner > .title {
        display: flex;
        align-items: center;
        margin-left: 5rem;
    }

    .waf-banner > .content-inner > .title h1 {
        margin: 0 0 0 0;
        font-weight: 400;
        font-size: 4rem;
        color: white;
    }

    .waf-banner > .content-inner > .title .icon-circle {
        margin-right: 2rem;
        height: 34px;
        width: 34px;
    }

    .waf-banner > .content-inner > .title .sb2wicon {
        margin-right: 0;
        height: 26px;
        width: 26px;
    }

    .waf-banner > .content-inner > .description {
        margin-left: 10.4rem;
    }

    .waf-banner > .content-inner > .description p {
        margin-bottom: 0.5vw;
        font-size: 2.5rem;
        font-weight: 300;
        color: white;
    }

    .waf-banner > .content-inner > .description .slogan {
        margin-bottom: 0.5vw;
        font-size: 2rem;
        font-weight: 100;
        color: white;
    }

    .waf-banner > .content-inner > .description h2 {
        color: #74b61b;
    }

    .waf-description {
        margin: 2rem 2rem 2rem 2rem;
        text-align: center;
        padding: 2rem 2rem 2rem 2rem;
    }

    .waf-description h2 {
        color: #5EA45D;
        font-size: 3rem;
        font-weight: 400;
    }

    .waf-description > p {
        font-size: 1.5rem;
        max-width: 70%;
        margin: 4rem 15% 4rem 15%;
        color: #484848;
    }

    .waf-description .row-icon {
        margin: 4rem 8rem 0rem 8rem;
    }

    .waf-description .row-icon .title {
        max-width: 25rem;
    }

    .waf-description .row-icon .title > p {
        color: #5EA45D;
        margin: 3rem 0 0 0;
        font-weight: 300;
    }

    .waf-explanation {
        background-color: #21272e;
        margin: 0 2rem 0 2rem;
        padding: 8rem 10% 4rem 10%;
        border-radius: 2.5px 2.5px 0 0;
    }

    .waf-explanation .row-waf {
        border-radius: 2.5px 2.5px 0 0;
    }

    .waf-explanation .row-waf > .waf-items {
        border-radius: 2.5px;
        border-color: #21272e;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
        border-radius: 2.5px 2.5px 2.5px 2.5px;
        align-items: center;
        text-align: center;
        background-color: #ffffff;
    }

    .waf-explanation .row-waf > .waf-items > .header-waf-items {
        background-color: #5EA45D;
        margin: 0 -1.5rem 0 -1.5rem;
        border-radius: 2.5px 2.5px 2.5px 2.5px;
        min-height: 14rem;
        padding: 2rem 0 0 3rem;
    }

    .waf-explanation .row-waf > .waf-items > .header-waf-items .sb2wicon {
        height: 2em;
        width: 2em;
    }

    .waf-explanation .row-waf > .waf-items > .header-waf-items > .title {
        margin-top: 1rem;
        color: #ffffff;
        font-weight: 200;
    }

    .waf-explanation .row-waf > .waf-items > .content-waf-items {
        text-align: center;
        max-width: 70%;
        margin: 2rem 15% 2rem 15%;
        min-height: 45rem;
    }

    .waf-explanation .row-waf > .waf-items > .content-waf-items > ul {
        margin: 2rem 0 0 0;
        color: #888888;
        line-height: 30px;
    }

    .waf-explanation .row-waf > .waf-items > .header-item-2 {
        min-height: 16rem;
        margin-top: -2rem;
        background-color: #7DC671;
        padding: 4rem 0 0 0;
    }

    .waf-explanation .row-waf > .waf-items > .content-item-2 {
        min-height: 47rem;
    }

    .waf-explanation .row-waf > .waf-items > .footer-items {
        padding: 0 0 2rem 0;
    }

    .modal-waf {
        text-align: center;
    }

    .modal-waf > .row > .title {
        color: #7DC671;
    }

    .modal-waf > .row > .title > h2 {
        color: #7DC671;
    }

    .modal-waf > .row > .title > .sb2wicon {
        height: 48px;
        width: 48px;
    }

    .modal-waf > .title {
        text-align: center;
    }

    .WafDescription {
        text-align: center;
        padding: 5rem 10rem 0rem 10rem;
    }

    .WafDescription > div .button-primary {
        margin: 5rem 0 3rem 0;
    }

    .color-primary {
        color: #5EA45D;
    }

    .mt-5 {
        margin-top: 5rem;
    }

    .mb-5 {
        margin-bottom: 5rem;
    }

    .mt-4 {
        margin-top: 4rem;
    }

    .mb-4 {
        margin-bottom: 4rem;
    }

    .mt-3 {
        margin-top: 3rem;
    }

    .mb-3 {
        margin-bottom: 3rem;
    }

    .mt-2 {
        margin-top: 2rem;
    }

    .mb-2 {
        margin-bottom: 2rem;
    }

    .text-left {
        text-align: left;
    }

    .m-0 {
        margin: 0 !important;
    }

    .text-center {
        text-align: center !important;
    }

    .font-weight-bold {
        font-weight: bold !important;
    }

    p.title {
        font-weight: bold !important;
        font-size: 16px;
        margin-top: 10px !important;
    }

    .text-danger {
        color: darkred !important;
    }
`;
