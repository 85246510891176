import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export const WizardSteps = ({ steps }) => (
    <div className="wizard-steps">

        {steps.map((step, number) => (
            <React.Fragment key={number}>
                <div className={classnames('step', { active: step.active })}>
                    <span className="stepNumber">{number + 1}</span>
                    <span>{step.title}</span>
                </div>
                <span className="separator"></span>
            </React.Fragment>
        ))}

    </div>
);

WizardSteps.defaultProps = {
    steps: [
        { title: 'Lorem', active: true },
        { title: 'Lorem', active: false },
        { title: 'Lorem', active: false },
    ],
};

WizardSteps.propTypes = {
    steps: PropTypes.array.isRequired,
};
