import {combineReducers, configureStore} from '@reduxjs/toolkit'
import monitorReducersEnhancer from './enhancers/monitorReducer';
import promise from 'redux-promise-middleware'
import thunkMiddleware from 'redux-thunk'
import {rootReducer} from "./reducers";
import {createBrowserHistory} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router'
// import zendeskMiddleware from './middlewares/zendesk';
import freshserviceMiddleware from './middlewares/freshServices';

export const history = createBrowserHistory();

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ...rootReducer
});

const middlewares = process.env.NODE_ENV !== 'production' ?
  [
    routerMiddleware(history),
    thunkMiddleware,
    promise,
    // zendeskMiddleware,
    freshserviceMiddleware
  ] :
  [
    routerMiddleware(history),
    thunkMiddleware,
    promise,
    // zendeskMiddleware,
    freshserviceMiddleware
  ];

const enhancers = process.env.NODE_ENV !== 'production' ? [monitorReducersEnhancer] : []

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...middlewares),
  enhancers: enhancers,
})

export default store
