import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { IconSbWaf } from '../../sb2w-icons/sb';
import { createLeadWAF } from '../../actions/waf';
import { connect } from 'react-redux';
import { withAccountId } from '../AccountContainer';
import {Button} from "../../components";

const customStyles = {
    content: {
        width: '40%',
        top: '30%',
        left: '52%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('body');

class ModalWAF extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onCreate = this.onCreate.bind(this);
    }
    componentWillMount() {
        Modal.setAppElement('body');
    }

    onCreate() {
        this.props.createLeadWAF(this.props.accountId, this.props.plan);
        this.closeModal();
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    render() {
        return (
            <div>
                <Button onClick={this.openModal}>
                    Quero Contratar
                </Button>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="modal-waf" ref={subtitle => { this.subtitle = subtitle; }}>
                        <div className="row row-icon">
                            <div className="col-md-12 col-xs-6 right">
                                <a href='/#' className="close right" onClick={this.closeModal}>
                                    <i className="material-icons close right">close</i>
                                </a>
                            </div>
                            <div className="col-md-12 col-xs-6 title">
                                <IconSbWaf fill="#74b61b" />
                                <h3>{this.props.plan}</h3>
                                <p>{this.props.size}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row row-icon WafDescription">
                        <div className="col-md-12 col-xs-12">
                            <p className="textWaf"> Você selecionou o plano <strong>{this.props.plan} </strong>
                            que é mais indicado para: {this.props.description}, clique em confirmar para proseguir.
                            </p>
                            <Button onClick={this.onCreate}>
                                Confirmar
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

ModalWAF.propTypes = {
    plan: PropTypes.string,
    size: PropTypes.string,
    description: PropTypes.string,
    createLeadWAF: PropTypes.func,
    accountId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ waf }) => ({ waf });

export default connect(mapStateToProps, { createLeadWAF })(withAccountId(ModalWAF));
