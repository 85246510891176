import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableWrap, TableWrapActions, TableWrapLeft, CountryFlags, Icon } from '../../components';
import { fetchVulnerabilities } from '../../actions/vulnerabilities';
import { formatDate } from '../../services/utils';

class VulnerabilitiesList extends React.PureComponent {
    // eslint-disable-next-line no-undef
    model = {
        provider: {
            name: 'Provider',
        },
        'family.title': {
            name: 'Família',
            style: { textAlign: 'left' },
        },
        title: {
            name: 'Titulo',
            renderer: (item, obj) => (
                <React.Fragment>
                    <span>{obj.title}</span><br />
                    <span><i>{obj.title_pt}</i></span>
                </React.Fragment>
            ),
            style: { textAlign: 'left' },
        },
        updated: {
            name: 'Atualizado em',
            renderer: formatDate,
        },
        translate: {
            name: 'Tradução',
            renderer: this.checkLanguages,
        },
        reviewed: {
            name: 'Revisado',
            filterItems: {
                y: 'Revisado',
                n: 'Não Revisado',
            },
            renderer: item => (!!item ? (
                <Icon name="check" className="check" />
            ) : <Icon name="block" style={{ color: '#ccc' }} />),
        },
        actions: {
            name: ' ',
            renderer: (item, obj) => (
                <Link to={`/system/vulnerabilities/${obj.id}`}>
                    Editar
                </Link>
            ),
        },
    }

    checkLanguages(item, obj) {
        const en = !!obj.title
        && !!obj.description
        && !!obj.consequence
        && !!obj.solution
        && !!obj.how_it_works;

        const pt = !!obj.title_pt
        && !!obj.description_pt
        && !!obj.consequence_pt
        && !!obj.solution_pt
        && !!obj.how_it_works_pt;

        return (
            <CountryFlags
                en={en}
                pt={pt}
            />
        );
    }

    render() {
        return (
            <TableWrap
                model={this.model}
                source={this.props.vulnerabilities}
                location={this.props.location}
                actionFetch={this.props.fetchVulnerabilities}
                hasSearch
            >
                <TableWrapActions>

                    <TableWrapLeft>
                        Lista de vulnerabilidades
                    </TableWrapLeft>

                </TableWrapActions>
            </TableWrap>
        );
    }
}

VulnerabilitiesList.propTypes = {
    vulnerabilities: PropTypes.object,
    location: PropTypes.object,
    fetchVulnerabilities: PropTypes.func,
};

const mapStateToProps = ({ vulnerabilities }) => ({ vulnerabilities });

export default connect(mapStateToProps, { fetchVulnerabilities })(VulnerabilitiesList);
