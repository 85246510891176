import PropTypes from 'prop-types'
import React from 'react'
import { Card } from '../../components'
import { CertificateOrdersList } from '../../containers'

export class Certificates extends React.Component {

  constructor(props) {
    super(props)
    this.PageTitle = 'Certificados'
  }

  componentDidMount() {
    this.props.setTitle &&
      this.props.setTitle(this.PageTitle)
  }

  render() {
    return (
      <section className="content">
        <Card>
          <CertificateOrdersList {...this.props} />
        </Card>
      </section>
    )
  }
}

Certificates.propTypes = {
  setTitle: PropTypes.func,
}
