import { fetch } from '../services/fetch';

export const FETCH = 'business-categories/FETCH';

export const fetchBusinessCategories = () => {
    const request = fetch('/business-categories').then(res => res.json());
    return {
        type: FETCH,
        payload: request,
    };
};
