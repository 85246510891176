import { fetch } from '../services/fetch'
import { push } from 'connected-react-router'
import { setMessage, SUCCESS, ERROR } from './messages'

// actions

const FETCH_DOMAIN = 'sb/domainValidation/FETCH_DOMAIN'
const VALIDATE_DOMAIN = 'sb/domainValidation/VALIDATE_DOMAIN'
const SEND_EMAIL = 'sb/domainValidation/SEND_EMAIL'

// initial state

const INITIAL_STATE = {
  domain: null,
  method: null, // 'email', 'http', 'dns'
  validating: false,
  validated: null,
  errorMessage: null,
}

// reducer

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case `${FETCH_DOMAIN}_FULFILLED`: {
      const domain = action.payload
      const validated = domain.validated

      return { ...state, domain, validated, errorMessage: null, isLoading: false }
    }

    case `${FETCH_DOMAIN}_PENDING`: {
      return { ...state, isLoading: true }
    }

    case `${SEND_EMAIL}_PENDING`: {
      return { ...state, validating: true, errorMessage: null }
    }

    case `${SEND_EMAIL}_FULFILLED`: {
      return { ...state, validating: false, errorMessage: null }
    }

    case `${SEND_EMAIL}_REJECTED`: {
      return { ...state, validating: false, errorMessage: action.payload.message }
    }

    case `${VALIDATE_DOMAIN}_PENDING`: {
      return { ...state, validating: true, errorMessage: null }
    }

    case `${VALIDATE_DOMAIN}_FULFILLED`: {
      const domain = action.payload
      const validated = domain.is_validated
      return { ...state, domain, validated, validating: false, errorMessage: null }
    }

    case `${VALIDATE_DOMAIN}_REJECTED`: {
      return { ...state, validating: false, errorMessage: action.payload.message }
    }

    default:
      return state
  }
}

// action creators

export function fetchDomain(domainId) {
  return {
    type: FETCH_DOMAIN,
    payload: fetch(`/domains/${domainId}`).then(res => res.json()),
  }
}

export function sendEmail(domainId, email) {
  return (dispatch) => {
    const request = fetch(`/domains/${domainId}/validation/email/send`,
      { method: 'POST', body: JSON.stringify({ email }) })
      .then(res => {
        // volta pra tela de dominios
        dispatch(push('/devices/domains'))

        // manda mensage de sucesso
        dispatch(setMessage(SUCCESS, `Email enviado para '${email}'`))

        return res
      })
      .catch(err => {
        const message = err.message || 'Erro validando domínio'

        dispatch(setMessage(ERROR, message))
      })
      
    return {
      type: SEND_EMAIL,
      payload: request,
    }
  }
}

function validate(method, domainId, body) {
  return (dispatch) => {
    const request = fetch(`/domains/${domainId}/validation/${method}`, { method: 'POST', body })
      .then(res => res.json())
      .then(domain => {
        if (domain.is_validated) {
          // volta pra tela de dominios
          dispatch(push('/devices/domains'))

          // manda mensagem de sucesso
          dispatch(setMessage(SUCCESS, `Domínio '${domain.name}' validado com sucesso`))
        } else {
          dispatch(setMessage(ERROR, `Domínio '${domain.name}' não validado`))
        }
        return domain
      })
      .catch(() => {
        const message = 'Erro validando domínio'
        dispatch(setMessage(ERROR, message))

        throw message
      })

    dispatch({
      type: VALIDATE_DOMAIN,
      payload: request,
    })
  }
}

export function validateEmail(domainId, token, hash) {
  return validate('email', domainId, JSON.stringify({ token, hash }))
}

export function validateDns(domainId) {
  return validate('dns', domainId)
}

export function validateHttp(domainId) {
  return validate('http', domainId)
}

export function validateStaff(domainId) {
  return validate('staff', domainId)
}
