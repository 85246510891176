// eslint-disable-next-line
import symbolObservable from 'symbol-observable';

import React from "react";
import App from './app/app'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {Provider, ReactReduxContext} from "react-redux";
import store, {history} from './app/store';
import {ConnectedRouter} from "connected-react-router";
import { createRoot } from 'react-dom/client';


const container = document.getElementById('main');
const main = createRoot(container);

Sentry.init({
  dsn: "https://f0e13f9c80aa4f909a89a4913f7542a9@o458286.ingest.sentry.io/6761028",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
console.log(symbolObservable);

main.render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <App history={history} context={ReactReduxContext}/>
    </ConnectedRouter>
  </Provider>
)
