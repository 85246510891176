import { css } from 'styled-components';

export default css`
    .dialog {
        visibility: hidden;
        opacity: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 90;
        transition: all .5s ease-out;
    }

    .dialog.open {
        visibility: visible;
        opacity: 1;
    }

    .dialog > .content-inner {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 50vw;
        background-color: #fff;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.24), 0 9px 18px 0 rgba(0, 0, 0, 0.5);
        padding: 2rem;
        transition: all .5s ease-out;
        transform: translate(-50%, -50%);
    }

    .dialog > .content-inner .header {
        position: relative;
        display: flex;
        margin-bottom: 1.5rem;
        max-width: 90%;
    }

    .dialog > .content-inner .header > .icon-circle {
        display: inline-flex;
    }

    .dialog > .content-inner .header > .title {
        display: inline-flex;
        margin: 0 0 0 1rem;
        font-weight: bold;
        align-self: center;
    }

    .dialog > .content-inner > .close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        z-index: 9;
        cursor: pointer;
        line-height: normal;
        height: 3rem;

    }

    .dialog > .content-inner > .close i {
        font-size: 3rem;
    }

    .dialog > .content-inner p {
        margin-bottom: 1rem;
    }
`;
