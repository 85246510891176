import PropTypes from 'prop-types'
import React from 'react'
import { ValidationChoices } from '../../containers'

export class DomainValidation extends React.Component {
  constructor(props) {
    super(props)
    this.PageTitle = 'Dispositivos'
  }

  componentDidMount() {
      this.props.setTitle(this.PageTitle);
  }

  render() {
    return (
      <section className="content">
        <ValidationChoices {...this.props} />
      </section>
    )
  }
}

DomainValidation.propTypes = {
  setTitle: PropTypes.func,
}
