import PropTypes from 'prop-types'
import React from 'react'
import { Card } from '../../components'
import { DomainsList } from '../../containers'

export class Devices extends React.Component {
  constructor(props) {
    super(props)
    this.title = 'Dispositivos'
  }

  componentDidMount() {
    this.props.setTitle(this.props.title)
  }

  render() {
    return (
      <section className="content">
        <Card>
          <DomainsList {...this.props} />
        </Card>
      </section>
    )
  }
}

Devices.propTypes = {
  setTitle: PropTypes.func,
  location: PropTypes.object,
}
