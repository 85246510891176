import PropTypes from 'prop-types';
import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { formatDate } from '../../services/utils';
import * as css from '../../styles/settings/variables';
import { connect } from 'react-redux';
import { fetchChartUptimeHistory } from '../../actions/uptime-chart';

class UptimeHistoryChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    componentDidMount() {
        this.props.fetchChartUptimeHistory();
    }

    render() {
        const AreaDefault = {
            stackId: '1',
            strokeWidth: '0',
            fillOpacity: 1,
            type: 'monotone',
            isAnimationActive: false,
        };
        return (
            <div className="chart">
                <h1 className="chart-title">Histórico de Uptime</h1>
                <AreaChart
                    width={400}
                    height={230}
                    data={this.props.dataHistory}
                    margin={{ top: 10, right: 5, left: 0, bottom: 0 }}
                >
                    <XAxis type="number" dataKey="x" domain={['dataMin', 'dataMax']} tickFormatter={formatDate} />
                    <YAxis allowDecimals={false} />
                    <CartesianGrid strokeDasharray="1 1" />
                    <Tooltip labelFormatter={formatDate} />
                    <Legend iconType="square" width={420} />
                    <Area dataKey="value[0]" name="Online" fill={css.$level1} stroke={css.$level1} {...AreaDefault} />
                    <Area dataKey="value[1]" name="Offline" fill={css.$level5} stroke={css.$level5} {...AreaDefault} />
                </AreaChart>
            </div>
        );
    }
}

UptimeHistoryChart.propTypes = {
    fetchChartUptimeHistory: PropTypes.func,
    isLoading: PropTypes.bool,
    dataHistory: PropTypes.array,
};

const mapStateToProps = ({ uptimechart }) => ({
    isLoading: uptimechart.isLoading,
    dataHistory: uptimechart.dataHistory,
});

export default connect(mapStateToProps, { fetchChartUptimeHistory })(UptimeHistoryChart);
