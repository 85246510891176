import PropTypes from 'prop-types';
import React from 'react';

export class RadioField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { checked: this.props.checked };
        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        if (nextProps.checked !== this.props.checked) {
            this.setState({ checked: nextProps.checked });
        }
    }

    onChange (e) {
        this.setState({ checked: e.target.value });

        if (this.props.onChange) {
            return this.props.onChange(e.target.value);
        }
    }

    isChecked (value) {
        return this.state.checked === value;
    }

    renderRadios () {
        return this.props.choices.map((choice, id) => (
            <Radio
                key={choice.value}
                label={choice.label}
                value={choice.value}
                name={this.props.name}
                checked={this.isChecked(choice.value)}
                onChange={this.onChange}
                required={id === 0 && this.props.required}
                errors={this.props.errors}
            />
        ));
    }

    render () {
        return (
            <div className="field">
                {this.props.title &&
                    <p>{this.props.title}</p>
                }
                {this.props.label &&
                    <label>{this.props.label}</label>
                }

                {this.renderRadios()}

                {this.props.errors && <span className="input-error">Este campo é obrigatório</span>}
            </div>
        );
    }
}

const Radio = (props) => (
    <label className="radio">
        <input
            type="radio"
            {...props}
        />
        <span className={props.errors ? 'input-error' : ''}>{props.label}</span>
    </label>
);

RadioField.propTypes = {
    checked: PropTypes.string,
    choices: PropTypes.array,
    name: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    errors: PropTypes.object,
};

Radio.propTypes = {
    label: PropTypes.string,
    errors: PropTypes.object,
};
