import PropTypes from 'prop-types'
import React from 'react'
import { Profile } from '../components'
import { connect } from 'react-redux'
import { logout } from '../actions/auth'

const ProfileContainer = (props) => {
    return (
        <Profile
            name={props.name}
            email={props.email}
            onLogout={props.logout}
        />
    )
}

ProfileContainer.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    logout: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({
    name: `${auth.data.user.first_name} ${auth.data.user.last_name || ''}`,
    email: auth.data.user.email,
})

export default connect(mapStateToProps, { logout })(ProfileContainer)
