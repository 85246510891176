import { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { HeaderMenu } from './HeaderMenu'

import { Breadcrumbs, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import routes from '../routes/privateRoutes'
import SBLogo from '../../assets/images/logo-site-blindado.svg';

const useStyles = makeStyles({
  breadcrumbs: {
    fontSize: '1.5rem',
    marginLeft: '1.5rem',
    textTransform: 'uppercase',
  },
  divider: {
    height: 30,
    backgroundColor: 'white',
    marginLeft: '2rem',
  },
  link: {
    fontSize: '1.3rem',
    color: 'white',
    fontWeight: 'bold',
  },
  item: {
    fontSize: '1.5rem',
    color: 'white',
    fontWeight: 'bold',
  },
})

export const Header = (props) => {
  const classes = useStyles()
  const location = useLocation()
  // const history = useHistory()
  const pathnames = location.pathname.split("/").filter(x => x)

  const [currentLocation, setCurrentLocation] = useState('')

  const mainIndex = routes.filter(r => r.path === `/${pathnames[0]}`)

  useEffect(() => {
    if (mainIndex[0]) {
      setCurrentLocation(mainIndex[0].name)
    }
    if (window.top !== window.self) {
      window.top.location.href = document.location.href;
    }
  }, [pathnames, mainIndex])

  return (
    <header className="main-header">
      <div className="brand">
        <Link to="/">
          <img
            src={SBLogo}
            alt="Site Blindado"
          />
        </Link>

        <Divider
          className={classes.divider}
          orientation="vertical"
        />

        <Breadcrumbs
          className={classes.breadcrumbs}
          separator=">"
        >
          {/* {_.isEmpty(pathnames)
            ? <Link
              className={classes.link}
              onClick={() => history.push("/store/site-blindado")}
            >
              Início
            </Link>
            : <Typography
              className={classes.item}
              color="primary"
            >
              Início
            </Typography>
          } */}

          {/* {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`

            const isLast = index === pathnames.length - 1

            return isLast
              ? <Typography
                key={name}
                className={classes.item}
                color="primary"
              >
                {name}
              </Typography>
              : <Link
                key={name}
                className={classes.link}
                onClick={() => history.push(routeTo)}
              >
                {name}
              </Link>
          })} */}

          <Typography
            className={classes.item}
            color="primary"
          >
            {currentLocation}
          </Typography>

          {props.currentPage !== '' &&
            <Typography
              className={classes.item}
              color="primary"
            >
              {props.currentPage}
            </Typography>
          }
        </Breadcrumbs>
      </div>
      <HeaderMenu />
    </header>
  )
}
