import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../Button';

export class InputGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: this.props.value };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
            this.setState({ value: nextProps.value });
        }
    }

    handleClick(e) {
        if (e) {
            e.preventDefault();
        }

        this.props.buttonClick(this.input);
    }

    handleChange(e) {
        this.setState({
            value: e.target.value,
        });
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            this.handleClick();
        }
    }

    render() {
        const { buttonName, ...inputProps } = this.props;
        delete inputProps.buttonClick;

        return (
            <label className="field-group field-group-reverse">
                <input
                    type="text"
                    ref={(input) => { this.input = input; }}
                    className="input"
                    {...inputProps}
                    value={this.state.value}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                />

                <Button onClick={this.handleClick}>
                    {buttonName}
                </Button>
            </label>
        );
    }
}

InputGroup.propTypes = {
    buttonClick: PropTypes.func.isRequired,
    value: PropTypes.string,
    buttonName: PropTypes.string,
};
