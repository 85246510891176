import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { ScanProfileReport } from '../../containers'

export const ScanDetails = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <article className="content">
      <ScanProfileReport
        scanId={props.match.params.id}
        {...props}
      />
    </article>
  )
}

ScanDetails.propTypes = {
  params: PropTypes.object,
}
