import { css } from 'styled-components';

import { $borderRadiusBase, $fontSizeSmall, $linkColor, $bodyFontColor } from '../settings/variables';

export default css`
    .space-button {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    /* TODO: Migrar para .button apos refatoração */
    .button,
    .button-pure,
    .button-primary,
    .button-secondary,
    .button-transparent {
        position: relative;
        display: inline-block;
        border-radius: ${$borderRadiusBase};
        border: none;
        width: auto;
        height: 3rem;
        min-width: auto;
        padding: .5rem 3.5rem;
        cursor: pointer;
        color: #fff;
        font-size: ${$fontSizeSmall};
        text-align: center;
        text-decoration: none;
        text-transform: none;
        outline: none;
        transition: all .2s ease-out;
    }

    .button[disabled] {
        pointer-events: none;
        opacity: .6;
    }

    .button.soft-disabled {
        pointer-events: none;
    }

    .button i {
        position: absolute;
        font-size: 2rem;
        left: .5rem;
        margin-top: -.2rem;
        margin: 0;
    }

    .button-pure {
        background-color: #f3f3f3;
        color: #2D343D;
        border: 1px solid #e3e3e3;
    }

    .button-primary {
        background-color: #74B61B;
        color: #fff !important;
    }

    .button-primary :not(.soft-disabled):hover {
        background-color: #13161C;
    }

    .button-secondary {
        background-color: #13161C;
    }

    .button-secondary[disabled] {
        opacity: .3;
    }

    .button-secondary:not(.soft-disabled):hover {
        background-color: #74B61B;
    }

    .button-transparent {
        background-color: transparent;
        color: ${$linkColor};
        border: none;
    }

    .button-transparent:hover {
        color: ${$bodyFontColor};
        text-decoration: underline;
    }

    .button-scheduling-form {
        position: fixed;
        bottom: 15px;
    }
`;
