import { MODAL_FALSE, MODAL_TRUE } from '../actions/modal';

const INITIAL_STATE = [];

export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODAL_FALSE:
            return { open: false };
        case MODAL_TRUE:
            return { open: true };
        default:
            return {
                ...state,
            };
    }
};
