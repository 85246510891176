import PropTypes from 'prop-types';
import React from 'react';
import { Content } from '../../components';

export class IndexStore extends React.Component {
    constructor(props) {
        super(props);

        this.state = { title: 'Loja' };
        this.submenu = [
            { route: '/store/site-blindado', name: 'Certificados Site Blindado' },
            { route: '/store/trust-sign', name: 'Certificados TrustSign' },
            { route: '/store/blindagem', name: 'Blindagem de Sites' },
            { route: '/store/trial', name: 'Trial', beta: true },
            { route: '/store/products', name: 'Meus Produtos' },
        ];
        this.setTitle = this.setTitle.bind(this);
    }

    setTitle(title) {
        this.setState({ title });
        this.props.setTitle(title);
    }

    render() {
        return (
            <div>
                <Content submenu={this.submenu} title={this.state.title} {...this.props}>
                    {this.props.children && React.cloneElement(this.props.children, {
                        setTitle: this.setTitle,
                    })}
                </Content>
            </div>
        );
    }
}

IndexStore.propTypes = {
    children: PropTypes.element.isRequired,
    setTitle: PropTypes.func,
};
