import PropTypes from 'prop-types'
import { StoreCatalog } from '../../containers'

export const RequestCertificate = (props) => {
  const { params } = props.match

  return (
    <StoreCatalog catalog={params.catalog} />
  )
}

RequestCertificate.propTypes = {
  params: PropTypes.object,
}
