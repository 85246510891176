import { Security } from '@material-ui/icons'
import { useEffect } from 'react'
import { Button, Card } from '../../components'

const WAFStarter = {
  title: 'WAF STARTER',
  description: 'Provedor: GoCache',
  size: 'ATÉ 1 TB DE TRÁFEGO',
  cdn: 'Nacional',
}
const WAFPro = {
  title: 'WAF PRO',
  description: 'Provedor: Radware',
  size: 'A PARTIR DE 10Mbps',
  cdn: 'Contratada a parte',
}
const WAFEnterprise = {
  title: 'WAF ENTERPRISE',
  description: 'Provedor: Imperva',
  size: 'A CONSULTAR',
  cdn: 'Internacional',
}

export const Waf = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [props])

  return (
    <section className="content">
      <Card className="waf-description">
        <h1 className="mt-2 font-weight-bold text-danger">
          VOCÊ AINDA NÃO POSSUI WAF!
        </h1>
        <p>
          Verificamos que você ainda não possui um produto WAF contratado
          conosco. <br></br>
          Aproveite e adquira para maior proteção do seu website.
        </p>
      </Card>
      <Card className="waf-description">
        <h2 className="mt-2">MAS O QUE É WAF?</h2>
        <p>
          O WAF (Firewall de Aplicação Web) é um serviço de segurança
          baseado em nuvem para websites, e-commerces, intranets,
          extranets, entre outras aplicações. Ele funciona, como um filtro
          entre o site e o restante da Internet e tem como principal
          função proteger ativamente as aplicações web contra ataques e
          tentativas de invasão.<br></br>A Site Blindado oferece três
          perfis de WAF que podem se adequar a realidade de cada cliente.
          Dependendo da solução do WAF pode ser modularizado e
          customizado, de acordo com as necessidades de cada cliente,
          podendo assim proteger a aplicação contra ataques, desde os mais
          comuns e frequentes (Cross-Site Scripting (XSS), SQL Injection,
          DDoS, etc.), até os mais complexos e elaborados.
        </p>

        <div className="row">
          <div className="col-md-2 col-xs-6">
            <img alt="icon" src="https://www.siteblindado.com/images/new-icons/ico_cloud.svg" />
            <p className="m-0 mt-1 text-center title">
              Serviço 100% na nuvem
            </p>
          </div>
          <div className="col-md-2 col-xs-6">
            <img alt="icon" src="https://www.siteblindado.com/images/new-icons/ico_locker.svg" />
            <p className="m-0 mt-1 text-center title">Proteção Ativa</p>
          </div>
          <div className="col-md-2 col-xs-6">
            <img alt="icon" src="https://www.siteblindado.com/images/new-icons/ico_cdn.svg" />
            <p className="m-0 mt-1 text-center title">
              Redução de custos de infraestrutura
            </p>
          </div>
          <div className="col-md-2 col-xs-6">
            <img alt="icon" src="https://www.siteblindado.com/images/new-icons/ico_velocimeter.svg" />
            <p className="m-0 mt-1 text-center title">
              Plantão emergencial 24x7*
            </p>
          </div>
          <div className="col-md-2 col-xs-6">
            <img alt="icon" src="https://www.siteblindado.com/images/new-icons/ico_frequency.svg" />
            <p className="m-0 mt-1 text-center title">
              Flexibilidade com planos de acordo com a utilização de
              dados
            </p>
          </div>
          <div className="col-md-2 col-xs-6">
            <img alt="icon" src="https://www.siteblindado.com/images/new-icons/ico_phone.svg" />
            <p className="m-0 mt-1 text-center title">
              Suporte para configurar e operar a ferramenta de forma
              ilimitada
            </p>
          </div>
          <small>* Apenas para plano Enterprise</small>
        </div>
      </Card>
      <Card className="waf-explanation">
        <div className="row row-waf">
          <div className="waf-items waf-item-1 col-md-4 col-xs-12">
            <div className="header-waf-items">
              <Security style={{ color: '#ffffff' }} />

              <h3 className="title">
                {WAFStarter.title}
                <div>{WAFStarter.size}</div>
              </h3>
            </div>

            <div className="content-waf-items">
              <p>
                <strong>{WAFStarter.description}</strong>
              </p>
              <p>
                <strong>CDN: {WAFStarter.cdn}</strong>
              </p>
              <ul>
                <li className="hasIcon">Emissão de SSL gratuita</li>
              </ul>
            </div>
            <div className="footer-items">
              <Button
                onClick={() =>
                  window.open(
                    'https://www.siteblindado.com/solucoes/waf/#quero-ser-blindado',
                    '_blank'
                  )
                }
              >
                Saiba Mais
              </Button>
            </div>
          </div>
          <div className="waf-items waf-item-2 col-md-4 col-xs-12">
            <div className="header-waf-items header-item-2">
              <Security style={{ color: '#ffffff' }} />

              <Security style={{ color: '#ffffff' }} />

              <Security style={{ color: '#ffffff' }} />

              <h3 className="title">
                {WAFEnterprise.title}
                <div>{WAFEnterprise.size}</div>
              </h3>
            </div>
            <div className="content-waf-items content-item-2">
              <p>
                <strong>{WAFEnterprise.description}</strong>
              </p>
              <p>
                <strong>CDN: {WAFEnterprise.cdn}</strong>
              </p>
              <ul>
                <li className="hasIcon">Emissão de SSL gratuita</li>
                <li className="hasIcon">
                  Proteção específica em API
                </li>
                <li className="hasIcon">
                  Proteção avançada contra bots
                </li>
                <li className="hasIcon">
                  Consulta de reputação de IPs
                </li>
                <li className="hasIcon">Notificação de ameaças</li>
                <li className="hasIcon">
                  Configurações avançadas de Cache
                </li>
                <li className="hasIcon">Plantão 24x7</li>
                <li className="hasIcon">Integração com SIEM</li>
                <li className="hasIcon">SLA preferencial</li>
                <li className="hasIcon">Suporte a IPv6</li>
                <li className="hasIcon">Report PCI Compliance</li>
                <li className="hasIcon">
                  Proteção contra Backdoor
                </li>
              </ul>
            </div>
            <div className="footer-items footer-item-2">
              <Button
                onClick={() =>
                  window.open(
                    'https://www.siteblindado.com/solucoes/waf/#quero-ser-blindado',
                    '_blank'
                  )
                }
              >
                Saiba Mais
              </Button>
            </div>
          </div>
          <div className="waf-items waf-item-3 col-md-4 col-xs-12">
            <div className="header-waf-items">
              <Security style={{ color: '#ffffff' }} />

              <Security style={{ color: '#ffffff' }} />

              <h3 className="title">
                {WAFPro.title}
                <div>{WAFPro.size}</div>
              </h3>
            </div>
            <div className="content-waf-items">
              <p>
                <strong>{WAFPro.description}</strong>
              </p>
              <p>
                <strong>CDN: {WAFPro.cdn}</strong>
              </p>
              <ul>
                <li className="hasIcon">
                  Proteção avançada contra bots
                </li>
                <li className="hasIcon">Plantão 24x7</li>
                <li className="hasIcon">Report PCI Compliance</li>
                <li className="hasIcon">
                  Proteção contra Backdoor
                </li>
              </ul>
            </div>
            <div className="footer-items">
              <Button
                onClick={() =>
                  window.open(
                    'https://www.siteblindado.com/solucoes/waf/#quero-ser-blindado',
                    '_blank'
                  )
                }
              >
                Saiba Mais
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </section>
  )
}
