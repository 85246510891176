import useStyles from './styles'
import PropTypes from 'prop-types'

import { MenuItem, TextField } from '@mui/material'
import { DatePicker } from '@mui/lab'
import moment from 'moment'

const FilterField = props => {
  const classes = useStyles()

  switch (props.type) {
    case 'select':
      return (
        <TextField
          {...props}
          className={classes.inputValue}
          color='primary'
          select
          value={props.value || ''}
        >
          <MenuItem value='' style={{ minHeight: 30 }} />

          {props.options && props.options.map((option, index) =>
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          )}
        </TextField>
      )
    case 'date':
      return (
        <DatePicker
          name={props.name}
          label={props.label}
          id={props.name}
          mask='__/__/____'
          onChange={value => props.onChange({ target: { name: props.name, value: moment(value).format('DD-MM-yyyy') } })}
          value={props.value ? moment(props.value, 'DD/MM/YYYY') : null}
          disabled={props.disabled}
          renderInput={(params) =>
            <TextField {...params} helperText={null} style={{ maxWidth: 150 }} defaultValue={null} />
          }
        />
      )
    case 'custom':
      return props.children
    default:
      return (
        <TextField {...props} value={props.value || ''} className={classes.inputValue} color='primary' />
      )
  }
}

FilterField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  type: PropTypes.string,
}

export default FilterField

