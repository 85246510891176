import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllPentests, fetchSummaryPentests } from '../../actions/pentests'
import { ArrowForward } from '@mui/icons-material'
import SmartTable from '../../components-v2/smart-table'
import { isEmpty } from 'lodash'
import moment from 'moment'

export const PentestSystem = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const pentests = useSelector(({ pentests }) => pentests)
  const summary = useSelector(({ pentestSummary }) => pentestSummary)
  const accountId = useSelector(({ account }) => account[0] ? account[0].id : undefined)

  const actionFetch = async params => dispatch(fetchAllPentests(params))

  useEffect(() => {
    actionFetch()
    isEmpty(summary.filters) &&
      dispatch(fetchSummaryPentests(accountId))
  }, [])

  const model = {
    ticket_number: {
      name: 'Chamado',
      field: rowData => rowData.ticket_number
    },
    url: {
      name: 'URL',
      field: rowData => rowData.url
    },
    status: {
      name: 'Status',
      field: rowData => summary.filters.status && summary.filters.status.find(r =>
        r.value === rowData.status).label
    },
    modality: {
      name: 'Tipo',
      field: rowData => summary.filters.modality && summary.filters.modality.find(r =>
        r.value === rowData.modality).label
    },
    pentester: {
      name: 'Pentester',
      field: rowData => summary.filters.pentester && summary.filters.pentester.find(r =>
        r.value === rowData.pentester).label
    },
    'exec_start': {
      name: 'Data de início',
      field: rowData => moment(rowData.exec_start).format('DD/MM/YYYY')
    },
    action: {
      name: '',
      field: rowData => (
        <Tooltip title='Relatório'>
          <IconButton
            size="large"
            onClick={() =>
              history.push(`/pentest/${rowData.id}/report/${rowData.report_id}`)}
          >
            <ArrowForward style={{ fontSize: '2rem' }} color='primary' />
          </IconButton>
        </Tooltip>
      ),
    },
  }

  const filterModule = {
    ticket_number: {
      label: 'Chamado',
      type: 'number',
    },
    url: {
      label: 'URL',
      type: 'text',
    },
    status: {
      label: 'Status',
      type: 'select',
      options: summary.filters.status,
    },
    modality: {
      label: 'Tipo',
      type: 'select',
      options: summary.filters.modality,
    },
    pentester: {
      label: 'Pentester',
      type: 'select',
      options: summary.filters.pentester,
    },
    exec_start: {
      label: 'Data de início',
      type: 'date',
    },
  }

  return (
    <section className="content">
      <SmartTable
        accountId={accountId}
        actionFetch={actionFetch}
        model={model}
        source={pentests}
        filterModule={filterModule}
        handleFilter={params => actionFetch(params)}
      />
    </section>
  )
}
