import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Wizard } from '../components';
import { nextStep, prevStep } from '../actions/wizard';

const WizardContainer = (props) => (
    <Wizard
        prevStep={props.prevStep}
        nextStep={props.nextStep}
        onSubmit={props.onSubmit}
        step={props.step}
    >
        {props.children}
    </Wizard>
);

WizardContainer.propTypes = {
    prevStep: PropTypes.func,
    nextStep: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.any,
    step: PropTypes.number,
};

const mapStateToprops = ({ wizard }) => ({ step: wizard.step });

export default connect(mapStateToprops, { nextStep, prevStep })(WizardContainer);
