import { css } from 'styled-components';

export default css`
    .accordion > .header {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .accordion > .header > .icon {
        margin-right: 1rem;
        font-size: 1.8rem;
        font-weight: bold;
        width: 2rem;
        text-align: center;
        line-height: 4rem;
    }
    .accordion > .header > .icon > span {
        display: block;
    }
    .accordion > .header > .title {
        font-weight: bold;
        font-size: 1.6rem;
    }
    .accordion > .container-box {
        overflow: hidden;
    }
    .accordion > .container-box > .content-box {
        padding: 2rem 2rem 2rem 4.5rem;
    }
`;

