import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { TextField, CountryField, Icon } from '../../components';
import { seekAddress } from '../../actions/settings';

const AddressFields = (props) => (
    <article className="field-address">
        <div className="row">
            <div className="col-md-4">
                {props.isLoading &&
                    <Icon className="cep-loading looping" name="autorenew" />
                }
                <Field
                    label="CEP"
                    name="address.zipcode"
                    handleBlur={props.seekAddress}
                    component={TextField}
                />
            </div>
            <div className="col-md-8">
                <Field
                    label="Endereço"
                    name="address.street"
                    component={TextField}
                    disabled={props.isLoading}
                />
            </div>
        </div>

        <div className="row">
            <div className="col-md-4">
                <Field
                    label="Número"
                    name="address.number"
                    component={TextField}
                />
            </div>
            <div className="col-md-4">
                <Field
                    label="Complemento"
                    name="address.complement"
                    component={TextField}
                />
            </div>
            <div className="col-md-4">
                <Field
                    label="Bairro"
                    name="address.district"
                    component={TextField}
                    disabled={props.isLoading}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <Field
                    label="Cidade"
                    name="address.city"
                    component={TextField}
                    disabled={props.isLoading}
                />
            </div>
            <div className="col-md-2">
                <Field
                    label="Estado"
                    name="address.state"
                    component={TextField}
                    disabled={props.isLoading}
                    maxLength="2"
                    normalize={value => value.toUpperCase()}
                />
            </div>
            <div className="col-md-4">
                <label htmlFor="">País</label>
                <Field
                    name="address.country"
                    component={CountryField}
                />
            </div>
        </div>
    </article>
);

AddressFields.propTypes = {
    street: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    district: PropTypes.string,
    zipcode: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    seekAddress: PropTypes.func,
    isLoading: PropTypes.bool,
};

const mapStateToProps = ({ form }) => {
    const currentForm = Object.keys(form).filter(key => form[key].registeredFields).shift();

    return {
        isLoading: form[currentForm] ? form[currentForm].addressLoading : false,
    };
};

export default connect(mapStateToProps, { seekAddress })(AddressFields);
