import { fetch } from '../../../services/fetch';
import { setMessage, SUCCESS, ERROR } from '../../../modules/messages';

export const FETCH_PENTEST_EVIDENCE = '@pentests/FETCH_PENTEST_EVIDENCE';
export const CREATE_PENTEST_EVIDENCE = '@pentests/CREATE_PENTEST_EVIDENCE';
export const UPDATE_PENTEST_EVIDENCE = '@pentests/UPDATE_PENTEST_EVIDENCE';

export function fetchPentestEvidence(accountId, pentestId, reportId, vulnerabilityId, evidenceId) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/` +
        `${reportId}/vulnerability/${vulnerabilityId}/evidence/${evidenceId}`;

    const request = fetch(url).then(res => res.json());

    return {
        type: FETCH_PENTEST_EVIDENCE,
        payload: request,
    };
}

export function createPentesttEvidence(accountId, pentestId, reportId, vulnerabilityId, data) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/` +
        `${reportId}/vulnerability/${vulnerabilityId}/evidence`;

    return (dispatch) => {
        const request = fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(res => {
            dispatch(setMessage(SUCCESS, 'Evidência cadastrada com sucesso!'));

            return res.json();
        }).catch(err => {
            const message = err.message || 'Erro ao cadastrar Evidência';

            dispatch(setMessage(ERROR, message));

            throw err;
        });

        dispatch({
            type: CREATE_PENTEST_EVIDENCE,
            payload: request,
        });
    };
}
export function updatePentesttEvidence(accountId, pentestId, reportId, vulnerabilityId, evidenceId, data) {
    const url = `/accounts/${accountId}/pentests/${pentestId}/report/` +
        `${reportId}/vulnerability/${vulnerabilityId}/evidence/${evidenceId}`;

    return (dispatch) => {
        const request = fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(res => {
            dispatch(setMessage(SUCCESS, 'Evidência atualizada com sucesso!'));

            return res.json();
        }).catch(err => {
            const message = err.message || 'Erro ao atualizar Evidência';

            dispatch(setMessage(ERROR, message));

            throw err;
        });

        dispatch({
            type: UPDATE_PENTEST_EVIDENCE,
            payload: request,
        });
    };
}
