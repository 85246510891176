import { css } from 'styled-components';

export default css`
    .products h1 {
        text-align: center;
    }

    .products li {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding: 1.3rem 0;
    }

    .products li > span {
        width: 18rem;
    }

    .products .table td, th:not(:first-child) {
        text-align: center;
    }
    .products .table td, th:last-child {
        text-align: right;
    }

    .products .items {
        margin-top: 1rem;
    }

    .products .items > .right {
        margin-top: .5rem;
    }

    .products > .empty {
        text-align: center;
        font-size: 2rem;
        color: #999;
        padding: 3rem 0;
    }

    .products.summary {
        width: 60rem;
        min-height: 25rem;
        padding: 0 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;
