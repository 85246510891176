import PropTypes from 'prop-types'
import React from 'react'
import { Card } from '../../components'
import { ServersList } from '../../containers'

export class Servers extends React.Component {
  constructor(props) {
    super(props)
    this.title = 'Dispositivos'
  }

  componentDidMount() {
      this.props.setTitle(this.title);
  }

  render() {
    return (
      <section className="content">
        <Card>
          <ServersList {...this.props} />
        </Card>
      </section>
    )
  }
}

Servers.propTypes = {
  setTitle: PropTypes.func,
}
