import { useEffect } from "react";
import { OrdersList } from "../../containers";

export const Orders = (props) => {
  useEffect(() => {
    props.setTitle(props.title);
  }, []);

  return (
    <section className="content">
      <OrdersList {...props} />
    </section>
  );
};
