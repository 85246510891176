import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import sum from 'lodash/sum'

import { Link } from 'react-router-dom'
import { SliderButton } from '../../containers'
import { Edit, InsertDriveFile } from '@mui/icons-material'
import moment from 'moment';
import { onlyAdmin } from '../../services/auth';
import { Scheduling } from "./Scheduling";

export const VulnerabilityBar = (props) => {
  const getSize = (value) => (value * 100) / props.range
  return (
    <React.Fragment>
      <div className={classnames('numbers', { 'with-box': !!props.numberInBox })}>
        {Object.keys(props.levels).map((item, key) => !!props.levels[item] &&
          <span
            key={key}
            className={item}
            style={!props.numberInBox ? {
              width: `${getSize(props.levels[item])}%`,
            } : {}}
          >
            {props.levels[item]}
          </span>
        )}
      </div>
      <div className="vulnerability-bar">
        {Object.keys(props.levels).map((item, key) =>
          <span
            key={key}
            className={item}
            style={{ width: `${getSize(props.levels[item])}%` }}
          />
        )}
      </div>
    </React.Fragment>
  )
}

const NotAdminComponent = () => <Edit name="autorenew" fontSize="large" style={{ marginLeft: '0.5rem' }} />;


const ProtectedSliderButton = onlyAdmin(
  (props) => {
    if (props.requiresAdmin) {
      return (
        <Edit name="autorenew" fontSize="large" style={{ marginLeft: '0.5rem' }} />
      )
    } else {
      return (
        <SliderButton
          button={<Edit name="calendar_today" fontSize="large" />}
          sliderTitle={`Editar recorrência de ${props.name}`}
        >
          <Scheduling
            scanProfileId={props.scanProfileId}
            scanType={props.name}
            source={props.source}
          />
        </SliderButton>
      )
    }
  },
  NotAdminComponent
);

VulnerabilityBar.propTypes = {
  levels: PropTypes.objectOf(
    PropTypes.number
  ).isRequired,
  range: PropTypes.number.isRequired,
  numberInBox: PropTypes.bool,
}


export const VulnerabilityLevel = (props) => {
  const dateNextScan = props.runningScan || props.nextScan;
  const { requiresAdmin, name, scanProfileId, source } = props;

  const renderButton = () => {
    if (props.runningScan) {
      return <Edit name="autorenew" fontSize="large" />;
    }

    // Se requer admin e o usuário não é admin, mostre o NotAdminComponent
    if (requiresAdmin) {
      return <ProtectedSliderButton name={name} scanProfileId={scanProfileId} source={source} />;
    }

    // Caso contrário, mostre o botão normal
    return (
      <SliderButton
        button={<Edit name="calendar_today" fontSize="large" />}
        sliderTitle={`Editar recorrência de ${name}`}
      >
        <Scheduling
          scanProfileId={scanProfileId}
          scanType={name}
          source={source}
        />
      </SliderButton>
    );
  };

  return (
    <article className="vulnerability-level">
      <div className="title">
        <div className="profile">
          <strong>
            Tipo:
          </strong>
          {props.name}
        </div>

        {!!props.lastScan &&
          <div>
            <strong>
              Relatório:
            </strong>
            &nbsp;

            <Link to={`/armour/scan-details/${props.scanId}`} title="Relatório do último scan">
              <InsertDriveFile fontSize="large" />
            </Link>
          </div>
        }
      </div>

      <VulnerabilityBar levels={props.levels} range={props.range} numberInBox />

      <div className="description">
        <span className="last-scan">
          {props.lastScan &&
            <span>
              <strong>
                Último scan: {''}
              </strong>

              <Link to={`/armour/scan-details/${props.scanId}`}>
                {moment(props.lastScan).format('DD/MM/YYYY [às] HH:mm')}
              </Link>
            </span>
          }
        </span>

        <span className="total">
          <strong>
            Total: {''}
          </strong>

          {sum(Object.values(props.levels))}
        </span>

        {dateNextScan ?
          <span className="next-scan">
            <strong>
              Próximo scan: {''}
            </strong>

            {moment(dateNextScan).format('DD/MM/YYYY [às] HH:mm')}

            {renderButton()}
          </span>
          :
          <SliderButton button={`Agendar ${props.name}`} sliderTitle={`Edição de ${props.name}`}>
            <Scheduling
              scanProfileId={props.scanProfileId}
              scanType={props.name}
              source={props.source}
            />
          </SliderButton>
        }
      </div>
    </article>
  )
}

VulnerabilityLevel.propTypes = {
  scanId: PropTypes.string,
  scanProfileId: PropTypes.string,
  name: PropTypes.string,
  range: PropTypes.number,
  levels: PropTypes.objectOf(
    PropTypes.number
  ).isRequired,
  lastScan: PropTypes.string,
  nextScan: PropTypes.string,
  runningScan: PropTypes.string,
  source: PropTypes.object,
  formType: PropTypes.string,
  choiceChange: PropTypes.func,
}
