import { css } from 'styled-components';

export default css`
    /* @-ms-viewport {
        width: device-width;
    } */

    .visible-xs,
    .visible-sm,
    .visible-md,
    .visible-lg {
        display: none !important;
    }

    .visible-xs-block,
    .visible-xs-inline,
    .visible-xs-inline-block,
    .visible-sm-block,
    .visible-sm-inline,
    .visible-sm-inline-block,
    .visible-md-block,
    .visible-md-inline,
    .visible-md-inline-block,
    .visible-lg-block,
    .visible-lg-inline,
    .visible-lg-inline-block {
        display: none !important;
    }

    @media (max-width: 767px) {
        .visible-xs {
            display: block !important;
        }

        table.visible-xs {
            display: table !important;
        }

        tr.visible-xs {
            display: table-row !important;
        }

        th.visible-xs,
        td.visible-xs {
            display: table-cell !important;
        }
    }

    @media (max-width: 767px) {
        .visible-xs-block {
            display: block !important;
        }
    }

    @media (max-width: 767px) {
        .visible-xs-inline {
            display: inline !important;
        }
    }

    @media (max-width: 767px) {
        .visible-xs-inline-block {
            display: inline-block !important;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .visible-sm {
            display: block !important;
        }

        table.visible-sm {
            display: table !important;
        }

        tr.visible-sm {
            display: table-row !important;
        }

        th.visible-sm,
        td.visible-sm {
            display: table-cell !important;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .visible-sm-block {
            display: block !important;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .visible-sm-inline {
            display: inline !important;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .visible-sm-inline-block {
            display: inline-block !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .visible-md {
            display: block !important;
        }

        table.visible-md {
            display: table !important;
        }

        tr.visible-md {
            display: table-row !important;
        }

        th.visible-md,
        td.visible-md {
            display: table-cell !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .visible-md-block {
            display: block !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .visible-md-inline {
            display: inline !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .visible-md-inline-block {
            display: inline-block !important;
        }
    }

    @media (min-width: 1200px) {
        .visible-lg {
            display: block !important;
        }

        table.visible-lg {
            display: table !important;
        }

        tr.visible-lg {
            display: table-row !important;
        }

        th.visible-lg,
        td.visible-lg {
            display: table-cell !important;
        }
    }

    @media (min-width: 1200px) {
        .visible-lg-block {
            display: block !important;
        }
    }

    @media (min-width: 1200px) {
        .visible-lg-inline {
            display: inline !important;
        }
    }

    @media (min-width: 1200px) {
        .visible-lg-inline-block {
            display: inline-block !important;
        }
    }

    @media (max-width: 767px) {
        .hidden-xs {
            display: none !important;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .hidden-sm {
            display: none !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .hidden-md {
            display: none !important;
        }
    }

    @media (min-width: 1200px) {
        .hidden-lg {
            display: none !important;
        }
    }

    .visible-print {
        display: none !important;
    }

    @media print {
        .visible-print {
            display: block !important;
        }

        table.visible-print {
            display: table !important;
        }

        tr.visible-print {
            display: table-row !important;
        }

        th.visible-print,
        td.visible-print {
            display: table-cell !important;
        }
    }

    .visible-print-block {
        display: none !important;
    }

    @media print {
        .visible-print-block {
            display: block !important;
        }
    }

    .visible-print-inline {
        display: none !important;
    }

    @media print {
        .visible-print-inline {
            display: inline !important;
        }
    }

    .visible-print-inline-block {
        display: none !important;
    }

    @media print {
        .visible-print-inline-block {
            display: inline-block !important;
        }
    }

    @media print {
        .hidden-print {
            display: none !important;
        }
    }

    /* Images */
    img.responsive {
        width: 100%;
    }
`;
