import React from 'react';

export const AboutSealStatus = () => (
    <React.Fragment>
        <h2>Como funcionam as vulnerabilidades?</h2>
        <p>
            <strong>Níveis 1 e 2</strong>: são apontamentos de informação e não apresentam risco ao site testado.
            Não é exigida a correção destes apontamentos para a exibição do Selo Site Blindado, contudo ressaltamos a
            importância de corrigir todas as vulnerabilidades e apontamentos encontrados.
        </p>

        <p>
            <strong>Níveis 3, 4 e 5</strong>: são vulnerabilidades críticas e representam alto risco ao site testado,
            tornando-o suscetível a ataques. É exigida a correção dessas vulnerabilidades para a exibição do Selo Site
            Blindado e recomendamos a correção das mesmas assim que encontradas para maior segurança e para continuar
            com o Selo Site Blindado visível no seu site.
        </p>

        <h2>Vulnerabilidade crítica encontrada, o que fazer?</h2>
        <p>
            Caso sejam encontradas vulnerabilidades, acesse o relatório do último scan realizado e siga as instruções
            contidas nele para a correção das vulnerabilidades encontradas. É requerido conhecimento técnico para
            realizar as correções e, em caso de maiores dúvidas, entre em contato com nosso
            Suporte Técnico: <a href="mailto:suporte@siteblindado.com.br">suporte@siteblindado.com.br</a>.
        </p>
        <p>
            Em caso de apontamento de vulnerabilidades críticas, é imprescindível que as mesmas sejam corrigidas no
            período de 150 horas para que o Selo Site Blindado não deixe ser exibido.
        </p>
        <p>
            Após a correção destas vulnerabilidades, agende um novo scan para validar as correções.
        </p>

    </React.Fragment>
);
