import PropTypes from 'prop-types';
import React from 'react';
import { Loading, Button } from '../../components';
import { Link } from 'react-router-dom';

export class RemoveOrganization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            isLoading: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    onSubmit() {
        this.setState({
            isChecked: false,
            isLoading: true,
        });

        this.props.deleteOrganization()
        .then(() => this.setState({ isLoading: false }));
    }

    handleClick() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        return (
            <article className="slide-confirmation">
                <Loading loading={this.state.isLoading} />

                <p>
                    <strong>Cuidado!</strong> A organização “<strong>{this.props.fullName}</strong>”
                    será <strong>excluida</strong> e <strong>não há como desfazer</strong> esta ação.
                    Ao ser excluída, todos os usuários desta organização perderão o acesso à ela.
                </p>

                <p>
                    Se preferir, você pode transferir a propriedade desta organização
                    <Link to="/organizations/users"> clicando aqui</Link>.
                </p>

                <label className="checkbox">
                    <input
                        type="checkbox"
                        name="remove_organization"
                        onClick={this.handleClick}
                        checked={this.state.isChecked || false}
                    />
                    <span>Sim, desejo realmente excluir esta organização</span>
                </label>

                <div className="buttons space-button">
                    <Button
                        disabled={!this.state.isChecked}
                        onClick={() => this.onSubmit()}
                    >
                        Excluir
                    </Button>
                    <Link to="/organizations/">Cancelar</Link>
                </div>
            </article>
        );
    }
}

RemoveOrganization.propTypes = {
    fullName: PropTypes.string,
    deleteOrganization: PropTypes.func,
};
