import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';

export const VulnerabilityCard = React.memo(props => {
    const { title, size, onClick, icon } = props;

    return (
        <Grid container item xs={12}>
            <Grid item xs={10}>
                <Typography
                    variant="h5"
                    onClick={onClick}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        // fontWeight: 500
                    }}
                >
                    {title}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography
                    variant="h5"
                    onClick={onClick}
                    style={{
                        display: 'flex',
                        justifyContent: _.isNumber(size)
                            ? 'space-between'
                            : 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {size}

                    {icon}
                </Typography>
            </Grid>
        </Grid>
    );
});

VulnerabilityCard.propTypes = {
    title: PropTypes.string,
    size: PropTypes.number,
    onClick: PropTypes.func,
    icon: PropTypes.any,
};

export default VulnerabilityCard;
