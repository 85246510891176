import { css } from 'styled-components';

import {
    $bluegrey200,
    $bluegrey800,
    $borderRadiusBase,
    $darkgrey500,
    $darkgrey700,
    $fontSizeSmall,
    $green300,
    $greenSb,
    $lightgrey300,
    $lightgrey600,
    $lightgrey700,
    $lightgrey800,
    $lightred300,
    $lightred400,
    $linkColor,
    $spaceBase,
} from '../settings/variables';

export default css`
    .table-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .table-wrap .table-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .table-wrap td:not(:first-child):not(:last-child) {
        text-align: center;
    }

    .table-wrap td.text-left {
        text-align: left !important;
    }

    .table-wrap th:not(:first-child):not(:last-child) .table-header {
        justify-content: center;
    }

    .table-wrap i.check {
        color: ${$greenSb} !important;
    }

    .table-wrap i.not_interested {
        color: ${$lightred400} !important;
    }

    .table-wrap > .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${$lightgrey300};
        padding: 0 ${$spaceBase};
        border-radius: ${$borderRadiusBase} ${$borderRadiusBase} 0 0;
        margin-bottom: 2.5rem;
        height: 5rem;
    }

    .table-wrap > .actions i {
        padding: 0 0.3rem;
    }

    .table-wrap > .actions .left {
        /* @extends h4; */
        font-weight: 700;
        font-size: 1.4rem;
        /*  */
    }

    .table-wrap > .actions .items,
    .table-wrap > .actions .items a {
        display: flex;
        align-items: center;
    }

    .table-wrap > .actions .items > .tag,
    .table-wrap > .actions .items a > .tag {
        padding-left: 1.5rem;
        background-color: ${$lightgrey600};
        margin-left: 3rem;
        border-radius: 0.5rem;
        font-weight: normal;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .table-wrap > .actions .items .clear-filter,
    .table-wrap > .actions .items a .clear-filter {
        height: 2.5rem;
        background-color: ${$lightgrey800};
        width: 3rem;
        text-align: center;
        margin-left: 1.5rem;
        line-height: 2.1rem;
        cursor: pointer;
    }

    .table-wrap > .actions > .right {
        display: flex;
    }

    .table-wrap > .actions > .right > * {
        margin-left: 2rem;
    }

    .table-wrap > .actions > .right a {
        /* @extends .button-primary; */
        background-color: ${$green300};
        color: #fff !important;
        position: relative;
        border-radius: 0.25rem;
        border: none;
        width: auto;
        height: 3rem;
        min-width: auto;
        padding: 0.5rem 3.5rem;
        cursor: pointer;
        font-size: 1.3rem;
        text-align: center;
        text-transform: none;
        outline: none;
        transition: all 0.2s ease-out;
        /*  */

        padding-right: 2rem;
        padding-left: 2rem;

    }

    .table-wrap > .actions > .right a:hover {
        background-color: ${$bluegrey800};
    }

    .table-wrap > .table {
        box-shadow: none;
        font-size: ${$fontSizeSmall};
        width: 96%;
        margin: 0 auto 3rem auto;
    }

    .table-wrap > .table th {
        background-color: #fff;
        color: ${$darkgrey700};
        font-size: ${$fontSizeSmall};
        padding: 1.05rem 1.5rem;
        font-weight: bold;
        border-bottom: 1px solid ${$lightgrey700};
        white-space: pre-line;
    }

    .table-wrap > .table tr td {
        /* padding: 1.05rem 1.5rem; */
        height: 5.5rem;
        font-size: ${$fontSizeSmall};
        border-bottom: 1px solid ${$lightgrey700};
    }

    .table-wrap > .table tr td.left {
        text-align: left;
    }

    .table-wrap > .table tr td .button-pure,
    .table-wrap > .table tr td .button-transparent {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .table-wrap > .table tr:nth-child(odd) {
        background-color: #fff;
    }

    .table-wrap > .table td.actions {
        text-align: right;
    }

    .table-wrap > .table a i {
        color: ${$bluegrey200};
    }

    .table-wrap > .table a.remove-button:hover {
        color: ${$lightred300};
    }

    .table-wrap > .table tr.subdomain {
        background-color: ${$lightgrey300};
        display: none;
    }

    .table-wrap > .table tr.subdomain.open {
        display: table-row;
    }

    .table-wrap > .table tr.subdomain .align-items {
        display: flex;
        align-items: center;
        position: relative;
    }

    .table-wrap > .table tr.subdomain .align-items i {
        font-size: 2rem;
        margin-right: .5rem;
    }

    .table-wrap > .table .add-box {
        position: absolute;
        margin-left: -2.8rem;
        margin-top: -.3rem;
        cursor: pointer;
    }

    .table-wrap > .footer-actions {
        padding: 0.5rem 1rem;
    }

    .table-wrap .table-search {
        position: relative;
        margin: 1rem 0;
        width: 25rem;
    }

    .table-wrap .table-search >.clear-search {
        position: absolute;
        cursor: pointer;
        right: .5rem;
        top: 1rem;
    }

    .table-wrap .table-search >.clear-search > i {
        font-size: 1.8rem;
    }

    .table-wrap .table-search > input {
        width: 100%;
    }

    .table-wrap .order-by {
        position: relative;
    }

    .table-wrap .order-by > a {
        margin-left: 2rem;
        color: #484848;
    }

    .table-wrap .order-by > a:hover {
        color: ${$linkColor};
        text-decoration: underline;
    }

    .table-wrap .order-by > .arrows {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: .2rem;
    }

    .table-wrap .order-by > .arrows .sb2wicon {
        font-size: 1.5rem;
    }

    .table-wrap .order-by > .arrows .sort-up, .sort-down {
        color: ${$green300};
    }

    .table-wrap .order-by > .arrows .sort {
        color: ${$darkgrey500};
    }
`;
