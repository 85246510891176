import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {Icon} from "../Icon";

export const ScanStatusLabels = {
  scheduled: 'Agendado',
  canceled: 'Cancelado',
  finished: 'Finalizado',
  incomplete: 'Incompleto',
  queued: 'Enfileirado',
  error: 'Erro',
  running: 'Executando',
};

const StatusIcon = {
  scheduled: <Icon name="schedule"/>,
  canceled: <Icon name="not_interested"/>,
  finished: <Icon name="done_all"/>,
  incomplete: <Icon name="not_interested"/>,
  queued: <Icon name="hourglass_empty"/>,
  error: <Icon name="error_outline"/>,
  running: <Icon className="looping" name="autorenew"/>,
};

export const ScanStatus = (props) => (
  <div className={classnames('scan-status', props.status)} title={props.message}>
    <span>{ScanStatusLabels[props.status] || '-'}</span>
    {StatusIcon[props.status] || ''}
  </div>
);

ScanStatus.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
};
