import { css } from 'styled-components';

export default css`
    .report {
        margin: 1rem 0 1rem;
    }

    .report .check {
        float: right;
        margin-right: 2rem;
    }

    .report-links a {
        margin-top: 1rem;
        margin-right: 2rem;
    }

    .report-links a:last-child {
        margin-right: 0;
    }

    .lastUpdated {
        text-align: right;
        color: #888;
        font-size: 1.2rem;
    }

    .lastUpdated > img {
        width: 4rem;
        margin-right: 1rem;
    }

    .reportlink {
        margin: 0.5rem 0 0 1rem;
    }
`;
