import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import useStyles from './style'

import { Grid, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const SummarySideBar = React.memo((props) => {
  const classes = useStyles()
  const { children, date, url, pentester, vulnerabilityLength, isLoading } = props

  return (
    <Grid item xs={3}>
      <Grid className={classes.detailsContainer}>
        {!isLoading
          ? <Grid>
            <Typography
              className={classes.subtitle}
              variant="h4"
              align="center"
            >
              Detalhes
            </Typography>

            <Typography className={classes.text}>
              <b>Data do relatório: </b>
              {moment(date).format('DD/MM/YYYY')}
            </Typography>

            <Typography className={classes.text}>
              <b>URL: </b>
              {url}
            </Typography>

            <Typography className={classes.text}>
              <b>Pentester: </b>
              {pentester}
            </Typography>

            <Typography className={classes.text}>
              <b>Total de vulnerabilidades: </b>
              {vulnerabilityLength}
            </Typography>
          </Grid>
          : <Skeleton height={142} />
        }

        {children}
      </Grid>
    </Grid>
  )
})

SummarySideBar.propTypes = {
  date: PropTypes.string,
  url: PropTypes.string,
  pentester: PropTypes.string,
  vulnerabilityLength: PropTypes.number,
  children: PropTypes.any,
}

export default SummarySideBar
