import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {
  Dropdown,
  ExpirationDate,
  ProgressCircle,
  Score,
  TableWrap,
  TableWrapActions,
  TableWrapLeft,
  TableWrapRight,
} from '../../components'
import {InstallStampBlindado, InstallStampTrustSign, ScanReport} from '../../views/certificates'
import {Link} from 'react-router-dom'
import {push} from 'connected-react-router'
import {fetchCertificates, runScan} from '../../actions'
import {fetchDashboard} from '../../actions/dashboards'
import {closeSlider} from '../../actions/slider'
import {SliderButton} from '../../containers'
import {withAccountId} from '../AccountContainer'
import classnames from 'classnames'
import get from 'lodash/get'
import {MenuItem} from '@mui/material'

const onScan = (props) => {
  const accountId = props.accountId

  props.runScan(props.id)
    .then(() => props.closeSlider())
    .then(() => props.fetchCertificates(accountId, {page: props.page, filter: props.filter}))
    .then(() => props.fetchDashboard('ssl', accountId, false))
    .catch()
}

const Actions = (props) => {
  const seals = {
    siteblindado: <InstallStampBlindado/>,
    trustsign: <InstallStampTrustSign/>,
  }

  if (!props.is_validated) {
    if (props.subdomain) {
      return false
    }

    return (
      <Link
        to={`/devices/validation/${props.id}/`}
        className="button-transparent"
      >
        Validar
      </Link>
    )
  }

  return (
    <Dropdown
      classes="button-pure"
      button={
        <div className="button-pure">
          Ações
        </div>
      }
    >
      {(props.latest_ssl_scans && !!Object.keys(props.latest_ssl_scans).length) &&
        <MenuItem>
          <SliderButton button="Relatório" sliderTitle="Relatório de Scan">

            <ScanReport
              id={props.id}
              runScan={() => onScan(props)}
              name={props.name}
              {...props.latest_ssl_scans}
            />

          </SliderButton>
        </MenuItem>
      }

      {(!props.latest_ssl_scans || !props.latest_ssl_scans.in_progress) &&
        <MenuItem>
          <a onClick={() => onScan(props)}>
            Rodar Scan
          </a>
        </MenuItem>
      }

      {!!get(props.certificate, 'brand', false) &&
        <MenuItem>
          <SliderButton button="Selo SSL" sliderTitle="Selo SSL">
            {seals[props.certificate.brand]}
          </SliderButton>
        </MenuItem>
      }
    </Dropdown>
  )
}

const ActionsContainer = connect(({certificates}) => ({
  page: certificates.page,
  filter: certificates.filter,
}), {fetchCertificates, fetchDashboard, runScan, closeSlider})(withAccountId(Actions))

class CertificatesInstalled extends React.Component {
  constructor(props) {
    super(props)

    this.model = {
      subdomain: {
        name: ' ',
        className: 'table-prefix',
        renderer: (name, obj) => (obj.subdomain ? `${obj.subdomain}.` : ''),
      },
      name: {
        name: 'Domínio',
        className: 'left',
        renderer: (name, obj) => `${obj.domain}.${obj.suffix}`,
      },
      latest_ssl_scans: {
        name: 'Nota',
        renderer: this.checkGrade.bind(this),
      },
      'certificate.product': {name: 'Produto', renderer: (name) => name || '-'},
      'certificate.validity.not_after': {
        name: 'Expira em',
        fieldName: 'expiration_date',
        renderer: (date) => <ExpirationDate date={date}/>,
      },
      certificate: {
        name: 'Emissor',
        renderer: (obj) => {
          if (!obj || !obj.issuer) {
            return '-'
          }

          return obj.issuer.organization_name || 'Outros'
        },
      },
      actions: {
        name: ' ',
        className: 'actions',
        renderer: (item, obj) => <ActionsContainer {...obj} />,
      },
    }
  }

  checkGrade(latest, item) {
    const score = latest && latest.ready && latest.ready.grade ? latest.ready.grade : ''

    return (
      <SliderButton
        sliderTitle="Relatório de Scan"
        selector="article"
        button={(
          <div className="score-box">
            <Score
              value={score}
              loading={latest && !!latest.in_progress}
              className={classnames({
                'scan-failed': (latest && latest.error),
                'in-progress': (latest && !!latest.in_progress && !!latest.in_progress.progress),
              })}
            />
            {!!latest.in_progress &&
              <ProgressCircle percent={latest.in_progress.progress}/>
            }
          </div>
        )}
      >
        <ScanReport
          id={item.id}
          runScan={() => onScan({...this.props, id: item.id})}
          name={item.name}
          {...latest}
        />
      </SliderButton>
    )
  }

  render() {
    const {page, certificates, filter, accountId} = this.props

    const filterName = {
      expired: 'Certificados expirados',
      expiring: 'Certificados expirando nos próximos 90 dias',
      vulnerable: 'Certificados vulneráveis',
      unshielded: 'Certificado não blindado',
    }

    return (
      <div>

        <TableWrap
          model={this.model}
          source={certificates}
          actionFetch={this.props.fetchCertificates}
          accountId={accountId}
          location={this.props.location}
          orderBy={['expiration_date']}
          hasSearch
        >
          <TableWrapActions>
            <TableWrapLeft>
              Certificados instalados
              {filter &&
                <div className="tag" style={{width: '100%'}}>
                  {filterName[filter]}
                  <span
                    onClick={() => this.props.fetchCertificates(accountId, {page})}
                    className="clear-filter"
                  >
                    x
                  </span>
                </div>
              }
            </TableWrapLeft>

            <TableWrapRight>
              <Link to="/certificates/new">
                Emitir certificado
              </Link>
            </TableWrapRight>
            <TableWrapRight>
              <Link to="/manuals/how-to-activate-ssl">
                Como Ativar um Certificado
              </Link>
            </TableWrapRight>
          </TableWrapActions>
        </TableWrap>
      </div>
    )
  }
}

CertificatesInstalled.propTypes = {
  accountId: PropTypes.string.isRequired,
  certificates: PropTypes.object,
  fetchCertificates: PropTypes.func,
  push: PropTypes.func,
  page: PropTypes.number,
  location: PropTypes.object,
  filter: PropTypes.string,
}

Actions.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  domain: PropTypes.string,
  subdomain: PropTypes.string,
  certificate: PropTypes.object,
  is_validated: PropTypes.bool,
  accountId: PropTypes.string,
  csr: PropTypes.string,
  latest_ssl_scans: PropTypes.object,
  fetchCertificates: PropTypes.func,
  fetchDashboard: PropTypes.func,
  runScan: PropTypes.func,
  name: PropTypes.string,
  filter: PropTypes.string,
  page: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
}

function mapStateToProps({certificates}, ownProps) {
  return {
    certificates,
    filter: certificates.filter,
    page: parseInt(ownProps.match.params.page || '1', 10),
  }
}

export default connect(
  mapStateToProps,
  {push, fetchCertificates, fetchDashboard, runScan, closeSlider},
)(withAccountId(CertificatesInstalled))
