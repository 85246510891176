import {fetch} from '../../../services/fetch';
import {ERROR, setMessage, SUCCESS} from "../../../modules/messages";

export const FETCH_SALESFORCE_AUTH = '@backoffice/FETCH_SALESFORCE_AUTH';
export const UPDATE_SALESFORCE_AUTH = '@backoffice/UPDATE_SALESFORCE_AUTH';

export function fetchSalesforceAuth() {
  return dispatch => {
    const request = fetch(`/integrations/salesforce/auth`, {
      method: 'GET',
    }).then(res => res.json())
      .catch(err => {
        const message = err.errors[0].message || 'Erro ao recuperar credenciais de integração para o Salesforce'
        dispatch(setMessage(ERROR, message))
      })

    return dispatch({
      type: FETCH_SALESFORCE_AUTH,
      payload: request,
    })
  }
}

export function updateSalesforceAuth(data) {
  return dispatch => {
    const request = fetch(`/integrations/salesforce/auth`, {
      method: 'PUT', body: JSON.stringify(data),
    }).then(res => {
      dispatch(setMessage(SUCCESS, 'Credenciais de integração para o Salesforce atualizadas com sucesso!'))
      return res.json()
    }).catch(err => {
      const message = err.errors[0].message || 'Erro ao atualizar credenciais de integração para o Salesforce'
      dispatch(setMessage(ERROR, message))
    })

    return dispatch({
      type: UPDATE_SALESFORCE_AUTH,
      payload: request,
    })
  }
}