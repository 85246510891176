import PropTypes from 'prop-types';
import React from 'react';
import { Button, TextField } from '../../components';
import { Field, reduxForm } from 'redux-form';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AwReport } from '../../actions/awreport';

class FormAwReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // redirectTo: this.props.location.query.redirect || '/',
            otpView: false,
            loading: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(data) {
        this.setState({ loading: true });
        this.props.AwReport(this.props.scanId, data.key);
    }

    render() {
        return (
            // <p> teste</p>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                {!this.state.otpView &&
                    <article>
                        <Field
                            type="password"
                            name="key"
                            placeholder="Insira sua Chave"
                            component={TextField}
                        />
                    </article>
                }

                <Button loading={this.state.loading}>
                    ACESSAR
                </Button>
            </form>
        );
    }
}

FormAwReport.propTypes = {
    location: PropTypes.object,
    AwReport: PropTypes.func,
    unlock_date: PropTypes.string,
    handleSubmit: PropTypes.func,
    scanId: PropTypes.string,
};

FormAwReport.contextTypes = {
    router: PropTypes.object,
};

const Form = reduxForm({
    form: 'formLogin',
})(FormAwReport);

const mapStateToProps = (state) => ({
    unlock_date: state.auth.unlock_date,
});

export default connect(mapStateToProps, { AwReport })(Form);
