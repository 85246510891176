import PropTypes from 'prop-types';
import React from 'react';
import { AlertCard } from '../components';

export class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.title = 'Página não encontrada';
    }

    componentDidMount() {
        this.props.setTitle(this.title);
    }

    render() {
        return (
            <article className="page">
                <div className="page-content">
                    <div className="content">
                        <AlertCard
                            title={this.title}
                            error
                        >
                            A página requisitada não existe.<br />
                            Verifique se a URL está correta ou utilize o menu lateral para lhe auxiliar.
                        </AlertCard>
                    </div>
                </div>
            </article>
        );
    }
}

NotFound.propTypes = {
    setTitle: PropTypes.func,
};
