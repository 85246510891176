import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {
  TableWrap,
  TableWrapActions,
  TableWrapRight,
  TableWrapLeft,
  RemoveItem,
  Choices,
} from '../../components'
import { RoleSelector, InviteUser } from '../../containers'
import { onlyAdmin, OnlyOrganizationAdmin } from '../../services/auth'
import {
  fetchOrganizationUsers,
  deleteOrganizationUser,
  clearOrganizationUsers,
  saveOrganizationUser,
} from '../../actions/organizations'
import moment from 'moment'

const RemoveUser = OnlyOrganizationAdmin((props) => (
  <RemoveItem
    section="usuário"
    id={props.userId}
    onRemove={props.onRemove}
  />
))

const ChangeRole = onlyAdmin((props) =>
  <Choices
    items={[
      { label: 'Comercial/Marketing', value: 'Comercial/Marketing' },
      { label: 'Técnico', value: 'Técnico' },
      { label: 'Financeiro', value: 'Financeiro' },
      { label: 'Proprietário', value: 'Proprietário' },
      { label: 'Outro', value: 'Outro' },
    ]}
    itemChecked={props.item}
    onClick={(choice) => {
      props.saveOrganizationUser(props.match.params.id, props.obj.user.id, { profile: choice })
    }}
  />,
  (props) =>
    <p> {props.item} </p>
)

class OrganizationUsersList extends React.Component {
  constructor(props) {
    super(props)
    this.simpleModel = {
      user: {
        name: 'Nome',
        renderer: (item) => (
          `${item.first_name} ${item.last_name || ''}`
        ),
      },
      'user.email': { name: 'E-mail' },
      'user.phone': { name: 'Telefone' },
      role: {
        name: 'Perfil',
        renderer: (item, obj) => {
          return (
            <RoleSelector
              role={obj.role}
              id={obj.user.id}
              organizationId={this.props.match.params.id}
            />
          )
        },
      },
    }

    this.model = {
      user: {
        name: 'Nome',
        renderer: (item) => (
          `${item.first_name} ${item.last_name || ''}`
        ),
      },
      'user.email': { name: 'E-mail' },
      'user.phone': { name: 'Telefone' },
      role: {
        name: 'Perfil',
        renderer: (item, obj) => {
          return (
            <RoleSelector
              role={obj.role}
              id={obj.user.id}
              edit
              organizationId={this.props.match.params.id}
            />
          )
        },
      },
      profile: {
        name: 'Cargo',
        renderer: (item, obj) => (
          // eslint-disable-next-line react/jsx-indent
          <ChangeRole
            item={item}
            obj={obj}
            match={this.props.match}
            saveOrganizationUser={this.props.saveOrganizationUser}
            organizationId={this.props.match.params.id}
          />
        ),
      },
      'user.last_login': {
        name: 'Último Login',
        renderer: (date) => <span>
          {date
            ? moment(date).format('DD/MM/YYYY HH:mm')
            : '-'
          }
        </span>,
      },
      actions: {
        name: ' ',
        className: 'actions',
        renderer: (item, obj) => {
          if (obj.user.id === this.props.userId || obj.role === 'owner') {

            return false
          }

          return (
            <RemoveUser
              userId={obj.user.id}
              onRemove={() => this.props.deleteOrganizationUser(this.props.match.params.id, obj.user.id)}
              organizationId={this.props.match.params.id}
            />
          )
        },
      },
    }
  }

  render() {
    const currentOrganization = this.props.userData.organizations[this.props.match.params.id]
    const isUserStaff = this.props.userData.role === 'admin'

    const model = () => {
      if (isUserStaff) {
        return this.model
      }
      
      if (currentOrganization && currentOrganization.role === 'user') {
        return this.simpleModel
      }

      return this.model
      
    }

    return (
      <div>
        <TableWrap
          model={model()}
          source={this.props.organizationUsers}
          actionFetch={this.props.fetchOrganizationUsers}
          location={this.props.location}
          accountId={this.props.match.params.id}
        >
          <TableWrapActions>
            <TableWrapLeft>
              Usuários da Organização
            </TableWrapLeft>

            <TableWrapRight>
              <InviteUser
                organizationId={this.props.match.params.id}
                formName="Users"
              />
            </TableWrapRight>

          </TableWrapActions>
        </TableWrap>
      </div>
    )
  }
}

OrganizationUsersList.propTypes = {
  id: PropTypes.string,
  userId: PropTypes.string,
  params: PropTypes.object,
  location: PropTypes.object,
  organizationUsers: PropTypes.object,
  fetchOrganizationUsers: PropTypes.func,
  deleteOrganizationUser: PropTypes.func,
  clearOrganizationUsers: PropTypes.func,
  saveOrganizationUser: PropTypes.func,
  userData: PropTypes.object,
}

const mapStateToProps = ({ auth, organizationUsers }) => {
  return ({
    userId: auth.data.user.id,
    userData: auth.data,
    organizationUsers,
  })
}

export default connect(mapStateToProps, {
  fetchOrganizationUsers,
  deleteOrganizationUser,
  clearOrganizationUsers,
  saveOrganizationUser,
})(OrganizationUsersList)
