export const MODAL_FALSE = 'MODAL_FALSE';
export const MODAL_TRUE = 'MODAL_TRUE';

export function modalFalse() {
    return {
        type: MODAL_FALSE,
    };
}

export function modalTrue() {
    return {
        type: MODAL_TRUE,
    };
}
