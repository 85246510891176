import { useEffect } from 'react'
import { Card } from '../../components'
import { OrganizationUsersList, OrganizationInvitesList } from '../../containers'

export const OrganizationUsers = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <OrganizationInvitesList {...props} />
      </Card>

      <Card>
        <OrganizationUsersList {...props} />
      </Card>
    </section>
  )
}
