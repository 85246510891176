import PropTypes from 'prop-types'
import React from 'react'
import { FormRecovery, FormPassword } from '../../components'

export class Recovery extends React.Component {
  inRecovering() {
    const { params } = this.props.match;
    const { hash, id } = params;

    return hash && id
  }

  renderForm() {
    if (window.top !== window.self) {
      window.top.location.href = document.location.href;
    }
    if (this.inRecovering()) {
      return <FormPassword {...this.props} />
    }

    return <FormRecovery {...this.props} />
  }

  render() {
   
    return this.renderForm()
  }
}

Recovery.propTypes = {
  setTitle: PropTypes.func,
  params: PropTypes.shape({
    id: PropTypes.string,
    hash: PropTypes.string,
  }),
}
