import PropTypes from 'prop-types'
import React from 'react'
import zxcvbn from 'zxcvbn'
export class PasswordStrengthField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      score: 0,
      value: this.props.value,
      size: 0,
    }
    this.colors = [
      'red',
      'rgb(253, 211, 46)',
      'rgb(121, 157, 191)',
      'rgb(108, 219, 41)',
    ]
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount() {
    this.setSizeInput(this.input.offsetWidth)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  setSizeInput(size) {
    this.setState({ size })
  }

  getSizeBar() {
    const { size, score } = this.state
    return (size * (25 * score)) / 100
  }

  handleChange(e) {
    const { value } = e.target
    const { score } = zxcvbn(value)

    this.setState({ score, value })
  }

  render() {
    return (
      <div className="password-strength">
        {this.props.label &&
          <label htmlFor="">{this.props.label}</label>
        }

        <input
          ref={(input) => (this.input = input)}
          type="password"
          {...this.props}
          className={`input-lg ${this.props.className.split(' ')}`}
          onChange={this.handleChange}
          value={this.state.value}
          required
        />
        <span style={{
          width: `${this.getSizeBar()}px`,
          height: '0.6rem',
          display: 'block',
          marginTop: '0.3rem',
          marginBottom: '1rem',
          backgroundColor: this.colors[this.state.score - 1],
          transition: 'all 0.3s ease',
        }} />
      </div>
    )
  }
}

PasswordStrengthField.defaultProps = {
  value: '',
  className: '',
}

PasswordStrengthField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
}
