import React from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'

export class EditorField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: RichTextEditor.createEmptyValue() }
    this.initialState = this.initialState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.empty = '<p><br></p>'
  }

  componentDidMount() {
    this.initialState()
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   this.state.value.toString('html') !== this.empty
  //     ? null
  //     : this.props.input.value !== nextProps.input.value
  //     && this.initialState(nextProps.input.value)
  //   // if (this.state.value.toString('html') !== this.empty) {
  //   //   return false
  //   // }
  //   // if (this.props.input.value !== nextProps.input.value) {
  //   //   this.initialState(nextProps.input.value)
  //   // }
  // }

  initialState(value = '') {
    this.setState({
      value: !!value ?
        RichTextEditor.createValueFromString(value, 'html') :
        RichTextEditor.createEmptyValue(),
    })
  }

  handleChange(value) {
    this.setState({ value })
    let html = value.toString('html')
    if (html === this.empty) {
      html = ''
    }

    this.props.input.onChange(html)
  }

  render() {
    const { error, label } = this.props
    const { error: metaError, touched } = this.props.meta

    return (
      <div className="field">
        {label &&
          <label htmlFor="">{label}</label>
        }

        <RichTextEditor
          value={this.state.value}
          onChange={this.handleChange}
        />

        {!!error &&
          <span className="input-error">{error}<br /></span>
        }

        {!!metaError && touched &&
          <span className="input-error">{metaError}</span>
        }
      </div>
    )
  }
}

EditorField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  meta: PropTypes.object,
}
