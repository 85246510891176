import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {RemoveItem, TableWrap, TableWrapActions, TableWrapLeft, TableWrapRight,} from '../components'
import {Link} from 'react-router-dom'
import {deleteServer, fetchServers} from '../actions'
import {withAccountId} from './AccountContainer'
import {Done, NotInterested} from '@material-ui/icons'

class ServersList extends React.Component {
  constructor(props) {
    super(props)
    this.model = {
      ip_address: {
        name: 'IP',
        renderer: (ipAddress, {id}) => (
          <Link to={`/devices/servers/${id}`}>
            {ipAddress}
          </Link>
        ),
      },
      name: {name: 'Nome', renderer: (name) => name || '(nenhum)'},
      is_validated: {
        name: 'Validado',
        renderer: (isValidated) => (isValidated ? <Done/> : <NotInterested/>),
      },
      actions: {
        name: ' ',
        className: 'actions',
        renderer: (item, obj) => <RemoveItem section="servidor" id={obj.id} onRemove={this.onRemove}/>,
      },
    }

    this.onRemove = this.onRemove.bind(this)
  }

  onRemove(id) {
    this.props.deleteServer(this.props.accountId, id, this.props.page)
  }

  render() {
    return (
      <div>
        <TableWrap
          model={this.model}
          source={this.props.servers}
          location={this.props.location}
          actionFetch={this.props.fetchServers}
          accountId={this.props.accountId}
        >
          <TableWrapActions>

            <TableWrapLeft>
              Lista de servidores
            </TableWrapLeft>

            <TableWrapRight>
              <Link to="/devices/servers/new">
                Adicionar servidor
              </Link>
            </TableWrapRight>

          </TableWrapActions>
        </TableWrap>
      </div>
    )
  }
}

ServersList.propTypes = {
  accountId: PropTypes.string.isRequired,
  location: PropTypes.object,
  servers: PropTypes.object,
  fetchServers: PropTypes.func,
  deleteServer: PropTypes.func,
  page: PropTypes.number,
}

function mapStateToProps({servers}, ownProps) {
  return {
    servers,
    page: 1,
  }
}

export default connect(mapStateToProps, {fetchServers, deleteServer})(withAccountId(ServersList))
