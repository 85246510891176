import React, { useState } from 'react';
import { Button } from '../../components';
import { AwReportPDF } from '../../actions/awreport';
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2';

export function PdfDownloader(props) {
  const scanReport = useSelector(state => state.scanReport);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState('Baixar PDF');

  async function onClick() {
    if (!scanReport.isLoading) {
      setLoading(true);
      setButtonText('Gerando PDF');

      try {
        await dispatch(AwReportPDF(scanReport.id, scanReport.application.name, scanReport.scan_profile.name));
      } catch (error) {
        Swal.fire({
          icon: 'error',
          html: 'Olá! <br><br> ' +
          'Estamos fazendo algumas atualizações em nosso portal e, por conta disso, neste momento não está sendo possível gerar em pdf os relatórios das vulnerabilidades apresentadas nos scans da Blindagem de Sites. <br><br>' +
          'Caso precise de algum relatório com urgência, faça contato com nossa equipe técnica através do e-mail <a href="mailto:suporte@siteblindado.com.br">suporte@siteblindado.com.br</a>.',
        })
      } finally {
        setButtonText('Baixar PDF');
        setLoading(false);
      }
    }
  }

  return (
    <Button
      pure
      loading={loading}
      onClick={onClick}
      disabled={loading}
    >
      {buttonText}
    </Button>
  );
}