import PropTypes from 'prop-types';
import React from 'react';
import { OrganizationUsersList } from '../containers';
import { withAccountId } from '../containers/AccountContainer';
import { Card, AlertCard } from '../components';

const Blocked = (props) => (
    <section className="content">
        <AlertCard
            title="Usuário não autorizado"
            warning
        >
            <span>
                Somente <strong>Administradores</strong> ou o <strong>Proprietário</strong> da organização
                    podem executar esta ação.
                <br />
                <br />
                Entre em contato com algum da lista abaixo ou com o suporte através do e-mail
                <a href="mailto:suporte@siteblindado.com.br"> suporte@siteblindado.com.br</a>.
            </span>
        </AlertCard>

        <Card>
            <OrganizationUsersList
                {...props}
                params={{
                    ...props.match.params,
                    id: props.accountId,
                }}
            />
        </Card>
    </section>
);

Blocked.propTypes = {
    params: PropTypes.object,
    accountId: PropTypes.string,
};

export default withAccountId(Blocked);
