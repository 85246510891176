import { FETCH_PENTEST_REPORT, UPDATE_PENTEST_REPORT } from '../../../actions/pentests/report'
import { INITIAL_LIST } from '../../../services/initial-states'
import normalizedState from '../../../services/normalize'

export const pentestReport = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_PENTEST_REPORT}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${FETCH_PENTEST_REPORT}_FULFILLED`: {
      return { ...state, results: action.payload, isLoading: false }
    }
    case `${FETCH_PENTEST_REPORT}_REJECTED`:
      return { ...state, isLoading: false }
    default:
      return state
  }
}


export const pentestReportUpdate = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${UPDATE_PENTEST_REPORT}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${UPDATE_PENTEST_REPORT}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload)

      return { ...state, ...action.payload, results, allIds, isLoading: false }
    }
    case `${UPDATE_PENTEST_REPORT}_REJECTED`:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
