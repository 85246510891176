import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export const FilterBox = ({ number, children, active, onClick, unfocused, className }) => (
    <div
        onClick={onClick}
        className={classnames('filter-box', className, { unfocused: unfocused && !active, active })}
    >
        <div className="number">{number}</div>
        <div className="text">{children}</div>
    </div>
);

FilterBox.propTypes = {
    number: PropTypes.number,
    active: PropTypes.bool,
    unfocused: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    children: PropTypes.any,
};
