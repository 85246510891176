import { fetch } from '../services/fetch';
import { setMessage, SUCCESS, ERROR } from '../modules/messages';
import { push } from 'connected-react-router';
import { toParams } from '../services/utils';

export const CREATE = 'promocode/CREATE';
export const FETCH_ALL = 'promocode/FETCH_ALL';
export const FETCH = 'promocode/FETCH';


export function createPromoCodes(data) {
    delete data.product_type;
    return dispatch => {
        const request = fetch('/promocodes', {
            method: 'POST',
            body: JSON.stringify(data),
        })
        .then(res => {
            dispatch(setMessage(SUCCESS, 'Promo codes criados com sucesso!'));
            dispatch(push('/system/promocode'));
            return res;
        })
        .catch(res => {
            dispatch(setMessage(ERROR, res.message));
            return res;
        });

        return dispatch({
            type: CREATE,
            payload: request,
        });
    };
}

export function fetchPromos(params = {}) {
    const query = toParams(params);
    const request = fetch(`/promocodes${query}`)
    .then(res => res.json());

    return {
        type: FETCH_ALL,
        payload: request,
    };
}

export function fetchPromoCodes(id) {
    const request = fetch(`/promocodes/${id}/accounts`)
    .then(res => res.json());

    return {
        type: FETCH,
        payload: request,
    };
}
