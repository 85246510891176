import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  inputValue: {
    fontSize: '1.5rem',
    width: '100%',
    [`& input`]: {
      fontSize: '1.5rem !important',
      // padding: '1rem !important',
    },
    [`& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button`]: {
      display: 'none',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  tableHead: {
    backgroundColor: '#f7f7f7'
  },
  tableCell: {
    textAlign: 'left',
    fontWeight: 'bold !important',
    textTransform: 'uppercase'
  }
})

export default useStyles
