import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'

import {Button, Loading} from '../../../components'
import {Card, Grid} from '@mui/material'
import TextFormField from './FormFields/TextFormField'
import {fetchSalesforceAuth, updateSalesforceAuth} from "../../../actions/backoffice/salesforce";
import _ from "lodash";
import {fetchUser} from "../../../actions/users";

const pattersForm = {
  // eslint-disable-next-line no-useless-escape
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
}

export default function SalesForceForm() {
  const {handleSubmit, control, formState: {errors,}, setValue} = useForm()
  const salesforce = useSelector(state => state.salesforce)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!salesforce.isLoading) {
      dispatch(fetchSalesforceAuth())
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(salesforce.email)) {
      setValue('email', salesforce.email)
      setValue('token', salesforce.token)
    }
  }, [salesforce])

  const onSubmit = data => {
    let FormattedSendData = {
      ...data
    }
    dispatch(updateSalesforceAuth(FormattedSendData))
  }

  const ErrorMessage = () =>
    <span className="input-error-v2">
      Campo obrigatório *
    </span>

  return (
    <Grid item xs={12} style={{padding: '2rem'}}>
      <Grid container>
        <Grid item xs={12}>
          <Card style={{height: '100%'}}>
            <form
              className="salesforce-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                style={{padding: '2rem'}}
                container
                spacing={2}
              >
                <Loading loading={salesforce.isLoading}/>

                <Grid
                  container
                  item
                  xs={6}
                  rowSpacing={2}
                  columnSpacing={1}
                >

                  <Grid item xs={12}>
                    <TextFormField
                      label="E-mail *"
                      name="email"
                      defaultValue={salesforce.email}
                      error={!!errors.email}
                      rules={{required: true, pattern: pattersForm.email}}
                      control={control}
                    />

                    {errors.email && <ErrorMessage/>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextFormField
                      label="Password *"
                      name="password"
                      defaultValue=""
                      error={!!errors.password}
                      rules={{required: true}}
                      control={control}
                      type="password"
                    />

                    {errors.password && <ErrorMessage/>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextFormField
                      label="Token *"
                      name="token"
                      defaultValue=""
                      error={!!errors.token}
                      rules={{required: true}}
                      control={control}
                    />

                    {errors.token && <ErrorMessage/>}
                  </Grid>
                </Grid>

                <Grid style={{margin: '20px 0'}} item xs={12}>
                  <Button style={{margin: '0 20px'}} type="submit">
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>

    </Grid>
  )
}
