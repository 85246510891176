import { css } from 'styled-components';

import { $level1, $greenSb } from '../settings/variables';

export default css`
    .progress-bar {
        background-color: #F7F7F7;
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.26);
        display: flex;
        height: .7rem;
        margin: 1rem 0 0;
        overflow: hidden;
        width: 100%;
    }

    .progress-bar > span {
        transition: all .4s ease-out;
        height: .7rem;
        mix-blend-mode: multiply;
        background-color: ${$level1};
    }

    .progress-circle > svg {
        transform: rotate(-90deg);
    }

    .progress-circle circle {
        transition: all .5s ease-out;
    }

    .progress-circle .status {
        color: ${$greenSb};
        font-size: 1rem;
        position: absolute;
        right: -3.5rem;
        top: 1rem;
    }
`;
