import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Delete } from '@material-ui/icons'
import { IconButton, Popover } from '@mui/material'


export const RemoveItem = props => {
  const [open, setOpen] = useState(false)

  const handleRemove = e => {
    e.preventDefault()

    props.onRemove(props.id)

    setOpen(false)
  }

  const handleClick = e => {
    e.preventDefault()
    setOpen(e.currentTarget)
  }

  const handleClose = e => {
    e.preventDefault()
    setOpen(false)
  }

  return (
    <React.Fragment>
      <IconButton
        className="delete"
        style={{ padding: 0, margin: '0 10px' }}
        onClick={handleClick}
      >
        <Delete
          // className="delete"
          fontSize="large"
          style={{ margin: 5 }}
        />
      </IconButton>

      <Popover
        id={open ? 'simple-popover' : undefined}
        onClose={handleClose}
        anchorEl={open}
        open={!!open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="remove-item">
          <h1 className="remove-title">
            Remover {props.section}
          </h1>

          <p className="remove-text">
            Este {props.section} será removido e não há como reverter esta ação.
          </p>

          {props.additional_information &&
            <p className="additional-info" style={{ color: 'red' }}>
              ATENÇÃO <br />
              {props.additional_information}
            </p>
          }

          <div className="remove-actions">
            <a href='/#' onClick={handleRemove}>
              Remover
            </a>
            <a href='/#' onClick={handleClose}>
              Cancelar
            </a>
          </div>
        </div>
      </Popover>
    </React.Fragment>
  )
}

RemoveItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  section: PropTypes.string.isRequired,
  additional_information: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
}

