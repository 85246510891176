import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Alert } from '../components';

const Message = (props) => {
    const types = {
        INFO: 'info',
        SUCCESS: 'success',
        WARNING: 'default',
        ERROR: 'error',
    };
    return (
        <Alert
            text={props.message && props.message.get('message')}
            type={props.message ? types[props.message.get('level')] : 'error'}
            autoClose
        />
    );
};

Message.defaultProps = {
    message: undefined,
};

Message.propTypes = {
    message: PropTypes.object,
};

function mapStateToProps(state) {
    return { message: state.messages.first() };
}

export { Message };
export default connect(mapStateToProps)(Message);
