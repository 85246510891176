import PropTypes from 'prop-types'
import React from 'react'
import TweenMax from 'gsap'

export class AccordionOld extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    this.close(0)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.close && nextProps.close !== this.props.close) {
      this.setState({ open: false }, this.close)
    }

    if (nextProps.open && nextProps.open !== this.props.open) {
      this.setState({ open: true }, this.open)
    }
  }

  open(speed = 0.4) {
    TweenMax.set(this.refs.container, { minHeight: '45vh' })
    TweenMax.from(this.refs.container, speed, { height: 'auto' })
  }

  close(speed = 0.4) {
    TweenMax.to(this.refs.container, speed, { height: 'auto' })
  }

  render() {
    return (
      <div className="accordion">
        <div className="accordion-header">
          {this.props.header}
        </div>
        <div className="accordion-container" style={{ height: this.state.open ? 'auto' : 0 }}>
          <div className="accordion-content">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

AccordionOld.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any,
  open: PropTypes.bool,
  close: PropTypes.bool,
}
