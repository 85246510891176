import { css } from 'styled-components';

export default css`
    @keyframes looping {
        0%  {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .looping {
        animation: looping 1s infinite linear;
        position: absolute;
        left: .5rem;
        font-size: 2rem;
        margin: 2px;
    }
`;
