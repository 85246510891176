import { css } from 'styled-components';

import { $lightgrey300, $lightgrey600 } from '../settings/variables';

export default css`
    .table-info {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .table-info td {
        font-style: italic;
        padding: 1rem;
        border: 1px solid ${$lightgrey600};
    }

    .table-info .title {
        font-style: initial;
        font-weight: bold;
        background-color: ${$lightgrey300};
    }
`;
