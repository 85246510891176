import { css } from 'styled-components';

export default css`
    .details {
        margin: 0 0 2rem;
    }

    .details-title {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    .details-title > .icon-circle {
        margin-right: 1rem;
        width: 3rem;
        height: 3rem;
        font-size: 1.7rem;
    }

    .details-title > .icon-circle i {
        font-size: 1.7rem;
    }

    .details-title span {
        font-size: 1.6rem;
    }

    .details > article {
        display: flex;
        margin-top: 2vh;
    }

    .details > article:first-child {
        margin-top: 0;
    }

    .details > article > .item {
        margin-right: 2rem;
        margin-left: 5rem;
        min-width: 30rem;
    }

    .details > article > .item > .details-title {
        margin-left: -5rem;
    }

    .details > article > .item > p {
        margin-bottom: 1.5rem;
    }

    .details .flex {
        display: flex;
    }

    .details .flex.multi-domain > p {
        max-width: 100%;
    }

    .details .flex.multi-domain > p > .multi-domain-item {
        display: inline-block;
        width: 17.7rem;
    }

    .details .flex > p {
        flex-grow: 1;
        max-width: 17.7rem;
        word-break: break-word;
        padding-right: 30px;
    }

    .details .flex > p:last-child {
        padding-right: 0;
    }

    .details .rating {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .details .rating-score {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12rem;
        height: 12rem;
        background-color: #7DD53B;
        color: #fff;
        border: .4rem solid #5EBF09;
        border-radius: 1rem;
        padding-top: 1rem;
        margin-top: 1rem;
        font-size: 9rem;
        font-weight: 900;
    }
`;
