import PropTypes from 'prop-types'
import React from 'react'
import {Card, GenericPieChart, Loading, MyProducts} from '../../components'
import {withAccountId} from '../AccountContainer'
import {connect} from 'react-redux'
import sumBy from 'lodash/sumBy'

const chartColors = {
  CANCELADO: '#2D343D',
  DISPONÍVEL: '#7DC671',
  'EM USO': '#65C4ED',
  NEGADO: '#FD4D4D',
  PENDENTE: '#FEE549',
  USADO: '#BBBBBB',
  EXPIRADO: '#F3F3F3',
}

const data = results => {
  const sumProducts = type => (
    sumBy(results, r => r.quantities[type])
  )

  const sumResult = {
    pending: {
      value: sumProducts('pending'),
      name: 'Pendente',
    },
    available: {
      value: sumProducts('available'),
      name: 'Disponível',
    },
    canceled: {
      value: sumProducts('canceled'),
      name: 'Cancelado',
    },
    denied: {
      value: sumProducts('denied'),
      name: 'Negado',
    },
    in_use: {
      value: sumProducts('in_use'),
      name: 'Em Uso',
    },
    used: {
      value: sumProducts('used'),
      name: 'Usado',
    },
    expired: {
      value: sumProducts('expired'),
      name: 'Expirado',
    },
  }

  return Object.values(sumResult).filter(index => index.value > 0)
}

const DashboardProducts = props => (
  <article className="dashboard">
    <Loading loading={props.isLoading}/>

    <Card>
      <div className="inline-items">
        <GenericPieChart
          title="Status dos Produtos"
          subtitle="Produtos"
          data={data(props.results)}
          colors={chartColors}
        />

        <MyProducts />
      </div>
    </Card>
  </article>
)

DashboardProducts.propTypes = {
  accountId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  chart: PropTypes.array,
  results: PropTypes.array,
}

const mapStateToProps = ({summaryProducts}) => ({...summaryProducts})

export default connect(mapStateToProps)(withAccountId(DashboardProducts))
