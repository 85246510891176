import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {FormBuyBox} from '../../containers'
import {Grid, Popover} from '@mui/material'
import {Button} from '../Button'

export const BuyBox = (props) => {
  const {title, id, config, catalog} = props

  const [modalOpen, setModalOpen] = useState(null)

  const handleClick = event => setModalOpen(event.currentTarget)

  const handleClose = event => {
    event && event.preventDefault()

    setModalOpen(null)
  }

  return (
    <Grid style={{position: 'absolute ', bottom: '2.5rem', right: '10rem'}}>
      <Button onClick={handleClick} secondary>
        Solicitar produto
      </Button>

      <Popover
        id={modalOpen ? 'simple-popover' : undefined}
        onClose={handleClose}
        anchorEl={modalOpen}
        open={!!modalOpen}
        anchorOrigin={props.position}
      >
        <article className="buy-box">
          <div className="title">{title}</div>

          <FormBuyBox
            id={id}
            catalog={catalog}
            config={config}
            handleClose={handleClose}
            inPopover
          />
        </article>
      </Popover>
    </Grid>
  )
}

BuyBox.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  title: PropTypes.string,
  config: PropTypes.object,
  catalog: PropTypes.string,
}
