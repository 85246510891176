import PropTypes from 'prop-types';
import React from 'react';
import { UptimeInstalled, DashboardUptime } from '../../containers';
import { Card } from '../../components';

export class Uptime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <section className="content">
                <DashboardUptime />
                <Card>
                    <UptimeInstalled {...this.props} />
                </Card>
            </section>
        );
    }
}

Uptime.propTypes = {
    setTitle: PropTypes.func,
};
