import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Field, reduxForm, reset } from 'redux-form'
import { connect } from 'react-redux'
import { TextField, Button, Loading } from '../../components'
import { fetchFamily, saveFamily, createFamily } from '../../actions/families'

class FormFamily extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.edit) {
      this.props.setBreadcrumbName(<Loading loading />)
      this.props.dispatch(fetchFamily(this.props.familyId))
        .then(({ value }) => {
          this.props.setBreadcrumbName(value.title)
        })
    }
  }

  onSubmit(data) {
    if (this.props.edit) {
      return this.props.saveFamily(this.props.familyId, data)
    }

    return this.props.createFamily(data)
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="form">
        <Loading loading={this.props.isLoading} />

        <Field
          label="Nome"
          name="title"
          component={TextField}
        />

        <Field
          label="Descrição"
          name="description"
          selector="textarea"
          className="textarea"
          component={TextField}
        />

        <div className="buttons">
          <Button
            loading={this.props.submitting}
          >
            Salvar
          </Button>
          <Link to="/system/families/">Cancelar</Link>
        </div>
      </form>
    )
  }
}

FormFamily.propTypes = {
  fetchFamily: PropTypes.func,
  saveFamily: PropTypes.func,
  setBreadcrumbName: PropTypes.func,
  familyId: PropTypes.string,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  submitting: PropTypes.bool,
}

const Form = reduxForm({
  form: 'formFamily',
})(FormFamily)

const mapStateToProps = (state) => ({
  isLoading: state.families.isLoading,
})

export default connect(mapStateToProps, { fetchFamily, saveFamily, createFamily, reset })(Form)
