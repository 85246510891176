import { css } from 'styled-components';

export default css`
    .two-factor .mobile-icons {
        display: flex;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .two-factor .mobile-icons > .item {
        margin-right: 1rem;
    }

    .two-factor .description {
        margin-bottom: 2rem;
    }

    .two-factor h3 {
        margin-top: 2rem;
    }

    .two-factor .enable-step {
        display: flex;
        margin-bottom: 1rem;
    }
`;
