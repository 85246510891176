import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Business, Person } from '@material-ui/icons'
import React from "react";
import AccountOriginText from "../AccountOriginText";

const AccountList = ({items, onClick, currentAccount }) => (
  <ul className="accounts">
    {items.map((item) =>
      <li
        key={item.id}
        className={classnames('item', { active: item.id === currentAccount })}
        onClick={() => onClick(item)}
      >
        {item.type === 'user'
          ? <Person style={{ margin: '5px 10px 5px 5px' }} />
          : <Business style={{ margin: '5px 10px 5px 5px' }} />
        }

        <span>
          <b>{item.name}</b>
          <span>{item.description || ''}</span>
          <AccountOriginText origin={item.origin} />
        </span>
      </li>
    )}
  </ul>
)

AccountList.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  currentAccount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
}

export default AccountList
