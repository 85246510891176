import { useEffect, useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { useSelector, useDispatch } from 'react-redux'
import { statusList } from '../../../services/pentest-fields'
import {
  fetchPentests,
  fetchSummaryPentests,
  deletePentest,
  updatePentest,
  updateRetest,
} from '../../../actions/pentests'

import Filters from './Filters'
import ListRow from './ListRow'
import { Card, PageTitle, Loading } from '../../../components'

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton
} from '@mui/material'
import useStyles from './styles'

const skeletonStructure = ['1', '2', '3', '4', '5']

const columns = [
  { label: 'Status', name: 'status', size: 2 },
  { label: 'Url', name: 'url', size: 3 },
  { label: 'Tipo', name: 'modality', size: 2 },
  { label: 'Pentester', name: 'pentester', size: 2 },
  { label: 'Data Inicial', name: 'exec_start', size: 1 },
  { label: 'Data Final', name: 'exec_end', size: 1 },
  { label: 'Ações', name: 'actions', size: 1}
]

export default function PenTestList(props) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const account = useSelector((state) => state.account)
  const pentests = useSelector((state) => state.pentests)
  const summary = useSelector((state) => state.pentestSummary)
  const auth = useSelector((state) => state.auth)

  const [filters, setFilters] = useState({})
  const [open, setOpen] = useState(undefined)
  const [accountId, setAccountId] = useState(undefined)

  const isLoading = (!account[0] || _.isEmpty(summary.filters))

  const fetchData = useCallback(async (accountId, params) => {
    if (_.isEmpty(summary.filters)) {
      dispatch(fetchSummaryPentests(accountId))
    }

    dispatch(fetchPentests(accountId, params, auth.data.role))
  }, [auth.data.role, dispatch, summary.filters])

  useEffect(() => {
    props.setTitle(props.title)
  }, [props])

  useEffect(() => {
    account[0] &&
      setAccountId(account[0].id)
  }, [account])

  useEffect(() => {
    if (accountId && !pentests.isLoading) {
      fetchData(accountId, filters)
    }
  }, [accountId, fetchData, filters, pentests.isLoading])

  const handleChangeFilterFields = ({ target }) => {
    setFilters(oldFilters => ({
      ...oldFilters,
      [target.name]: target.value,
    }))
  }

  const handleRemovePentest = async (pentestId) => {
    await dispatch(deletePentest(account[0].id, pentestId))

    return fetchData(accountId, filters)
  }

  const handleChangeStatus = async (data, status, retestData) => {
    let FormattedSendData = { ...data }

    if (retestData) {
      FormattedSendData = {
        ...FormattedSendData,
        retest_exec_start: data.retest[0].retest_exec_start,
        retest_exec_end: data.retest[0].retest_exec_end,
        retest_pentester: data.retest[0].retest_pentester,
        retest: [{
          ...retestData,
          status: statusList[status].value,
        }],
      }

      delete FormattedSendData.id
      delete FormattedSendData.retest[0].id
    } else {
      FormattedSendData = {
        ...FormattedSendData,
        status: statusList[status].value,
      }

      if (data.has_retest) {
        FormattedSendData = {
          ...FormattedSendData,
          retest_exec_start: data.retest[0].retest_exec_start,
          retest_exec_end: data.retest[0].retest_exec_end,
          retest_pentester: data.retest[0].retest_pentester,
        }
      }

      delete FormattedSendData.retest
      delete FormattedSendData.id
    }

    retestData
      ? await dispatch(
        updateRetest(accountId, data.id, data.retest[0].id, FormattedSendData))
      : await dispatch(
        updatePentest(accountId, data.id, FormattedSendData))
  }

  return (
    isLoading
      ? <Loading loading />
      : (
        <Grid className={classes.tableContainer}>
          <Card className={classes.card}>
            <PageTitle title="Listagem de Pentest" />
          </Card>

          <Grid className={classes.container}>
            <Table className={classes.root}>
              <TableHead className={classes.header}>
                <Filters
                  data={summary.filters}
                  filtersValues={filters}
                  handleChange={handleChangeFilterFields}
                  clearFilters={() => setFilters({})}
                />
              </TableHead>

              <TableHead className={classes.header}>
                <TableRow>
                  <TableCell colSpan={1} />

                  {columns.map(row =>
                    <TableCell key={row.name} align="left">
                      <Typography className={classes.headerItem} variant="h6">
                        {row.label}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {pentests.isLoading
                  ? skeletonStructure.map(x =>
                    <tr key={x}>
                      <td colSpan="8">
                        <Skeleton animation="wave" height={100} />
                      </td>
                    </tr>
                  )
                  : pentests.allIds.map(id =>
                    <ListRow
                      key={id}
                      auth={auth}
                      data={pentests.results[id]}
                      summary={summary.filters}
                      open={open}
                      isLoading={pentests.isLoading}
                      handleOpen={row => setOpen(row)}
                      handleDelete={row => handleRemovePentest(row)}
                      handleChangeStatus={handleChangeStatus}
                    />
                  )
                }
              </TableBody>
            </Table>

            {_.isEmpty(pentests.allIds) && !pentests.isLoading &&
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: '50vh' }}
              >
                <Typography
                  variant="body1"
                  style={{ fontSize: '1.5rem' }}
                >
                  Nenhum Pentest encontrado.
                </Typography>
              </Grid>
            }
          </Grid>

          {/* {_.isEmpty(pentests.results) && !pentests.isLoading &&
          dispatch(push('/pentest/landing-page'))
        } */}
        </Grid>
      )
  )
}

PenTestList.propTypes = {
  target: PropTypes.object,
  currentAccount: PropTypes.string,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
