import {LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER} from '../actions/auth'

const INITIAL_STATE = {
  token: null,
  data: null,
  isAuthenticated: false,
  isAuthenticating: false,
  isAdmin: false,
  unlock_date: null,
}


export const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        unlock_date: null,
      }

    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        data: action.payload.decodedToken,
        isAuthenticated: true,
        isAuthenticating: false,
        isAdmin: action.payload.decodedToken.role === 'admin',
        unlock_date: null,
      }
    }

    case LOGIN_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        isAdmin: false,
        token: null,
        data: null,
        unlock_date: action.payload.unlock_date,
      }

    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        isAdmin: false,
        token: null,
        data: null,
        unlock_date: null,
      }

    default:
      return state
  }
}
