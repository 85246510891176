import { css } from 'styled-components';

import {
    $fontSizeBase,
    $fontSizeLarge,
    $fontSizeLarger,
    $fontSizeLargest,
    $fontSizeSmall,
    $fontSizeSmaller,
    $spaceBase,
    spaceBase,
} from '../settings/variables';

export default css`
    /* Headers */
    h1 {
        font-weight: 300;
        font-size: ${$fontSizeLargest};
    }

    h2 {
        font-weight: 300;
        font-size: ${$fontSizeLarger};
        margin: ${spaceBase * 2}rem 0 ${$spaceBase} 0;
    }

    h3 {
        font-weight: 700;
        font-size: ${$fontSizeLarge};
    }

    h4 {
        font-weight: 700;
        font-size: ${$fontSizeBase};
    }

    h5 {
        font-weight: 400;
        font-size: ${$fontSizeSmall};
    }

    h6 {
        font-weight: 400;
        font-size: ${$fontSizeSmaller};
    }

    /* Paragraph */
    p {
        margin: ${spaceBase / 2}rem 0;
    }
`;
