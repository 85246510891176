import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import zxcvbn from "zxcvbn";

export class PasswordField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            score: 0,
            size: 0,
        };

        this.colors = [
            'red',
            'rgb(253, 211, 46)',
            'rgb(121, 157, 191)',
            'rgb(108, 219, 41)',
        ];

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setSizeInput(this.refs.input.offsetWidth);
    }

    setSizeInput(size) {
        this.setState({ size });
    }

    getSizeBar() {
        const { size, score } = this.state;
        return (size * (25 * score)) / 100;
    }

    handleChange(value) {
        const { score } = zxcvbn(value);
        this.setState({ score });
    }

    render() {
        const { input, label, className, required, error, disabled, handleBlur, placeholder } = this.props;
        const { error: metaError, touched } = this.props.meta;
        const markRequired = (!!required ? '*' : '');
        const inputClass = classnames('input', className, {
            'input-error': (!!error || !!metaError) && touched,
        });
        const spanClass = classnames('input-error', className);

        return (
            <div className="field">
                {label &&
                    <label htmlFor="">{`${label} ${markRequired}`}</label>
                }
                <input
                    {...input}
                    ref="input"
                    type="password"
                    className={inputClass}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={event => {
                        this.handleChange(input.value);
                        input.onChange(event);
                    }}
                    onBlur={event => {
                        if (handleBlur) {
                            handleBlur(input.value);
                        }

                        input.onBlur(event);
                    }}
                />
                <span style={{
                    width: `${this.getSizeBar()}px`,
                    height: '0.6rem',
                    display: 'block',
                    marginTop: '0.3rem',
                    marginBottom: '1rem',
                    backgroundColor: this.colors[this.state.score - 1],
                    transition: 'all 0.3s ease',
                }} />

                {(metaError || error) && touched &&
                    <span className={spanClass}>{metaError || error}</span>
                }
            </div>
        );
    }
}

PasswordField.defaultProps = {
    className: '',
};

PasswordField.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    disabled: PropTypes.bool,
    handleBlur: PropTypes.func,
    placeholder: PropTypes.string,
};
