import { fetch } from '../services/fetch';
import { toParams } from '../services/utils';
import { push } from 'connected-react-router';
import { setMessage, SUCCESS } from '../modules/messages';

export const FETCH = 'evidences/FETCH';
export const FETCH_ALL = 'evidences/FETCH_ALL';
export const SAVE = 'evidences/SAVE';
export const FALSE_POSITIVE = 'evidences/FALSE_POSITIVE';


export function fetchEvidences(params = {}) {
    const query = toParams(params);
    const request = fetch(`/evidences${query}`)
    .then(res => res.json());

    return {
        type: FETCH_ALL,
        payload: request,
    };
}

export function fetchEvidence(id) {
    const request = fetch(`/evidences/${id}`)
    .then(res => res.json());

    return {
        type: FETCH,
        payload: request,
    };
}

export function saveEvidence(id, data) {
    return dispatch => {
        const request = fetch(`/evidences/${id}`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => {
            dispatch(push('/system/evidences'));
            dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'));
            return res.json();
        });

        return dispatch({
            type: SAVE,
            payload: request,
        });
    };
}
