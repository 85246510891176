import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../components';
import { validateDns } from '../modules/domainValidation';

const DnsValidation = (props) => {
    const sendValidation = (e) => {
        e.preventDefault();
        props.validateDns(props.domain.id);
    };

    return (
        <article>
            <h2>Validação por DNS</h2>

            <p>Para validar seu domínio você deve criar uma entrada TXT nos registros de DNS conforme abaixo.</p>

            <table className="table-info">
                <tbody>
                    <tr>
                        <td className="title">Nome</td>
                        <td>_siteblindado.{props.domain.name}</td>
                    </tr>
                    <tr>
                        <td className="title">Tipo</td>
                        <td>TXT</td>
                    </tr>
                    <tr>
                        <td className="title">Valor</td>
                        <td>{props.domain.validation_code}</td>
                    </tr>
                </tbody>
            </table>

            <p>Depois de adicioná-lo clique no botão abaixo.</p>

            <div className="space-button">
                <Button onClick={sendValidation} loading={props.validating}>
                    Validar Domínio via DNS
                </Button>
            </div>
        </article>
    );
};

DnsValidation.propTypes = {
    domain: PropTypes.object,
    validating: PropTypes.bool,
    validateDns: PropTypes.func,
};


function mapStateToProps(state) {
    return {
        domain: state.domainValidation.domain,
        validating: state.domainValidation.validating,
    };
}

export default connect(mapStateToProps, { validateDns })(DnsValidation);
