import React, {memo, useEffect, useState} from 'react'
import {Route, Switch} from 'react-router-dom'
import {Content} from '../components'

export function GenericIndex(props) {
  const [title, setTitle] = useState('');
  const [submenu, setSubmenu] = useState([]);

  useEffect(() => {
    setTitle(props.title);
    if (props.submenu) {
      setSubmenu(props.submenu);
    }
  }, [props])

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}
