import PropTypes from 'prop-types';
import React from 'react';
import { Cell, BarChart, Bar, XAxis, CartesianGrid, LabelList } from 'recharts';

const colors = [
    '#2D343D',
    '#FD4D4D',
    '#F2AC5B',
    '#FEE549',
];

export const ExpiringCertificates = ({ data }) => (
    <div className="chart">
        <h1 className="chart-title">Certificados Expirando</h1>

        <BarChart
            barSize={40}
            width={300}
            height={250}
            data={data}
            margin={{ top: 15, right: 5, bottom: 5, left: 5 }}
        >
            <XAxis dataKey="name" />
            <CartesianGrid
                strokeDasharray="0"
                stroke="#dddddd"
                vertical={false}
            />

            <Bar
                dataKey="value"
                fill="red"
                isAnimationActive={false}
            >
                <LabelList dataKey="value" position="top" fill="#000" />
                {data.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]} />)}
            </Bar>


        </BarChart>
    </div>
);

ExpiringCertificates.propTypes = {
    data: PropTypes.array.isRequired,
};
