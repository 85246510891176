import React, { memo } from 'react'
import PropTypes from 'prop-types'
import useStyles from '../styles'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

const TextFormField = ({
  label,
  name,
  defaultValue,
  control,
  error,
  rules,
  disabled,
  type
}) => {
  const classes = useStyles()

  return (
    <Controller
      render={({ field }) =>
        <TextField
          className={classes.inputValue}
          label={label}
          name={name}
          variant="outlined"
          error={!!error}
          disabled={disabled}
          type={type}
          {...field}
        />
      }
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

TextFormField.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  control: PropTypes.any,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  type: PropTypes.string,
}

export default memo(TextFormField)
