import React from 'react'

import { Delete } from '@mui/icons-material'
import { IconButton, Popover } from '@mui/material'

export const RemoveApplication = props => {
  const { id, onRemove, section } = props

  const [state, setState] = React.useState({
    open: false,
  })

  const remove = event => {
    event.preventDefault()

    onRemove(id)
    setState(oldState => ({
      ...oldState,
      open: false,
    }))
  }

  const close = event => {
    event.preventDefault()

    setState(oldState => ({
      ...oldState,
      open: false,
    }))
  }

  const handleClick = event => {
    setState(oldState => ({
      ...oldState,
      open: event.currentTarget,
    }))
  }

  return (
    <React.Fragment>
      <IconButton className="delete" onClick={handleClick} style={{ padding: 0, margin: '0 10px' }}>
        <Delete
          // className="delete"
          fontSize="large"
          style={{ margin: 5 }}
        />
      </IconButton>

      <Popover
        id={state.open ? 'simple-popover' : undefined}
        onClose={close}
        anchorEl={state.open}
        open={!!state.open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="remove-item">
          <h1 className="remove-title">
            Remover {section}
          </h1>

          <p className="remove-text">
            Esta {section} será removida e não há como reverter esta ação.
          </p>

          <div className="remove-actions">
            <a href='/#' onClick={remove}>
              Remover
            </a>
            <a href='/#' onClick={close}>
              Cancelar
            </a>
          </div>
        </div>
      </Popover>
    </React.Fragment>
  )
}

export default RemoveApplication
