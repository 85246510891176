import { goBack } from 'connected-react-router'

import { fetch } from '../../services/fetch'
import { toParams } from '../../services/utils'
import { setMessage, SUCCESS, ERROR } from '../../modules/messages'
import _ from 'lodash'
import {useHistory} from "react-router-dom";

export const FETCH_SUMMARY = '@pentests/FETCH_SUMMARY'
export const FETCH_PENTESTS = '@pentests/FETCH_PENTESTS'
export const FETCH_PENTESTS_USER = '@pentests/FETCH_PENTESTS_USER'
export const FETCH_PENTEST = '@pentests/FETCH_PENTEST'
export const CREATE_PENTEST = '@pentests/CREATE_PENTEST'
export const UPDATE_PENTEST = '@pentests/UPDATE_PENTEST'
export const DELETE_PENTEST = '@pentests/DELETE_PENTEST'

export const fetchPentests = (accountId, params = {}, role) => {
  const query = toParams(params)
  const history = useHistory();

  const request = fetch(`/accounts/${accountId}/pentests${query}`)
    .then(res => res.json())
    .then(response => {
      if (_.isEmpty(response.results) && role === 'user') {
        history.replace('/pentest/landing-page')
      }

      return response
    })
    .catch(err => {
      history.replace('/pentest/landing-page')

      return err.errors
    })

  return {
    type: FETCH_PENTESTS,
    payload: request,
  }
}

export const fetchAllPentests = (params = {}) => {
  const query = toParams(params)

  const request = fetch(`/pentests${query}`)
    .then(res => res.json())

  return {
    type: FETCH_PENTESTS,
    payload: request,
  }
}

export function fetchSummaryPentests(accountId) {
  const request = fetch(`/accounts/${accountId}/pentests/summary`).then(res => res.json())

  return {
    type: FETCH_SUMMARY,
    payload: request,
  }
}

export function fetchPentest(accountId, pentestId) {
  const request = fetch(`/accounts/${accountId}/pentests/${pentestId}`).then(res => res.json())

  return {
    type: FETCH_PENTEST,
    payload: request,
  }
}

export const createPentest = (accountId, data) => dispatch => {
  const request = fetch(`/accounts/${accountId}/pentests`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then(res => {
      dispatch(goBack())
      dispatch(setMessage(SUCCESS, 'PENTEST cadastrado com sucesso!'))

      return res.json()
    })
    .catch(err => {
      const message = err.errors[0].message || 'Erro ao cadastrar PENTEST'
      // eslint-disable-next-line no-unused-expressions
      dispatch(setMessage(ERROR, message))

      return err.errors
    })

  return dispatch({
    type: CREATE_PENTEST,
    payload: request,
  })
}


export function updatePentest(accountId, pentestId, data) {
  return dispatch => {
    const request = fetch(`/accounts/${accountId}/pentests/${pentestId}`, {
      method: 'PUT', body: JSON.stringify(data),
    }).then(res => {
      dispatch(goBack())
      dispatch(setMessage(SUCCESS, 'PENTEST atualizado com sucesso!'))

      return res.json()
    }).catch(err => {
      const message = err.errors[0].message || 'Erro ao cadastrar PENTEST'

      dispatch(setMessage(ERROR, message))
    })

    return dispatch({
      type: UPDATE_PENTEST,
      payload: request,
    })
  }
}

export function updateRetest(accountId, pentestId, retestId, data) {
  return dispatch => {
    const request = fetch(`/accounts/${accountId}/pentests/${pentestId}/retest/${retestId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
    .then(res => {
      dispatch(setMessage(SUCCESS, 'RETEST atualizado com sucesso!'))

      return res.json()
    })

    return dispatch({
      type: UPDATE_PENTEST,
      payload: request,
    })
  }
}

export function deletePentest(accountId, pentestId) {
  return (dispatch) => {
    const request = fetch(`/accounts/${accountId}/pentests/${pentestId}`, { method: 'DELETE' })
      .then(() => {
        dispatch(setMessage(SUCCESS, 'PENTEST removido com sucesso!'))
      })
      .catch(err => {
        const message = err.message || 'Erro ao remover PENTEST'

        dispatch(setMessage(ERROR, message))

        throw err
      })

    dispatch({
      type: DELETE_PENTEST,
      payload: request,
    })
  }
}
