import {fetch} from '../services/fetch'
import {push} from 'connected-react-router'
import {ERROR, setMessage, SUCCESS} from '../modules/messages'
import {toParams} from '../services/utils'
import {refreshToken, tokenHandler} from './auth'

export const FETCH_INVITES = 'organizations/FETCH_INVITES'
export const FETCH_USERS = 'organizations/FETCH_USERS'
export const CLEAR_USERS = 'organizations/CLEAR_USERS'
export const FETCH_ALL = 'organizations/FETCH_ALL'
export const FETCH = 'organizations/FETCH'
export const DELETE = 'organizations/DELETE'
export const DELETE_USER = 'organizations/DELETE_USER'
export const DELETE_INVITE = 'organizations/DELETE_INVITE'
export const SAVE = 'organizations/SAVE'
export const CHANGE_USER = 'organizations/CHANGE_USER'
export const CREATE = 'organizations/CREATE'

export const fetchOrganizations = (userId, params = {}) => {
  const query = toParams(params)
  const request = fetch(`/organizations${query}`).then((res) => res.json())
  return {
    type: FETCH_ALL,
    payload: request,
  }
}

export const fetchOrganization = (organizationId) => {
  if (organizationId === null) {
    return {
      type: FETCH,
      payload: null,
    }
  }
  const request = fetch(`/organizations/${organizationId}`)
    .then((res) => res.json())
    .then((data) => data)
    .catch(err => {
      setMessage(ERROR, 'Oops, ocorreu um erro.')

      throw err
    })

  return {
    type: FETCH,
    payload: request,
  }
}

export const createOrganization = (data) => (dispatch) => {
  const request = fetch('/organizations', {
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then(() => {
      tokenHandler(dispatch)
    })
    .then(res => {
      // dispatch(refreshToken());
      dispatch(push('/organizations'))
      dispatch(setMessage(SUCCESS, 'Organização criada com sucesso!'))

      return res.json()
    })
    .catch(error => {
      error.message
        ? dispatch(setMessage(ERROR, error.message))
        : dispatch(setMessage(ERROR, 'Ocorreu um erro'))
    })

  return dispatch({
    type: CREATE,
    payload: request,
  })
}

export const saveOrganization = (organizationId, data) => (
  dispatch,
  getState
) => {
  const {user} = getState().auth.data

  const request = fetch(`/organizations/${organizationId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
    .then(tokenHandler(dispatch))
    .then(() => {
      dispatch(push('/organizations'))
      dispatch(fetchOrganizations(user.id))
      dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'))
    })
    .catch(error => {
      error.message
        ? dispatch(setMessage(ERROR, error.message))
        : dispatch(setMessage(ERROR, 'Ocorreu um erro'))
    })

  return dispatch({
    type: SAVE,
    payload: request,
  })
}

export const deleteOrganization = (organizationId) => (dispatch, getState) => {
  const {user} = getState().auth.data

  const request = fetch(`/organizations/${organizationId}`, {
    method: 'DELETE',
  })
    .then((res) => {
      dispatch(refreshToken())
      dispatch(push('/organizations'))
      dispatch(fetchOrganizations(user.id))
      dispatch(setMessage(SUCCESS, 'Organização removida com sucesso!'))
      return res.json()
    })
    .catch((res) => res)

  return dispatch({
    type: DELETE,
    payload: request,
  })
}

// TODO separar arquivo
export const fetchOrganizationUsers = (organizationId, params = {}) => {
  const query = toParams(params)

  const request = fetch(
    `/organizations/${organizationId}/users${query}`
  ).then((res) => res.json())
  return {
    type: FETCH_USERS,
    payload: request,
  }
}

export const clearOrganizationUsers = () => ({
  type: CLEAR_USERS,
})

export const deleteOrganizationUser = (organizationId, userId) => (
  dispatch
) => {
  const request = fetch(`/organizations/${organizationId}/users/${userId}`, {
    method: 'DELETE',
  })
    .then((res) => {
      dispatch(fetchOrganizationUsers(organizationId))
      dispatch(setMessage(SUCCESS, 'Usuário removido com sucesso!'))

      return res.json()
    })
    .catch((res) => res)

  return dispatch({
    type: DELETE_USER,
    payload: request,
  })
}

export const changeOrganizationUser = (
  organizationId,
  userId,
  data,
  reFetch = true
) => (dispatch) => {
  const request = fetch(`/organizations/${organizationId}/users/${userId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
    .then((res) => {
      dispatch(fetchOrganizationUsers(organizationId))

      if (res.status === 403) {
        throw Object.assign(
          new Error('Você não tem permissão parar alterar o usuário!'),
          {code: 402}
        );
      }

      dispatch(setMessage(SUCCESS, 'Usuário alterado com sucesso!'))
      return res.json()
    })
    .catch(error => {
      dispatch(setMessage(ERROR, error))
      throw error
    })

  return dispatch({
    type: CHANGE_USER,
    payload: request,
  })
}

export const saveOrganizationUser = (
  organizationId,
  userId,
  data,
  reFetch = true
) => (dispatch) => {
  const request = fetch(`/organizations/${organizationId}/users/${userId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (reFetch) {
        dispatch(fetchOrganizationUsers(organizationId))
      }
      dispatch(setMessage(SUCCESS, 'Usuário alterado com sucesso!'))
      return res.json()
    })
    .catch((res) => res)

  return dispatch({
    type: CHANGE_USER,
    payload: request,
  })
}

export const fetchOrganizationInvites = (organizationId, params = {}) => {
  const query = toParams(params)
  const request = fetch(
    `/organizations/${organizationId}/invites${query}`
  ).then((res) => res.json())
  return {
    type: FETCH_INVITES,
    payload: request,
  }
}

export const sendInvite = (organizationId, email) => (dispatch) =>
  fetch(`/organizations/${organizationId}/invites`, {
    method: 'POST',
    body: JSON.stringify({email}),
  })
    .then((res) => {
      dispatch(setMessage(SUCCESS, 'Convite enviado com sucesso!'))
      dispatch(fetchOrganizationInvites(organizationId))

      return res.json()
    })
    .catch((res) => res)

export const deleteOrganizationInvite = (organizationId, inviteId) => (
  dispatch
) => {
  const request = fetch(`/invites/${inviteId}`, {method: 'DELETE'})
    .then(res => {
      if (res.status === 403) {
        throw Object.assign(
          new Error('Você não tem permissão parar alterar o usuário!'),
          {code: 402}
        );
      }

      dispatch(setMessage(SUCCESS, 'Convite removido com sucesso!'))
      dispatch(fetchOrganizationInvites(organizationId))
    })
    .catch(err => {
      dispatch(setMessage(ERROR, err))
      dispatch(fetchOrganizationInvites(organizationId))
    })

  return dispatch({
    type: DELETE_INVITE,
    payload: request,
  })
}
