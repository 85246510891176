export const risks = [
    { label: 'Nenhum', value: '' },
    { label: 'Info', value: 1 },
    { label: 'Baixo', value: 2 },
    { label: 'Médio', value: 3 },
    { label: 'Alto', value: 4 },
    { label: 'Crítico', value: 5 },
]

export const risk = index => risks[index].label

export const statusList = {
    to_start: { value: 'to_start', label: 'Não iniciado' },
    in_progress: { value: 'in_progress', label: 'Em progresso' },
    done: { value: 'done', label: 'Concluído' },
    paused: { value: 'paused', label: 'Pausado' }
}
