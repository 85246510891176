import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { fetchOrganizationUsers, changeOrganizationUser } from '../../actions/organizations'
import { CheckboxItem, Loading } from '../../components'

class UsersNotify extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    if (!this.props.organizationUsers.isLoading) {
      this.props.fetchOrganizationUsers(this.props.organizationId, { page_size: 99999 })
    }
  }

  handleClick(check, id) {
    this.props.changeOrganizationUser(this.props.organizationId, id, { notify: check }, false)
  }

  render() {
    const { allIds, results, isLoading } = this.props.organizationUsers

    return (
      <article className="users-notify">
        <Loading loading={isLoading} />

        {allIds.map(id =>
          <div key={id} className="item-user">
            <div className="info">
              <span className="user-name">
                {`${results[id].user.first_name} `}

                {results[id].user.last_name || ''}
              </span> <br />

              <span className="user-email">
                {results[id].user.email}
              </span>
            </div>

            <div className="button">
              <CheckboxItem
                value={results[id].notify}
                onClick={(check) => this.handleClick(check, id)}
              />
            </div>
          </div>
        )}
      </article>
    )
  }
}

UsersNotify.propTypes = {
  organizationId: PropTypes.string,
  organizationUsers: PropTypes.object,
  fetchOrganizationUsers: PropTypes.func,
  changeOrganizationUser: PropTypes.func,
}

const mapStateToProps = ({ organizationUsers }) => ({ organizationUsers })

export default connect(mapStateToProps, { fetchOrganizationUsers, changeOrganizationUser })(UsersNotify)
