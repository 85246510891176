import { css } from 'styled-components';

export default css`
    .graphs {
        display: flex;
        justify-content: space-around;
        margin: 1rem 0;
    }

    .graphs-item {
        text-align: center;
        min-width: 40rem;
    }
`;
