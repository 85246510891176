import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Loading } from '../../components'
import { acceptInvite } from '../../actions/invite'

class InvitationAccepted extends React.Component {
  componentDidMount() {
    this.props.setTitle('Convite Aceito')

    this.props.acceptInvite(this.props.match.params.id)
  }

  render() {
    return (
      <Loading loading />
    )
  }
}

InvitationAccepted.propTypes = {
  setTitle: PropTypes.func,
  acceptInvite: PropTypes.func,
  params: PropTypes.object,
}

export default connect(null, { acceptInvite })(InvitationAccepted)
