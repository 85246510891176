import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

// import { Container } from './styles';
import { Button, TableWrap, TableWrapActions, TableWrapLeft } from '../../../../../components'
import { Grid } from '@material-ui/core'
import PentestVulnerabilityForm from '../Form'

import { risk } from '../../../../../services/pentest-fields'
import { fetchKnowledgebase } from '../../../../../actions/system/pentest-knowledgebase'

function PenTestVulnerabilitySearch(props) {
  const dispatch = useDispatch()
  const { accountId, pentestId, reportId, onCloseModal } = props
  const [data, setData] = useState()

  const pentestKnowledgebase = useSelector(state => state.pentestKnowledgebase)

  async function addVulnerability(obj) {
    setData(obj)
  }

  function rowAction(text, obj) {
    return <span onClick={() => addVulnerability(obj)}>{text}</span>
  }

  const model = {
    vulnerability_id: {
      name: 'Código',
      renderer: (name, obj) => (
        <span style={{ cursor: 'pointer' }}>{rowAction(obj.vulnerability_id, obj)}</span>
      ),
    },
    title: {
      name: 'Título',
      renderer: (name, obj) => (
        <span style={{ cursor: 'pointer' }}>{rowAction(obj.title, obj)}</span>
      ),
    },
    ports: {
      name: 'Porta',
      renderer: (name, obj) => (
        <span style={{ cursor: 'pointer' }}>{rowAction(obj.ports, obj)}</span>
      ),
    },
    risk: {
      name: 'Risco',
      renderer: (name, obj) => (
        <span style={{ cursor: 'pointer' }}>{rowAction(risk(obj.risk), obj)}</span>
      ),
    },
  }

  async function onSubmit(id, term) {
    await dispatch(fetchKnowledgebase(id, term))
  }

  return (
    <section className="content">
      {!data &&
        <Grid>
          <TableWrap
            model={model}
            source={pentestKnowledgebase}
            accountId={accountId}
            actionFetch={onSubmit}
            location={props.location}
            hasSearch
          >
            <TableWrapActions>
              <TableWrapLeft>
                Clique em uma vulnerabilidade para adicionar
              </TableWrapLeft>
            </TableWrapActions>
          </TableWrap>
          <Button onClick={onCloseModal}> Voltar</Button>
        </Grid>
      }

      {data &&
        <PentestVulnerabilityForm
          {...data}
          accountId={accountId}
          pentestId={pentestId}
          reportId={reportId}
          onCloseModal={onCloseModal}
          new
        />
      }
    </section>
  )
}

PenTestVulnerabilitySearch.propTypes = {
  location: PropTypes.object,
  accountId: PropTypes.string,
  pentestId: PropTypes.string,
  reportId: PropTypes.string,
  onCloseModal: PropTypes.func,
}

export default PenTestVulnerabilitySearch
