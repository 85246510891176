import { fetch } from '../services/fetch';

const translate = (data) => {
    const defaultData = {
        target: 'pt',
    };

    return fetch('/translate', {
        method: 'POST',
        body: JSON.stringify({ ...defaultData, ...data }),
    })
    .then(res => res.json());
};

export default translate;
