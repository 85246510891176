import { FETCH_CHART_UPTIME_ONLINE, FETCH_CHART_UPTIME_HISTORY } from '../actions/uptime-chart';
import { LOGOUT_USER } from '../actions/auth';

const INITIAL_STATE = {
    isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${FETCH_CHART_UPTIME_ONLINE}_PENDING`:
            return { ...state, isLoading: action.payload.loading };
        case `${FETCH_CHART_UPTIME_ONLINE}_FULFILLED`: {
            const dataOnline = action.payload;
            return { ...state, dataOnline, isLoading: false };
        }
        case `${FETCH_CHART_UPTIME_HISTORY}_FULFILLED`: {
            const dataHistory = action.payload;
            return { ...state, dataHistory, isLoading: false };
        }
        case LOGOUT_USER:
            return INITIAL_STATE;

        default:
            return state;
    }
};
