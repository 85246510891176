import React from 'react';
import PropTypes from 'prop-types';

export default function Success({ firstName, email }) {
    return (
        <div className="success-signup">
            <h1 className="title">Criar Conta</h1>
            <h2>Cadastro realizado com sucesso!</h2>
            <h3>Bem-vindo {firstName}!</h3>
            <p>
                Em breve você receberá um e-mail no endereço <br />
                <strong>{email}</strong><br />
                com instruções para a ativação de sua conta.
            </p>
            <p>
                Caso não encontre nosso e-mail, procure na sua caixa de SPAM ou entre em contato conosco.
            </p>
            <p>
                Grato!<br />
                <strong>Equipe Site Blindado</strong>
            </p>
        </div>
    );
}

Success.propTypes = {
    firstName: PropTypes.string,
    email: PropTypes.string,
};
