import PropTypes from 'prop-types';
import React from 'react';

export const TableWrapLeft = ({ children }) => (
    <div className="items">
        {children}
    </div>
);

TableWrapLeft.defaultProps = {
    name: 'left',
};

TableWrapLeft.propTypes = {
    children: PropTypes.any,
};
