import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { SelectField } from '../../components';
import { fetchProductTypes } from '../../actions/products';

class SelectProductTypesField extends React.PureComponent {
    // eslint-disable-next-line no-undef
    static defaultProps = {
        name: 'product',
    }

    componentWillMount() {
        this.props.fetchProductTypes();
    }

    render() {
        return (
            <Field
                {...this.props}
                options={this.props.productsOptions}
                component={SelectField}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { productTypes } = state;
    return {
        productsOptions: productTypes.allIds.map(id => ({
            id: productTypes.results[id].id,
            description: productTypes.results[id].name,
        })),
    };
};

export default connect(mapStateToProps, { fetchProductTypes })(SelectProductTypesField);
