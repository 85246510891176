import { useEffect } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import {
  fetchSummaryPentests,
  fetchPentest,
  createPentest,
  updatePentest,
} from '../../../actions/pentests'

import { Button, Loading } from '../../../components'
import { Card, Grid, Typography } from '@mui/material'
import TextFormField from './FormFields/TextFormField'
import SelectFormField from './FormFields/SelectFormField'
import TextBlockField from './FormFields/TextBlockField'
import CalendarDatePicker from './FormFields/CalendarDatePicker'
import {useHistory} from "react-router-dom";

const pattersForm = {
  // eslint-disable-next-line no-useless-escape
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
}
export default function PenTestForm(props) {
  const { id } = props.match.params
  const history = useHistory()

  const { handleSubmit, control, formState: { errors, }, setValue } = useForm()

  const dispatch = useDispatch()

  const account = useSelector(state => state.account)
  const summary = useSelector(state => state.pentestSummary)
  const pentest = useSelector(state => state.pentest)

  const dateFormatter = (date) => {
    return date.format('YYYY-MM-DD')
  }

  useEffect(() => {
    props.setTitle(props.title)

    if (_.isEmpty(summary.filters)) {
      dispatch(fetchSummaryPentests())
    }

    if (account[0]) {
      id && dispatch(fetchPentest(account[0].id, id))
      setValue('corporate_name', account[0].name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, account, dispatch, summary.filters])

  // useEffect(() => {
  //   if (id && !_.isEmpty(pentest.results)) {
  //     setValue('url', pentest.results.url)
  //     setValue('modality', pentest.results.modality)
  //     setValue('pentester', pentest.results.pentester)
  //     setValue('exec_start', dateFormatter(pentest.results.exec_start))
  //     setValue('exec_end', dateFormatter(pentest.results.exec_end))
  //     setValue('comment', pentest.results.comment)
  //     setValue('has_retest', pentest.results.has_retest)

  //     setHasRetest(pentest.results.has_retest)
  //     setPentestIsDone(pentest.results.status === 'done' ? true : false)

  //     if (!_.isEmpty(pentest.results.retest)) {
  //       setFormDates({
  //         ...formDates,
  //         exec_start: pentest.results.exec_start,
  //         exec_end: pentest.results.exec_end,
  //         retest_exec_start: pentest.results.retest[0].retest_exec_start,
  //         retest_exec_end: pentest.results.retest[0].retest_exec_end
  //       })

  //       setValue('retest_pentester', pentest.results.retest[0].retest_pentester)
  //       setValue('retest_exec_start', pentest.results.retest[0].retest_exec_start)
  //       setValue('retest_exec_end', pentest.results.retest[0].retest_exec_end)
  //     }
  //   }
  // }, [pentest, id, setValue, formDates])

  const onSubmit = data => {
    let FormattedSendData = {
      ...data,
      exec_start: dateFormatter(data.date[0]),
      exec_end: dateFormatter(data.date[1]),
      // status: statusList.to_start.value,
      // retest_exec_start: data.has_retest ? dateFormatter(data.retest_exec_start) : null,
      // retest_exec_end: data.has_retest ? dateFormatter(data.retest_exec_end) : null,
    }

    delete FormattedSendData.date

    if (!data.has_retest) {
      delete FormattedSendData.retest_exec_start
      delete FormattedSendData.retest_exec_end
    }

    return id
      ? dispatch(updatePentest(account[0].id, id, FormattedSendData))
      : dispatch(createPentest(account[0].id, FormattedSendData))
  }

  const ErrorMessage = () =>
    <span className="input-error-v2">
      Campo obrigatório *
    </span>

  return (
    <Grid item xs={12} style={{ padding: '2rem' }} >
      <Grid container>
        <Grid item xs={12}>
          <Card style={{ height: '100%' }}>
            <form
              className="pentest-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                style={{ padding: '2rem' }}
                container
                spacing={2}
              >
                <Loading loading={pentest.isLoading} />

                <Grid
                  container
                  item
                  xs={6}
                  rowSpacing={2}
                  columnSpacing={1}
                >
                  <Grid item xs={12}>
                    <TextFormField
                      label="Nº do chamado *"
                      name="ticket_number"
                      type="number"
                      defaultValue=""
                      error={!!errors.called_id}
                      rules={{ required: true }}
                      control={control}
                    />

                    {errors.called_id && <ErrorMessage />}
                  </Grid>

                  <Grid item xs={12}>
                    <TextFormField
                      label="Razão Social *"
                      name="corporate_name"
                      defaultValue=""
                      error={!!errors.corporate_name}
                      rules={{ required: true }}
                      control={control}
                      disabled
                    />

                    {errors.corporate_name && <ErrorMessage />}
                  </Grid>

                  <Grid item xs={12}>
                    <TextFormField
                      label="Url *"
                      name="url"
                      defaultValue=""
                      error={!!errors.url}
                      rules={{ required: true, pattern: pattersForm.url }}
                      control={control}
                    />

                    {errors.url && <ErrorMessage />}
                  </Grid>

                  <Grid item xs={12}>
                    <SelectFormField
                      label="Pentester *"
                      name="pentester"
                      options={summary.filters ? summary.filters.pentester : []}
                      defaultValue=""
                      error={!!errors.pentester}
                      rules={{ required: true, validate: value => value !== '' }}
                      control={control}
                    />

                    {errors.pentester && <ErrorMessage />}
                  </Grid>

                  <Grid item xs={12}>
                    <SelectFormField
                      label="Tipo *"
                      name="modality"
                      options={summary.filters ? summary.filters.modality : []}
                      defaultValue=""
                      error={!!errors.modality}
                      rules={{
                        required: true,
                        validate: value => value !== '',
                      }}
                      control={control}
                    />

                    {errors.modality && <ErrorMessage />}
                  </Grid>

                  <Grid item xs={12}>
                    <TextBlockField
                      label="Comentário *"
                      name="comment"
                      defaultValue=""
                      rules={{ required: true, validate: value => value !== '' }}
                      error={!!errors.comment}
                      control={control}
                    />

                    {errors.comment && <ErrorMessage />}
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography align='center' variant='h3' style={{ color: errors.date ? 'red' : '' }}>
                    Período
                  </Typography>

                  <Grid
                    container
                    direction='column'
                    alignItems='center'
                    item
                    xs={12}
                  >
                    <Controller
                      render={(({ field }) =>
                        <CalendarDatePicker
                          {...field}
                          error={errors.date}
                          handleChange={value => setValue('date', value)}
                        />
                      )}
                      name='date'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={null}
                    />

                    {errors.date && <ErrorMessage />}
                  </Grid>
                </Grid>

                <Grid style={{ margin: '20px 0' }} item xs={12}>
                  <Button
                    onClick={() =>
                      history.goBack()}
                  >
                    Voltar
                  </Button>

                  <Button style={{ margin: '0 20px' }} type="submit">
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>

    </Grid>
  )
}

PenTestForm.propTypes = {
  params: PropTypes.object,
  route: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  nextStep: PropTypes.func,
  target: PropTypes.element,
  location: PropTypes.object,
  err: PropTypes.array,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
