import React, {useEffect, useState} from 'react';
import {Icon} from './Icon';
import {faBan, faCheckCircle, faInfoCircle, faTimes, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

export function Alert(props) {
  const [type, setType] = useState('default');
  const [visible, setVisible] = useState(true)
  const [timer, setTimer] = useState(5000)


  function getStyle() {
    return {
      top: visible && props.text ? '1rem' : '-10rem',
    };
  }

  function getIcon() {
    if (!type) {
      return;
    }

    const icons = {
      default: ['info', faCheckCircle],
      success: ['check_circle', faTimesCircle],
      error: ['cancel', faBan],
      info: ['info', faInfoCircle]
    };

    return <Icon name={icons[type][0]} icon={icons[type][1]}/>;
  }

  function close() {
    setVisible(false);
    clearTimeout(timer);
    if (props.onClose) {
      props.onClose();
    }
  }

  useEffect(() => {
    setVisible(true);
    setType(props.type);
    if (props.autoClose) {
      setTimeout(close, timer);
    }
  }, [props, timer])

  return (
    <div className={`alert-${type}`} style={getStyle()}>
      <div className="content">
        <span className="message">{getIcon()} {props.text}</span>
        <span onClick={() => close()} className="close">
          <Icon name="close" icon={faTimes}/>
        </span>
      </div>
    </div>
  );
}
