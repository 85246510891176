import { css } from 'styled-components';

import { $lightgrey700, $darkgrey600, $linkColorHover, $btnSecondaryColor } from '../settings/variables';

export default css`
    .choices {
        display: flex;
        justify-content: center;
    }

    .choices >.item {
        border: 1px solid ${$lightgrey700};
        padding: .2rem 1rem;
        cursor: pointer;
        color: ${$darkgrey600};
        min-width: 7rem;
        transition: all .4s ease-out;
    }

    .choices >.item:not(:first-child) {
        border-left: none;
    }

    .choices >.item:first-child {
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
    }

    .choices >.item:last-child {
        border-top-right-radius: .5rem;
        border-bottom-right-radius: .5rem;
    }

    .choices >.item:hover {
        background-color: ${$linkColorHover};
        color: white;
        border: solid 1px ${$linkColorHover};
    }

    .choices >.item.checked {
        background-color: ${$btnSecondaryColor};
        color: #fff;
        border: none;
        opacity: .3;
    }

    .choices >.item.checked:hover {
        cursor: default;
    }
`;
