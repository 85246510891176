import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableWrap, TableWrapActions, TableWrapLeft, TableWrapRight, CopyToClipBoardInList } from '../../components';
import { fetchPromos } from '../../actions/promocode';

class PromoCodesList extends React.PureComponent {
    // eslint-disable-next-line no-undef
    model = {
        product_type: {
            name: 'Tipo de Produto',
        },
        status: {
            name: 'Status',
        },
        justification: {
            name: 'Justificativa',
            style: { textAlign: 'left' },
        },
        creator: {
            name: 'Criador',
        },
        expires: {
            name: 'Expiração',
        },
        url: {
            name: 'Código',
            renderer: (item, obj) => (
                <CopyToClipBoardInList>
                    {obj.url}
                </CopyToClipBoardInList>
            ),
        },
    }

    render() {
        return (
            <TableWrap
                model={this.model}
                source={this.props.promocode}
                location={this.props.location}
                actionFetch={this.props.fetchPromos}
            >
                <TableWrapActions>

                    <TableWrapLeft>
                        Lista de Códigos Promocionais
                    </TableWrapLeft>

                    <TableWrapRight>
                        <Link to="/system/promocode/new">
                            Criar Promoção
                        </Link>
                    </TableWrapRight>

                </TableWrapActions>
            </TableWrap>
        );
    }
}

PromoCodesList.propTypes = {
    promocode: PropTypes.object,
    location: PropTypes.object,
    fetchPromos: PropTypes.func,
};

const mapStateToProps = ({ promocode }) => ({ promocode });

export default connect(mapStateToProps, { fetchPromos })(PromoCodesList);
