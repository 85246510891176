import {onlyAdmin} from "../services/auth";
import React from "react";
import PropTypes from "prop-types";

const Badge = ({backgroundColor="#55555599  ", color="#fff", origin}) => {
    return (
        <small style={{
            marginTop: 5,
            backgroundColor: backgroundColor,
            padding: "5px 10px",
            borderRadius: "10px",
            color: color
        }}>{origin}</small>
    )
}

const AccountOriginBadge = onlyAdmin(props => {
        if (props.origin === 'COMMERCIAL') {
            return <Badge backgroundColor="#00819c99" color="#fff" origin={props.origin}/>;
        } else {
            return <Badge backgroundColor="#9c008499" color="#fff" origin={props.origin}/>;
        }

        return <Badge origin={`NÃO INFORMADO`}/>;
    }, props => (<small></small>)
)

Badge.propTypes = {
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    origin: PropTypes.string,
}

AccountOriginBadge.propTypes = {
    origin: PropTypes.string,
}

export default AccountOriginBadge;
