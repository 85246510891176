import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {SelectField} from '../../components';
import {fetchDevices} from '../../actions/domains';
import {withAccountId} from '../AccountContainer';

class SelectDevicesField extends React.PureComponent {
  // eslint-disable-next-line no-undef
  static defaultProps = {
    name: 'device',
  }

  constructor(props) {
    super(props);
    this.addSelectText = this.addSelectText.bind(this);
  }

  componentWillMount() {
    this.props.fetchDevices(this.props.accountId, {
      page_size: 99999, /* TODO: temporário */
    });
  }

  addSelectText(product) {
    return [{id: '', description: 'Selecione um Domínio'}].concat(product);
  }

  render() {
    return (
      <Field
        {...this.props}
        options={this.addSelectText(this.props.devices)}
        component={SelectField}
      />
    );
  }
}

const mapStateToProps = ({devices}) => ({
  devices: devices.results.map(({id, name}) => ({id, description: name})),
});

export default connect(mapStateToProps, {fetchDevices})(withAccountId(SelectDevicesField));
