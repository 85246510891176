import { css } from 'styled-components';

export default css`
    .accordion {
        position: relative;
    }
    .accordion-header {
        padding: 1rem 2rem;
        border: 0.1rem solid $darkgrey-200;
        border-bottom: none;
        background-color: $lightgrey-400;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .accordion-header [class*='mark-'] {
        margin-left: -1rem;
    }
    .accordion-header > div {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .accordion-header > div > .score {
        margin: 0 1rem;
    }
    .accordion-container {
        border: 0.1rem solid $darkgrey-200;
        border-top: none;
        overflow: hidden;
    }
    .accordion-content {
        padding: 1rem 2rem;
    }
`;
