import {Main} from '../components'
import {Route} from 'react-router-dom'
import {MainSignUp} from '../views'
import {requireAuth} from "../services/auth";

const Routes = () => (
    <Route component={requireAuth(Main, MainSignUp)}/>
)

export default Routes
