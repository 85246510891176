import { css } from 'styled-components';

import { $darkgrey200, $lightgrey400, $lightgrey600, $green400 } from '../settings/variables';

export default css`
    .validation-method .accordion {
        position: relative;
        margin-bottom: 2rem;
    }

    .validation-method .accordion-header {
        cursor: initial;
        padding: 1rem 2rem;
        border: .1rem solid ${$darkgrey200};
        border-bottom: none;
        background-color: ${$lightgrey400};
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .validation-method .accordion-header [class*='mark-'] {
        margin-left: -1rem;
    }

    .validation-method .accordion-header > div {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .validation-method .accordion-header > div > .score {
        margin: 0 1rem;
    }

    .validation-method .accordion-container {
        border: .1rem solid ${$darkgrey200};
        border-top: none;
        overflow: hidden;
    }

    .validation-method .accordion-content {
        padding: 1rem 2rem;
    }

    /* TODO: Troca essas classes de arquivo */
    .header-validation {
        display: float left;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
    }

    .header-validation .type {
        padding: .3rem 1.5rem;
        border-radius: .5rem;

        background-color: ${$lightgrey600};
    }

    .header-validation .type.selected {
        background-color: ${$green400};
        color: #fff;
    }

    .header-validation > .field {
        margin-bottom: 0 !important;
    }

    .header-validation > .field > .radio {
        margin-bottom: 0;
    }
`;
