import { FETCH_SALESFORCE_AUTH, UPDATE_SALESFORCE_AUTH } from '../../../actions/backoffice/salesforce';

export const INITIAL_LIST = {
  email: '',
  token: '',
  isLoading: false
};

export const salesforce = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_SALESFORCE_AUTH}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${FETCH_SALESFORCE_AUTH}_FULFILLED`: {
      return { ...state, ...action.payload, isLoading: false }
    }
    case `${FETCH_SALESFORCE_AUTH}_REJECTED`:
      return { ...state, isLoading: false }
    case `${UPDATE_SALESFORCE_AUTH}_PENDING`:
      return { ...state, ...action.payload, isLoading: true }
    case `${UPDATE_SALESFORCE_AUTH}_FULFILLED`: {
      return { ...state, ...action.payload, isLoading: false }
    }
    case `${UPDATE_SALESFORCE_AUTH}_REJECTED`:
      return { ...state, isLoading: false }

    default:
      return state
  }
}
