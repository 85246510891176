import { useEffect, useMemo, useState } from 'react'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import { HighlightOff, Search } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import FilterField from './FilterFields'
import SmartRow from './SmartRow'
import useStyles from './styles'

export const SmartTable = ({
  actionFetch,
  filteredValues,
  filterModule,
  handleFilter,
  model,
  source
}) => {
  const classes = useStyles()

  const [fields, setFields] = useState([])
  const [filterFields, setFilterFields] = useState([])
  const [filterValues, setFilterValues] = useState({})

  useEffect(() => {
    setFields(Object.keys(model))

    if (filterModule) {
      setFilterFields(Object.keys(filterModule))
    }
  }, [])

  useEffect(() => {
    if (!source.isLoading) {
      actionFetch(filteredValues)
    }
  }, [])

  return (
    <TableContainer component={Paper} style={{ marginBottom: '60px' }}>
      <Table>
        {isEmpty(filterFields)
          ? null
          : (
            <TableHead>
              {filterFields.map((name, index) =>
                <TableCell key={index} style={{ textAlign: 'left' }}>
                  {name !== '' && filterModule[name]
                    ? (
                      <FilterField
                        {...filterModule[name]}
                        name={name}
                        select={filterModule[name].type === 'select'}
                        value={filterValues[name]}
                        onChange={({ target }) => setFilterValues(oldState => ({ ...oldState, [target.name]: target.value }))}
                        disabled={source.isLoading}
                        fullWidth
                      />
                    )
                    : null
                  }
                </TableCell>
              )}

              <TableCell>
                <IconButton
                  size='large'
                  onClick={() => handleFilter(filterValues)}
                  disabled={isEmpty(filterValues)}
                >
                  <Search style={{ fontSize: '2rem' }} color='primary' />
                </IconButton>

                <Tooltip title='Limpar filtro'>
                  <IconButton
                    size="large"
                    onClick={() => {
                      setFilterValues({})
                      handleFilter({})
                    }}
                    disabled={isEmpty(filterValues)}
                  >
                    <HighlightOff style={{ fontSize: '2rem' }} color='primary' />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableHead>
          )
        }

        <TableHead className={classes.tableHead}>
          {fields.map((key, index) =>
            <TableCell key={index} className={classes.tableCell}>
              {model[key].name}
            </TableCell>
          )}
        </TableHead>

        <TableBody>
          {useMemo(() => {
            if (source.isLoading) {
              return (
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((s, index) =>
                  <SmartRow key={index} columns={fields} />
                )
              )
            } else if (!isEmpty(source.allIds)) {
              return source.allIds.map((rowId, index) =>
                <SmartRow
                  key={index}
                  data={source.results[rowId]}
                  model={model}
                  columns={fields}
                />
              )
            } else {
              return (
                <TableRow>
                  <TableCell colSpan={12} align='center' >
                    Nenhum item encontrado.
                  </TableCell>
                </TableRow>
              )
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [source, model])}
        </TableBody>

        {useMemo(() => {
          if (source.count > source.page_size) {
            return (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={source.count}
                    rowsPerPage={source.page_size}
                    labelRowsPerPage={source.isLoading ? '' : `Exibindo ${source.page_size} de ${source.count}`}
                    labelDisplayedRows={({ page }) => `Página ${page + 1}`}
                    page={source.page - 1 || 0}
                    onPageChange={(e, page) => actionFetch({ page: page === source.page ? page + 1 : source.page - 1, page_size: source.page_size, ...filteredValues })}
                    onRowsPerPageChange={(e, page_size) => actionFetch({ page_size, page: source.page, ...filteredValues })}
                  />
                </TableRow>
              </TableFooter>
            )
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [source.isLoading])}
      </Table>
    </TableContainer>
  )
}

SmartTable.propTypes = {
  actionFetch: PropTypes.func,
  filteredValues: PropTypes.object,
  filterModule: PropTypes.object,
  handleFilter: PropTypes.func,
  model: PropTypes.object,
  source: PropTypes.object,
}