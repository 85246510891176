import { css } from 'styled-components';
import bannerSiteBlindado from '../../../assets/images/banner/banner-site-blindado.jpg';
import bannerTrustsign from '../../../assets/images/banner/banner-trust.jpg';
import bannerBlindagem from '../../../assets/images/banner/banner-blindagem.jpg';

const $safeAreaH = '26vw';
// const $safeAreaW = '50rem';

export default css`
    .store-banner {
        height: ${$safeAreaH};
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        width: 100%;
    }

    .banner-site-blindado {
        background:url(${bannerSiteBlindado});
    }

    .banner-trust-sign {
        background:url(${bannerTrustsign});
    }
  
    .banner-blindagem {
        background:url(${bannerBlindagem});
    }
  
    .store-banner > .content-inner {
        display: flex;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        z-index: 10;
        width: 70rem;
        margin-left: 5rem;
        margin-top: .5rem;
    }

    .store-banner > .content-inner > .title {
        display: flex;
        align-items: baseline;
    }

    .store-banner > .content-inner > .title h1 {
        margin: 0 0 2rem 1rem;
        font-weight: 700;
        font-size: 3rem;
        color: white;
    }

    .store-banner > .content-inner > .title .sb2wicon {
        margin-right: 0;
    }

    .store-banner > .content-inner > .description p {
        margin-bottom: 0.5vw;
        font-size: 2rem;
        font-weight: 2rem;
        color: white;
    }

    .store-banner > .content-inner > .description .slogan {
        margin-bottom: 0.5vw;
        font-size: 2rem;
        font-weight: 100;
        color: white;
    }

    .store-banner > .content-inner > .description h2 {
        color: #74b61b;
    }

    .store-divisor {
        height: 4rem;
    }

    .store-divisor > .title {
        position: absolute;
        left: 50%;
        color: white;
        font-size: 2rem;
        text-transform: uppercase;
        margin-top: 0.5rem;
        transform: translateX(-50%);
    }

    .store-divisor > .link {
        position: absolute;
        right: 0;
        margin-right: 15px;
        color: white;
        padding: 1rem;
    }

    .store-catalog {
        position: relative;
        margin-top: -0.4rem;
    }

    .store-catalog > .content .card-group {
        display: flex;
        flex-wrap: wrap; 
    }

    .store-catalog > .content .card-group > .card {
        flex: 0 0 calc(33.333% - 20px);
        margin: 10px;
    }

    .store-catalog > .content .card-group > .card:nth-child(3n) {
        margin-right: 0;
    }

    /* TODO: TEMPORARIO */
    .store-catalog .buy-box {
        bottom: -20rem;
        left: 15rem;
    }
`;
