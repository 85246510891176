export const OPEN = 'slider/OPEN';
export const CLOSE = 'slider/CLOSE';
export const CLEAR = 'slider/CLEAR';

export const openSlider = (content, title) => ({
    type: OPEN,
    payload: { content, title },
});

export const closeSlider = () => ({
    type: CLOSE,
});

export const clearSlider = () => ({
    type: CLEAR,
});
