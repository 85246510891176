import { css } from 'styled-components';

import { spaceBase, $lightgrey600, $borderRadiusBase, $fontSizeBase, $lightgrey300 } from '../settings/variables';

export default css`
    /* TODO: Reavaliar combo quando trouxer o less completo do bootstrap */
    .combo {
        font-size: 1.2rem;
        padding: ${spaceBase / 2}rem;
        background-color: #fff;
        border: .1rem solid ${$lightgrey600};
        box-shadow: none;
        border-radius: ${$borderRadiusBase};
        height: 3.5rem;
        outline: none;
        line-height: normal !important;
        transition: all .2s ease-in-out;
    }

    .combo:not(.combo-error):focus {
        border-color: #7fb93a;
    }

    .combo.combo-error {
        border-color: #b71c1c;
    }

    .combo[multiple] {
        min-height: 15rem;
    }

    .combo[multiple]:disabled {
        font-size: ${$fontSizeBase} !important;
        background-color: #fff;
    }

    .combo:disabled {
        background-color: ${$lightgrey300};
        cursor: not-allowed;
    }
`;

