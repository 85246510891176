import { fetch } from '../services/fetch';

export const ADD = 'notifications/ADD';
export const FETCH = 'notifications/FETCH';
// export const READ = 'notifications/READ';
// export const READ_ALL = 'notifications/READ_ALL';
// const notifySound = new Audio('@assets/audio/notify.mp3');

export function add(message) {
    return {
        type: ADD,
        payload: { message },
    };
}

export function fetchNotifications() {
    const request = fetch('/notifications').then(res => res.json());

    return {
        type: FETCH,
        payload: request,
    };
}

// window.fetchNotifications = fetchNotifications;
// window.add = add;
