import { css } from 'styled-components';

export default css`
    .register-page {
        display: flex;
        justify-content: space-between;
    }

    .register-page >.register {
        min-width: 50rem;
    }

    .register-page >.progress {
        max-width: 500px;
        padding: 10px 0 10px 10px;
    }
`;
