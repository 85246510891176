import { css } from 'styled-components';

export default css`
    .link-icon {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        color: #74B61B;
        padding: 1rem 0;
        cursor: pointer;
    }

    .link-icon i {
        margin-right: 0.5rem;
    }
`;
