import { css } from 'styled-components';

import { $borderRadiusBase, $shadowTooltip } from '../settings/variables';

export default css`
    .chart-tooltip {
        margin: 0;
        padding: 1rem;
        border: 1px solid rgb(204, 204, 204);
        white-space: nowrap;
        transition: all .6s;
        background: #F7F7F7;
        border-radius: ${$borderRadiusBase};
        text-align: left;
        box-shadow: ${$shadowTooltip};
    }

    .chart-tooltip ul {
        margin: 1rem 0;
    }
`;
