import { css } from 'styled-components';

import {
    $bodyFontColor,
    $fontSizeLarge,
    $green500,
    $lightgrey300,
    $lightgrey400,
    $shadowButtonPressed,
    $spaceBase,
} from '../settings/variables';

export default css`
    .remove-item {
        width: 25rem;
        padding: 1rem 1.5rem .5rem 1.5rem;
        background-color: ${$lightgrey400};
        color: ${$bodyFontColor};
    }

    .remove-item .space-button {}

    .remove-item .button {
        padding: .5rem ${$spaceBase};
    }

    .remove-item .button:hover {
        background-color: ${$green500};
        color: #fff;
    }

    .remove-item .button:active {
        box-shadow: ${$shadowButtonPressed};
    }

    .remove-item >.remove-title {
        font-weight: 700;
        font-size: ${$fontSizeLarge};

        margin-bottom: ${$spaceBase};
    }

    .remove-actions {
        display: flex;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .remove-actions > a {
        padding: 1.5rem;
    }

    /* TODO: melhorar isso aqui */
    .tooltip-content .remove-item + .tooltip-arrow {
        border-color: ${$lightgrey300} transparent transparent transparent;
    }
    .tooltip-content .additional-info {
        color: red;
    }
`;

