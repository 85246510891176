import React, {useState} from 'react'
import {Button, CheckboxItem, Loading} from '../../components'
import {Link} from 'react-router-dom'
import {closeSlider} from "../../actions/slider";
import {useDispatch, useSelector} from "react-redux";
import {fetchSummaryProducts, setProductStatus} from "../../actions/products";
import {useForm} from "react-hook-form";


export function RefundProduct(props) {
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const account = useSelector(state => state.account)
  const accountId = account[0] ? account[0].id : undefined
  const dispatch = useDispatch();
  const {handleSubmit, register} = useForm()

  function onSubmit({observation}) {
    setIsChecked(false)
    setIsLoading(true)
    dispatch(setProductStatus(props.product_id, 'canceled', observation))
    dispatch(fetchSummaryProducts(accountId))
    setIsLoading(false)
    dispatch(closeSlider())
  }

  function onClick() {
    setIsChecked(!isChecked)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <article className="slide-confirmation">
        <Loading loading={isLoading}/>
        <p>
          <strong>
            Cuidado!</strong> O produto “<strong>{props.product}</strong>”
          será <strong>cancelado</strong> e <strong>não há como desfazer</strong> esta ação.
          Ao ser cancelado, o reembolso do produto será realizado.
        </p>

        <div className="field">
          <p>
            Descreva o motivo do cancelamento abaixo:
          </p>

          <textarea
            name="observation"
            className="textarea"
            defaultValue=""
            rows="5"
            {...register('observation')}
          />

        </div>

        <CheckboxItem
          label="Sim, desejo realmente cancelar este produto"
          name="refund_product"
          value={isChecked}
          onClick={onClick}
        />

        <div className="buttons space-button">
          <Button
            loading={isLoading}
            disabled={!isChecked}
          >
            Confirmar Reembolso
          </Button>

          <Link to="/store/products/">
            Cancelar
          </Link>
        </div>
      </article>
    </form>
  )
}
