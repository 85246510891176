import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import { FormSignup } from '../../containers';

export const SignUp = () => {
    useEffect(() => {
        if (window.top !== window.self) {
          window.top.location.href = document.location.href;
        }
    })

    return (
        <FormSignup />
    );
};

SignUp.propTypes = {
    setTitle: PropTypes.func,
};
