import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '../Icon';
import { fileSave } from '../../services/files';

export const FileValidation = ({ uniqueHash, sha256, md5 }) => {
    const fileDownload = (e) => {
        e.preventDefault();
        fileSave(`${md5.toUpperCase()}.txt`, [sha256, '\ncomodoca.com\n', uniqueHash]);
    };

    return (
        <article>
            <h2>Validação por Hash</h2>
            <p>Optando pela validação via hash,
            você terá que adicionar o arquivo abaixo no diretório
                <strong> /.well-known/pki-validation/ </strong>
            do seu domínio:</p>
            <div className="space-button">
                <a
                    href="/#"
                    onClick={(e) => fileDownload(e)}
                    className="link-icon"
                >
                    <Icon name="file_download" /> Baixar arquivo
                </a>
            </div>
            <p><strong>Obs:</strong> O arquivo .txt não irá alterar aspecto visual ou funcional
            da sua aplicação. Uma vez validado o certificado,
            você pode remover o arquivo sem problemas.</p>
        </article>
    );
};

FileValidation.propTypes = {
    uniqueHash: PropTypes.string,
    sha256: PropTypes.string,
    md5: PropTypes.string,
};
