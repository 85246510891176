import { CHANGE_PASSWORD, CREATE, FETCH, FETCH_ALL, SAVE, FETCH_ORGS } from '../actions/users'
import { LOGIN_USER_SUCCESS } from '../actions/auth'
import { INITIAL_LIST } from '../services/initial-states'
import normalizedState from '../services/normalize'
import jwtDecode from "jwt-decode";

export const users = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_PENDING`:
      return { ...state, isLoading: true, allIds: [] }
    case `${FETCH_ALL}_REJECTED`:
      return { ...state, ...action.payload, isLoading: false }
    case `${FETCH_ALL}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)
      return { ...state, ...action.payload, results, allIds, isLoading: false }
    }
    case `${CHANGE_PASSWORD}_FULFILLED`:
      return { ...state, ...action.payload }
    case `${FETCH}_PENDING`:
      return { ...state, isLoading: true, allIds: [] }
    case `${FETCH}_REJECTED`:
      return { ...state, ...action.payload, isLoading: false }
    case `${FETCH}_FULFILLED`: {
      return { ...state, results: { ...action.payload }, isLoading: false }
    }
    default:
      return state

  }
}

export const user = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${CREATE}_PENDING`:
      return { ...state, isLoading: true }
    case `${CREATE}_REJECTED`:
      return { ...action.payload, isLoading: false }
    case `${CREATE}_FULFILLED`:
      return { ...action.payload, isLoading: false }
    case `${FETCH}_PENDING`:
      return { ...state, isLoading: true, allIds: [] }
    case `${FETCH}_REJECTED`:
      return { ...state, ...action.payload, isLoading: false }
    case `${FETCH}_FULFILLED`:
      return { ...state, results: { ...action.payload }, isLoading: false }
    case `${SAVE}_PENDING`:
      return { ...state, isLoading: true, allIds: [] }
    case `${SAVE}_FULFILLED`:
      return { ...state, isLoading: false, ...action.payload, results: action.payload }
    case LOGIN_USER_SUCCESS:
      const token = action.payload.token
      const decodedToken = jwtDecode(token)
      return {
        ...state,
        isLoading: false,
        results: decodedToken.user,
      }
    // return { ...state, isLoading: false, ...action.payload }
    default:
      return state
  }
}

export const usersOrganizations = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_ORGS}_PENDING`:
      return { ...state, isLoading: false }
    case `${FETCH_ORGS}_REJECTED`:
      return { ...action.payload, isLoading: false }
    case `${FETCH_ORGS}_FULFILLED`: {
      const { results, allIds } = normalizedState(
        action.payload.results,
        'organization.id'
      )
      return {
        ...state,
        ...action.payload,
        results,
        allIds,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

// export const user = () => {
//     switch (action.type) {
//     }
// }
