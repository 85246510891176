import { MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Icon, Dropdown } from '../components'

export const Notifications = ({ items }) => (
  <div className="notifications hide" style={items.length ? { cursor: 'pointer' } : {}}>

    {items.length ?
      <Dropdown
        right
        button={(
          <span>
            <Icon name="notifications" />
            <span className="counter">{items.length}</span>
          </span>
        )}
      >
        <div className="notifications-list">
          {items.map((item, key) =>
            <MenuItem className="item" key={key}>
              <span>
                {item.message}
              </span>
            </MenuItem>
          )}
        </div>
      </Dropdown>
      : <Icon name="notifications_none" />
    }
  </div>
)

Notifications.propTypes = {
  items: PropTypes.array,
}
