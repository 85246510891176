import { ADD, DELETE, CLEAR } from '../actions/domains-box';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${ADD}_PENDING`:
            return {
                ...state,
                [action.meta.domain]: { loading: true },
            };
        case `${ADD}_FULFILLED`:
            return {
                ...state,
                [action.meta.domain]: action.payload,
            };
        case DELETE: {
            const data = state;
            delete data[action.payload.domain];
            return data;
        }
        case CLEAR:
            return INITIAL_STATE;
        default:
            return state;
    }
};
