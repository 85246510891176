import {
  FETCH_CLIENT_ORDERS,
  FETCH_ORDER_BY_PROMOCODE,
  FETCH_ORDER_TYPES,
  FETCH_STORE_ORDERS,
  ORDER_FORM,
  REQUEST_ORDER,
  SET_ORDER_STATUS,
} from '../actions/orders'
import {LOGOUT_USER} from '../actions/auth'
import {INITIAL_LIST} from '../services/initial-states'
import normalizedState from '../services/normalize'

const INITIAL_ORDERS_STATE = {
  ...INITIAL_LIST,
  availablesIds: [],
}

const INITIAL_STATE = {
  currentOrder: {},
  results: [],
  chart: [],
}


export const orderInfo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_ORDER_BY_PROMOCODE}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_ORDER_BY_PROMOCODE}_FULFILLED`: {
      const results = action.payload

      return {...state, results, isLoading: false}
    }
    case LOGOUT_USER:
      return INITIAL_STATE
    case ORDER_FORM:
      return {...state, currentOrder: action.payload}
    default:
      return state
  }
}

export const clientOrders = (state = INITIAL_ORDERS_STATE, action) => {
  switch (action.type) {
    case `${FETCH_CLIENT_ORDERS}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_CLIENT_ORDERS}_FULFILLED`: {
      const {results, allIds} = normalizedState(action.payload.results)

      const availablesIds = allIds.filter(id =>
        results[id].status === 'available'
      )

      return {...state, ...action.payload, results, allIds, availablesIds, isLoading: false}
    }
    case `${SET_ORDER_STATUS}_PENDING`: {
      return {...state, isLoading: true}
    }
    case `${SET_ORDER_STATUS}_FULFILLED`: {
      const results = state.results

      if (action.payload.id) {
        results[action.payload.id] = action.payload
      }

      return {...state, results, isLoading: false}
    }
    case LOGOUT_USER:
      return INITIAL_ORDERS_STATE
    default:
      return state
  }
}

export const storeOrders = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_STORE_ORDERS}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_STORE_ORDERS}_FULFILLED`: {
      const {results, allIds} = normalizedState(action.payload.order_types)

      return {
        ...state,
        isLoading: false,
        banner: action.payload.banner,
        slug: action.payload.slug,
        name: action.payload.name,
        website: action.payload.website,
        websiteTitle: action.payload.website_title,
        description: action.payload.description,
        results,
        allIds,
      }
    }
    default:
      return state
  }
}

export const orderTypes = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_ORDER_TYPES}_PENDING`:
      return {...state, isLoading: true}
    case `${FETCH_ORDER_TYPES}_FULFILLED`: {
      const {results, allIds} = normalizedState(action.payload)

      return {
        ...state,
        isLoading: false,
        name: action.payload.name,
        description: action.payload.description,
        results,
        allIds,
      }
    }
    default:
      return state
  }
}

export const requestOrder = (state = {isLoading: false, errors: []}, action) => {
  switch (action.type) {
    case `${REQUEST_ORDER}_PENDING`:
      return {...state, isLoading: true}
    case `${REQUEST_ORDER}_FULFILLED`:
      return {...state, ...action.payload, isLoading: false}
    case `${REQUEST_ORDER}_REJECTED`:
      return {...state, ...action.payload, isLoading: false}
    default:
      return state
  }
}
