import { css } from 'styled-components';

export default css`
    .message {
        padding: 1rem;
        margin: 1.5rem 0;
        border-radius: 3px;
        display: flex;
    }

    .message > div > .title {
        font-weight: bold;
    }

    .message > i {
        align-self: center;
        margin-right: 1rem;
        font-size: 2rem;
    }

    .message.error {
        background-color: #FFE0E0;
        color: #c30000;
    }

    .message.warning {
        background-color: #FFE899;
        color: #2D343D;
    }

    .message.warning > i {
        color: #F2AC5B;
    }
`;
