import { css } from 'styled-components';

import { $fontSizeBase, $fontSizeLarger } from '../settings/variables';

export default css`
    .breadcrumbs {
        padding: 1.1rem 2rem;
        background-color: #fbfbfb;
        border-bottom: 1px solid #dddddd;
        font-size: ${$fontSizeBase};
        font-weight: 300;
    }

    .breadcrumbs a {
        color: #21272E;
    }

    .breadcrumbs-list {
        display: flex;
    }

    .breadcrumbs-list > li {
        display: flex;
        align-items: center;
        margin-left: 1rem;
    }

    .breadcrumbs-list > li:after {
        content: '\\003e';
        color: #bbbbbb;
        font-size: 2rem;
        font-weight: bold;
        margin-left: .5rem;
    }

    .breadcrumbs-list > li:last-child {
        font-size: ${$fontSizeLarger};
        margin-left: .5rem;
        position: relative;
        top: -0.1rem;
        /* pointer-events none */
    }

    .breadcrumbs-list > li:last-child:after {
        content: '';
    }

    .breadcrumbs-active {
        font-size: 2rem;
        pointer-events: none;
    }

    .breadcrumbs-prepend {
        display: flex;
        align-items: center;
    }

    .breadcrumbs-divider {
        color: #bbb;
        font-size: 2rem;
        font-weight: bold;
        margin: 0 0.5rem;
    }

    .breadcrumbs .loader {
        padding: 5px 15px;
    }
`;
