import React from 'react'

import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles({
  titleContainer: {
    width: '98%',
    margin: '20px auto',
    height: '65px',
    paddingLeft: '30px',
  },
  title: {
    color: '#95959c',
    margin: '20px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
})

const PageTitle = React.memo(({ title, children }) => {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      <Grid
        className={classes.titleContainer}
        container
        alignItems="center"
      >
        <Grid item xs={8}>
          {title
            ? <Typography className={classes.title} variant="h3">
              {title}
            </Typography>
            : <Skeleton width={300} height={85} />
          }
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          item
          xs={4}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
})

PageTitle.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
}

export default PageTitle
