import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../components';
import { validateStaff } from '../modules/domainValidation';
import { userIsAdmin } from '../services/auth';

const StaffValidation = (props) => {
    const sendValidation = (e) => {
        e.preventDefault();
        props.validateStaff(props.domain.id);
    };

    return (
        <article>
            <small><i>* Disponível apenas para Staff Siteblindado.</i></small>
            <h2>Validação por STAFF</h2>

            <p>Ao utilizar este tipo de validação você estará validando imediatamente este domínio.</p>

            <div className="space-button">
                <Button onClick={sendValidation} loading={props.validating}>
                    Validar Domínio
                </Button>
            </div>
        </article>
    );
};

StaffValidation.propTypes = {
    domain: PropTypes.object,
    validating: PropTypes.bool,
    validateStaff: PropTypes.func,
};


function mapStateToProps(state) {
    return {
        domain: state.domainValidation.domain,
        validating: state.domainValidation.validating,
    };
}

export default connect(mapStateToProps, { validateStaff })(userIsAdmin(StaffValidation));
