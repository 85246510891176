import React from 'react';
import classnames from 'classnames';

import Edit from './applicationForm/Edit';
import SingleScheduling from './applicationForm/SingleScheduling';
import moment from 'moment';

export class Scheduling extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            strDate: this.getExecutionDate(),
            listFormTypes: [
                { label: 'Editar recorrência', value: 'edit' },
                { label: 'Agendamento único', value: 'unit' },
            ],
            itemChecked: 'edit',
        };
        this.onChange = this.onChange.bind(this);
        this.getUTCDate = this.getUTCDate.bind(this);
    }

    getExecutionDate(dateString = moment().add(10, 'minutes')) {
        return dateString.format('YYYY-MM-DD HH:mm');
    }

    getUTCDate(dateString = Date.now()) {
        return new Date(dateString);
    }

    onChange(date, strDate) {
        this.setState({ strDate });
    }

    onChangeChose(value) {
        this.setState({ ...this.state, itemChecked: value });

        if (value === 'edit') {
            document.querySelector('#slider-title').innerHTML = `Editar recorrência de ${this.props.scanType}`;
        }

        if (value === 'unit') {
            document.querySelector('#slider-title').innerHTML = `Agendamento único de ${this.props.scanType}`;
        }
    }

    render() {
        return (
            <div className="scheduling">
                <div className="choices">
                    {this.state.listFormTypes.map(item =>
                        <div
                            key={item.value}
                            className={classnames('item', { checked: item.value === this.state.itemChecked })}
                            onClick={() => this.onChangeChose(item.value)}
                        >
                            {item.label}
                        </div>
                    )}
                </div>

                {this.state.itemChecked === 'edit'
                    ? <Edit
                        onChange={this.onChange}
                        defaultDate={this.state.strDate}
                        scanProfileId={this.props.scanProfileId}
                        scanType={this.props.scanType}
                        strDate={this.state.strDate}
                        source={this.props.source}
                    />
                    : <SingleScheduling
                        onChange={this.onChange}
                        defaultDate={this.state.strDate}
                        scanProfileId={this.props.scanProfileId}
                        scanType={this.props.scanType}
                        strDate={this.state.strDate}
                        source={this.props.source}
                    />
                }
            </div>
        );
    }
}
