import {
  FETCH_ORDER,
  FETCH_ORDERS,
  FETCH_CSRINFO,
  CLEAR_CSRINFO,
  FETCH_SERVERS_OPTIONS,
} from '../actions'
import { SEND_RENEW } from '../actions/certificate-orders'
import { INITIAL_LIST } from '../services/initial-states'
import normalizedState from '../services/normalize'

export const renew = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case `${FETCH_ORDER}_PENDING`:
      return { ...state, isLoading: true }
    case `${FETCH_ORDER}_FULFILLED`:
      return { ...state, order: action.payload, isLoading: false }
    case `${FETCH_ORDER}_REJECTED`:
      return { ...state, isLoading: false }

    case `${SEND_RENEW}_PENDING`:
      return { ...state, isLoading: true }

    case `${SEND_RENEW}_FULFILLED`:
      return { ...state, newOrder: action.payload, isLoading: false }

    default:
      return state
  }
}

export const orders = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_ORDERS}_PENDING`:
      return { ...action.payload, isLoading: true }
    case `${FETCH_ORDERS}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)

      return { ...action.payload, results, allIds, isLoading: false }
    }

    case `${FETCH_ORDERS}_REJECTED`:
      return { isLoading: false }

    default:
      return state
  }
}

export const serversOptions = (state = [], action) => {
  switch (action.type) {
    case `${FETCH_SERVERS_OPTIONS}_PENDING`:
      return [{ description: 'Carregando...' }]
    case `${FETCH_SERVERS_OPTIONS}_FULFILLED`:
      return action.payload
    default:
      return state
  }
}

export const csrInfo = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_CSRINFO:
      return {}
    case `${FETCH_CSRINFO}_PENDING`:
      return { ...state, isLoading: true }
    case `${FETCH_CSRINFO}_REJECTED`:
      return { }
    case `${FETCH_CSRINFO}_FULFILLED`:
      return {
        common_name: action.payload.subject.common_name,
        key_size: action.payload.public_key.bits,
        sha256: action.payload.sha256,
        md5: action.payload.md5,
        emails: action.payload.dcv_emails,
        domains: action.payload.domains || [action.payload.subject.common_name],
        subject: {
          ...action.payload.subject,
          key_size: action.payload.public_key.bits,
        },
      }

    // case `${REFRESH_DOMAINS}`: {
    //     return { ...state, domains: action.payload.domains };
    // }

    default:
      return state
  }
}

export const certificateData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return action.payload
    default:
      return state
  }
}
