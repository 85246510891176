import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Loading} from '../../components';
import {withAccountId} from '../AccountContainer';
import {fetchDevices, fetchDomains} from '../../actions/domains';

class DashboardWaf extends React.Component {
  state = {
    accountId: this.props.accountId,
    loading: false,
    data: [],
    domains: []
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.results.length > 0) {
      this.setState({domains: nextProps.results})
    }
  }

  getData = async () => {
    this.setState({loading: true});
    this.props.fetchDevices(this.state.accountId, {page_size: 9999})
  }

  render() {
    const {domains = []} = this.state;
    console.log(domains);

    return (
      <div className="dashboard">
        <Loading loading={this.state.loading}/>
        <div className="row" style={{display: 'flex', flexDirection: 'row', marginBottom: '1rem'}}>
          <div className="col-md-4">
            <div className="card text-center" style={{marginBottom: '1rem'}}>
              <div className="card-body p-2">
                <h3>TRANSFERÊNCIA DE DADOS</h3>
                <p>Em Mbytes</p>
                <h1>stats.bandwidth.total/1000</h1>
              </div>
            </div>
            <div className="card text-center" style={{marginBottom: 0}}>
              <div className="card-body p-2">
                <h3>CONSUMO DE BANDA</h3>
                <p>Em Kbps</p>
                <h1>traffic.bandwidth_per_sec.total</h1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card text-center" style={{marginBottom: 0, height: '100%'}}>
              <div className="card-body">
                <h3>stats.bandwidth.3lcloud/stats.bandwidth.total e stats.bandwidth.user/stats.bandwidth.total</h3>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card text-center" style={{marginBottom: 0, height: '100%'}}>
              <div className="card-body">
                <h3>stats.pageviews.3lcloud/stats.pageviews.total e stats.pageviews.user/stats.pageviews.total</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card text-center">
              <div className="card-body">
                <h3>stats.bandwidth</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card text-center">
              <div className="card-body">
                <h3>stats.pageviews</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card text-center">
              <div className="card-body">
                <h3>traffic.bandwidht_per_sec</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card text-center">
              <div className="card-body">
                <h3>stats.latency</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DashboardWaf.propTypes = {
  fetchDashboard: PropTypes.func,
  accountId: PropTypes.string,
  charts: PropTypes.array,
  isLoading: PropTypes.bool,
  rejected: PropTypes.bool,
  status: PropTypes.bool,
  devices: PropTypes.object
};

const mapStateToProps = ({devices}) => ({...devices});
export default connect(mapStateToProps, {fetchDomains, fetchDevices})(withAccountId(DashboardWaf));
