import { FETCH_ALL, FETCH } from '../actions/evidences';
import { INITIAL_LIST } from '../services/initial-states';
import normalizedState from '../services/normalize';

export default (state = INITIAL_LIST, action) => {
    switch (action.type) {
        case `${FETCH_ALL}_PENDING`:
            return { ...state, ...action.payload, isLoading: true };
        case `${FETCH_ALL}_FULFILLED`: {
            const { results, allIds } = normalizedState(action.payload.results);
            return { ...state, ...action.payload, results, allIds, isLoading: false };
        }
        case `${FETCH_ALL}_REJECTED`:
            return { ...state, isLoading: false };
        case `${FETCH}_PENDING`:
            return { ...state, ...action.payload, isLoading: true };
        case `${FETCH}_FULFILLED`: {
            const { results, allIds } = normalizedState(action.payload.results);
            return { ...state, ...action.payload, results, allIds, isLoading: false };
        }
        case `${FETCH}_REJECTED`:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
