import React from 'react'
import PropTypes from 'prop-types'

export class ContentFilterItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: this.props.isChecked ? this.props.isChecked : false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(oldState => ({ ...oldState, checked: !oldState.checked }), () => {
      this.props.filterItems(this.refs.input.name, this.state.checked)
    })
  }

  render() {
    return (
      <div
        className="checkbox"
        onClick={this.handleClick}
      >
        <input
          type="checkbox"
          value={this.props.value}
          name={this.props.filterName}
          checked={this.state.checked || !!this.props.isChecked}
          ref="input"
          onChange={event => event.target.value}
        />
        <span>
          {this.props.value}
        </span>
      </div>
    )
  }
}

ContentFilterItem.propTypes = {
  value: PropTypes.string,
  filterName: PropTypes.string,
  filterItems: PropTypes.func,
  isChecked: PropTypes.bool,
}
