import jwtDecode from 'jwt-decode';
import { LOGIN_USER_SUCCESS, LOGOUT_USER } from '../actions/auth';
import { FETCH_MRU } from '../actions/account';

let isInitialized = false;
const userData = {};

export const removeFreshserviceElements = (forceRemove = false) => {
    // Lista de IDs e tipos dos elementos para remover
    const idsToRemove = ['FreshWidget', 'freshwidget-button'];

    // Função para remover um elemento
    const removeElementById = id => {
        const elem = document.getElementById(id);
        if (elem) {
            elem.remove();
            console.log(`Removed element with id: ${id}`);
        }
    };

    // Função para remover elementos pelo nome da tag e um filtro adicional
    const removeElementsByTag = (tag, filterFn) => {
        const elements = [...document.getElementsByTagName(tag)];
        elements
            .filter(filterFn)
            .forEach(elem => {
                elem.remove();
                console.log(`Removed element: ${elem}`);
            });
    };

    // Remover os elementos
    idsToRemove.forEach(removeElementById);
    removeElementsByTag('script', elem => elem.src && elem.src.includes('freshservice'));
    removeElementsByTag('link', elem => elem.href && elem.href.includes('freshservice'));

    // Tentar remover os elementos novamente após um pequeno delay, para pegar qualquer elemento criado de forma assíncrona
    if (forceRemove) {
        setTimeout(() => {
            idsToRemove.forEach(removeElementById);
            removeElementsByTag('script', elem => elem.src && elem.src.includes('freshservice'));
            removeElementsByTag('link', elem => elem.href && elem.href.includes('freshservice'));
        }, 500);
    }
};

const initializeFreshservice = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'freshservice-script';
    script.src = 'https://assets.freshservice.com/widget/freshwidget.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
        window.FreshWidget.init("", {
            "queryString": `&widgetType=popup&searchArea=no&helpdesk_ticket[name]=${userData.name}&helpdesk_ticket[requester]=${userData.email}&helpdesk_ticket[custom_field][organizao_595484]=${userData.organization}&disable[name]=true&disable[requester]=true&disable[custom_field][organizao_595484]=true`,
            "utf8": "✓",
            "widgetType": "popup",
            "buttonType": "text",
            "buttonText": "Suporte",
            "buttonColor": "black",
            "buttonBg": "#74B537",
            "alignment": "2",
            "offset": "50%",
            "formHeight": "500px",
            "url": "https://site-blindado.freshservice.com"
        });
    };
};

const freshserviceMiddleware = () => next => action => {
    if (action.type === LOGOUT_USER) {
        isInitialized = false;
        removeFreshserviceElements(true);
    }

    if (action.type === LOGIN_USER_SUCCESS) {
        const { user } = jwtDecode(action.payload.token);
        userData.name = `${user.first_name} ${user.last_name || ''}`;
        userData.email = user.email;
    }

    if (action.type === FETCH_MRU) {
        removeFreshserviceElements();
        userData.organization = action.payload[0].name ? action.payload[0].name : action.payload[0].full_name;
        initializeFreshservice();
    }

    if (Object.keys(userData).length === 3 && !isInitialized) {
        initializeFreshservice();
        isInitialized = true;
    }

    next(action);
};

export default freshserviceMiddleware;