import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    width: '64%',
    margin: '0',
  },
  card: {
    // width: '96%',
    margin: '0 0 15px 0',
    boxShadow: 'none !important',
  },
  cardContent: {
    height: 'calc(100vh - 22rem)',
    backgroundColor: '#ffff',
    color: '#95959c !important',
  },
  itemContainer: {
    // padding: '10px 20px',
  },
  checkboxContainer: {
    padding: '5px 20px',
    paddingBottom: '20px',
  },
  collapse: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  inputLabel: {
    fontSize: '1.5rem',
  },
  commentInput: {
    lineHeight: '2rem',
  },
  inputValue: {
    fontSize: '1.5rem',
    width: '100%',
    [`& input`]: {
      fontSize: '1.5rem !important',
      // padding: '1rem !important',
    },
    [`& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button`]: {
      display: 'none',
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  textBlock: {
    lineHeight: '2rem !important',
  },
  // mensageContainer: {
  //     width: '30%',
  //     display: 'flex',
  //     flexDirection: 'row',
  //     justifyContent: 'space-between',
  //     alignItems: 'center',
  //     margin: '0px 30px',
  //     color: 'red',
  // },
})

export default useStyles
