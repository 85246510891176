import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setOrderStatus} from "../../actions/orders";
import {withAccountId} from "../../containers/AccountContainer";
import {userIsAdmin} from "../../services/auth";
import formatDate from "format-date";

const STATUS_DICT = {
    'CREATED': ['CREATED', 'CANCELLED'],
    'SIGNED': ['SIGNED', 'CANCELLED'],
    'WAITING_PAYMENT': [
        'WAITING_PAYMENT',
        'PAID',
        'INVOICED',
        'APPROVED',
        'REJECTED',
        'CANCELLED',
        'UNDONE'
    ],
    'PAID': [
        'PAID',
        'INVOICED',
        'APPROVED',
        'REJECTED',
        'CANCELLED',
        'UNDONE'
    ],
    'INVOICED': [
        'INVOICED',
        'APPROVED',
        'REJECTED',
        'CANCELLED',
        'UNDONE'
    ],
    'APPROVED': [
        'INVOICED',
        'APPROVED',
        'REJECTED',
        'CANCELLED',
        'UNDONE'
    ],
    'REJECTED': [],
    'CANCELLED': [],
    'UNDONE': []
}

const isValidStatus = (key, status) => {
    console.log("key", key, "status", status)
    if (key === null || key === undefined) {
        return false;
    }

    if (status === null || status === undefined) {
        return false;
    }

    if (!STATUS_DICT.hasOwnProperty(key)) {
        return false;
    }

    return STATUS_DICT[key].includes(status);
}

const WaitingPaymentActionButtons = userIsAdmin((props) => {
    if (props.status === "SIGNED") {
        return (
            <div className={'order-tracker-buttons'}>
                <button
                    title={`Após gerar e enviar o boleto para o cliente, via email, clique aqui aguardar o pagamento.`}
                    className={"order-tracker-button"}
                    onClick={() => props.setOrderStatus(props.id, 'WAITING_PAYMENT')}>
                    Boleto gerado e enviado
                </button>
                <button
                    title={`Caso o cliente desista da compra, antes da geração do boleto, clique aqui cancelar o Pedido.`}
                    className={"order-tracker-button order-tracker-button-cancel"}
                    onClick={() => props.setOrderStatus(props.id, 'CANCELLED')}>
                    Cancelar pedido
                </button>
            </div>
        );
    }

    return null;
});

const PaymentActionButtons = userIsAdmin((props) => {
    if (props.status === "WAITING_PAYMENT") {
        return (
            <div className={'order-tracker-buttons'}>
                <button
                    title={`Após recebermos a confirmação do pagamento do Pedido, clique aqui aguardar a geração da Nota Fiscal (DARF).`}
                    className={"order-tracker-button"}
                    onClick={() => props.setOrderStatus(props.id, 'PAID')}>
                    Aprovar pagamento
                </button>
                <button
                    title={`Após expirar o prazo de pagamento do boleto, clique aqui para rejeitar o Pagamento e cancelar o Pedido.`}
                    className={"order-tracker-button order-tracker-button-warn"}
                    onClick={() => props.setOrderStatus(props.id, 'REJECTED')}>
                    Não houve pagamento
                </button>
                <button
                    title={`Caso o cliente desista da compra, antes de efetuar o pagamento do boleto, clique aqui cancelar o Pedido.`}
                    className={"order-tracker-button order-tracker-button-cancel"}
                    onClick={() => props.setOrderStatus(props.id, 'CANCELLED')}>
                    Cancelar pedido
                </button>
            </div>
        );
    }

    return null;
});

const InvoiceActionButtons = userIsAdmin((props) => {
    if (props.status === "PAID") {
        return (
            <div className={'order-tracker-buttons'}>
                <button
                    title={`Após gerar a Nota Fiscal (DARF) e enviar ao cliente, via email, clique aqui para aprovar o Pedido e liberar os creditos ao cliente.`}
                    className={"order-tracker-button"}
                    onClick={() => props.setOrderStatus(props.id, 'APPROVED')}>
                    Nota fiscal gerada e enviada
                </button>
                <button
                    disabled={true}
                    title={`Caso o cliente desista da compra, em até 7 dias corridos e não tenha utilizado os creditos adqueridos, clique aqui para estornar o pagamento e desfazer o Pedido.`}
                    className={"order-tracker-button order-tracker-button-cancel"}
                    onClick={() => props.setOrderStatus(props.id, 'UNDONE')}>
                    Solicitar estorno
                </button>
            </div>
        );
    }

    return null; //Emissão
});

const FinalActionButtons = userIsAdmin((props) => {
    if (props.status === "APPROVED") {
        return (
            <div className={'order-tracker-buttons'}>
                <button
                    disabled={true}
                    title={`Caso o cliente desista da compra, em até 7 dias corridos e não tenha utilizado os creditos adqueridos, clique aqui para estornar o pagamento e desfazer o Pedido.`}
                    className={"order-tracker-button order-tracker-button-cancel"}
                    onClick={() => props.setOrderStatus(props.id, 'UNDONE')}>
                    Solicitar estorno
                </button>
            </div>
        );
    }

    return null;
});

const BubbleTrack = ({ dateAt, label, description = null, active = false, cancelled = false, children }) => {
    const convertDate = (dateString) => {
        return formatDate('{day}/{month}/{year} às {hours}:{minutes}:{seconds}', new Date(dateString));
    }

    return (
        <div className="order-tracker-step">
            <p className="order-tracker-date">
                {dateAt && convertDate(dateAt)}
            </p>
            <p className={`order-tracker-status ${active ? 'order-tracker-status-active' : ''} ${cancelled ? 'order-tracker-status-cancelled' : ''}`}>
                {label}
            </p>
            {description && <p className="order-tracker-description">{description}</p>}
            {children}
        </div>
    )
}

const OrderTracker = ({order, id, status, setOrderStatus}) => {

    const convertDate = (date) => {
        return formatDate('{day}/{month}/{year} às {hours}:{minutes}:{seconds}', new Date(date));
    }

    function getDateOfLastStatus(status, order) {
        if (status === "REJECTED") {
            return order.rejected_at;
        }

        if (status === "CANCELLED") {
            return order.cancelled_at;
        }

        if (status === "UNDONE") {
            return order.undone_at;
        }

        return order.approved_at;
    }

    function getLabelOfLastStatus(status) {
        if (status === "REJECTED") {
            return "Pedido rejeitado";
        }

        if (status === "CANCELLED") {
            return "Pedido cancelado";
        }

        if (status === "UNDONE") {
            return "Pedido estornado";
        }

        return "Pedido aprovado";
    }

    return (
        <div className="order-tracker">
            <BubbleTrack dateAt={order.created_at} label={'Pedido recebido'} description={'Seu pedido foi recebido e o  contrato de prestação de serviço assinado.'} active={true}/>
            <BubbleTrack dateAt={order.waiting_payment_at} label={'Geração do Boleto'} description={'Nosso sistema está gerando um boleto, que será enviado ao seu e-mail de cadastro.'} active={isValidStatus('WAITING_PAYMENT', status) && order.waiting_payment_at}>
                <WaitingPaymentActionButtons id={id} status={status} setOrderStatus={setOrderStatus}/>
            </BubbleTrack>
            <BubbleTrack dateAt={order.paid_at} label={order.reject_at ? 'Pagamento não processado' : 'Pagamento processado'} description={'O pagamento foi realizado e processado.'} active={isValidStatus('PAID', status) && order.paid_at}>
                <PaymentActionButtons id={id} status={status} setOrderStatus={setOrderStatus}/>
            </BubbleTrack>
            <BubbleTrack dateAt={order.invoiced_at} label={'Nota fiscal gerada'} description={'A nota fiscal foi gerada e enviada para o seu e-mail de cadastro.'} active={isValidStatus('INVOICED', status) && order.invoiced_at}>
                <InvoiceActionButtons id={id} status={status} setOrderStatus={setOrderStatus}/>
            </BubbleTrack>
            <BubbleTrack
                dateAt={getDateOfLastStatus(status, order)}
                label={getLabelOfLastStatus(status)}
                description={``}
                active={status === "APPROVED"}
                cancelled={order.cancelled_at || order.rejected_at || order.undone_at}>
                <p className="order-tracker-description">
                    Pedido aprovado e produtos disponíveis para emissão.{" "}<a href="/store/products">Acesse seus produtos.</a>
                </p>
                <FinalActionButtons id={id} status={status} setOrderStatus={setOrderStatus}/>
            </BubbleTrack>

            <span className="order-tracker-line">.</span>
        </div>
    );
};

BubbleTrack.defaultProps = {
    dateAt: null,
    active: false,
    cancelled: false,
};

BubbleTrack.propTypes = {
    dateAt: PropTypes.string || null,
    label: PropTypes.string,
    description: PropTypes.any || null,
    active: PropTypes.bool,
    cancelled: PropTypes.bool,
    children: PropTypes.any,
};

OrderTracker.defaultProps = {
    status: "CREATED",
};

OrderTracker.propTypes = {
    order: PropTypes.object,
    orderId: PropTypes.string,
    status: PropTypes.string,
    setOrderStatus: PropTypes.func,
};

const mapStateToProps = ({
                             dispatch,
                         }) => ({dispatch});

export default connect(mapStateToProps, {
    setOrderStatus,
})(withAccountId(OrderTracker));
