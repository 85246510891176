import PropTypes from 'prop-types';
import React from 'react';

export const CardGroup = ({ children }) => (
    <section className="card-group">
        {children}
    </section>
);

CardGroup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
};
