import React, {memo, useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {Content} from "../../components";

export function IndexOrganizations(props) {
  const [title, setTitle] = useState('Organizações');
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    setAuthorized(!!props.match.params.id)
  }, [props])

  return (
    <Content authorized={authorized} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}
