import { NEXT_STEP, RESET, PREV_STEP, GO_TO_STEP } from '../actions/wizard';

const INITIAL_STATE = { step: 1 };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NEXT_STEP:
            return { step: state.step + 1 };
        case PREV_STEP:
            return { step: state.step - 1 };
        case GO_TO_STEP:
            return { step: action.payload.step };
        case RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
};
