import {fetch} from '../services/fetch'
import {push} from 'connected-react-router'
import {fetchUser} from './users'
import {fetchOrganization} from './organizations'
import {goToStep, reset as resetWizard} from '../actions/wizard'
import {ERROR, setMessage, SUCCESS} from '../modules/messages'
import {toParams} from '../services/utils'
import includes from 'lodash/includes'

export const SEND_RENEW = 'certificate-orders/SEND_RENEW'
export const FETCH_ORDER = 'certificate-orders/FETCH_ORDER'
export const FETCH_ORDERS = 'certificate-orders/FETCH_ORDERS'
export const FETCH_SERVERS_OPTIONS = 'certificate-orders/FETCH_SERVERS_OPTIONS'
export const FETCH_CSRINFO = 'certificate-orders/FETCH_CSRINFO'
export const CLEAR_CSRINFO = 'certificate-orders/CLEAR_CSRINFO'
export const ISSUE_CERTIFICATE = 'certificate-orders/ISSUE_CERTIFICATE'
export const POPULATE_ORGANIZATION = 'certificate-orders/POPULATE_ORGANIZATION'

export function fetchOrders(accountId, params = {}) {
  const query = toParams(params)
  const request = fetch(`/accounts/${accountId}/certificate-orders${query}`)
    .then(res => res.json())

  return {
    type: FETCH_ORDERS,
    payload: {
      promise: request,
      data: {accountId, ...params},
    },
  }
}

export function fetchOrder(orderId) {
  const request = fetch(`/certificate-orders/${orderId}`)
    .then(res => res.json())

  return {
    type: FETCH_ORDER,
    payload: request,
  }
}

export function clearCsrInfo() {
  return {
    type: CLEAR_CSRINFO,
  }
}

export function fetchServersOptions() {
  const request = fetch('/certificate-orders/servers').then(res => res.json())
  return {
    type: FETCH_SERVERS_OPTIONS,
    payload: request,
  }
}

export function populateOrganization(account) {
  return (dispatch, getState) => {
    const {user} = getState().auth.data

    const actionRequest = account.type === 'organization' ? fetchOrganization : fetchUser
    const request = dispatch(actionRequest(account.id))

    return dispatch({
      type: POPULATE_ORGANIZATION,
      payload: {
        promise: request,
        data: {
          contact: {...user},
        },
      },
    })
  }
}

export function getCsrInfo(csr) {
  return (dispatch) => {
    const request = fetch('/csr-info', {
      method: 'POST',
      body: JSON.stringify({csr}),
    })
      .then(res => res.json())
      .catch(err => {
        dispatch(setMessage(ERROR, 'Ocorreu um erro na consulta do CSR'));

        throw(err)
      })

    return dispatch({
      type: FETCH_CSRINFO,
      payload: request,
    })
  }
}

export function issueCertificate(accountId, data) {
  return (dispatch) => {
    const request = fetch(`/accounts/${accountId}/certificate-orders`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => {
        dispatch(push('/certificates/orders'))
        dispatch(setMessage(SUCCESS, 'Solicitação enviada! Aguarde a validação'))
        dispatch(clearCsrInfo())
        dispatch(resetWizard())
        return res
      })
      .catch(err => {
        if (err.code === 422) {
          dispatch(setMessage(ERROR, err.title));
          dispatch(goToStep(1));
          return err;
        }

        dispatch(setMessage(ERROR, 'Ocorreu um erro na consulta do CSR'));
        return err;
      })

    return dispatch({
      type: ISSUE_CERTIFICATE,
      payload: request,
    })
  }
}

export function sendRenew(orderId, data) {
  return dispatch => {
    const request = fetch(`/certificate-orders/${orderId}/renew`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => {
        dispatch(push('/certificates/orders'))
        dispatch(setMessage(SUCCESS, 'Renovação solicitada com sucesso!'))
        return res
      })
      .catch(error => {
        dispatch(setMessage(ERROR, error.message))
        return error
      })

    return dispatch({
      type: SEND_RENEW,
      payload: request,
    })
  }
}

export function setOrganizationData(data) {
  return dispatch => {
    dispatch({
      type: 'SET_DATA',
      payload: data,
    })
  }
}
