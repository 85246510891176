import PropTypes from 'prop-types';
import React from 'react';
import { TextField, Button, CheckboxItem } from '../../components';
import { Link } from 'react-router-dom';
import { saveApplication, fetchApplication } from '../../actions/applications';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { toFormErrors } from '../../services/utils';

class FormApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isPriority: true };
        this.onSubmit = this.onSubmit.bind(this);
        this.onClickPriority = this.onClickPriority.bind(this);
    }

    componentDidMount() {
        this.props.fetchApplication(this.props.id)
        .then(({ action }) => {
            this.setState({ isPriority: action.payload.priority === '2' });
        });
    }

    onSubmit(data) {
        return this.props.saveApplication(this.props.id, data);
    }

    onClickPriority() {
        this.setState({ isPriority: !this.state.isPriority });
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="form" >
                <Field
                    component={TextField}
                    name="name"
                    label="Nome"
                    error={this.props.errors.name || ''}
                />

                <Field
                    component={TextField}
                    name="url"
                    label="URL"
                    error={this.props.errors.url || ''}
                />

                <Field
                    component={TextField}
                    name="port"
                    label="Porta"
                    error={this.props.errors.port || ''}
                />

                <CheckboxItem
                    label="Scan prioritário"
                    name="priority"
                    value={this.state.isPriority}
                    onClick={this.onClickPriority}
                />

                <div className="buttons">
                    <Button
                        disabled={this.props.submitting}
                    >
                        Salvar
                    </Button>

                    <Link to="/armour/applications">Cancelar</Link>
                </div>
            </form>
        );
    }
}

FormApplication.propTypes = {
    saveApplication: PropTypes.func,
    fetchApplication: PropTypes.func,
    handleSubmit: PropTypes.func,
    params: PropTypes.object,
    errors: PropTypes.object,
    submitting: PropTypes.bool,
    id: PropTypes.string,
};

const Form = reduxForm({
    form: 'formApplication',
})(FormApplication);

const mapStateToProps = ({ form }) => ({
    initialValues: {
        name: '',
        url: '',
        priority: false,
    },
    errors: toFormErrors(form.formApplication.errors),
});

export default connect(mapStateToProps, {
    fetchApplication,
    saveApplication,
})(Form);
