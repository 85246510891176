import { css } from 'styled-components';

export default css`
    .mark,
    .mark-ok,
    .mark-warn,
    .mark-active,
    .mark-inactive,
    .mark-vulnerable,
    .mark-suspended  {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 10rem;
        margin: 0 1rem;
    }

    .mark-ok {
        background-color: #7DC671;
    }

    .mark-warn {
        background-color: #F2AC5B;
    }

    .mark-active {
        background-color: #7DC671;
    }

    .mark-inactive {
        background-color: #F4F4F4;
    }

    .mark-vulnerable {
        background-color: #FEEC58;
    }

    .mark-suspended {
        background-color: #FD4D4D;
    }
`;
