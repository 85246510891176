import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { sendEmail } from '../modules/domainValidation';
import { EmailValidation } from '../components';

const Validation = (props) => (
    <EmailValidation
        emails={props.emails}
        sendValidation={(email) => props.sendEmail(props.domain.id, email)}
    />
);

Validation.propTypes = {
    emails: PropTypes.array,
    sendEmail: PropTypes.func,
    domain: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        domain: state.domainValidation.domain,
        emails: state.domainValidation.domain.validation_emails,
    };
}

export default connect(mapStateToProps, { sendEmail })(Validation);
