import PropTypes from 'prop-types'
import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { formatDate } from '../../services/utils'
import * as css from '../../styles/settings/variables'

export const VulnerabilitiesHistoryChart = (props) => {
  const AreaDefault = {
    stackId: '1',
    strokeWidth: '0',
    fillOpacity: 1,
    type: 'monotone',
    isAnimationActive: false,
  }
  return (
    <div className="chart">
      <h1 className="chart-title">
        Histórico de Vulnerabilidades (SDA)
      </h1>

      <AreaChart
        width={400}
        height={230}
        data={props.data}
        margin={{ top: 10, right: 5, left: 0, bottom: 0 }}
      >
        <XAxis type="number" dataKey="x" domain={['dataMin', 'dataMax']} tickFormatter={formatDate} />
        <YAxis allowDecimals={false} />
        <CartesianGrid strokeDasharray="1 1" />
        <Tooltip labelFormatter={formatDate} />
        <Legend iconType="square" width={420} />
        <Area dataKey="value[4]" name="Nível 5" fill={css.$level5} stroke={css.$level5} {...AreaDefault} />
        <Area dataKey="value[3]" name="Nível 4" fill={css.$level4} stroke={css.$level4} {...AreaDefault} />
        <Area dataKey="value[2]" name="Nível 3" fill={css.$level3} stroke={css.$level3} {...AreaDefault} />
        <Area dataKey="value[1]" name="Nível 2" fill={css.$level2} stroke={css.$level2} {...AreaDefault} />
        <Area dataKey="value[0]" name="Nível 1" fill={css.$level1} stroke={css.$level1} {...AreaDefault} />
      </AreaChart>
    </div>
  )
}

VulnerabilitiesHistoryChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
}
