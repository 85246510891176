import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { MenuItem, TextField } from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import { DatePicker } from '@mui/lab'
import { isEmpty } from 'lodash'

const useStyles = makeStyles({
  inputValue: {
    fontSize: '1.5rem',
    width: '100%',
    [`& input`]: {
      fontSize: '1.5rem !important',
      // padding: '1rem !important',
    },
    [`& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button`]: {
      display: 'none',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
})
export const Field = props => {
  const { control, defaultValue, name, type, options, rules } = props
  const classes = useStyles()

  return (
    <Controller
      render={({ field }) => {
        switch (type) {
          case 'select':
            return (
              <TextField {...props} {...field} className={classes.inputValue}>
                {!isEmpty(options) && options.map((option, index) =>
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                )}
              </TextField>
            )
          case 'date':
            return (
              <DatePicker
                id="initial_date"
                mask='__/__/____'
                {...props}
                renderInput={(params) =>
                  <TextField {...params} {...field} helperText={null} style={{ maxWidth: 150 }} defaultValue={null} />
                }
              />
            )
          default:
            return <TextField {...props} {...field} className={classes.inputValue} />
        }
      }}
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

Field.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.string || PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
}