import React from 'react';
import PropTypes from 'prop-types';

export const CountryFlags = (props) => (
    <React.Fragment>
        <span
            className="inline-flags"
            style={{
                backgroundImage: 'url(/images/en.svg)',
                opacity: !!props.en ? 1 : 0.3,
            }}
        />

        <span
            className="inline-flags"
            style={{
                backgroundImage: 'url(/images/br.svg)',
                opacity: !!props.pt ? 1 : 0.3,
            }}
        />
    </React.Fragment>
);

CountryFlags.propTypes = {
    pt: PropTypes.bool,
    en: PropTypes.bool,
};
