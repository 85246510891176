import {fetch} from '../services/fetch'
import {ERROR, setMessage, SUCCESS, WARNING} from '../modules/messages'
import {toParams} from '../services/utils'
import {push} from 'connected-react-router'
import {CONSOLIDATED_ACCOUNT} from './account'

export const FETCH = 'domains/FETCH'
export const FETCH_DOMAIN = 'domains/FETCH_DOMAIN'
export const FETCH_DEVICES = 'domains/FETCH_DEVICES'
export const DELETE = 'domains/DELETE'
export const ADD = 'domains/ADD'
export const CLEAR = 'domains/CLEAR'

export function fetchDomains(accountId, params = {}) {
  const defaultArgs = {tree: true}
  const query = toParams({...defaultArgs, ...params})

  const url = accountId === CONSOLIDATED_ACCOUNT.id ? `/domains${query}` : `/accounts/${accountId}/domains${query}`
  const request = fetch(url).then(res => res.json())
  return {
    type: FETCH, payload: request,
  }
}

export function fetchDevices(accountId, params = {}) {
  const query = toParams(params)

  const url = `/accounts/${accountId}/devices${query}`
  const request = fetch(url).then(res => res.json())
  return {
    type: FETCH_DEVICES, payload: request,
  }
}

export function fetchDomain(domainId) {
  const request = fetch(`/domains/${domainId}`).then(res => res.json())
  return {
    type: FETCH_DOMAIN, payload: request,
  }
}

export function deleteDomain(domainId, hard = false) {
  return dispatch => {
    const request = fetch(`/domains/${domainId}`, {method: 'DELETE', body: JSON.stringify({hard})})
      .then(response => {
        if (response.status === 204) {
          dispatch(setMessage(SUCCESS, 'Domínio removido com sucesso!'))
        } else if (response.status === 403) {
          dispatch(setMessage(WARNING, 'Apenas usuários de nível administrativo podem remover domínios'))
        } else {
          const message = response.message || 'Não foi possível remover o usuário'

          dispatch(setMessage(WARNING, message))
        }
      })
      .catch(err => {
        const message = err.message || 'Erro ao tentar deletar domínio'

        dispatch(setMessage(ERROR, message))
      })

    return dispatch({
      type: DELETE, payload: request,
    })
  }
}

export function addDomain(accountId, domain) {
  return async dispatch => {
    const request = await fetch(`/accounts/${accountId}/domains`, {
      method: 'POST', body: JSON.stringify({name: domain}),
    })
      .then(res => {
        dispatch(push('/devices/domains'))
        dispatch(setMessage(SUCCESS, 'Domínio adicionado com sucesso!'))
        return res.json()
      })
      .catch(error => error)

    return dispatch({
      type: ADD, payload: request,
    })
  }
}

export function domainValidation(id, hash) {
  return dispatch => {
    fetch(`/domains/${id}/validation/email`, {
      method: 'POST', body: JSON.stringify({hash}),
    })
      .then(res => {
        if (res.status === 200) {
          history.push('/devices/domains')
          dispatch(setMessage(SUCCESS, 'Domínio validado com sucesso!'))
        } else if (res.status === 204) {
          dispatch(push('/devices/domains'))
          dispatch(setMessage(SUCCESS, 'Domínio validado com sucesso!'))
        } else {
          dispatch(push('/devices/domains'))
          dispatch(setMessage(ERROR, 'Domínio não validado.'))
        }
      })
      .catch(() => {
        dispatch(push('/devices/domains'))
        dispatch(setMessage(ERROR, 'Domínio não validado.'))
      })
  }
}

export function clearData() {
  return {
    type: CLEAR,
  }
}
