import { css } from 'styled-components';

import { $lightgrey300 } from '../settings/variables';

const arrowSize = 2.8;
const $arrowSize = `${arrowSize}rem`;

export default css`
    .popover {
        position: relative;
        display: block;
    }

    .popover-box {
        visibility: hidden;
        opacity: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
        min-width: 28rem;
        z-index: 11;
        background-color: ${$lightgrey300};
        box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.34), -2px 0 4px 0 rgba(0, 0, 0, 0.22);
        transition: opacity .5s;
    }

    .popover-box::before {
        content: ' ';
        position: absolute;
        width: ${$arrowSize};
        height: ${$arrowSize};
        background-color: ${$lightgrey300};
        display: block;
        border: .1rem solid darken(${$lightgrey300}, 15%);
        border-top-color: transparent;
        border-right-color: transparent;
    }

    .popover-box > .content-inner {
        position: relative;
    }

    .popover.open  .popover-box {
        visibility: visible;
        opacity: 1;
        height: initial;
        overflow: initial;
    }

    .popover.top .popover-box::before {
        top: 4rem;
    }

    .popover.bottom .popover-box::before {
        bottom: ${$arrowSize};
    }

    .popover.left .popover-box::before {
        right: -${arrowSize / 2}rem;
        transform: rotate(225deg);
    }

    .popover.right .popover-box::before {
        left: -${arrowSize / 2}rem;
        transform: rotate(45deg);
    }

    .popover.right {
        float: initial !important;
    }

    .popover > .toggle {
        cursor: pointer;
    }

    .box-report {
        width: 50rem;
    }

    .box-report > .content-inner > .row > .col-md-10 h1 {
        margin-left: 2rem;
    }

    .box-report > .content-inner > .row > .col-md-2 a {
        margin: 1.2rem 1rem 0 0;
    }

    .code-block-url {
        padding: 2rem 0 0 2rem;
    }

    .code-block-url > .link-icon {
        margin-top: -1.5rem;
    }

    .code-block-url > .col-md-8 > input {
        width: 34rem;
        margin-top: -1.5rem;
    }

    .code-block-password {
        padding: 2rem 0 0 2rem;
    }

    .code-block-password > .link-icon {
        margin-top: -0.2rem;
    }

    .code-block-password > .col-md-8 > input {
        width: 34rem;
        margin-top: .5rem;
    }

    .label-url {
        padding: .5rem 0 0 6.7rem;
    }

    .label-password {
        margin-top: 1.4rem;
        padding: 0.5rem 0 0 1.5rem;
    }

    .share-info {
        margin: 2rem 0 0 0.8rem;
    }
`;
