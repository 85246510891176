import PropTypes from 'prop-types'
import React from 'react'
import { Card } from '../../components'
import { OrganizationsList } from '../../containers'

export class Organizations extends React.Component {
  constructor(props) {
    super(props)
    this.title = 'Organizações'
  }

  componentDidMount() {
    this.props.setTitle(this.title)
  }

  render() {
    return (
      <section className="content">
        <Card>
          <OrganizationsList {...this.props} />
        </Card>
      </section>
    )
  }
}

Organizations.propTypes = {
  setTitle: PropTypes.func,
}
