import React, { memo } from 'react'
import PropTypes from 'prop-types'
import useStyles from '../styles'
import { TextField, MenuItem } from '@mui/material'
import { Controller } from 'react-hook-form'
import _ from 'lodash';

const SelectFormField = ({
  label,
  name,
  options,
  defaultValue,
  control,
  error,
  rules,
  disabled,
}) => {
  const classes = useStyles()

  return (
    <Controller
      render={({ field }) =>
        <TextField
          className={classes.inputValue}
          select
          label={label}
          name={name}
          defaultValue={defaultValue}
          variant="outlined"
          color="primary"
          fullWidth
          error={!!error}
          {...field}
          disabled={disabled}
        >
          <MenuItem value={''}>
            Escolha uma opção
          </MenuItem>

          {!_.isEmpty(options) && options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      }
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

SelectFormField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  control: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
}

export default memo(SelectFormField)
