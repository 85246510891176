import { css } from 'styled-components';

import { $greenSb } from '../settings/variables';

export default css`
    .help i {
        font-size: 1.6rem;
        margin: 0 .5rem;
    }

    .help i:hover {
        color: ${$greenSb} !important;
    }
`;
