import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import loadingSvg from '../../assets/images/loading.svg';

export const listColors = {
    'A+': '#7DC671',
    A: '#7DC671',
    'A-': '#7DC671',
    B: '#65C4ED',
    C: '#C3C3C3',
    D: '#FEE549',
    E: '#F2AC5B',
    F: '#FD4D4D',
    T: '#90989F',
    M: '#26989D',
    'SEM NOTA': '#F3F3F3',
};

export const Score = ({ value = '', loading, className }) => (
    <span
        className={classnames('score', className)}
        style={{ backgroundColor: listColors[value.toUpperCase()] || '#eee' }}
    >
        <span>{value.toUpperCase()}</span>
        {loading &&
            <img src={loadingSvg} alt="Loading" />
        }
    </span>
);

Score.propTypes = {
    value: PropTypes.string,
    loading: PropTypes.bool,
    className: PropTypes.string,
};
