import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Notifications } from '../components';
import { add } from '../actions/notifications';

class NotificationsHeader extends React.Component {
    componentDidMount() {
        // setInterval(() => {
        //     this.props.add('Testando mensagem');
        // }, 5000);
    }

    render() {
        return (
            <Notifications items={this.props.notifications} />
        );
    }
}

NotificationsHeader.propTypes = {
    notifications: PropTypes.array,
};

const mapStateToProps = ({ notifications }) => ({ notifications });

export default connect(mapStateToProps, { add })(NotificationsHeader);
