import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card } from '../../components'
import { FormCertificates } from '../../containers'

export const NewCertificate = (props) => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <FormCertificates
          location={props.location}
          params={props.match.params}
          renew={!!props.match.params.orderId}
        />
      </Card>
    </section>
  )
}

NewCertificate.propTypes = {
  location: PropTypes.object,
  params: PropTypes.object,
  route: PropTypes.object,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
