import React from 'react'
import { userIsAdmin } from '../../services/auth'

export const AutoApproveProduct = userIsAdmin(props => (
  <label className="checkbox" style={props.fullWidth ? { width: '100%' } : null}>
    <input
      type="checkbox"
      name="approve_product"
      onClick={props.handleClick}
      defaultChecked={props.autoApprove || false}
    />
    <span>Aprovar produto automaticamente</span>
  </label>
))
