import { useEffect } from 'react';
import { Card } from '../../components';

import { UsersOrganizationsList } from '../../containers';

export const UsersOrganizations = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <UsersOrganizationsList {...props} />
      </Card>
    </section>
  )
}
