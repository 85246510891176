import PropTypes from 'prop-types'
import React from 'react'
import { withAccountId } from './AccountContainer'
import { Waypoint } from 'react-waypoint'
import { Loading } from '../components'
import loadingSvg from '../../assets/images/loading.svg';

class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      onLoad: true,
      page: 1,
      globalLoading: false,
    }

    this.loadMore = this.loadMore.bind(this)
    this.nextFetch = this.nextFetch.bind(this)
  }

  componentDidMount() {
    if (!this.props.isLoading) {
      this.nextFetch()
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.accountId !== newProps.accountId) {
      this.setState({ page: 1, onLoad: true, globalLoading: true })
      this.props.actionFetch(newProps.accountId, { page: 1, page_size: 60, ...this.props.moreParams })
        .then(() => {
          this.setState({ onLoad: false, globalLoading: false })
        })
    }
  }

  async nextFetch() {
    const { page } = this.state
    this.setState({ onLoad: true })
    await this.props.actionFetch(this.props.accountId, { page: 1, page_size: page * 60, ...this.props.moreParams })
    this.setState({ page: page + 1, onLoad: false })
  }

  loadMore() {
    this.nextFetch()
  }

  render() {
    return (
      <article>

        <Loading loading={this.state.globalLoading || this.props.isLoading} inverse />

        {this.props.children}

        {!this.props.finished &&
          <div className="infinity-loader">
            <img src={loadingSvg} alt="Loading" />
          </div>
        }

        {!this.props.finished && !this.state.onLoad &&
          <Waypoint
            onEnter={this.loadMore}
          />
        }

        {this.props.finished && !this.state.onLoad &&
          <p style={{ textAlign: 'center' }}>----</p>
        }

      </article>

    )
  }
}

InfiniteScroll.propTypes = {
  actionFetch: PropTypes.func,
  moreParams: PropTypes.object,
  accountId: PropTypes.string,
  children: PropTypes.any,
  finished: PropTypes.bool,
}

export default withAccountId(InfiniteScroll)
