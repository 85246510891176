import {fetch} from '../services/fetch'
import {toParams} from '../services/utils'
import {ERROR, setMessage, SUCCESS} from '../modules/messages'
import {push} from 'connected-react-router'
import {CONSOLIDATED_ACCOUNT} from "./account";

export const FETCH_SUMMARY_ORDERS = 'summary-orders/FETCH_SUMMARY_ORDERS'
export const FETCH_CLIENT_ORDERS = 'orders/FETCH_CLIENT_ORDERS'
export const FETCH_STORE_ORDERS = 'orders/FETCH_STORE_ORDERS'
export const REQUEST_ORDER = 'orders/REQUEST_ORDER'
export const SET_ORDER_STATUS = 'orders/SET_ORDER_STATUS'
export const CHOOSE_ORDER = 'orders/CHOOSE_ORDER'
export const CHOOSE_ORDER_TYPE = 'orders/CHOOSE_ORDER_TYPE'
export const FETCH_ORDER_TYPES = 'orders/FETCH_ORDER_TYPES'
export const FETCH_ORDER_BY_PROMOCODE = 'orders/FETCH_ORDER_BY_PROMOCODE'
export const ORDER_FORM = 'orders/ORDER_FORM'

export function orderForm(data) {
  return dispatch =>
    dispatch({
      type: ORDER_FORM,
      payload: data,
    })
}

export function chooseOrder(orderId, nextPage) {
  return dispatch => {
    dispatch(push(nextPage))

    return setTimeout(() => dispatch({
      type: CHOOSE_ORDER,
      payload: { orderId },
    }), 1000)
  }
}

export function chooseOrderType(orderTypeId, nextPage) {
  return dispatch => {
    dispatch(push(nextPage))

    return setTimeout(() => dispatch({
      type: CHOOSE_ORDER_TYPE,
      payload: {orderTypeId},
    }), 1000)
  }
}

export function fetchSummaryOrders(accountId, params = {}) {
  const query = toParams(params)
  const url = accountId === CONSOLIDATED_ACCOUNT.id ? `/account/${accountId}/orders${query}` : `/orders${query}`
  const request = fetch(url).then(res => res.json())
  return {
    type: FETCH_SUMMARY_ORDERS,
    payload: request,
  }
}

export function fetchOrders(accountId, params = {}) {
  return dispatch => {
    const query = toParams(params)

    console.log('accountId', accountId)

    const url = accountId === CONSOLIDATED_ACCOUNT.id
      ? `/orders${query}`
      : `/account/${accountId}/orders${query}`

    const request = fetch(url).then(res => res.json())

    return dispatch({
      type: FETCH_CLIENT_ORDERS,
      payload: request,
      meta: {renew: !!params.order},
    })
  }
}

export function setOrderStatus(id, orderStatus) {
  return dispatch => {
    const request = fetch(`/orders/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({status: orderStatus}),
    })
      .then(res => res.json())
      .then(res => {
        const {status, id} = res
        dispatch(setMessage(SUCCESS, `Pedido "${id}" alterado para (${status})`))
        return dispatch(push('/store/orders'))
      })
      .catch(() => {
        dispatch(setMessage(ERROR, `Ocorreu um erro na alteração do Status. Procure a TI.`))

        return ({id: false})
      })
  }
}

export function fetchStoreOrders(store) {
  const request = fetch(`/stores/${store}`).then(res => res.json())
  return {
    type: FETCH_STORE_ORDERS,
    payload: request,
  }
}

export function requestOrder(accountId, data, autoApprove) {
  return dispatch => {
    const request = fetch(`/orders`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(res => {
        if (autoApprove) {
          dispatch(setMessage(SUCCESS, `Pedido '${res.order_type.name}' aprovado automaticamente`))
          return dispatch(push('/store/orders'))
        }

        dispatch(push('/store/success'))
        return res
      })
      .catch(() => {
        dispatch(setMessage(ERROR, 'Ocorreu um erro'))
      })

    return dispatch({
      type: REQUEST_ORDER,
      payload: request,
    })
  }
}
