import PropTypes from 'prop-types'
import React from 'react'
import { Wizard } from '../../containers'
import { Csr, Organization, Validation } from '../../views/certificates'
import { connect } from 'react-redux'
import { change, formValueSelector, reduxForm, reset } from 'redux-form'
// import { toFormErrors } from '../../services/utils';
import { withAccountId } from '../AccountContainer'
import { clearCsrInfo, issueCertificate } from '../../actions'
import { goToStep } from '../../actions/wizard'
import { addDomain, clearDomains } from '../../actions/domains-box'
import { fetchOrder, getCsrInfo } from '../../actions/certificate-orders'
import { chooseProduct, fetchProducts } from '../../actions/products'
import md5 from "md5";
// import md5 from 'blueimp-md5'

class FormCertificates extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.createComodoHash = this.createComodoHash.bind(this)
  }

  componentDidMount () {
    const { search } = this.props.location
    this.props.initialize({
      emissao: ''
    });

    this.props.clearCsrInfo()
    this.createComodoHash()

    if (search.product) {
      this.props.chooseProduct(search.product, '/certificates/new');
    }


    if (this.props.renew) {
      this.props.fetchOrder(this.props.match.params.orderId)
        .then(({ value }) => {
          Promise.all([
            this.props.fetchProducts(this.props.accountId, {
              product: value.product_id,
              page_size: 99999 /* TODO: temporário */,
            }),
            this.props.getCsrInfo(value.csr),
            ...value.domains.map(({ domain }) => this.props.addDomain(domain)),
          ])
        })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((nextProps.renew !== this.props.renew) || (nextProps.accountId !== this.props.accountId)) {
      this.componentWillUnmount()
      this.createComodoHash()

      if (!nextProps.renew) {
        this.props.fetchProducts(this.props.accountId, { page_size: 99999 /* TODO: temporário */ })
      }
    }
  }

  componentWillUnmount () {
    this.props.reset()
    this.props.clearCsrInfo()
    this.props.clearDomains([])
    this.props.goToStep(1)
  }

  onSubmit (data) {
    const accountId = this.props.accountId

    data.organization = {
      ...this.props.formData.organization,
      ...this.props.formData.address,
    }

    data.contact = {
      ...this.props.formData.contact,
    }

    delete data.address
    return this.props.issueCertificate(accountId, data)
  }

  createComodoHash () {
    this.props.change('comodo_hash', md5(+new Date()).substr(-20))
  }

  render () {
    const { emissao } = this.props;
    return (
      <Wizard onSubmit={this.onSubmit}>
        <Csr stepTitle="Selecionar certificado" renew={this.props.renew} errors={this.props.errors} emissao={emissao} />
        <Organization stepTitle="Dados da Empresa e Contato" errors={this.props.errors} emissao={emissao} />
        <Validation stepTitle="Validação" uniqueHash={this.props.comodoHash} errors={this.props.errors} />
      </Wizard>
    )
  }
}

FormCertificates.propTypes = {
  accountId: PropTypes.string.isRequired,
  csrInfo: PropTypes.object,
  initialValues: PropTypes.object,
  comodoHash: PropTypes.string,
  location: PropTypes.object,
  params: PropTypes.object,
  renew: PropTypes.bool,
  errors: PropTypes.object,
  issueCertificate: PropTypes.func,
  clearCsrInfo: PropTypes.func,
  clearDomains: PropTypes.func,
  goToStep: PropTypes.func,
  chooseProduct: PropTypes.func,
  change: PropTypes.func,
  reset: PropTypes.func,
  fetchOrder: PropTypes.func,
  fetchProducts: PropTypes.func,
  getCsrInfo: PropTypes.func,
  addDomain: PropTypes.func,
  formData: PropTypes.object,
}

const globalValues = {
  product: '',
  emissao: 'pessoa_fisica',
  server: '',
  csr: '',
  comodo_hash: '',
}

const addressValues = {
  zipcode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  country: 'BR',
}

const contactValues = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',

}

const organizationValues = {
  name: '',
  registration: '',
}

const Form = reduxForm({
  form: 'formCertificates',
  initialValues: {
    ...globalValues,
    organization: { ...organizationValues },
    address: { ...addressValues },
    contact: { ...contactValues },
  },
})(FormCertificates)

const selector = formValueSelector('formCertificates')

const mapStateToProps = (state) => {
  const {
    productInfo,
    csrInfo, domainsBoxList, certificateData
  } = state
  let errors = {}

  // if (form.formCertificates.errors) {
  //     errors = toFormErrors(form.formCertificates.errors)
  // }
  // else {
  //     errors =[]
  // }

  return {
    comodoHash: selector(state, 'comodo_hash'),
    emissao: selector(state, 'emissao'),
    csrInfo,
    productInfo,
    domains: domainsBoxList,
    errors: {
      ...globalValues,
      ...errors,
      organization: { ...organizationValues, ...errors },
      address: { ...addressValues, ...errors },
      contact: { ...contactValues, ...errors },
    },
    formData: certificateData,
  }
}

export default connect(mapStateToProps, {
  issueCertificate,
  clearCsrInfo,
  clearDomains,
  goToStep,
  chooseProduct,
  change,
  reset,
  fetchOrder,
  getCsrInfo,
  addDomain,
  fetchProducts,
})(withAccountId(Form))
