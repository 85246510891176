import { css } from 'styled-components';

import { $bluegray600, $lightgrey500, $fontSizeSmall, $borderRadiusBase } from '../settings/variables';

export default css`
    #main {
        height: 100vh;
    }

    .main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${$bluegray600};
        padding: 0 1rem;
        width: 100%;
        position: relative;
        min-height: 60px;
    }

    .main-header > .brand img {
        height: 3.5rem;
        width: auto;
        margin-left: 1rem;
    }

    .MuiBreadcrumbs-li > li > a {
        font-size: 1.6rem;
        font-weight: bold;
        color: #ffff;
        text-transform: uppercase;
    }

    .MuiBreadcrumbs-separator {
        color: #ffff;
    }

    .main-header > .brand {
        min-width: 330px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-breadcrumbs {
        color: #ffff !important;
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
    }

    .main-header > .tools {
        font-weight: 400;
        font-size: ${$fontSizeSmall};

        color: ${$lightgrey500};
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .main-header > .tools > div {
        margin-right: 3.5rem;
    }

    .main-header > .tools > div:last-child {
        margin-right: 2rem;
    }

    .main-header > .tools .btn-new-org {
        position: relative;
        display: inline-block;
        border-radius: ${$borderRadiusBase};
        border: none;
        width: auto;
        height: 3rem;
        min-width: auto;
        padding: .5rem 3.5rem;
        cursor: pointer;
        font-size: ${$fontSizeSmall};
        text-align: center;
        text-decoration: none;
        text-transform: none;
        outline: none;
        transition: all .2s ease-out;
        background-color: #74B61B;
        color: #fff !important;
        display: inline-flex;
        align-items: center;
        padding: 1rem;
        padding-right: 2rem;
        margin-right: 1.75rem;
    }

    .main-header > .tools .btn-new-org:hover {
        color: #fff;
    }

    .main-header .gravatar {
        border-radius: 100%;
        margin-right: 1rem;
        width: 2.8rem !important;
        height: 2.8rem !important;
    }

    .main-header .dropdown span {
        display: flex;
        align-items: center;
    }

    .main-header .profile svg {
        margin-left: .5rem;
        margin-top: .1rem;
    }

    .main-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        flex-grow: 1;
        width: 100%;
    }

    .main-content > .main-section {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 200px;
    }

    .main-content .gravatar {
        border-radius: 100%;
        margin-right: 1rem;
    }

    .main-content .dropdown span {
        display: flex;
        align-items: center;
    }

    .main-content .profile svg {
        margin-left: .5rem;
        margin-top: .1rem;
    }
`;
