import PropTypes from 'prop-types'
import { Loading } from '../../../components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Check, Mail, SupervisorAccount } from '@material-ui/icons'

import IconCheck from '../../../../assets/images/svg-icons/icon-check-circle.svg'
import LogoSb from '../../../../assets/images/logo-site-blindado.svg'
import NextStepIcon from '../../../../assets/images/arrow-next-step.svg'

// TODO: Criar container para essa conexao com Reduxss
const OrderSuccess = ({ isLoading }) => (
  <div className="content order-success">

    <Loading loading={isLoading} />

    <article className="content-inner card">
      <div className="title">
        <img
          src={IconCheck}
          alt="ícone check"
        />

        <h2>
          Pedido recebido com sucesso!
        </h2>
      </div>

      <div className="description">
        <p>
          Nossa equipe de vendas está analisando seu pedido e entrará em contato com você o quanto antes.
        </p>
      </div>

      <div className="details">
        <h3>
          Acompanhe sua solicitação de compra
        </h3>

        <div className="products-step">
          <div className="step">
            <Check style={{ fontSize: '4rem' }} />

            <p>
              Pedido Efetuado
            </p>
          </div>

          <div className="separator">
            <img
              src={NextStepIcon}
              alt="seta próximo passo"
            />
          </div>

          <div className="step">
            <SupervisorAccount style={{ fontSize: '4rem' }} />

            <p>
              Análise do time de vendas do
              <br />
              Site Blindado
            </p>
          </div>

          <div className="separator">
            <img
              src={NextStepIcon}
              alt="seta próximo passo"
            />
          </div>

          <div className="step">
            <Mail style={{ fontSize: '4rem' }} />

            <p>
              Aviso por e-mail<br />
              da aprovação da compra
            </p>
          </div>
        </div>

        <strong>
          Obrigado pela solicitação!<br />
          Equipe
        </strong>

        <div className="brand">
          <img
            src={LogoSb}
            alt="Site Blindado"
          />
        </div>
      </div>

      <Link
        to="/store/site-blindado"
        className="button-primary"
      >
        Solicitar outro produto
      </Link>
    </article>
  </div>
)

OrderSuccess.propTypes = {
  // numberProduct: PropTypes.string,
  isLoading: PropTypes.bool,
}

const mapStateToProps = ({ requestProduct }) => ({
  // numberProduct: requestProduct.id,
  isLoading: requestProduct.isLoading,
})

export default connect(mapStateToProps)(OrderSuccess)
