import PropTypes from 'prop-types';
import React from 'react';
import { userIsAdmin } from '../services/auth';

const Beta = ({ children, text }) => (
    <div className="beta">
        <span className="str">{text}</span>
        {children}
    </div>
);

Beta.defaultProps = {
    text: 'beta',
};

Beta.propTypes = {
    text: PropTypes.string,
    children: PropTypes.any,
};

export default userIsAdmin(Beta);
