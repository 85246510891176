import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from '../../components';

export const AwFields = (props) => (
    <Field
        type="number"
        label="Número de domínios"
        name="max_domains"
        component={TextField}
        error={props.errors.max_domains}
    />
);

AwFields.propTypes = {
    years: PropTypes.array,
    isMultdomain: PropTypes.bool,
    errors: PropTypes.object,
};
