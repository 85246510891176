/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { connect, useSelector, useDispatch } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { populateOrganization, setOrganizationData } from '../../../actions/certificate-orders'
import { prevStep, nextStep } from '../../../actions/wizard'
import { Button } from '../../../components'
import NewAddressFields from '../../../components/forms/newAdressFields'

const formPatters = {
  email: '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/',
  cpf: '([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})',
}

const Organization = ({ emissao }) => {

  const account = useSelector(state => state.account.find(a => a.id))
  const organization = useSelector(state => state.organization)
  const user = useSelector(state => state.auth.data.user)

  const dispatch = useDispatch()

  const { register, formState: { errors }, control, setValue, setError, handleSubmit } = useForm()

  const fetchOrganizationData = e => {
    e.preventDefault()
    dispatch(populateOrganization(account))
  }

  useEffect(() => {
    setValue('organization.name', organization.legal_name)
    setValue('organization.registration', organization.cnpj)

    if (!_.isEmpty(organization.address)) {
      setValue('address.street', organization.address.street)
      setValue('address.complement', organization.address.complement)
      setValue('address.country', organization.address.country)
      setValue('address.district', organization.address.district)
      setValue('address.city', organization.address.city)
      setValue('address.number', organization.address.number)
      setValue('address.state', organization.address.state)
      setValue('address.zipcode', organization.address.zipcode)
    }
  }, [organization])

  const onSubmit = (dataForm) => {
    Swal.fire({
      title: 'Atenção',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      icon: 'warning',
      iconColor: '#74B61B',
      html:
        `Este site pode enviar dados pessoais para identificação de nossos clientes: <br>
        <br><b>Nome:</b> ${user.first_name} ${user.last_name}<br>
        <b>E-mail:</b> ${user.email}<br>
        <b>Telefone:</b> ${user.phone}<br>
        <br>Repassamos essas informações total ou parcialmente à uma empresa terceira (nossa autoridade certificadora), para emissão de certificados solicitados.`,
      confirmButtonColor: '#74B61B'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setOrganizationData(dataForm))
        dispatch(nextStep())
      }
    })
  }

  return (
    <section className="content" style={{ paddin: 0 }}>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <Button
            pure
            onClick={fetchOrganizationData}
          >
            Carregar informações do meu cadastro
          </Button>
        </div>

        {emissao === 'pessoa_juridica' && (
          <>
            <div className="field">
              <label htmlFor="organization.name">
                Empresa
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.organization && errors.organization.name
                      ? 'input-error'
                      : ''}`
                    }
                    type="text"
                  />
                }
                defaultValue=""
                name="organization.name"
                rules={{ maxLength: 64 }}
                control={control}
              />

              {errors.organization && errors.organization?.name?.type === "required" && (
                <span className="input-error">Este campo é obrigatório</span>
              )}
              {errors.organization && errors.organization?.name?.type === "maxLength" && (
                <span className="input-error">Este campo é limitado a 64 caracteres</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="organization.registration">
                CNPJ
              </label>

              <Controller
                render={({ field }) =>
                  <InputMask
                    {...field}
                    className={`input ${errors.organization && errors.organization.registration
                      ? 'input-error'
                      : ''}`
                    }
                    type="text"
                    pattern={formPatters.cpf}
                    mask="99.999.999/9999-99"
                  />
                }
                defaultValue=""
                name="organization.registration"
                control={control}
              />
              {errors.organization && errors.organization.registration?.type === "required" && (
                <span className="input-error">Este campo é obrigatório</span>
              )}
            </div>
          </>
        )}

        <h2>
          Endereço
        </h2>

        <NewAddressFields
          isLoading={organization.isLoading}
          register={register}
          errors={errors}
          control={control}
        // disabled={userData.data.role === 'user'}
        />

        <h2>
          Dados do contato
        </h2>

        <div className="row">
          <div className="col-md-6">
            <div className="field">
              <label htmlFor="contact.first_name">
                Nome *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.contact && errors.contact.first_name
                      ? 'input-error'
                      : ''}`
                    }
                    type="text"
                  />
                }
                defaultValue={user.first_name || ''}
                name="contact.first_name"
                rules={{ required: true }}
                control={control}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="field">
              <label htmlFor="contact.last_name">
                Sobrenome *
              </label>

              <Controller
                render={({ field }) =>
                  <input
                    {...field}
                    className={`input ${errors.contact && errors.contact.last_name
                      ? 'input-error'
                      : ''}`
                    }
                    type="text"
                  />
                }
                defaultValue={user.last_name || ''}
                name="contact.last_name"
                rules={{ required: true }}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="contact.phone">Telefone *</label>
            <Controller
              render={({ field }) =>
                <InputMask
                  {...field}
                  className={`input ${errors.contact && errors.contact.phone
                    ? 'input-error'
                    : ''}`
                  }
                  type="text"
                  mask="(99) 9999-99999"
                />
              }
              defaultValue={user.phone || ''}
              name="contact.phone"
              rules={{ required: true }}
              control={control}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="contact.email">E-mail *</label>
            <Controller
              render={({ field }) =>
                <input
                  {...field}
                  className={`input ${errors.contact && errors.contact.email
                    ? 'input-error'
                    : ''}`
                  }
                  type="text"
                />
              }
              defaultValue={user.email || ''}
              name="contact.email"
              rules={{ required: true, pattern: formPatters.email }}
              control={control}
            />
          </div>
        </div>

        <div className="buttons">
          <Button>
            Próximo
          </Button>

          <a onClick={() => dispatch(prevStep())}>
            Voltar
          </a>
        </div>
      </form>
    </section>
  )
};

export default Organization;


// import React, { useEffect } from 'react'
// import { connect } from 'react-redux'
// import { formValueSelector } from 'redux-form'
// import { useForm, Controller } from 'react-hook-form'
// import InputMask from 'react-input-mask'
// import Swal from 'sweetalert2'
// import _ from 'lodash'
// import { useSelector, useDispatch } from 'react-redux'
// import { populateOrganization, setOrganizationData } from '../../../actions/certificate-orders'
// import { prevStep, nextStep } from '../../../actions/wizard'
// import { Button } from '../../../components'
// import NewAddressFields from '../../../components/forms/newAdressFields'

// const formPatters = {
//   email: '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/',
//   cpf: '([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})',
// }

// function Organization() {
//   const account = useSelector(state => state.account.find(a => a.id))
//   const organization = useSelector(state => state.organization)
//   const user = useSelector(state => state.auth.data.user)
//   const selectedProduct = useSelector(state => state.clientProducts.results[selector(state, 'product')]);

//   const dispatch = useDispatch()

//   const { register, formState: { errors }, control, setValue, handleSubmit } = useForm()

//   const fetchOrganizationData = e => {
//     e.preventDefault()
//     dispatch(populateOrganization(account))
//   }

//   useEffect(() => {
//     setValue('organization.name', organization.legal_name)
//     setValue('organization.registration', organization.cnpj)

//     if (!_.isEmpty(organization.address)) {
//       setValue('address.street', organization.address.street)
//       setValue('address.complement', organization.address.complement)
//       setValue('address.country', organization.address.country)
//       setValue('address.district', organization.address.district)
//       setValue('address.city', organization.address.city)
//       setValue('address.number', organization.address.number)
//       setValue('address.state', organization.address.state)
//       setValue('address.zipcode', organization.address.zipcode)
//     }
//   }, [organization])

//   const onSubmit = (dataForm) => {
//     Swal.fire({
//       title: 'Atenção',
//       showCancelButton: true,
//       cancelButtonText: 'Cancelar',
//       confirmButtonText: 'Confirmar',
//       icon: 'warning',
//       iconColor: '#74B61B',
//       html:
//         `Este site pode enviar dados pessoais para identificação de nossos clientes: <br>
//         ${selectedProduct.product_type.is_dv ? (`<br><b>E-mail:</b> ${user.email}<br></br>`) :
//           (`<br><b>Nome:</b> ${user.first_name} ${user.last_name}
//         <br><b>Telefone:</b> ${user.phone}
//         <br><b>E-mail:</b> ${user.email}<br>`)}
//         <br>Repassamos essas informações total ou parcialmente à uma empresa terceira (nossa autoridade certificadora), para emissão de certificados solicitados.`,
//       confirmButtonColor: '#74B61B'
//     }).then((result) => {
//       if (result.isConfirmed) {
//         dispatch(setOrganizationData(dataForm))
//         dispatch(nextStep())
//       }
//     })
//   }

//   function renderFieldsBasedOnProductType(product) {
//     if (!product || !product.product_type) return null;

//     if (selectedProduct.product_type.is_dv) {
//       return (<div className="row">
//         <div className="col-md-10">
//           <label htmlFor="contact.email">E-mail *</label>
//           <Controller
//             render={({ field }) =>
//               <input
//                 {...field}
//                 className={`input ${errors.contact && errors.contact.email
//                   ? 'input-error'
//                   : ''}`
//                 }
//                 type="text"
//               />
//             }
//             defaultValue={user.email || ''}
//             name="contact.email"
//             rules={{ required: true, pattern: formPatters.email }}
//             control={control}
//           />
//         </div>
//       </div>)
//     } else {
//       return (
//         <>
//           <div className="row">
//             <div className="col-md-6">
//               <div className="field">
//                 <label htmlFor="contact.first_name">
//                   Nome *
//                 </label>

//                 <Controller
//                   render={({ field }) =>
//                     <input
//                       {...field}
//                       className={`input ${errors.contact && errors.contact.first_name
//                         ? 'input-error'
//                         : ''}`
//                       }
//                       type="text"
//                     />
//                   }
//                   defaultValue={user.first_name || ''}
//                   name="contact.first_name"
//                   rules={{ required: true }}
//                   control={control}
//                 />
//               </div>
//             </div >
//             <div className="col-md-6">
//               <div className="field">
//                 <label htmlFor="contact.last_name">
//                   Sobrenome *
//                 </label>

//                 <Controller
//                   render={({ field }) =>
//                     <input
//                       {...field}
//                       className={`input ${errors.contact && errors.contact.last_name
//                         ? 'input-error'
//                         : ''}`
//                       }
//                       type="text"
//                     />
//                   }
//                   defaultValue={user.last_name || ''}
//                   name="contact.last_name"
//                   rules={{ required: true }}
//                   control={control}
//                 />
//               </div>
//             </div>
//           </div >

//           <div className="row">
//             <div className="col-md-6">
//               <label htmlFor="contact.phone">Telefone *</label>
//               <Controller
//                 render={({ field }) =>
//                   <InputMask
//                     {...field}
//                     className={`input ${errors.contact && errors.contact.phone
//                       ? 'input-error'
//                       : ''}`
//                     }
//                     type="text"
//                     mask="(99) 9999-99999"
//                   />
//                 }
//                 defaultValue={user.phone || ''}
//                 name="contact.phone"
//                 rules={{ required: true }}
//                 control={control}
//               />
//             </div>
//             <div className="col-md-6">
//               <label htmlFor="contact.email">E-mail *</label>
//               <Controller
//                 render={({ field }) =>
//                   <input
//                     {...field}
//                     className={`input ${errors.contact && errors.contact.email
//                       ? 'input-error'
//                       : ''}`
//                     }
//                     type="text"
//                   />
//                 }
//                 defaultValue={user.email || ''}
//                 name="contact.email"
//                 rules={{ required: true, pattern: formPatters.email }}
//                 control={control}
//               />
//             </div>
//           </div>
//         </>
//       )
//     }
//   }

//   return (
//     <section className="content" style={{ paddin: 0 }}>
//       <form className="form" onSubmit={handleSubmit(onSubmit)}>
//         <div className="field">
//           <Button
//             pure
//             onClick={fetchOrganizationData}
//           >
//             Carregar informações do meu cadastro
//           </Button>
//         </div>

//         <div className="field">
//           <label htmlFor="organization.name">
//             Empresa *
//           </label>

//           <Controller
//             render={({ field }) =>
//               <input
//                 {...field}
//                 className={`input ${errors.organization && errors.organization.name
//                   ? 'input-error'
//                   : ''}`
//                 }
//                 type="text"
//               />
//             }
//             defaultValue=""
//             name="organization.name"
//             rules={{ required: true }}
//             control={control}
//           />
//         </div>

//         <div className="field">
//           <label htmlFor="organization.registration">
//             CNPJ *
//           </label>

//           <Controller
//             render={({ field }) =>
//               <InputMask
//                 {...field}
//                 className={`input ${errors.organization && errors.organization.registration
//                   ? 'input-error'
//                   : ''}`
//                 }
//                 type="text"
//                 pattern={formPatters.cpf}
//                 mask="99.999.999/9999-99"
//               />
//             }
//             defaultValue=""
//             name="organization.registration"
//             rules={{ required: true }}
//             control={control}
//           />
//         </div>

//         <h2>
//           Endereço
//         </h2>

//         <NewAddressFields
//           isLoading={organization.isLoading}
//           register={register}
//           errors={errors}
//           control={control}
//         // disabled={userData.data.role === 'user'}
//         />

//         <h2>
//           Dados do contato
//         </h2>
//         {renderFieldsBasedOnProductType(selectedProduct)}

//         <div className="buttons">
//           <Button>
//             Próximo
//           </Button>

//           <a onClick={() => dispatch(prevStep())}>
//             Voltar
//           </a>
//         </div>
//       </form >
//     </section >
//   )
// }

// const selector = formValueSelector('formCertificates');

// const mapStateToProps = (state) => {
//   const { clientProducts } = state;

//   const selectedProduct = clientProducts.results[selector(state, 'product')];

//   return {
//     selectedProduct
//   };
// };

// export default connect(mapStateToProps)(Organization);
