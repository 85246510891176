import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import Gravatar from 'react-gravatar'
import { Settings, ExitToApp } from '@material-ui/icons'
import { MenuItem } from '@mui/material'
import {Dropdown} from "./Dropdown";

export const Profile = ({ name, email, onLogout }) => (
  <div className="profile">
    <Dropdown
      style={{ minWidth: '10%' }}
      button={(
        <span>
          <Gravatar
            className="gravatar"
            size={30}
            email={email}
            default="mm"
          />

          <div className="info-area">
            <span className="name">
              {name}
            </span>

            <span className="email">
              {email}
            </span>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5">
            <g fill="none" fillRule="evenodd">
              <path fill="#EEE" d="M0 0l5 5 5-5" />
              <path d="M-7-10h24v24H-7" />
            </g>
          </svg>
        </span>
      )}
    >
      <MenuItem>
        <Link
          style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
          to="/settings/profile/"
        >
          <Settings fontSize="medium" />

          <p style={{ width: '80%' }}>
            Configurações
          </p>
        </Link>
      </MenuItem>

      <MenuItem>
        <Link
          style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
          onClick={onLogout}
          to='/login'
        >
          <ExitToApp fontSize="medium" />

          <p style={{ width: '80%' }}>
            Sair
          </p>
        </Link>
      </MenuItem>
    </Dropdown>
  </div>
)

Profile.defaultProps = {
  email: '',
}

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
}
