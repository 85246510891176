import { fetch } from '../services/fetch';
import { toParams } from '../services/utils';
import { push } from 'connected-react-router';
import { setMessage, SUCCESS } from '../modules/messages';

export const FETCH = 'vulnerabilities/FETCH';
export const FETCH_ALL = 'vulnerabilities/FETCH_ALL';
export const SAVE = 'vulnerabilities/SAVE';


export function fetchVulnerabilities(accountId, params = {}) {
    const query = toParams(params);
    const request = fetch(`/vulnerabilities${query}`)
    .then(res => res.json());

    return {
        type: FETCH_ALL,
        payload: request,
    };
}

export function fetchVulnerability(id) {
    const request = fetch(`/vulnerabilities/${id}`)
    .then(res => res.json());

    return {
        type: FETCH,
        payload: request,
    };
}

export function saveVulnerability(id, data) {
    data.reviewed = JSON.parse(data.reviewed);

    return dispatch => {
        const request = fetch(`/vulnerabilities/${id}`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => {
            dispatch(push('/system/vulnerabilities'));
            dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'));
            return res.json();
        });

        return dispatch({
            type: SAVE,
            payload: request,
        });
    };
}
