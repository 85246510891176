import { css } from 'styled-components';

export default css`
    .inline-flags {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-size: cover;
        background-position: center;
        border-radius: 100%;
        margin: 0 .5rem;
    }
`;
