import PropTypes from 'prop-types'
import moment from 'moment'

import { Icon, Score, ContextMessage, Button } from '../../components'
import { Link } from 'react-router-dom'
import { ImportContacts } from '@material-ui/icons'
import loadingSvg from '../../../assets/images/loading.svg';

export const ScanReport = (props) => {
  let dateCreated
  if (props.ready) {
    dateCreated = moment(props.ready.date_ready).format('DD/MM/YYYY [às] HH:mm')
  }

  const urlReport = (domainId, scanId) => (
    `/certificates/report/${scanId}?id=${domainId}`
  )

  return (
    <div className="scan-report">
      <div className="inline-items">
        <div>
          <h3>Domínio</h3>
          <strong>{props.name}</strong>
        </div>

        <div>
          <h4>Último Scan</h4>
          {dateCreated &&
            <span>
              {dateCreated}
            </span>
          }
        </div>

        {props.in_progress &&
          <div className="lastUpdated">
            <img src={loadingSvg} alt="Loading" />
            escaneando
          </div>
        }

        {props.ready && !props.in_progress &&
          <div>
            <Button
              onClick={props.runScan}
              className="right"
            >
              Rodar Scan
            </Button>
          </div>
        }
      </div>

      {(props && props.error) &&
        <ContextMessage
          type="error"
          message={props.error.error_message}
        />
      }

      {props.ready && props.ready.vulnerabilities &&
        <div className="report">
          <table className="table table-no-border">
            <thead>
              <tr>
                <th>Vulnerabilidades</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.ready.vulnerabilities.map((vulnerability, key) =>
                <tr key={key}>
                  <td>
                    {vulnerability.description}
                  </td>
                  <td>
                    <Icon name="check" className="check" style={{ color: '#74B61B' }} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      }

      {!!props && !!props.ready && !!props.ready.result.endpoints && !props.error &&
        <div className="report">
          <table className="table table-no-border">
            <thead>
              <tr>
                <th>Servidor</th>
                <th>Nota</th>
              </tr>
            </thead>
            <tbody>
              {props.ready.result.endpoints.map((endpoint, key) =>
                <tr key={key}>
                  <td>
                    <div>
                      {endpoint.ipAddress}
                    </div>
                    {endpoint.serverName}
                  </td>
                  <td><Score value={endpoint.grade} /></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      }

      <div className="report-links">
        {/* TODO: Link com ícone */}
        <a
          className="link-icon"
          href="https://www.siteblindado.com/documents/manual_da_nota.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <ImportContacts style={{ marginRight: 10 }} />

          <p>
            Manual da Nota
          </p>
        </a>

        <Link hidden className="link-icon" to="#" >
          <Icon name="chat" />

          Abrir Chamado
        </Link>

        <Link hidden to="#" className="link-icon">
          <Icon name="file_download" />

          Baixar Relatório
        </Link>

        {props.ready &&
          <Link
            to={urlReport(props.id, props.ready.id)}
            className="button-primary right"
          >
            Visualizar Relatório
          </Link>
        }
      </div>
    </div>
  )
}

ScanReport.propTypes = {
  grade: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  endpoints: PropTypes.array,
  in_progress: PropTypes.object,
  error: PropTypes.object,
  ready: PropTypes.object,
  runScan: PropTypes.func,
}
