import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from '../../components';

const validDomainValues = value => {
  if(!value || value < 1) {
    return 1
  } else if(value > 100) {
    return 100
  } else {
    return value
  }
}

export const SSLFields = (props) => (
    <Fragment>
        {props.isMultdomain &&
            <Field
                type="number"
                label="Número de domínios"
                name="max_domains"
                oninput="validity.valid||(value='');"
                normalize={validDomainValues}
                component={TextField}
                error={props.errors.max_domains}
            />
        }
    </Fragment>
);

SSLFields.propTypes = {
    years: PropTypes.array.isRequired,
    isMultdomain: PropTypes.bool.isRequired,
    errors: PropTypes.object,
};
