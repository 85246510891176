import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm, formValueSelector } from 'redux-form'
import { RadioField, EmailValidation, FileValidation, CnameValidation, Button, AccordionOld } from '../../../components'
import { connect } from 'react-redux'

class ValidationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: {},
      email: {},
      loading: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.openAccordion = this.openAccordion.bind(this)
  }

  componentDidMount() {
    this.props.domains.forEach(({ domain, value }) => {
      const { type, email } = this.state

      if (value.search('@') > -1) {
        type[domain] = 'email'
        email[domain] = value
      } else {
        type[domain] = value
      }

      this.setState({ type, email })
    })
  }

  onSubmit(data) {
    this.setState({ loading: !this.state.loading });
    const domains = Object.keys(this.state.type).map(domain => ({
      domain,
      value: this.state.type[domain] !== 'email' ? this.state.type[domain] : this.state.email[domain],
    }));

    return this.props.onSubmit({ ...data, domains });
  }

  openAccordion(domain) {
    this.setState({ isOpen: domain })
  }

  handleRadio(value, domain) {
    const { type, email } = this.state
    type[domain] = value

    if (value !== 'email') {
      delete email[domain]
    }

    this.setState({ type, email, isOpen: domain })
  }

  handleEmail(value, domain) {
    const { email } = this.state
    email[domain] = value
    this.setState({ email })
  }

  checkChoiceTypes() {
    return Object.keys(this.props.emailsByDomains).every(domain => {
      if (!this.state.type[domain]) {
        return false
      }

      if (this.state.type[domain] === 'email' && !this.state.email[domain]) {
        return false
      }

      return true
    })
  }

  render() {
    return (
      <form className="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>

        <h2>
          Escolha um tipo de validação para todos os dominio abaixo.
        </h2>

        {Object.keys(this.props.emailsByDomains).map((domain, key) =>
          <div className="validation-method">
            <AccordionOld
              key={key}
              open={this.state.isOpen === domain}
              close={this.state.isOpen !== domain}
              header={(
                <div
                  className="header-validation"
                  onClick={() => this.openAccordion(domain)}
                >
                  <span className="domain">
                    {domain}
                  </span>

                  <RadioField
                    onChange={(value) => this.handleRadio(value, domain)}
                    choices={[
                      { label: 'Email', value: 'email' },
                      { label: 'HASH', value: 'hash' },
                      { label: 'DNS', value: 'dns' },
                    ]}
                    checked={this.state.type[domain]}
                  />
                </div>
              )}
            >

              <article>
                {this.state.type[domain] === 'email' &&
                  <EmailValidation
                    emails={this.props.emailsByDomains[domain].emails}
                    handleClick={(value) => this.handleEmail(value, domain)}
                    name={domain}
                    checkedEmail={this.state.email[domain] || ''}
                    hiddenButton
                  />
                }

                {this.state.type[domain] === 'hash' &&
                  <FileValidation
                    uniqueHash={this.props.uniqueHash}
                    sha256={this.props.csrInfo.sha256}
                    md5={this.props.csrInfo.md5}
                  />
                }

                {this.state.type[domain] === 'dns' &&
                  <CnameValidation
                    domain={domain}
                    uniqueHash={this.props.uniqueHash}
                    sha256={this.props.csrInfo.sha256}
                    md5={this.props.csrInfo.md5}
                  />
                }
              </article>

            </AccordionOld>
          </div>
        )}

        <div className="buttons">
          <Button
            disabled={this.state.loading || !this.checkChoiceTypes()}
            loading={this.state.loading}
          >
            Finalizar
          </Button>

          <a onClick={this.props.prevStep}>
            Voltar
          </a>
        </div>
      </form>
    )
  }
}

ValidationForm.propTypes = {
  uniqueHash: PropTypes.string,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  prevStep: PropTypes.func,
  onSubmit: PropTypes.func,
  emailsByDomains: PropTypes.object,
  csrInfo: PropTypes.object,
  domains: PropTypes.array,
}

const selector = formValueSelector('formCertificates')

const mapStateToProps = (state) => ({
  loading: state.form.formCertificates.loading,
  csrInfo: state.csrInfo,
  emailsByDomains: state.emailsByDomains,
  domains: selector(state, 'domains') || [],
})

const Validation = reduxForm({
  form: 'formCertificates',
  destroyOnUnmount: false,
})(ValidationForm)

export default connect(mapStateToProps)(Validation)
