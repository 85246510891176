import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    minHeight: 'calc(100vh - 22rem)',
    backgroundColor: '#ffff',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
  },
  tableContainer: {
    padding: '2rem',
    color: '#95959c !important',
  },
  loading: {
    height: 'calc(100vh - 40rem)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    width: '96%',
    margin: '0 auto',
  },
  card: {
    boxShadow: 'none !important',
  },
  headerItem: {
    textAlign: 'left',
    color: '#95959c',
    textTransform: 'uppercase',
    fontWeight: 'bold !important',
  },
  header: {
    border: '13px solid #ffff',
    backgroundColor: '#ffff',
  },
  tableItem: {
    textAlign: 'left !important',
    color: '#717171',
    fontSize: '1.3rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    width: '75%',
    borderRight: '2px solid #acacac',
  },
})

export default useStyles
