import { css } from 'styled-components';

import { $bluegray600 } from '../settings/variables';

export default css`
    .wizard-steps {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        padding: 2rem;
        background-color: #f7f7f7;
    }

    .wizard-steps > .step {
        flex: 0 1 auto;
    }

    .wizard-steps > .step > .stepNumber {
        background: #bbb;
        border-radius: 100%;
        width: 2.5rem;
        height: 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: white;
        margin-right: .5rem;
        padding-bottom: 0.2rem;
    }

    .wizard-steps > .step.active > .stepNumber {
        background: ${$bluegray600};
    }

    .wizard-steps > .separator {
        background: #ddd;
        flex: 1 1 auto;
        height: .2rem;
        margin: 0 1.5rem;
    }

    .wizard-steps > .separator:last-child {
        display: none;
    }

    .wizard-content {
        padding: 2rem;
    }
`;
