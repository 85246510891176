import PropTypes from 'prop-types';
import React from 'react';

export const Code = ({ children }) => (
    <div className="code-block">
        <pre>
            {children}
        </pre>
    </div>
);

Code.propTypes = {
    children: PropTypes.any,
};
