import { useEffect } from 'react'

import PropTypes from 'prop-types'
import { Card, CheckboxItem } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrganizationUsers, changeOrganizationUser } from '../../actions/organizations'

export default function ProductsPermissions(props) {
  const { match } = props
  const dispatch = useDispatch()

  const users = useSelector(state => state.organizationUsers)

  useEffect(() => {
    props.setTitle(props.title)
    dispatch(fetchOrganizationUsers(match.params.id))
  }, [])

  const handleChangeAccess = (id, value) => {
    dispatch(changeOrganizationUser(match.params.id, id, { access_api_ssl: value }, false))
  }

  return (
    <Card className="content" style={{ margin: '2rem' }}>
      <p>
        O portal Site Blindado disponibiliza ferramentas para serviços como por exemplo
        a API de SSL.
      </p>

      <article className="users-notify">
        {!users.isLoading &&
          users.allIds.map(id =>
            <div key={id} className="item-user">
              <div className="info">
                <span className="user-name">
                  {users.results[id].user.first_name}
                  {users.results[id].user.last_name}
                </span>
                <br />
                <span className="user-email">
                  {users.results[id].user.email}
                </span>
              </div>

              <div className="button" style={{ display: 'flex' }}>
                <CheckboxItem
                  value={users.results[id].access_api_ssl}
                  onClick={value => handleChangeAccess(id, value)}
                />
                <label style={{ color: 'black' }}>API de SSL</label>
              </div>
            </div>
          )
        }
      </article>
    </Card>
  )
}

ProductsPermissions.propTypes = {
  match: PropTypes.object,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}
