import React from 'react'
import get from 'lodash/get'
import { reducer as formReducer } from 'redux-form'
import { SEEK_ADDRESS } from '../actions/settings'
import { FETCH as FETCH_SERVERS, ADD } from '../actions/servers'
import { FETCH_CLIENT_PRODUCTS, CHOOSE_PRODUCT, CHOOSE_PRODUCT_TYPE } from '../actions/products'
import { FETCH as FETCH_ORGANIZATION, SAVE, CREATE } from '../actions/organizations'
import { POPULATE_ORGANIZATION, ISSUE_CERTIFICATE, FETCH_ORDER } from '../actions'
import { CREATE as CREATE_USER, FETCH as FETCH_USER, SAVE as SAVE_USER } from '../actions/users'
import { SET as SET_2FA } from '../actions/two-factor-auth'
import { FETCH as FETCH_VULNERABILITY } from '../actions/vulnerabilities'
import { FETCH as FETCH_FAMILY } from '../actions/families'
import { FETCH as FETCH_EVIDENCE } from '../actions/evidences'
import { SET_TRANSLATED_VALUE } from '../actions/forms'
import { CREATE as CREATE_ARMOUR, FETCH_UNIQUE as FETCH_APPLICATION } from '../actions/applications'
import { CREATE as CREATE_PROMOCODE } from '../actions/promocode'
import { ErrorsProduct } from '../components'

const INITIAL_STATE_FORM = {
  errors: [],
}

const INITIAL_CERTIFICATE = {
  errors: [],
  loading: false, // TODO isLoading
}

const chooseProducts = (state, action) => ({
  ...state,
  values: {
    ...state.values,
    product: action.payload.productId,
  },
})

const chooseProductTypes = (state, action) => ({
  ...state,
  values: {
    ...state.values,
    product_type: action.payload.productTypeId,
  },
})

// TODO: pensar em uma forma melhor
const wrapperAddress = (state = { values: {} }, action) => {
  switch (action.type) {
    case `${SEEK_ADDRESS}_PENDING`:
      return { ...state, addressLoading: true }

    case `${SEEK_ADDRESS}_REJECTED`:
      return { ...state, addressLoading: false }

    case `${SEEK_ADDRESS}_FULFILLED`: {
      const newAddress = {
        zipcode: action.payload.cep,
        street: action.payload.logradouro,
        district: action.payload.bairro,
        city: action.payload.cidade,
        state: action.payload.estado,
        country: 'BR',
      }

      return {
        ...state,
        addressLoading: false,
        values: {
          ...state.values,
          address: {
            ...newAddress,
          },
        },

      }
    }

    default:
      return state
  }
}

export const form = formReducer.plugin({
  formCertificates: (state = INITIAL_CERTIFICATE, action) => {
    switch (action.type) {
      case `${ISSUE_CERTIFICATE}_PENDING`:
        return {
          ...state,
          loading: true,
          values: {
            ...state.values,
            address: {
              zipcode: state.values.organization.zipcode,
              street: state.values.organization.street,
              district: state.values.organization.district,
              city: state.values.organization.city,
              state: state.values.organization.state,
              number: state.values.organization.number,
              complement: state.values.organization.complement,
              country: state.values.organization.country,
            },
          },
        }
      case `${ISSUE_CERTIFICATE}_FULFILLED`:
      case `${ISSUE_CERTIFICATE}_REJECTED`:
        return {
          ...state,
          loading: false,
        }
      case `${POPULATE_ORGANIZATION}_PENDING`:
        return {
          ...state,
          loading: true,
          values: {
            ...state.values,
            ...action.payload,
          },
        }
      case `${POPULATE_ORGANIZATION}_FULFILLED`:
        return {
          ...state,
          loading: false,
          values: {
            ...state.values,
            ...action.payload.value,
            organization: {
              name: action.payload.value.legal_name || '',
              registration: action.payload.value.cnpj || '',
              ...action.payload.value.address,
              country: action.payload.value.address ? action.payload.value.address.country : 'BR',
            },
            address: {
              ...action.payload.value.address,
              country: action.payload.value.address ? action.payload.value.address.country : 'BR',
            },
          },
        }

      case `${SEEK_ADDRESS}_PENDING`: // TODO pog temporária para fazer o loading aparecer no AddressField
      case `${SEEK_ADDRESS}_FULFILLED`:
        return {
          ...state,
          ...wrapperAddress(state, action),
        }
      case `${FETCH_ORDER}_PENDING`:
        return {
          ...state,
          loading: true,
        }
      case `${FETCH_ORDER}_FULFILLED`:
        return {
          ...state,
          loading: false,
          values: {
            ...state.values,
            server: 2,
            csr: action.payload.csr,
            domains: action.payload.domains,
            organization: {
              name: action.payload.organization_name,
              registration: action.payload.organization_registration,
            },
            address: {
              zipcode: action.payload.organization_postalcode,
              street: action.payload.organization_street,
              number: action.payload.organization_number,
              complement: action.payload.organization_complement,
              district: action.payload.organization_district,
              city: action.payload.organization_city,
              state: action.payload.organization_state,
              country: action.payload.organization_country,
            },
            contact: {
              first_name: action.payload.contact_forename,
              last_name: action.payload.contact_surname,
              phone: action.payload.contact_phone,
              email: action.payload.contact_email,
            },
          },
        }
      case CHOOSE_PRODUCT:
        return chooseProducts(state, action)
      case CHOOSE_PRODUCT_TYPE:
        return chooseProductTypes(state, action)

      // ERROS DE PRODUCTS
      case `${FETCH_CLIENT_PRODUCTS}_FULFILLED`: {
        const availables = !!action.payload.results
          && action.payload.results.filter(item => item.status === 'available')
        const pending = !!action.payload.results
          && action.payload.results.filter(item => item.status === 'pending')

        if (availables.length) {
          return state
        }

        const type = {
          notProduct: !availables.length && !pending.length && !action.meta.renew,
          notAvailable: !!(!availables.length && pending.length),
          renew: action.meta.renew,
        }

        return {
          ...state,
          errors: [
            { field: 'product', message: <ErrorsProduct {...type} /> },
          ],
        }
      }
      default:
        return state
    }
  },
  formArmour: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      // case CHOOSE_PRODUCT:
      //     return chooseProducts(state, action);
      case `${CREATE_ARMOUR}_FULFILLED`:
        return {
          ...state,
          errors: {
            ...action.payload.errors,
            product: action.payload.errors.product_id,
          },
        }
      default:
        return state
    }
  },
  // TODO: Refatorar para não repedir tanto codigo
  formAddServer: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${FETCH_SERVERS}_PENDING`:
        return {
          ...state,
          isLoading: true,
        }
      case `${FETCH_SERVERS}_FULFILLED`:
        return {
          ...state,
          isLoading: false,
          values: {
            ...state.values,
            ip_address: action.payload.ip_address,
            name: action.payload.name,
          },
        }
      case `${ADD}_FULFILLED`:
        return {
          ...state,
          errors: action.payload.errors,
        }

      default:
        return state
    }
  },

  formOrganization: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${FETCH_ORGANIZATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
          values: {
            ...state.values,
            ...action.payload,
          },
        }
      case `${FETCH_ORGANIZATION}_FULFILLED`:
        return {
          ...state,
          isLoading: false,
          values: {
            ...state.values,
            ...action.payload,
            address: {
              ...action.payload.address,
              country: action.payload.address ? action.payload.address.country : 'BR',
            },
          },
        }
      case `${SAVE}_FULFILLED`:
        return {
          ...state,
          errors: action.payload.errors,
        }
      // deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
      case `${CREATE}_FULFILLED`:
        return {
          ...state,
          errors: action.payload.errors,
        }
      case `${SEEK_ADDRESS}_PENDING`: // TODO pog temporária para fazer o loading aparecer no AddressField
      case `${SEEK_ADDRESS}_REJECTED`: // TODO pog temporária para fazer o loading aparecer no AddressField
      case `${SEEK_ADDRESS}_FULFILLED`:
        return {
          ...state,
          ...wrapperAddress(state, action),
        }

      default:
        return state
    }
  },

  formApplication: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${FETCH_APPLICATION}_PENDING`:
        return {
          ...state,
          isLoading: true,
          values: {
            ...state.values,
            ...action.payload,
          },
        }
      case `${FETCH_APPLICATION}_FULFILLED`:
        return {
          ...state,
          isLoading: false,
          values: {
            ...state.values,
            ...action.payload,
          },
        }
      case `${SAVE}_FULFILLED`:
        return {
          ...state,
          errors: action.payload.errors,
        }
      case `${CREATE}_FULFILLED`:
        return {
          ...state,
          errors: action.payload.errors,
        }

      default:
        return state
    }
  },

  formSignup: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${CREATE_USER}_FULFILLED`:
        return {
          ...state,
          user: action.payload,

        }
      case `${CREATE_USER}_REJECTED`:
        return {
          ...state,
          errors: action.payload.errors,
        }
      default:
        return state
    }
  },

  formProfile: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${SAVE_USER}_FULFILLED`:
        return {
          ...state,
          errors: action.payload.errors,

        }
      case `${FETCH_USER}_PENDING`:
        return {
          ...state,
          isLoading: true,
        }
      case `${FETCH_USER}_FULFILLED`:
        delete action.payload.timezone
        delete action.payload.otp_enabled
        return {
          ...state,
          isLoading: false,
          updateInfo: action.payload.update_info,
          values: {
            ...state.values,
            ...action.payload,
          },
        }
      case `${SEEK_ADDRESS}_PENDING`: // TODO pog temporária para fazer o loading aparecer no AddressField
      case `${SEEK_ADDRESS}_REJECTED`: // TODO pog temporária para fazer o loading aparecer no AddressField
      case `${SEEK_ADDRESS}_FULFILLED`:
        return {
          ...state,
          ...wrapperAddress(state, action),
        }
      default:
        return state
    }
  },

  formTwoFactorAuth: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${SET_2FA}_FULLFILED`:
        return { ...state, errors: [] }
      case `${SET_2FA}_REJECTED`:
        return {
          ...state,
          errors: action.payload.errors,
        }
      default:
        return state
    }
  },

  formVulnerabilityTranslate: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${FETCH_VULNERABILITY}_PENDING`:
        return {
          ...state,
          isLoading: true,
        }
      case `${FETCH_VULNERABILITY}_FULFILLED`:
        return {
          ...state,
          isLoading: false,
          values: {
            ...state.values,
            family: action.payload.family,
            title: action.payload.title,
            title_pt: action.payload.title_pt,
            description: action.payload.description,
            description_pt: action.payload.description_pt,
            consequence: action.payload.consequence,
            consequence_pt: action.payload.consequence_pt,
            solution: action.payload.solution,
            solution_pt: action.payload.solution_pt,
            how_it_works: action.payload.how_it_works,
            how_it_works_pt: action.payload.how_it_works_pt,
            reviewed: String(!!action.payload.reviewed),
            errors: [],
          },
        }
      case `${SET_TRANSLATED_VALUE}[${get(action, 'meta.form', '')}]`:
        return {
          ...state,
          values: {
            ...state.values,
            [action.meta.field]: action.payload.text,
          },
        }
      default:
        return state
    }
  },
  formFamily: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${FETCH_FAMILY}_PENDING`:
        return {
          ...state,
          isLoading: true,
        }
      case `${FETCH_FAMILY}_FULFILLED`:
        return {
          ...state,
          isLoading: false,
          values: {
            ...state.values,
            title: action.payload.title,
            description: action.payload.description,
            errors: [],
          },
        }
      default:
        return state
    }
  },
  formEvidence: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case `${FETCH_EVIDENCE}_PENDING`:
        return {
          ...state,
          isLoading: true,
        }
      case `${FETCH_EVIDENCE}_FULFILLED`:
        return {
          ...state,
          isLoading: false,
          values: {
            ...state.values,
            application: action.payload.application,
            client: action.payload.client,
            scan_date: action.payload.scan_date,
            created: action.payload.created,
            url: action.payload.url,
            status: action.payload.status,
            false_positive_motive: action.payload.false_positive_motive,
            errors: [],
          },
        }
      default:
        return state
    }
  },
  formPromo: (state = INITIAL_STATE_FORM, action) => {
    switch (action.type) {
      case CHOOSE_PRODUCT_TYPE:
        return chooseProductTypes(state, action)
      case `${CREATE_PROMOCODE}_FULFILLED`:
        return {
          ...state,
          errors: {
            ...action.payload.errors,
            product_type: action.payload.errors.product_type_id,
          },
        }
      default:
        return state
    }
  },
})
