import { css } from 'styled-components';

export default css`
    .refresh-button {
        display: flex;
        align-items: center;
        margin: 0;
        cursor: pointer;
    }

    .refresh-button > svg {
        margin: 0;
        fill: #666;
        border-left: 1px solid #ddd;
        padding: 0 1rem;
        width: 5rem;
    }
`;
