import React, { useState } from 'react';

import Cookies from 'js-cookie';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Button} from "./Button";
const useStyles = makeStyles({
    privacyPolicyShow: {
        position: 'fixed',
        width: '100%',
        bottom: '5px',
        zIndex: '9999',
    },
    privacyPolicyHide: {
        display: 'none !important',
    },
    cardLgpd: {
        bottom: '5px',
        display: 'flex',
        justifyContent: 'center'
    },
    card: {
        border: '0',
        boxShadow: '0 0 10px #aaa',
        padding: '20px',
        width: '70%',
        borderRadius: '0.375rem',
        backgroundColor: '#fff',
    },
    container: {
        width: '80%',
        height: 60,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
        backgroundColor: '#fff',
        borderRadius: '.25rem',
        boxShadow: '60px 60px 60px 0 rgb(0 0 0 / 30%), 0px 0px 80px 0 rgb(0 0 0 / 30%)',
    },
    link: {
        color: '#74b61b',
        fontWeight: 'bold',
    },
    buttonGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        width: '35%',
        padding: '1.5rem 3.5rem 1rem 3.5rem !important',
        display: 'flex',
        height: 'auto !important',
        justifyContent: 'space-between',
        borderRadius: '5px',
        background: 'linear-gradient(126deg, rgba(116,182,55,1) 0%, rgba(40,48,32,1) 55%)',
        textTransform: 'uppercase !important',
        fontSize: '12px',
        fontWeight: 'bold'
    },
});

export default function PrivacyPolicy() {
    const classes = useStyles();

    const [hasAcceptedTerm, setHasAcceptedTerm] = useState(Cookies.get().SB_P_PPT);

    const handleAccept = () => {
        Cookies.set('SB_P_PPT', true, { expires: 365 });
        setHasAcceptedTerm(true);
    };

    return (
        <Grid className={hasAcceptedTerm ? classes.privacyPolicyHide : classes.privacyPolicyShow}>
            <Grid className={classes.cardLgpd}>
                <Grid container className={classes.card}>
                    <Grid item xs={10}>
                        <p><strong>Site Blindado e os cookies</strong>: a gente usa cookies para melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa&nbsp;
                        <a href="http://www.siteblindado.com.br/privacidade-politica/"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.link}>Política de Privacidade</a>. Ao clicar neste aceite você também concorda com o nosso&nbsp;
                        <a href="https://www.siteblindado.com/assets/documents/TERMODEUSODOSOFTWAREPORTALSITEBLINDADO.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.link}>Termo de uso do portal</a>.</p>
                    </Grid>
                    <Grid item className={classes.buttonGrid} xs={2}>
                        <Button className={classes.button} onClick={handleAccept}>Continuar e fechar</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
