import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
// import { fetch } from 'services/fetch';
// import localStore from 'store';
// import { API_URL, API_VERSION } from '../../constants';

/** Para funcionar deve criar um estado e passar os dois atributos, text e setText.
 *  Primeiro o text como uma string inicial e
 *  setText como uma função para alterar o estado dessa string  */

 // RETORNA ATRAVES DESSA FUNCAO HANDLE EDITOR PARA A FUNCAO IMPORTADA

function WysiwygEditor(props) {
    const { text, setText } = props;

    const handleEditorChange = content => setText(content || ' ');

    const fileSenderCallback = (blobInfo, success) => {
        // PARA IMPLEMENTACAO FUTURA VIA S3 E API
        // const formData = new FormData();
        // formData.append('file', blobInfo.blob(), blobInfo.filename());
        // const url = url;
        // const request = fetch(url, {
        //     method: 'POST',
        //     body: formData,
        //     headers: {
        //         Authorization: `Bearer ${localStore.get('token')}`,
        //     },
        // }).then(res => {
        //     try {
        //         if (res.ok) {
        //             return res.json();
        //         }
        //         throw new Error(res);
        //     } catch (err) {
        //         console.log(err.message);
        //         failure(err);
        //     }
        // }
        // ).then(resJson => {
        // });
        // IMPLEMENTADO VIA BASE64 E BLOB
        success(
            `data:${blobInfo.blob().type};base64,${blobInfo.base64()}`
        );
    };

    return (
        <Editor
            {...props}
            ref={useRef(null)}
            value={text}
            apiKey={process.env.REACT_APP_KEY_EDITOR}
            init={{
                height: '30vh',
                menubar: false,
                images_upload_handler: fileSenderCallback,
                paste_data_images: true,
                plugins: [
                    'advlist autolink lists link image',
                    'charmap print preview anchor help',
                    'searchreplace visualblocks code',
                    'insertdatetime media table paste wordcount',
                ],
                file_browser_callback_types: 'image',
                toolbar:
                    'undo redo | formatselect | bold italic |' +
                    'alignleft aligncenter alignright |' +
                    'bullist numlist outdent indent image | help | forecolor backcolor',
            }}
            onEditorChange={handleEditorChange}
        />
    );
}

WysiwygEditor.propTypes = {
    text: PropTypes.string,
    setText: PropTypes.func,
};

export default WysiwygEditor;
