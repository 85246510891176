import React, { memo } from 'react'

import { Content } from '../../components'
import { Route, Switch } from 'react-router-dom'

export const EditUser = memo(props => {
  const submenu = [
    {
      route: `/users/${props.match.params.id}`,
      name: 'Detalhes',
    },
    {
      route: `/users/${props.match.params.id}/organizations`,
      name: 'Organizações',
    }
  ]

  const { setTitle } = props

  return (
    <Content submenu={submenu} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={props =>
              <route.component
                routes={route.routes}
                setTitle={setTitle}
                title={route.name}
                {...props}
              />
            }
          />
        )}
      </Switch>
    </Content>
  )
})
