import { OPEN_DIALOG, CLOSE_DIALOG } from '../actions/dialog';

const INITIAL_STATE = {
    isOpen: false,
    content: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_DIALOG: {
            const content = action.payload.content;
            return { ...state, content, isOpen: true };
        }
        case CLOSE_DIALOG:
            return { ...state, isOpen: false };
        default:
            return state;
    }
};
