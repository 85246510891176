import { css } from 'styled-components';

export default css`
    .icon-circle {
        background-color: green;
        display: flex;
        width: 4rem;
        height: 4rem;
        border-radius: 10rem;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .material-icons.expand_more {
        transform: rotate(-90deg);
    }

    .material-icons.expand_less {
        transform: rotate(180deg);
    }
`;
