import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Button,
  TableWrap,
  TableWrapActions,
  TableWrapLeft,
  TableWrapRight,
} from '../../../../components'
import PenTestVulnerabilitySearch from './Search'
import PentestVulnerabilityForm from './Form'

import { fetchPentestVulnerabilities, deletePentestVulnerability } from '../../../../actions/pentests/vulnerabilities'
import { risk } from '../../../../services/pentest-fields'

import { Grid, Typography } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Popover } from '@mui/material'

const useStyles = makeStyles({
  container: {
    height: 'calc(100vh - 22rem)',
    overflow: 'overlay',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  containerAddVunerability: {
    height: 'calc(100vh - 22rem)',
    overflowX: 'auto !important',
  },
  mainTitle: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  title: {
    width: '100%',
    padding: 25,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    backgroundColor: '#f7f7f7',
  },
})
export default function PenTestVulnerabilities(props) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const account = useSelector(state => state.account)
  const pentestVulnerabilities = useSelector(state => state.pentestVulnerabilities)

  const accountId = account[0] ? account[0].id : undefined
  const { id, report_id } = props.match.params

  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [editData, setEditData] = useState(undefined)
  const [deleteVulnerability, setDeleteVulnerability] = useState(null)

  async function getVulnerabilities() {
    await dispatch(fetchPentestVulnerabilities(accountId, id, report_id))
  }

  async function onRemove(currentId) {
    await dispatch(deletePentestVulnerability(accountId, id, report_id, currentId))

    setDeleteVulnerability(null)

    await getVulnerabilities()
  }

  const onOpenModalAdd = () => setOpenAdd(true)

  const onCloseModalAdd = async () => {
    setOpenAdd(false)
    // await getVulnerabilities()
  }

  const onOpenModalEdit = () => setOpenEdit(!openEdit)

  const onCloseModalEdit = async () => {
    setOpenEdit(false)

    // await getVulnerabilities()
  }

  const handleDelete = event => setDeleteVulnerability(event.currentTarget)

  const model = {
    vulnerability_id: {
      name: 'Código',
      renderer: (name, obj) => obj.vulnerability_id,
    },
    title: {
      name: 'Título',
      renderer: (name, obj) => obj.title,
    },
    url: {
      name: 'URL',
      renderer: (name, obj) => obj.url,
    },
    recommendation: {
      name: 'Recomendação',
      // eslint-disable-next-line no-confusing-arrow
      renderer: (name, obj) =>
        obj.recommendation.length < 35 ? obj.recommendation : `${obj.recommendation.substring(0, 32)}...`,
      tooltip: (name, obj) => obj.recommendation,
    },
    ports: {
      name: 'Portas',
      renderer: (name, obj) => obj.ports,
    },
    risk: {
      name: 'Risco',
      renderer: (name, obj) => risk(obj.risk),
    },
    references: {
      name: 'Referências',
      // eslint-disable-next-line no-confusing-arrow
      renderer: (name, obj) =>
        obj.references.length < 35 ? obj.references : `${obj.references.substring(0, 32)}...`,
      tooltip: (name, obj) => obj.references,
    },
    edit: {
      name: ' ',
      renderer: (name, obj) => (
        <a href='/#' className="table-icons">
          <Edit fontSize="large" onClick={event => {
            event.preventDefault()

            setEditData(obj)
            onOpenModalEdit()
          }} />
        </a>
      ),
    },
    delete: {
      name: ' ',
      renderer: (name, obj) =>
        <a href='/#' className="table-icons">
          <Delete onClick={handleDelete} style={{ fontSize: '2rem' }} />

          <Popover
            id={deleteVulnerability ? 'simple-popover' : undefined}
            onClose={() => setDeleteVulnerability(null)}
            anchorEl={deleteVulnerability}
            open={!!deleteVulnerability}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Grid>
              <Grid className="remove-item">
                <h1 className="remove-title">
                  Remover Vulnerabilidade
                </h1>

                <p className="remove-text">
                  Esta vulnerabilidade será removida e não há como reverter esta ação.
                </p>

                <Grid className="remove-actions">
                  <a href='/#' onClick={() => onRemove(obj.id)}>
                    Remover
                  </a>

                  <a href='/#' onClick={() => setDeleteVulnerability(null)}>
                    Cancelar
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Popover>
        </a>
    },
  }

  return (
    <Grid>
      {!openAdd && !openEdit
        ? (
          <Grid className={classes.container}>
            <TableWrap
              model={model}
              source={pentestVulnerabilities}
              accountId={accountId}
              actionFetch={getVulnerabilities}
              vulnerability
              location={props.location}
              hasSearch={false}
            >
              <TableWrapActions>
                <TableWrapLeft>
                  <Typography className={classes.mainTitle} variant="h2">
                    Vulnerabilidades
                  </Typography>
                </TableWrapLeft>

                <TableWrapRight>
                  <Button onClick={onOpenModalAdd}>
                    Adicionar vulnerabilidade
                  </Button>
                </TableWrapRight>
              </TableWrapActions>
            </TableWrap>

            <div className="buttons" style={{ padding: '10px' }}>
              <Button onClick={props.backStep} style={{ marginRight: '10px' }}>
                Anterior
              </Button>

              <Button onClick={props.nextStep}>
                Próximo
              </Button>
            </div>
          </Grid>
        )
        : (
          <Grid>
            {openAdd &&
              <Grid className={classes.containerAddVunerability}>
                <Typography className={classes.title} variant="h2">
                  Adicionar vulnerabilidade
                </Typography>

                <PenTestVulnerabilitySearch
                  {...props}
                  accountId={accountId}
                  id={id}
                  reportId={report_id}
                  pentestId={id}
                  onCloseModal={onCloseModalAdd}
                />
              </Grid>
            }

            {openEdit &&
              <Grid className={classes.containerAddVunerability}>
                <Typography className={classes.title} variant="h2">
                  Editar vulnerabilidade
                </Typography>

                <PentestVulnerabilityForm
                  {...editData}
                  accountId={accountId}
                  pentestId={id}
                  reportId={report_id}
                  onCloseModal={onCloseModalEdit}
                />
              </Grid>
            }
          </Grid>
        )
      }
    </Grid>
  )
}

PenTestVulnerabilities.propTypes = {
  location: PropTypes.object,
  backStep: PropTypes.func,
  nextStep: PropTypes.func,
  params: PropTypes.object,
}
