import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm, reset } from 'redux-form';
// import { toFormErrors } from '../../services/utils';
import { connect } from 'react-redux';
import { TextField, Button, Loading, DatePicker } from '../../components';
import { createPromoCodes } from '../../actions/promocode';
import { SelectProductTypesField } from '../../containers';

class FormPromo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.edit) {
            this.props.setBreadcrumbName(<Loading loading />);
        }
    }

    onSubmit(data) {
        return this.props.dispatch(createPromoCodes({
            ...data,
            expiration: this.state.date,
            product_type_id: data.product_type,
        }));
    }

    onChange(date) {
        this.setState({ date: new Date(date).toISOString() });
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="form">

                <Loading loading={this.props.isLoading} />

                <SelectProductTypesField
                    label="Tipos de Produtos *"
                    name="product_type"
                    error={this.props.errors.product_type || ''}
                />

                <Field
                    label="Quantidade"
                    name="quantity"
                    component={TextField}
                />

                <Field
                    label="Justificativa"
                    name="justification"
                    selector="textarea"
                    className="textarea"
                    component={TextField}
                />

                <div className="field">
                    <label>Data limite para a Promoção: *</label>
                    <DatePicker
                        onChange={this.onChange}
                        options={{
                            inline: true,
                            enableTime: true,
                            hideInput: true,
                            minDate: 'today',
                        }}
                    />
                </div>

                <div className="buttons">
                    <Button
                        loading={this.props.submitting}
                    >
                        Salvar
                    </Button>
                    <Link to="/system/promocode/">Cancelar</Link>
                </div>
            </form>
        );
    }
}

FormPromo.propTypes = {
    setBreadcrumbName: PropTypes.func,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    submitting: PropTypes.bool,
};

const Form = reduxForm({
    form: 'formPromo',
})(FormPromo);

const mapStateToProps = (state) => ({
    isLoading: state.promocode.isLoading,
    // errors: toFormErrors(form.formPromo.errors),
});

export default connect(mapStateToProps, { createPromoCodes, reset })(Form);
