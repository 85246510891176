import PropTypes from 'prop-types'
import { SliderButton } from '../containers'
import { HelpOutline } from '@material-ui/icons'

export const Help = (props) => (
  <SliderButton
    sliderTitle={props.title}
    className="help"
    style={{ ...props.style }}
    button={(
      <HelpOutline />
    )}
  >
    {props.children}
  </SliderButton>
)

Help.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
}
