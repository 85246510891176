import PropTypes from 'prop-types'

import {
  Check,
  ErrorOutline,
  InfoOutlined,
  Warning
} from '@material-ui/icons'

export const ContextMessage = ({ type, title, message }) => {
  const typeFormatted = type.toLowerCase()

  return (
    <div className={`message ${typeFormatted}`}>
      {typeFormatted === 'success' &&
        <Check style={{ marginRight: 10 }} />
      }

      {typeFormatted === 'warning' &&
        <Warning style={{ marginRight: 10 }} />
      }

      {typeFormatted === 'info' &&
        <InfoOutlined style={{ marginRight: 10 }} />
      }

      {typeFormatted === 'error' &&
        <ErrorOutline style={{ marginRight: 10 }} />
      }

      <div>
        <div className="title">
          {title}
        </div>

        <span>
          {message}
        </span>
      </div>
    </div>
  )
}
ContextMessage.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
}
