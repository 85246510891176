import { css } from 'styled-components';

export default css`
    .app-outside form {
        margin: 1rem 0;
        min-width: 35rem;
    }

    .app-outside .input-lg {
        width: 35rem;
    }
`;
