import PropTypes from 'prop-types';
import React from 'react';
// import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { CopyToClipBoardURL, CopyToClipBoardPassword } from '../../components';


export class PopoverReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: this.props.open };
        this.handleClick = this.handleClick.bind(this);
        this.setBoxPosition = this.setBoxPosition.bind(this);
        this.isRight = this.isRight.bind(this);
        this.isTop = this.isTop.bind(this);
        this.onClose = this.onClose.bind(this);
        this.refPopover = React.createRef();
    }

    componentDidMount() {
        // this.obj = ReactDOM.findDOMNode(this);
        this.box = this.refPopover.querySelector('.popover-box');
        this.toggle = this.refPopover.firstElementChild;
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }
    onClose() {
        this.setState({ open: !this.state.open });
    }
    setBoxPosition() {
        if (!this.refPopover) {
            return;
        }

        const posX = -45;
        const posY = -510;

        const style = {};
        style[this.isTop() ? 'top' : 'bottom'] = posX;
        style.left = posY;

        return style;
    }

    isRight() { return this.props.right || !this.props.left; }

    isTop() { return this.props.top || !this.props.bottom; }

    handleClick(e) {
        if (this.box.contains(e.target)) {
            return;
        }

        if (!this.state.open) {
            document.addEventListener('click', this.handleClick);
        } else {
            document.removeEventListener('click', this.handleClick);
        }

        this.setState({ open: !this.state.open });
    }

    render() {
        const popClasses = classnames('popover', {
            open: this.state.open,
            right: false,
            top: true,
            bottom: this.props.bottom,
            left: true,
        });


        return (
            <span className={popClasses}>
                <span className="toggle" ref={(toggle) => { this.toggle = toggle; }} onClick={this.handleClick}>
                    {this.props.toggle}
                </span>

                <div className="popover-box box-report" style={this.setBoxPosition()}>
                    <div className="content-inner">
                        <div className="row">
                            <div className="col-md-10">
                                <h1><strong>Opções de compartilhamento</strong></h1>
                            </div>
                            <div className="col-md-2">
                                <a href='/#' className="close right" onClick={this.onClose}>
                                    <i className="material-icons close right">close</i>
                                </a>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            {/* TODO: Criar componente único  */}
                            <CopyToClipBoardURL>
                                {this.props.url}
                            </CopyToClipBoardURL>
                        </div>
                        <div className="row">
                            <CopyToClipBoardPassword>
                                {this.props.password}
                            </CopyToClipBoardPassword>
                        </div>
                        <div className="row share-info">
                            <p>Após <strong>7 dias</strong> esse compartilhamento irá expirar automaticamente.</p>
                        </div>
                    </div>
                </div>
            </span>
        );
    }
}

PopoverReport.propTypes = {
    open: PropTypes.bool,
    right: PropTypes.bool,
    left: PropTypes.bool,
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    toggle: PropTypes.any,
    url: PropTypes.string,
    password: PropTypes.string,
};
