import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../components';

export class Revoke extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            display: 'none',
            reason: '',
        };

        this.handlerChange = this.handlerChange.bind(this);
        this.onRevoke = this.onRevoke.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onRevoke(e) {
        e.preventDefault();
        this.props.onRevoke(this.props.order, this.state.reason);
        this.form.reset();
        this.setState({ reason: '' });
    }

    onCancel() {
        this.form.reset();
        this.setState({ reason: '' });
        this.props.closeSlider();
    }

    handlerChange(e) {
        const { checked } = this.radios[this.radios.length - 1];
        this.setState({
            display: checked ? 'block' : 'none',
            reason: checked ? this.textarea.value : e.target.value,
        });
    }

    renderRadio() {
        this.radios = [];

        return this.props.choices.map((item) => (
            <div key={item} className="field-radio">
                <label className="radio">
                    <input
                        type="radio"
                        onChange={this.handlerChange}
                        name="opcao"
                        ref={(ref) => this.radios.push(ref)}
                        value={item}
                    />
                    <span>{item}</span>
                </label>
            </div>
        ));
    }

    render() {
        return (
            <form ref={(form) => { this.form = form; }} className="form">
                <p><strong>Informe o motivo para a revogação do certificado:</strong></p>

                {this.renderRadio()}

                <div className="field" style={{ display: this.state.display }}>
                    <textarea
                        onChange={this.handlerChange}
                        className="textarea"
                        ref={(ref) => { this.textarea = ref; }}
                        cols="30"
                        rows="10"
                    />
                </div>

                <div className="buttons">
                    <Button
                        onClick={this.onRevoke}
                        disabled={!this.state.reason}
                    >
                        Revogar Certificado
                    </Button>
                    <a onClick={this.onCancel}>Cancelar</a>
                </div>
            </form>
        );
    }
}

Revoke.propTypes = {
    onRevoke: PropTypes.func,
    closeSlider: PropTypes.func,
    choices: PropTypes.array,
    order: PropTypes.number,
};
