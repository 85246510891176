import { css } from 'styled-components';

export default css`
    .application-filter {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .application-filter .search {
        position: relative;
    }

    .application-filter .search >.clear-search {
        position: absolute;
        cursor: pointer;
        right: .5rem;
        top: 1rem;
    }

    .application-filter .search >.clear-search > i {
        font-size: 1.8rem;
    }

    .application-filter .search > input {
        width: 30rem;
    }

    .application-filter .sb2wicon {
        font-size: 1.5rem;
    }
`;
