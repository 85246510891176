import { css } from 'styled-components';

export default css`
    .blocked-page {
        display: flex;
        align-items: center;
    }

    .blocked-page > svg {
        margin-left: -10rem;
        margin-right: 5rem;
    }

    .blocked-page > .message {
        text-align: center;
    }

    .blocked-page p {
        margin: 2rem 0;
    }
`;
