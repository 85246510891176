import PropTypes from 'prop-types';
import React from 'react';
import { openSlider } from '../actions/slider';
import { connect } from 'react-redux';

const SliderButton = (props) => (
    <props.selector
        className={props.className}
        title={props.sliderTitle}
        onClick={() => props.dispatch(openSlider(props.children, props.sliderTitle))}
        style={props.style ? { ...props.style } : null}
    >
        {props.button}
    </props.selector>
);

SliderButton.defaultProps = {
    selector: 'a',
};

SliderButton.propTypes = {
    openSlider: PropTypes.func,
    button: PropTypes.any.isRequired,
    selector: PropTypes.string,
    sliderTitle: PropTypes.string,
};

const mapStateToProps = ({ dispatch }) => ({ dispatch });

export default connect(mapStateToProps)(SliderButton);