import PropTypes from 'prop-types';
import React from 'react';
import { IconSbSort, IconSbSortUp, IconSbSortDown } from '../../sb2w-icons/sb';

export const ColumnOrderBy = (props) => (
    <span className="order-by">
        <a onClick={() => props.onClick(props.fieldName)}>
            {props.name}
        </a>
        <span className="arrows">
            {!props.currentOrder &&
                <IconSbSort />
            }

            {(props.currentOrder === props.fieldName) &&
                <IconSbSortUp />
            }

            {(props.currentOrder === `-${props.fieldName}`) &&
                <IconSbSortDown />
            }
        </span>
    </span>
);

ColumnOrderBy.propTypes = {
    name: PropTypes.string,
    fieldName: PropTypes.string,
    currentOrder: PropTypes.string,
    onClick: PropTypes.func,
};
