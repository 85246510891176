import { FETCH_ALL } from '../actions/servers';
import { INITIAL_LIST } from '../services/initial-states';
import { LOGOUT_USER } from '../actions/auth';
import normalizedState from '../services/normalize';

export const servers = (state = INITIAL_LIST, action) => {
    switch (action.type) {
        case `${FETCH_ALL}_PENDING`:
            return { ...state, ...action.payload, isLoading: true };
        case `${FETCH_ALL}_FULFILLED`: {
            const { results, allIds } = normalizedState(action.payload.results);

            return { ...state, ...action.payload, results, allIds, isLoading: false };
        }
        case `${FETCH_ALL}_REJECTED`:
            return { ...state, isLoading: false };
        case LOGOUT_USER:
            return INITIAL_LIST;
        default:
            return state;
    }
};
