import { css } from 'styled-components';

export default css`
    .scan-api-list {
        height: calc(100vh - 16rem);
        overflow: overlay;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .scan-api-card {
        width: 50%;
        padding: 50px;
        background-color: white;
        height: 25vh;
        border: 1px solid;
    }
`;
