import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import {
  CardAccordion, Accordion, Evidences, ShowMoreWrapper,
  ScanReportHeader, Loading, VulnerabilityDigest
} from '../../components'
import { connect } from 'react-redux'
import { getScanProfile } from '../../actions/applications'
import { fetchEvidences } from '../../actions/evidences'
import { fetch } from '../../services/fetch'
import { toParams } from '../../services/utils'
import classnames from 'classnames'

class ScanProfileReport extends React.Component {
  constructor(props) {
    super(props)
    this.levelAttributes = {
      level5: {
        label: 'Nível 5',
        number: 5,
        color: '#fd4d4d',
      },
      level4: {
        label: 'Nível 4',
        number: 4,
        color: '#ff807e',
      },
      level3: {
        label: 'Nível 3',
        number: 3,
        color: '#ffa8a8',
      },
      level2: {
        label: 'Nível 2',
        number: 2,
        color: '#b1de80',
      },
      level1: {
        label: 'Nível 1',
        number: 1,
        color: '#74c676',
      },
      level0: {
        label: 'Passou',
        number: 0,
        color: '#bbbbbb',
      },
    }

    this.category = {
      new: 'Nova',
      passed: 'Passou',
      fixed: 'Corrigida',
      recurring: 'Recorrente',
    }

    this.handleFetch = this.handleFetch.bind(this)
    this.listVulnerabilities = this.listVulnerabilities.bind(this)
  }

  componentWillMount() {
    if (!this.props.isLoading) {
      this.props.getScanProfile(this.props.scanId)
    }
  }

  handleFetch(page, pageSize, fetchProps) {
    const query = toParams({ page, page_size: pageSize })

    return fetch(
      `/aw-scans/${this.props.scanId}/report/${fetchProps.id}/` +
      `evidences/${this.levelAttributes[fetchProps.level].number}${query}`
    )
      .then(res => res.json())
      .then(res =>
        res.results.map(evidence => (
          <Evidences key={evidence.id} evidence={evidence} />
        ))
      )
  }

  listVulnerabilities(familyId) {
    const list = []
    let levels = Object.keys(this.props.details).reverse()
    levels = levels.filter(item => !!this.props.details[item].length)

    levels.forEach(level => this.props.details[level].forEach(item => {
      if (item.family_id === familyId) {
        list.push({ level, ...item })
      }
    }))

    return sortBy(list, (item) => item.level).reverse()
  }

  scanContentRender(item) {
    return (
      <CardAccordion
        key={`${item.title}${item.family_id}${item.level}`}
        color={this.levelAttributes[item.level].color}
        title={item.digest ? (
          <React.Fragment>
            <span>{item.title}</span>
            <span className={classnames('tag', item.level)}>
              {this.levelAttributes[item.level].label}
            </span>
          </React.Fragment>
        ) : item.title}
      >

        <div className="scan-details-content">
          <Accordion title="Descrição Técnica">
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </Accordion>

          <Accordion title="Impacto">
            <div dangerouslySetInnerHTML={{ __html: item.consequence }} />
          </Accordion>

          <Accordion title="Solução">
            <div dangerouslySetInnerHTML={{ __html: item.solution }} />
          </Accordion>

          <Accordion
            title={(
              <span>Evidências
                <small>
                  - {item.evidences_count || item.evidences.length} evidências encontradas
                </small>
              </span>
            )}
          >
            <ShowMoreWrapper
              fetchProps={{ ...item }}
              startPage={2}
              loadMore={this.handleFetch}
              itemsCount={item.evidences_count}
            >
              {item.evidences.map(evidence =>

                <Evidences
                  key={evidence.id}
                  evidence={evidence}
                // scanId={this.props.scanId}
                // vulnerabilityLevel={item.level}
                />
              )}
            </ShowMoreWrapper>
          </Accordion>
        </div>

      </CardAccordion>
    )
  }
  render() {
    return (
      <div className="scan-details">
        <ScanReportHeader
          scanId={this.props.scanId}
          name={this.props.applications.name}
          url={this.props.applications.url}
          profile={this.props.profile.name}
          expirationDate={this.props.expirationDate}
          password={this.props.password}
          status={this.props.status}
          finished={this.props.finished}
          initiated={this.props.initiated}
          sealStatus={this.props.applications.seal_status}
        />

        <Loading loading={this.props.isLoading} inverse />

        {this.props.vulnerabilityDigest.map((family, key) =>
          <VulnerabilityDigest
            key={`${family.family_name}${key}`}
            title={family.family_name}
            description={family.family_description}
          >
            <div className="vulnerable-list">
              {this.listVulnerabilities(family.id).map(item => this.scanContentRender(item))}
            </div>
          </VulnerabilityDigest>
        )}

        <VulnerabilityDigest title="Others" description={''}>
          <div className="vulnerable-list">
            {this.listVulnerabilities(undefined).map(item => this.scanContentRender(item))}
          </div>

          <div className="vulnerable-list">
            {this.listVulnerabilities("None").map(item => this.scanContentRender(item))}
          </div>
        </VulnerabilityDigest>
      </div>
    )
  }
}

ScanProfileReport.propTypes = {
  getScanProfile: PropTypes.func,
  setBreadcrumbName: PropTypes.func,
  scanId: PropTypes.string,
  details: PropTypes.object,
  vulnerabilityDigest: PropTypes.array,
  applications: PropTypes.object,
  profile: PropTypes.object,
  status: PropTypes.string,
  isLoading: PropTypes.bool,
  getEvidences: PropTypes.func,
  initiated: PropTypes.string,
  finished: PropTypes.string,
  expirationDate: PropTypes.string,
  password: PropTypes.string,
}

const mapStateToProps = (state) => {
  return ({
    isLoading: state.scanReport.isLoading,
    status: state.scanReport.status,
    initiated: state.scanReport.initiated,
    finished: state.scanReport.finished,
    expirationDate: state.scanReport.expiration_date,
    password: state.scanReport.key,
    details: get(state, 'scanReport.report.details', {}),
    vulnerabilityDigest: get(state, 'scanReport.report.vulnerability_digest', []),
    applications: get(state, 'scanReport.application', {}),
    profile: get(state, 'scanReport.scan_profile', {}),
  })
}

export default connect(mapStateToProps, { getScanProfile, fetchEvidences })(ScanProfileReport)
