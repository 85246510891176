import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { RemoveItem } from '../../../../components'
import { fetchKnowledgebase, deleteKnowledgebaseVulnerability } from '../../../../actions/system/pentest-knowledgebase'
import { risk } from '../../../../services/pentest-fields'
import SmartTable from '../../../../components-v2/smart-table'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import { isEmpty } from 'lodash'
function PentestKnowledgebaseList(props) {
  const dispatch = useDispatch()
  const account = useSelector(state => state.account)
  const pentestKnowledgebase = useSelector(state => state.pentestKnowledgebase)

  const accountId = account[0] ? account[0].id : undefined

  const textAbbreviation = text => `${text.slice(0, 80)}...`

  const model = {
    // vulnerability_id: {
    //   name: 'Código',
    //   renderer: (name, obj) => obj.vulnerability_id,
    // },
    title: {
      name: 'Título',
      field: rowData => rowData.title,
    },
    // ports: {
    //   name: 'Porta',
    //   field: rowData => rowData.ports,
    // },
    risk: {
      name: 'Risco',
      field: rowData => risk(rowData.risk),
    },
    references: {
      name: 'Referências',
      field: rowData =>
        <Tooltip title={rowData.references}>
          <span>{textAbbreviation(rowData.references)}</span>
        </Tooltip>
    },
    description: {
      name: 'Descrição',
      field: rowData =>
        <Tooltip title={rowData.description}>
          <span>{textAbbreviation(rowData.description)}</span>
        </Tooltip>
    },
    actions: {
      name: ' ',
      field: rowData => (
        <Link to={`/system/vulnerabilities-pentest/edit/${rowData.id}`}>
          Editar
        </Link>
      ),
    },
    delete: {
      name: ' ',
      field: rowData => (
        <RemoveItem
          section="Vulnerability"
          id={rowData.id}
          onRemove={id => dispatch(deleteKnowledgebaseVulnerability(id))}
        />
      ),
    },
  }

  async function getVulnerabilities(params) {
    isEmpty(params)
      ? await dispatch(fetchKnowledgebase())
      : await dispatch(fetchKnowledgebase(params))
  }

  // useEffect(() => {
  //   props.setTitle(props.title)

  //   if (accountId) {
  //     getVulnerabilities(accountId)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [accountId])

  return (
    <section className="content">
      <SmartTable
        model={model}
        source={pentestKnowledgebase}
        accountId={accountId}
        actionFetch={getVulnerabilities}
      />
    </section>
  )
}

PentestKnowledgebaseList.propTypes = {
  location: PropTypes.object,
}

export default PentestKnowledgebaseList
