import PropTypes from 'prop-types'
import React from 'react'
import {AutoApproveProduct, Loading} from '../../components'
import {requestProduct} from '../../actions/products'
import {reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {toFormErrors} from '../../services/utils'
import {withAccountId} from '../AccountContainer'
import {SSLFields} from './SSLFields'
import {AwFields} from './AwFields'

class FormBuyBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      autoApprove: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  onSubmit(data) {
    data = {...data, product_type: this.props.id, status: this.state.autoApprove ? 'available' : 'pending'}

    return this.props.requestProduct(this.props.accountId, data, this.state.autoApprove)
      .catch(({errors}) => {
        this.setState({
          errors: toFormErrors(errors),
          autoApprove: false,
        })
      })
  }

  handleClick() {
    this.setState(oldState => ({...oldState, autoApprove: !oldState.autoApprove}))
  }

  render() {
    return (
      <form className="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Loading loading={this.props.submitting}/>

        <div className="content-inner">

          {this.props.catalog === 'blindagem' || this.props.catalog === 'trial' ?
            <AwFields
              errors={this.state.errors}
            />
            :
            <SSLFields
              years={this.props.config.years}
              isMultdomain={this.props.config.isMultdomain}
              errors={this.state.errors}
            />
          }

          <AutoApproveProduct
            autoApprove={this.state.autoApprove}
            handleClick={this.handleClick}
            fullWidth
          />
        </div>
        <button type="submit" className="button-buy" disabled={this.props.submitting}>
          SOLICITAR
        </button>
      </form>
    )
  }
}

FormBuyBox.propTypes = {
  accountId: PropTypes.string.isRequired,
  id: PropTypes.string,
  catalog: PropTypes.string,
  config: PropTypes.object,
  submitting: PropTypes.bool,
  inPopover: PropTypes.bool,
  requestProduct: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  errors: PropTypes.object,
}

const Form = reduxForm({
  initialValues: {max_domains: '1'}
})(FormBuyBox)

const mapStateToProps = (state, props) => ({
  form: `formBuyBox${props.id}${props.inPopover ? '-popover' : ''}`,
  errors: toFormErrors(state.requestProduct.errors),
})

export default connect(mapStateToProps, {requestProduct})(withAccountId(Form))
