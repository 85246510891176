import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  fetchOrders,
  fetchSummaryOrders,
  orderForm,
  setOrderStatus,
} from "../../actions/orders";
import { closeSlider } from "../../actions/slider";
import {
  OrdersWrap,
} from "../../components";
import { withAccountId } from "../AccountContainer";

class OrdersList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <article>
        <OrdersWrap
          accountId={this.props.accountId}
          source={this.props.clientOrders}
          actionFetch={this.props.fetchOrders}
          location={this.props.location}
        />
      </article>
    );
  }
}

OrdersList.propTypes = {
  accountId: PropTypes.string.isRequired,
  fetchOrders: PropTypes.func,
  clientOrders: PropTypes.object,
  orderForm: PropTypes.func,
  push: PropTypes.func,
  location: PropTypes.object,
};

const mapStateToProps = ({
  clientOrders,
  dispatch,
}) => ({ clientOrders, dispatch });

export default connect(mapStateToProps, {
  fetchOrders,
  fetchSummaryOrders,
  push,
  closeSlider,
  orderForm,
})(withAccountId(OrdersList));
