import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export const Card = ({ children, height, fake, style, className }) => (
    <article
        className={classnames('card', className, { fake })}
        style={{ height, ...style }}
    >
        {children}
    </article>
);

Card.propTypes = {
    children: PropTypes.any.isRequired,
    height: PropTypes.string,
    className: PropTypes.string,
    fake: PropTypes.bool,
    style: PropTypes.object,
};
