import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    TableWrap,
    // TableWrapActions,
    // TableWrapRight,
    // TableWrapLeft,
    // Choices,
} from '../../components';
// import { Link } from 'react-router-dom';
// import { fetchOrganizations, deleteOrganization, saveOrganization } from '../../actions/organizations';
// import { userIsAdmin } from '../../services/auth';
import { fetchUptime } from '../../actions/uptime';

class UptimeList extends React.Component {
    constructor(props) {
        super(props);
        this.model = {
            // full_name: {
            //     name: 'Nome Fantasia',
            //     renderer: (n, { id }) => (
            //         <Link to={`/organizations/${id}`}>
            //             {n}
            //         </Link>
            //     ),
            // },
            // legal_name: { name: 'Razão Social' },
            // cnpj: { name: 'CNPJ' },
            // // status: {
            // //     name: 'Status',
            // //     renderer: (item, obj) => <ChooseStatus {...props} item={item} obj={obj} />,
            // // },
            // dummy: {
            //     name: ' ',
            // },
        };
    }

    render() {
        return (
            <div>
                <TableWrap
                    model={this.model}
                    source={this.props}
                    actionFetch={this.props.fetchUptime}
                    location={this.props.location}
                    hasSearch
                >
                    {/* <TableWrapActions>

                        <TableWrapLeft>
                            Lista de URLs Monitoradas
                        </TableWrapLeft>

                        <TableWrapRight>
                            <Link to="/uptime/new">
                                Ativar Monitoramento
                            </Link>
                        </TableWrapRight>

                    </TableWrapActions> */}
                </TableWrap>
            </div>
        );
    }
}

UptimeList.propTypes = {
    location: PropTypes.object,
    data: PropTypes.object,
    fetchUptime: PropTypes.func,
    isLoading: PropTypes.bool,
};

const mapStateToProps = ({ uptime }) => ({
    isLoading: uptime.isLoading,
    // data: uptime.results,
});

export default connect(mapStateToProps, { fetchUptime })(UptimeList);
