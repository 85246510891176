import React from 'react';
import { CopyToClipBoard } from '../../components';

export const InstallStampTrustSign = () => (
    <section>
        <div className="slider-title">Instalar selo SSL TrustSign</div>
        <p>
            <img
                src="https://selo.siteblindado.com/images/selo.png"
                alt="Selo SSL"
                style={{ float: 'right', marginLeft: '10rem' }}
            />
            Copie e cole o código abaixo no seu site para obter a imagem do Selo SSL TrustSign:
        </p>

        <CopyToClipBoard>
            {'<div id="ssltrust">'}
            {'\n    <param id="ssltrust_preload" value="true"/>'}
            {'\n</div>'}
            {'\n\n<script type="text/javascript" src="//selo.siteblindado.com/ssltrust.js">'}
            {'</script>'}
        </CopyToClipBoard>

        <span>
            <small className="code-block-obs">
                O SELO SSL TRUSTSIGN APENAS FICARÁ VISÍVEL MEDIANTE A INSTALAÇÃO DO CERTIFICADO
            </small>
        </span>

    </section>
);
