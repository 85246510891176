import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const CsrSubject = (props) => (
    <table className="table-info">
        <tbody>
            {props.key_size &&
                <tr>
                    <td className="title">Tamanho da chave</td>
                    <td>{props.key_size}</td>
                </tr>
            }

            {props.common_name &&
                <tr>
                    <td className="title">Nome comum (CN)</td>
                    <td>{props.common_name}</td>
                </tr>
            }

            {props.organization &&
                <tr>
                    <td className="title">Organização (O)</td>
                    <td>{props.organization}</td>
                </tr>
            }

            {props.oragnizational_unit &&
                <tr>
                    <td className="title">Unidade Organizadora (OU)</td>
                    <td>{props.oragnizational_unit}</td>
                </tr>
            }

            {props.country &&
                <tr>
                    <td className="title">País (C)</td>
                    <td>{props.country}</td>
                </tr>
            }

            {props.state &&
                <tr>
                    <td className="title">Estado (ST)</td>
                    <td>{props.state}</td>
                </tr>
            }

            {props.locality &&
                <tr>
                    <td className="title">Localidade (L)</td>
                    <td>{props.locality}</td>
                </tr>
            }

            {props.street_address &&
                <tr>
                    <td className="title">Rua (STREET)</td>
                    <td>{props.street_address}</td>
                </tr>
            }

            {props.first_name &&
                <tr>
                    <td className="title">Nome (givenName)</td>
                    <td>{props.first_name}</td>
                </tr>
            }

            {props.last_name &&
                <tr>
                    <td className="title">Sobrenome (surName)</td>
                    <td>{props.last_name}</td>
                </tr>
            }

            {props.email &&
                <tr>
                    <td className="title">Email (EMAIL)</td>
                    <td>{props.email}</td>
                </tr>
            }

            {props.job_title &&
                <tr>
                    <td className="title">Título (T)</td>
                    <td>{props.job_title}</td>
                </tr>
            }
        </tbody>
    </table>
);

CsrSubject.defaultProps = {
    domains: [],
};
CsrSubject.propTypes = {
    key_size: PropTypes.number,
    common_name: PropTypes.string,
    domains: PropTypes.array,
    organization: PropTypes.string,
    oragnizational_unit: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    locality: PropTypes.string,
    street_address: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    job_title: PropTypes.string,
};

const mapStateToProps = ({ csrInfo }) => (csrInfo.subject || {});

export default connect(mapStateToProps)(CsrSubject);
