import React from 'react';
import { CopyToClipBoard } from '../../components';
import selossl from '../../../assets/images/selossl.png';

export const InstallStampBlindado = () => (
    <section>
        <div className="slider-title">Instalar selo SSL Blindado</div>
        <p>
            <img src={selossl} alt="Selo SSL" style={{ float: 'right', marginLeft: '10rem' }} />
            Copie e cole o código abaixo no seu site para obter a imagem do Selo SSL Blindado:
        </p>

        <CopyToClipBoard>
            {'<div id="sslblindado">'}
            {'\n    <param id="sslblindado_preload" value="true"/>'}
            {'\n</div>'}
            {'\n\n<script type="text/javascript" src="https://selo.siteblindado.com/sslblindado.js"></script>'}
        </CopyToClipBoard>

        <span>
            <small className="code-block-obs">
                O SELO SSL BLINDADO APENAS FICARÁ VISÍVEL MEDIANTE A INSTALAÇÃO DO CERTIFICADO
            </small>
        </span>

    </section>
);
