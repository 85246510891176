import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export const ExpirationDate = (props) => {
    if (!props.date) {
        return false;
    }

    const date = new Date(props.date.replace(/-/g, '/'));
    const now = new Date();
    const timeDiff = Math.abs(now.getTime() - date.getTime());
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));

    let classExpired = '';

    [90, 60, 30].forEach((days) => {
        if (daysLeft <= days) {
            classExpired = `expires-in-${days}`;
        }
    });

    if (date < now) {
        classExpired = 'expired';
    }

    return (
        <span className={classnames({ badge: !!classExpired }, classExpired)}>
            {(date < now) ? 'Expirado' : `${date.toLocaleDateString('pt-BR')}`}
        </span>
    );
};

ExpirationDate.propTypes = {
    date: PropTypes.any,
};
