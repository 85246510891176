import {
  FETCH_ALL,
  FETCH,
  FETCH_USERS,
  FETCH_INVITES,
  CHANGE_USER,
  CLEAR_USERS,
} from '../actions/organizations'
import { LOGOUT_USER } from '../actions/auth'
import normalizedState from '../services/normalize'

const INITIAL_LIST = {
  results: {},
  allIds: [],
  isLoading: false,
  count: 0,
  page: 0,
}

export const organizations = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_PENDING`:
      return { ...state, isLoading: true }
    case `${FETCH_ALL}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)
      return {
        ...state,
        ...action.payload,
        results,
        allIds,
        isLoading: false,
      }
    }
    case `${FETCH_ALL}_REJECTED`: {
      return {
        ...state,
        isLoading: false
      }
    }
    case LOGOUT_USER:
      return INITIAL_LIST
    default:
      return state
  }
}

export const organization = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH}_PENDING`:
      return { ...state, isLoading: true }
    case `${FETCH}_FULFILLED`: {
      return { ...state, ...action.payload, isLoading: false }
    }
    case `${FETCH}_REJECTED`: {
      return { ...state, isLoading: false }
    }
    default:
      return state
  }
}

export const organizationUsers = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_USERS}_PENDING`:
      return { ...state, isLoading: true }

    case `${FETCH_USERS}_FULFILLED`: {
      const { results, allIds } = normalizedState(
        action.payload.results,
        'user.id'
      )
      return {
        ...state,
        ...action.payload,
        results,
        allIds,
        isLoading: false,
      }
    }
    case `${FETCH_USERS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CLEAR_USERS:
    case LOGOUT_USER:
      return INITIAL_LIST
    default:
      return state
  }
}

export const organizationInvites = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_INVITES}_PENDING`:
      return { ...state, isLoading: true }
    case `${FETCH_INVITES}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)

      return {
        ...state,
        ...action.payload,
        results,
        allIds,
        isLoading: false,
      }
    }
    case `${FETCH_INVITES}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case LOGOUT_USER:
      return INITIAL_LIST
    default:
      return state
  }
}

export const organizationUser = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case `${CHANGE_USER}_PENDING`:
      return { ...state, isLoading: true }
    case `${CHANGE_USER}_FULFILLED`:
      return { ...state, ...action.payload, isLoading: false }
    case LOGOUT_USER:
      return { isLoading: false }
    default:
      return state
  }
}
