import PropTypes from 'prop-types';
import React from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { getMinutes } from 'date-fns';

export class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { start: new Date() };
    }

    componentDidMount() {
        this.initCount();
    }

    UNSAFE_componentWillReceiveProps() {
        if (!this.interval) {
            this.setState({ start: new Date() });
            this.initCount();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getUTCDate(dateString = Date.now()) {
        const date = new Date(dateString);

        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );
    }

    initCount() {
        this.interval = setInterval(() => {
            this.setState({
                start: new Date(),
            });
        }, 1000);
    }

    clearCount() {
        clearInterval(this.interval);
        this.interval = 0;
        return '00:00';
    }

    formatTime(date) {
        // const options = { minute: 'numeric', second: 'numeric' };
        const dateFinal =
            getMinutes(date) > 9
            ? `${getMinutes(date)}:00`
            : `0${getMinutes(date)}:00`;
        return dateFinal;
    }

    renderTimer() {
        const { start } = this.state;
        const now = this.getUTCDate();
        const end = new Date(this.props.end);
        const diffTime = new Date(end - start);

        if (!!differenceInSeconds(end, now)) {
            return this.formatTime(diffTime);
        }

        return this.clearCount();
    }

    render() {
        return <span className="countdown">{this.renderTimer()}</span>;
    }
}

Countdown.propTypes = {
    end: PropTypes.any,
};
