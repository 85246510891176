import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import {useDispatch, useSelector} from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Button } from '../../components'
import { fetchServer, addServer, updateServer } from '../../actions/servers'

export default function FormServer(props) {
  const isRename = !!props.match.params.id
  const dispatch = useDispatch()
  const account = useSelector(state => state.account)
  const accountId = account[0] ? account[0].id : undefined
  const { handleSubmit, errors = {}, control } = useForm()

  useEffect(() => {
    if (isRename) {
      fetchServer(props.match.params.id)
    }
  })

  const onSubmit = data => {
    if (isRename) {
      return dispatch(updateServer(props.match.params.id, data))
    }

    return dispatch(addServer(accountId, data))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form form-new">
      <div className="field">
        <label htmlFor="ip">IP *</label>
        <Controller
          render={({ field }) =>
            <InputMask
              {...field}
              className={`input ${errors.ip ? 'input-error' : ''}`}
              type="text"
              disabled={isRename}
              controller={control}
            />
          }
          defaultValue=""
          name="ip"
          rules={{ required: true }}
          control={control}
        />
      </div>

      <div className="field">
        <label htmlFor="name">Nome</label>
        <Controller
          render={({ field }) =>
            <input
              {...field}
              className={`input ${errors.name ? 'input-error' : ''}`}
              type="text"
            />
          }
          defaultValue=""
          name="name"
          rules={{ required: false }}
          control={control}
        />
      </div>

      <div className="buttons">
        <Button >Salvar</Button>
        <Link to="/devices/servers">Cancelar</Link>
      </div>
    </form>
  )
}

FormServer.propTypes = {
  params: PropTypes.object,
}
