import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#7DC671',
      light: '#74B61B',
      dark: '#49a231',
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      'sans-serif',
    ].join(','),
    body1: {
      fontSize: '1.4rem',
    },
  },
  body1: {
    fontSize: '1.5rem',
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: '#2B3D13',
          fontWeight: 'bold',
          backgroundColor: '#74b61b',
          '&:hover': {
            backgroundColor: '#74b61b94',
          },
        },
      }
    }
    ,
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontSize: '1.4rem'
        },
        caption: {
          fontSize: '0.95rem'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: 'none !important',
          fontSize: '1.5rem',
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          border: 'none !important'
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem !important',
          // 'Mui-selected': {
          //   color: 'white'
          // }
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 'inherit'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.2rem !important'
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: '3.5rem',
        },
        text: {
          fontSize: '1rem'
        },
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.4rem',
          '&.Mui-active': {
            fontWeight: 'bold',
          }
        },
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          marginLeft: 17,
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        }
      }
    }
  }
  // overrides: {
  //   MuiCollapse: {
  //     wrapper: {
  //       width: '100%',
  //     },
  //   },
  //   MuiFormControlLabel: {
  //     label: {
  //       fontSize: '1.5rem',
  //     },
  //   },
  //   MuiFormControl: {
  //     root: {
  //       fontSize: '1.5rem',
  //     },
  //   },
  //   MuiSelect: {
  //     outlined: {
  //       padding: 17,
  //       textAlign: 'left',
  //     },
  //   },
  //   MuiInputLabel: {
  //     shrink: {
  //       fontSize: '1.5rem',
  //       top: '-10px',
  //     },
  //     formControl: {
  //       fontSize: '1.3rem',
  //     },
  //   },
  //   MuiStepConnector: {
  //     vertical: {
  //       marginLeft: 'none',
  //       margin: 15,
  //     },
  //     line: {
  //       border: '#0000009e',
  //     },
  //   },
  //   MuiInputBase: {
  //     input: {
  //       fontSize: '1.5rem !important',
  //       lineHeight: '2rem',
  //     },
  //   },
  //   MuiStepIcon: {
  //     active: {
  //       height: 'auto',
  //       width: 30,
  //       color: '#74B61B !important',
  //     },
  //     root: {
  //       height: 'auto',
  //       width: 30,
  //       color: '#ffffff',
  //     },
  //     text: {
  //       fontSize: '1.5rem',
  //     },
  //   },
  //   MuiStepLabel: {
  //     active: {
  //       fontWeight: 'bold !important',
  //       fontSize: '1.4rem',
  //     },
  //     label: {
  //       color: '#0000009e !important',
  //       fontSize: '1.2rem',
  //     },
  //   },
  //   MuiTooltip: {
  //     tooltip: {
  //       fontSize: '1.2rem',
  //     },
  //   },
  //   MuiSvgIcon: {
  //     root: {
  //       fontSize: '2rem',
  //     },
  //   },
  //   MuiTableCell: {
  //     root: {
  //       fontSize: '1.3rem',
  //     },
  //   },
  //   MuiFormLabel: {
  //     root: {
  //       fontSize: '1.5rem',
  //       marginBottom: '10px',
  //     },
  //   },
  //   MuiBreadcrumbs: {
  //     li: {
  //       display: 'flex',
  //     },
  //   },
  //   MuiMenuItem: {
  //     root: {
  //       fontSize: '1.5rem',
  //     },
  //   },
  //   MuiTypography: {
  //     body1: {
  //       fontSize: '1.3rem',
  //     },
  //     body2: {
  //       fontSize: '1.3rem',
  //     },
  //     caption: {
  //       fontSize: '1rem',
  //     },
  //   },
  // },
})

export default theme
