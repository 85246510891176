import { FETCH_CERTIFICATES, RUN_SCAN, GET, REVOKE_CERTIFICATE, UPDATE_CERTIFICATE } from '../actions/certificates'
import { FETCH_DOMAIN } from '../actions/domains'
import { INITIAL_LIST } from '../services/initial-states'
import { LOGOUT_USER } from '../actions/auth'
import normalizedState from '../services/normalize'

const INITIAL_REPORT_STATE = {
  isLoading: false,
  details: {
    latest_ssl_scans: {
      ready: {
        result: {
          endpoints: [],
        },
      },
    },
  },
  result: {
    isLoading: false,
    summary: {},
    certificate: [],
    configurations: [],
  },
}

export const certificates = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_CERTIFICATES}_PENDING`:
      return {
        ...state,
        page: action.payload.page,
        filter: action.payload.filter,
        isLoading: true,
      }

    case `${FETCH_CERTIFICATES}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)

      return { ...state, ...action.payload, results, allIds, isLoading: false }
    }
    case `${FETCH_CERTIFICATES}_REJECTED`:
      return { ...state, isLoading: false }

    case `${RUN_SCAN}_PENDING`:
      return { ...state, isLoading: true }

    case UPDATE_CERTIFICATE: {
      const { results } = state
      results[action.payload.id] = action.payload

      return {
        ...state,
        results,
      }
    }

    case LOGOUT_USER:
      return INITIAL_LIST

    default:
      return state
  }
}

export const scan = (state = { run: false }, action) => {
  switch (action.type) {
    case `${RUN_SCAN}_PENDING`:
      return { ...state, isLoading: true }

    case `${RUN_SCAN}_FULFILLED`: {
      return { run: true, isLoading: false }
    }

    case `${RUN_SCAN}_REJECTED`:
      return { ...state, isLoading: false }

    default:
      return state
  }
}

export const report = (state = INITIAL_REPORT_STATE, action) => {
  switch (action.type) {
    case `${FETCH_DOMAIN}_PENDING`:
      return { ...state, isLoading: true }
    case `${FETCH_DOMAIN}_FULFILLED`:
      return {
        ...state, details: action.payload, isLoading: false,
      }
    case `${GET}_PENDING`:
      return { ...state, result: { ...INITIAL_REPORT_STATE.result, isLoading: true } }
    case `${GET}_FULFILLED`:
      return { ...state, result: { ...state.result, ...action.payload.result, isLoading: false } }
    case `${RUN_SCAN}_PENDING`:
      return { ...state, isLoading: true }
    case `${RUN_SCAN}_FULFILLED`:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export const revoke = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case `${REVOKE_CERTIFICATE}_PENDING`:
      return { ...state, isLoading: true }
    case `${REVOKE_CERTIFICATE}_FULFILLED`:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
