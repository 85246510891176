import PropTypes from 'prop-types'
import React from 'react'
import { SubMenu } from './SubMenu'

export class Content extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbName: {},
    }
    this.setBreadcrumbName = this
      .setBreadcrumbName
      .bind(this)
  }

  setBreadcrumbName(name) {
    const { routes } = this.props

    if (routes[routes.length - 1].name.length) {
      name = `${routes[routes.length - 1].name}: ${name}`
    }

    this.setState({
      breadcrumbName: {
        name,
      }
    })
  }

  render() {
    return (
      <article className="page">
        <div className="page-content">
          {(this.props.submenu || [])
            .filter(item => !(item.authorized === false))
            .length > 1 && <SubMenu items={this.props.submenu || ''} />
          }

          {this.props.children && React
            .cloneElement(this.props.children, { setBreadcrumbName: this.setBreadcrumbName })}

        </div>
      </article>
    )
  }
}

Content.propTypes = {
  submenu: PropTypes.array,
  children: PropTypes.object,
  routes: PropTypes.array,
  params: PropTypes.object,
}
