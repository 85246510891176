import React from 'react'
import {NavLink} from 'react-router-dom'
import {SliderButton} from '../../containers'
import {Button, Loading} from '../../components'
import classnames from 'classnames'
import _, {isEmpty} from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import {chooseProduct} from "../../actions/products";

export default function ProductsSummary(props) {
  const summaryProducts = useSelector(state => state.summaryProducts)
  const account = useSelector(state => state.account)
  const accountId = account[0] ? account[0].id : undefined
  const dispatch = useDispatch();

  function listingOrganizer(data) {
    const sortedArray = [...data].sort(
      (a, b) => a.quantities.available > 0 && (a.product_id === b.product_id ? 0 : a > b ? 1 : -1),
    );
    return sortedArray;
  }

  function productSlice() {
    if (!isEmpty(summaryProducts.results)) {
      const list = listingOrganizer(summaryProducts.results);
      return list.slice(0, props.expanded
        ? summaryProducts.results.length
        : 4
      )
    }
    return []
  }

  function avaliablesProducts() {
    if (!isEmpty(summaryProducts.results)) {
      const productsCurrentlyAvailable = productSlice().filter(_product =>
        _product.quantities.available > 0 || _product.quantities.pending > 0)

      return listingOrganizer(productsCurrentlyAvailable)
    }
    return []
  }

  const url = {
    ssl: '/certificates/new',
    aw: '/armour/new',
    api: '/armour/scan-api/new'
  }

  return (
    <section className={classnames('products', {summary: !props.expanded})}>
      <Loading loading={summaryProducts.isLoading}/>

      {_.isEmpty(productSlice()) &&
        <div className="empty">
          Você não possui nenhum produto
        </div>
      }

      {(!_.isEmpty(productSlice()) && _.isEmpty(avaliablesProducts())) &&
        <div className="empty">
          Nenhum produto disponível
        </div>
      }

      {!_.isEmpty(productSlice()) &&
        <table className="table table-no-border">
          <thead>
          <tr>
            <th>Produto</th>
            <th>Disponíveis</th>
            <th>Pendentes</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          {productSlice().map((item, key) => (
            item.quantities.available > 0 &&
            <tr key={key}>
              <td align='left'>{item.product_type.name}</td>

              <td align='left'>
                {`${item.quantities.available} `}
              </td>

              <td align='left'>
                {item.quantities.pending}
              </td>


              <td>
                <Button
                  disabled={item.quantities.available <= 0}
                  onClick={
                    () =>
                      dispatch(chooseProduct(
                        item.product_id,
                        url[item.product_type.category],
                        item
                      ))
                  }>
                  Utilizar
                </Button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      }

      <div className="items space-button">
        <NavLink
          className="button-primary"
          to="/store/site-blindado"
        >
          Solicitar Produto
        </NavLink>

        {!props.expanded &&
          <SliderButton
            className="right"
            button="Visualizar Todos"
            sliderTitle="Meus Produtos"
          >
            {summaryProducts.isLoading
              ? <Loading loading/>
              : <ProductsSummary accountId={accountId} expanded {...props} />
            }
          </SliderButton>
        }
      </div>
    </section>
  )
}
