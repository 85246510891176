import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { openSlider, closeSlider } from '../../actions/slider'
import { changeOrganizationUser } from '../../actions/organizations'
import { ChangeOwnership } from '../../components'

const roles = {
  owner: 'Proprietário',
  admin: 'Administrador',
  user: 'Usuário',
}

const privileges = {
  owner: ['owner', 'admin', 'user'],
  admin: ['admin', 'user'],
  user: []
}

const Role = ({ role }) => (
  <span>{roles[role]}</span>
)

class RoleSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: this.props.role || 'user',
    }

    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect(e) {
    const { organizationId, id } = this.props
    const { value } = e.target
    const ownState = this.state.selected

    this.setState({ selected: value }, () => {
      if (this.state.selected === 'owner') {
        return this.props.openSlider(
          <ChangeOwnership
            changeOwnership={() => this.props.changeOrganizationUser(organizationId, id, { role: value })}
            closeSlider={() => this.props.closeSlider()}
            onCancel={() => this.setState({ selected: ownState })}
          />,
          'Troca de Proprietário'
        )
      }

      return this.props.changeOrganizationUser(organizationId, id, { role: value })
    })
  }

  render() {
    if (this.props.role === 'owner' || this.props.currentRole === 'user') {
      return (
        <Role role={this.props.role} />
      )
    }

    return (
      <div>
        <select
          className="combo"
          onChange={this.handleSelect}
          value={this.state.selected}
        >
          {privileges[this.props.currentRole].map(key =>
            <option key={key} value={key}>{roles[key]}</option>
          )}
        </select>
      </div>
    )
  }
}

RoleSelector.propTypes = {
  currentRole: PropTypes.string,
  changeOrganizationUser: PropTypes.func,
  openSlider: PropTypes.func,
  closeSlider: PropTypes.func,
  organizationId: PropTypes.string,
  id: PropTypes.string,
  currentUserId: PropTypes.string,
  role: PropTypes.string,
}

Role.propTypes = {
  role: PropTypes.string,
}

function getCurrentRole(user, organizationId) {
  if (user.role === 'admin') {
    return 'owner'
  }

  return user.organizations[organizationId].role
}

const mapStateToProps = ({ auth }, ownProps) => ({
  currentUserId: auth.data.user.id,
  currentRole: getCurrentRole(auth.data, ownProps.organizationId),
})

export default connect(mapStateToProps, {
  changeOrganizationUser,
  openSlider,
  closeSlider,
})(RoleSelector)
