import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { EmailValidation, HttpValidation, DnsValidation, StaffValidation } from './'
import { RadioField, Loading, Card } from '../components'
import * as domainValidationActions from '../modules/domainValidation'

class ValidationChoices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'email',
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount() {
    if (!this.props.isLoading) {
      this.props.fetchDomain(this.props.match.params.id)
    }
  }

  handleChange(type) {
    this.setState({ type })
  }

  render() {
    const { domain } = this.props

    if (!domain) {
      return <Loading loading />
    }

    const choices = [
      { label: 'Email', value: 'email' },
      { label: 'HTTP', value: 'http' },
      { label: 'DNS', value: 'dns' },
    ]

    if (this.props.isStaff) {
      choices.push({ label: 'STAFF', value: 'staff' })
    }

    return (
      <Card>
        <div className="card-content">
          <form className="form">
            <RadioField
              title="Que tipo de validação você deseja utilizar?"
              choices={choices}
              name="type"
              onChange={this.handleChange}
              checked={this.state.type}
            />

            {this.state.type === 'email' &&
              <EmailValidation />
            }

            {this.state.type === 'http' &&
              <HttpValidation />
            }

            {this.state.type === 'dns' &&
              <DnsValidation />
            }

            {this.state.type === 'staff' &&
              <StaffValidation />
            }
          </form>
        </div>
      </Card>
    )
  }
}

ValidationChoices.propTypes = {
  params: PropTypes.object,
  fetchDomain: PropTypes.func,
  domain: PropTypes.object,
  setBreadcrumbName: PropTypes.func,
  isStaff: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    isStaff: get(state, 'auth.data.role', false) === 'admin',
    domain: state.domainValidation.domain,
    validating: state.domainValidation.validating,
    validated: state.domainValidation.validated,
    isLoading: state.domainValidation.isLoading,
  }
}

export { ValidationChoices }
export default connect(mapStateToProps, domainValidationActions)(ValidationChoices)
