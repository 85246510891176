import { fetch } from '../services/fetch';
import { toParams } from '../services/utils';
import { push } from 'connected-react-router';
import { setMessage, SUCCESS } from '../modules/messages';

export const FETCH_ALL = 'families/FETCH_ALL';
export const FETCH = 'families/FETCH';
export const SAVE = 'families/SAVE';
export const CREATE = 'families/CREATE';

export function fetchFamilies(accountId, params = {}) {
    const query = toParams(params);
    const request = fetch(`/vulnerability-families${query}`)
    .then(res => res.json());

    return {
        type: FETCH_ALL,
        payload: request,
    };
}

export function fetchFamily(id) {
    const request = fetch(`/vulnerability-families/${id}`)
    .then(res => res.json());

    return {
        type: FETCH,
        payload: request,
    };
}

export function saveFamily(id, data) {
    return dispatch => {
        const request = fetch(`/vulnerability-families/${id}`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => {
            dispatch(push('/system/families'));
            dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'));
            return res.json();
        });

        return dispatch({
            type: SAVE,
            payload: request,
        });
    };
}

export const createFamily = (data) => (
    (dispatch) => {
        const request = fetch('/vulnerability-families', { method: 'POST', body: JSON.stringify(data) })
        .then(res => {
            dispatch(push('/system/families'));
            dispatch(setMessage(SUCCESS, 'Família criada com sucesso!'));
            return res.json();
        })
        .catch(res => res);

        return dispatch({
            type: CREATE,
            payload: request,
        });
    }
);
