import { css } from 'styled-components';

import { $lightgrey600 } from '../settings/variables';

export default css`
    .domains-box {
        position: relative;
    }

    .domains-list {
        border: .1rem solid ${$lightgrey600};
        padding: 0 1rem;
        position: relative;
    }

    .domains-list > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
    }

    .domains-list > li > span:not([disabled]) {
        cursor: pointer;
    }

    .domains-list > li:first-child {
        color: #A0A0A0;
    }

    .domains-list > li > a {
        display: flex;
    }

    .domains-list > li i {
        font-size: 2rem;
    }
`;
