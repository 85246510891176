import { css } from 'styled-components';

export default css`
    .right {
        float: right !important;
    }

    .vertical-align {
        display: flex !important;
    }

    .nowrap {
        white-space: nowrap !important;
    }
`;
